import {
  CREATE_EVENT,
  READ_EVENTS,
  UPDATE_EVENT,
  DELETE_EVENT,
  EventsActionTypes,
  IEventsState,
} from '../types'

const initialState: IEventsState = {
  events: [],
}

const events = (state = initialState, action: EventsActionTypes) => {
  switch (action.type) {
    case CREATE_EVENT:
      return {
        events: [...state.events, action.event]
      }
    case READ_EVENTS:
      return {
        events: action.events
      }
    case UPDATE_EVENT: {
      const events = [...state.events]
      const eIndex = events.findIndex(e => e.id === action.event?.id)
      if (eIndex > -1) {
        events.splice(eIndex, 1, action.event)
      } else throw('No event found to update')
      return { events }
    }
    case DELETE_EVENT: {
      const events = [...state.events]
      const eIndex = events.findIndex(e => e.id === action.eventId)
      if (eIndex > -1) {
        events.splice(eIndex, 1)
      } else throw('No event found to delete')
      return { events }
    }
    default:
      return state;
  }
};

export default events;
