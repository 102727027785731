import { useFocusEffect } from '@react-navigation/core'
import React, { useCallback, useEffect, useRef } from 'react'
import {Platform, StyleSheet, View} from 'react-native'
import {TextInput} from 'react-native-gesture-handler'
import Icon from './IconWrapper'
import {css} from '../styles'
import {iconsMapper} from '../types'

type Props = {
  placeholder?: string
  navigateWithString?: Function
  searchQuery?: string
  search?: Function
}

export function SearchInput ({placeholder, navigateWithString, searchQuery, search}: Props) {
  const setCursorToTheEnd = () => {
    let maxSearchLength = searchQuery ? Math.max(value.length, searchQuery.length) : value.length
    maxSearchLength = maxSearchLength === NaN ? 0 : maxSearchLength
    // on focus go to the end of search query, this is needed when searching from home and moving to patients list
    setSelection({start: maxSearchLength, end: maxSearchLength})
  }
  const [value, setValue] = React.useState(searchQuery ? searchQuery : '');
  const [selection, setSelection] = React.useState({start:0, end: 0})
  const inputRef = useRef(null)
  const handleSelectionChange = (e: any) => {
    setSelection(e.nativeEvent.selection)
  }

  useFocusEffect(useCallback(() => {
    setValue(searchQuery ? searchQuery : '')
    if (inputRef.current && !!searchQuery) {
      setCursorToTheEnd()
      // @ts-ignore
      inputRef.current.focus()
    }
  }, [searchQuery]))

  useEffect(() => {
    setCursorToTheEnd()
  }, [searchQuery])

  return (
    <View style={{position: 'relative', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
      <TextInput
        style={styles.input}
        placeholder={placeholder ? placeholder : 'Search'}
        placeholderTextColor={css.colorText + '50'}
        onChangeText={text => {
          setValue(text)
          if (navigateWithString) navigateWithString(text)
          else if (search) search(text)
        }}
        onSelectionChange={handleSelectionChange}
        selection={selection}
        value={value}
        ref={inputRef}
      />
      <Icon
        name={iconsMapper.search}
        size={20}
        color={css.colorTextLight}
        style={{
          position: 'absolute',
          // paddingTop: css.padding / 1.7,
          paddingLeft: css.padding,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
    backgroundColor: css.colorDarkBg,
    color: css.colorTextLight,
    padding: css.padding / 3,
    paddingLeft: css.padding * 3,
    paddingRight: css.padding,
    borderRadius: css.borderRadius * 3,
    height: Platform.OS === 'android' ? css.android.minRequiredInputHeight : css.ios.minRequiredInputHeight,
  },
})
