import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { useNavigation } from '@react-navigation/core'
import React, { useEffect, useState } from 'react'
import {Dimensions, ImageURISource, StyleSheet, View} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import {css, eventStatusColor} from '../styles'
import { EventStatus, IClinic, IComment, IDiagnosis, IEvent, IPatient, ISpecialist, IUser, UserType } from '../types'
import { fullDateTime } from '../utils'
import { ButtonRounded } from './ButtonRounded'
import { Hr } from './Hr'
import { LocalizationIcon } from './LocalizationIcon'
import { TextCodeItem } from './TextCodeItem'
import { TextItem } from './TextItem'
import { ButtonCall } from './ButtonCall'
import { ViewRow } from './ViewRow'
import { getImageUriForDiagnosis } from '../utils'
import { ButtonEmail } from './ButtonEmail'
import { ChatListItemLast } from './ChatListItemLast'
import ImageWithPreview from './ImageWithPreview'
import { EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, HOME_ROUTE_NAME, StackParams } from '../types'

type Props = {
  event: IEvent
  userByUid: (id?: string) => ISpecialist | IPatient | undefined
  diagnosesByIds: (ids: string[]) => IDiagnosis[]
  clinicById: (id?: string) => IClinic | undefined
  deleteConsultation?: Function
}

type NavigationProps = BottomTabNavigationProp<StackParams, typeof EVENTS_ROUTE_NAME>

export function HomeChatBlock ({event, deleteConsultation, diagnosesByIds, userByUid, clinicById}: Props) {
  const showPictures = Dimensions.get('window').height > 700
	const { navigate } = useNavigation<NavigationProps>()
  const [images, setImages] = useState<JSX.Element[]>([])
  const [lastMessage, setLastMessage] = useState<IComment | undefined>()
  const [lastMessageColor, setLastMessageColor] = useState(css.colorDarkBgLighten3)
  const [clinic, setClinic] = useState<IClinic | undefined>()
  const mainContacts = clinicById('kauno_klinikos')
  const [user, setUser] = useState<IUser | undefined>()

  useEffect(() => {
    let i: JSX.Element[] = []
    const maxLength = 3
    const diagnoses = diagnosesByIds(event.diagnosesIds)
    for (const d of diagnoses) {
      const urls = d.imageResult.originalImageUrl
      if (urls.length > 0) {
        let imgSrc: ImageURISource | undefined = undefined
        imgSrc = getImageUriForDiagnosis(d)
        const isLastItem = i.length + 1 === maxLength || i.length + 1 === diagnoses.length
        i.push(<View key={`active_event_image_${i}`} style={[styles.thumbnail, isLastItem ? styles.thumbnailLast : undefined]}>
          {imgSrc &&
            <ImageWithPreview key={`active_event_image_preview${i}`} source={imgSrc.uri ? imgSrc.uri : imgSrc} style={styles.maxWidthHeight} />
          }
        </View>)
        if (i.length >= 3) break
      }
    }
    setImages(i)
    const sComments = event.comments // .filter(c => auth.user?.type === UserType.patient ? c.user?.type === UserType.specialist || c.clinicId : c.user?.type === UserType.patient)
    setLastMessage(sComments.length > 0 ? sComments[sComments.length - 1] : undefined)
    setLastMessageColor(eventStatusColor[event.status ?? 'pending'])
  }, [event])
  useEffect(() => {
    setClinic(clinicById(lastMessage?.clinicId))
    setUser(userByUid(lastMessage?.user?.uid))
  }, [lastMessage])
  return (
    <ScrollView
      style={styles.activeConsultation}
      contentContainerStyle={{flexGrow: 1, paddingBottom: css.padding*3}}
    >
      <ViewRow style={{alignItems: 'center'}}>
        <View style={{flexGrow: 1}}>
          <TextItem style={[styles.headerText, {textTransform: 'capitalize'}]}>{event.type}</TextItem>
          <TextItem style={{color: css.colorText}}>{fullDateTime(event.createdAt)}</TextItem>
        </View>
        <TextCodeItem style={{ padding: css.padding/4, marginRight:css.padding/2, fontSize: 14 }}>{event.id}</TextCodeItem>
        <LocalizationIcon />
      </ViewRow>
      {showPictures && images.length > 0 &&
        <>
          <Hr />
          <ViewRow>
            {/* <View style={styles.thumbnail}></View>
            <View style={styles.thumbnail}></View>
            <View style={[styles.thumbnail, styles.thumbnailLast]}></View> */}
            {images}
          </ViewRow>
        </>
      }
      <Hr />
      {/* Block on proceeding the data */}
      {(event.status === EventStatus.pendingReview || event?.comments?.find(c => c.user?.type === UserType.specialist || c.clinicId) === undefined) &&
        <ViewRow style={styles.proceeding}>
          <View style={{flexShrink: 1}}>
            <TextItem style={styles.proceedingTitle}>
              It could take several working days to process the data
            </TextItem>
            <TextItem style={styles.proceedingContent}>
              If you have any questions or experiencing a delay, please contact us by call or by sending a message
            </TextItem>
          </View>
          {(mainContacts?.address.phone || mainContacts?.address.email) &&
            <View style={{justifyContent: 'center'}}>
              {(mainContacts.departmentAddress.phone || mainContacts.address.phone) &&
                <>
                  <ButtonCall small={true} phone={mainContacts?.departmentAddress.phone ?? mainContacts.address.phone} />
                  <View style={{paddingBottom: css.padding/2}} />
                </>
              }
              {(mainContacts.departmentAddress.email || mainContacts.address.email)&&
                <ButtonEmail small={true} email={mainContacts.departmentAddress.email ?? mainContacts.address.email} />
              }
            </View>
          }
        </ViewRow>
      }
      {event.status !== EventStatus.pendingReview && lastMessage &&
        <ChatListItemLast clinic={clinic} user={user} comment={lastMessage} backgroundColor={lastMessageColor} iconBackgroundColor={lastMessageColor === css.colorError ? css.colorInfo : css.colorError} />
      }
      <Hr />
      <ViewRow style={{flex: 100}}>
        {event.type === 'consultation' &&
          <ButtonRounded title="Delete" borderColor={css.colorError} width='45%' onPress={() => deleteConsultation?.()} />
        }
        <ButtonRounded title="See event" width={event.type === 'consultation' ? '45%' : '95%'} onPress={() => {
          navigate(HOME_ROUTE_NAME)
          setTimeout(() => {
            navigate(EVENT_ROUTE_NAME, {eventId: event.id})
          }, 50);
        }} />
      </ViewRow>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  activeConsultation: {
    borderRadius: css.borderRadius,
    backgroundColor: css.colorDarkBgLighten2,
    padding: css.padding * 1.5,
    marginBottom: css.padding/2,
    flexGrow: 1,
  },
  headerText: {
    flexGrow: 1,
    fontFamily: css.fontFamily600,
    fontSize: 18
  },
  thumbnail: {
    overflow: 'hidden',
    borderRadius: css.borderRadius/2,
    // borderWidth: 1,
    borderColor: css.colorPrimary + '70',
    height: 100,
    flexGrow: 1,
    marginRight: css.padding/2
  },
  thumbnailLast: {
    marginRight: 0,
  },
  // PROCEEDINGS
  proceeding: {
    padding: css.padding * 1.5,
    backgroundColor: css.colorDarkBgLighten3,
    borderRadius: css.padding/2,
  },
  proceedingTitle: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: css.fontFamily600,
  },
  proceedingContent: {
    alignSelf: 'center',
    fontSize: 12,
    textAlign: 'center',
    color: css.colorText,
    padding: css.padding/2,
  },
  proceedingIcon: {
    marginRight: 0,
    marginLeft: css.padding,
  },
  proceedingIconPhone: {
    marginBottom: css.padding/2,
  },
  maxWidthHeight: {
    width : '100%',
    height: '100%',
  }
})