import React, {useContext} from 'react'
import {ButtonRounded} from './ButtonRounded'
import { AppContext } from '../contexts'
import { css } from '../styles'
import { iconsMapper, IPatient } from '../types'
import { Platform, StyleProp, ViewStyle } from 'react-native'
import { IconOutlined } from './IconOutlined'
import { get } from 'idb-keyval'

type Props = {
  buttonTitle: string
  images: any[]
  anonymous?: true
  message?: string
  patient?: Partial<IPatient>
  isIcon?: boolean
  style?: StyleProp<ViewStyle>
}

export function ShareButton ({images, buttonTitle, anonymous, message, patient, isIcon, style}: Props) {
  const appContext = useContext(AppContext)
  const finishSharing = async () =>{
    appContext?.dispatch({...appContext?.state, openWaitOverlay: false})
  }
  const shareSave = async () => {
    appContext?.dispatch({...appContext?.state, openWaitOverlay: true})
    try {
      let i = 1
      for await (const image of images) {
        const img = await get(image)
        console.log(img)
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(img);
        a.download = `image_${i}.jpg`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        i++
      } 
      finishSharing()
    } catch (e) {
      console.log('Error', e)
      finishSharing()
    }
  }
  return <>
    {isIcon && 
      <IconOutlined
        onPress={shareSave}
        iconName={iconsMapper.share}
        style={[css.iconOutlinedMin, {marginRight: 0}]}
      />
    }
    {!isIcon && <ButtonRounded skin={Platform.OS === 'web' ? "light" : undefined} title={buttonTitle} onPress={shareSave} style={style} />}
  </>
}

