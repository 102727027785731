import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Icon from './IconWrapper'
import { css } from '../styles'
import { iconsMapper, Item } from '../types'
import { TextItem } from './TextItem'
import Moment from 'moment'
import { ViewRow } from './ViewRow'
import { ModalView } from './ModalView'
import { ButtonRounded } from './ButtonRounded'
import { useEffect } from 'react'
import { SelectPicker } from './SelectPicker'

type Props = {
	date?: Date
	setDate: (date?: Date) => void
}

export function CalendarPicker ({ date, setDate }: Props) {
	const [showDatePicker, setShowDatePicker] = useState(false)
	const [tempDate, setTempDate] = useState(date ?? new Date())
	const getDaysCount = () => {
		const year = tempDate.getFullYear()
		const month = tempDate.getMonth()
		return new Date(year, month, 0).getDate();
	}
	const getDayItems: () => Item[] = () => {
		
		return [...Array(getDaysCount())].map((item, index) => {
			const day = index + 1
			return {
				label: day + '',
				value: day + '',
			}
		})
	}
	const [dayItems, setDayItems] = useState(getDayItems())
	const yearItems: Item[] = [0,1,2].map(item => {
		const year = new Date().getFullYear() - item
		return {
			label: year + '',
			value: year + '',
		}
	})

	const monthItems: Item[] = [...Array(12)].map((item, index) => {
    const month = (new Date(`${index+1}/1/2000`)).toLocaleString('en-En', { month: "long" });
		return {
			label: month + '',
			value: index + '',
		}
	})

	useEffect(() => {
		setTempDate(date ?? new Date())
	}, [date])
	useEffect(() => {
		if (dayItems.length !== getDaysCount()) {
			setDayItems(getDayItems())
		}
	}, [tempDate])

	return (
		<View style={{flexDirection: 'column'}}>
			<TouchableOpacity
				style={[styles.touchable]}
				onPress={() => {
					setShowDatePicker(!showDatePicker)
				}}
			>
				<ViewRow style={[styles.calendarPicker]}>
					<Icon
						name={iconsMapper.calendar}
						size={20}
						color={css.colorTextLight}
						style={styles.icon}
					/>
					<TextItem style={date ? styles.date : styles.select}>
						{date ? Moment(date).format('DD/MM/YYYY') : 'Select date'}
					</TextItem>
				</ViewRow>
			</TouchableOpacity>
			<ModalView
				show={showDatePicker}
				onClose={() => {
					setTempDate(date ?? new Date())
					setShowDatePicker(false)
				}}
				title='Select date'
				subtitle="Click 'x' otherwise"
				iconName={iconsMapper.calendar}
			>
				<View style={styles.datePickerModal}>
					<ViewRow style={{zIndex:1}}>
						<SelectPicker
							key="date-picker"
							wrapperStyle={{minWidth:50, flexShrink: 1, marginRight: css.padding}}
							selected={tempDate.getDate() + ''}
							setSelected={(val: string) => {
								let date = tempDate
								date.setDate(parseInt(val))
								setTempDate(date)
							}}
							options={dayItems}
						/>
						<SelectPicker
							key="month-picker"
							wrapperStyle={{flexGrow:1, minWidth:120, marginRight: css.padding}}
							selected={tempDate.getMonth() + ''}
							setSelected={(val: string) => {
								let date = tempDate
								date.setMonth(parseInt(val))
								setTempDate(date)
							}}
							options={monthItems}
						/>
						<SelectPicker
							key="year-picker"
							wrapperStyle={{minWidth:75}}
							selected={tempDate.getFullYear() + ''}
							setSelected={(val: string) => {
								let date = tempDate
								date.setFullYear(parseInt(val))
								setTempDate(date)
							}}
							options={yearItems}
						/>
					</ViewRow>
					<ButtonRounded
						title='Pick date'
						width={280}
						onPress={() => {
							setDate(tempDate)
							setShowDatePicker(false)
						}}
						style={styles.datePickerModalButton}
					/>
					<ButtonRounded
						title='Delete date'
						width={280}
						onPress={() => {
							setDate()
							setTempDate(new Date())
							setShowDatePicker(false)
						}}
						style={styles.datePickerModalButton}
					/>
				</View>
			</ModalView>
		</View>
	)
}

const styles = StyleSheet.create({
	touchable: {
		flexGrow: 1,
		flexDirection: 'row',
	},
	calendarPicker: {
		height: 38,
		borderRadius: 16,
		backgroundColor: css.colorDarkBg,
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexGrow: 1,
		paddingLeft: css.padding,
		paddingRight: css.padding
	},
	icon: {
		color: css.colorTextLight,
		paddingRight: css.padding / 1.5
	},
	date: {},
	select: {
		color: css.colorTextLight + '50'
	},
	datePickerModal: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	datePickerModalButton: {
		marginTop: css.padding
	}
})
