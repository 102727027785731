import React, { useContext } from 'react'
import {StyleSheet, View, ActivityIndicator} from 'react-native'
import { css } from '../styles'
import { TextItem } from './TextItem'
import { AppContext } from '../contexts/AppContext'

export function WaitOverlay () {
  const appContext = useContext(AppContext)
  const styles = StyleSheet.create({
    waitOverlay: {
      top: 0,
      left: 0,
      zIndex: 10000,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: css.colorAppBg + 'dd',
    },
    text: {
      marginTop: css.padding/2,
      zIndex: 100001,
      color: css.colorPrimary
    },
    icon: {
      paddingTop: 15,
      paddingLeft: 2,
      paddingBottom: css.padding,
      color: css.colorTextLight + '98',
    },
    container: {
      top: '50%',
      // left: 0,
      marginTop: -55,
      borderRadius: css.borderRadius,
      width: 150,
      height: 120,
      backgroundColor: css.colorComponentBg,
      position: 'absolute'
    }
  })
  return <>
    {appContext?.state.openWaitOverlay &&
      <View style={styles.waitOverlay}>
          <View style={styles.container} />
          <ActivityIndicator color={css.colorPrimary} style={{marginTop: css.padding}} />
          <TextItem style={styles.text}>Loading...</TextItem>
      </View>
    }
  </>
}
