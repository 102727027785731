import React, { useEffect, useState, useContext } from 'react'
import { Platform, StyleProp, StyleSheet, TextStyle, View } from 'react-native'
import { css } from '../styles'
import { EventStatus, IDiagnosis, IDiagnosisAiResult, IEvent } from '../types'
import { ViewRow } from '../components/ViewRow'
import { TextItem } from './TextItem'
import { LocalizationIcon } from './LocalizationIcon'
import moment from 'moment'
import { AppContext, Theme } from '../contexts'
import { findDiagnosisByMaxProbability } from '../utils'

type Props = {
  active?: boolean
  event?: IEvent
  diagnoses: IDiagnosis[]
  setActiveEvent?: Function
  isLast?: boolean
}

export function PatientEventsListItem ({active, event, diagnoses, setActiveEvent, isLast}: Props) {
  const appContext = useContext(AppContext)
  const [skin, setSkin] = useState<Theme>('dark')
  useEffect(() => {
    if (appContext?.state.skin && skin !== appContext?.state.skin) {
      setSkin(appContext?.state.skin)
    }
  }, [appContext?.state.skin])
  const colorByProbability = (aiResult: IDiagnosisAiResult) => {
    const code = aiResult.icdCode
    const probability = aiResult.probability
    if (code === 'd22') return {borderColor: css.colorPrimary, color: css.colorPrimary }
    if (probability < 20) return {borderColor: css.colorSuccess, color: css.colorSuccess }
    if (probability < 50) return {borderColor: css.colorWarning, color: css.colorWarning }
    return {borderColor: css.colorError, color: css.colorError }
  }
  const wrapContent: StyleProp<TextStyle> = Platform.OS === 'web' ? {width:'min-content', flex: 1, flexWrap: 'wrap'} : {}
  const [messageBlock, setMessageBlock] = useState<JSX.Element | undefined>()
  const eventMessage = () => {
    if (event?.status === EventStatus.canceled) return <TextItem style={{color: css.colorError}}>{event.type + ' canceled'}</TextItem>
    if (event?.status === EventStatus.needsReply) return <TextItem style={{color: css.colorInfo}}>{event.type + ' needs reply'}</TextItem>
    if (event?.status === EventStatus.needsVisit) return <TextItem style={{color: css.colorWarning}}>{'patient will arrange visit'}</TextItem>
    if (event?.status === EventStatus.pending) return <TextItem style={{color: skin === 'light' ? css.colorTextDark : css.colorTextLight}}>{'Waiting for a visit'}</TextItem>
    if (event?.status === EventStatus.pendingReview) return <TextItem style={{color: css.colorWarning}}>{'review is needed'}</TextItem>
    let issues: JSX.Element[] = []
    let issuesCount = 0
    for (const d of diagnoses) {
      if (d.customDiagnosis) {
        issues.push(<TextItem key={d.id + d.customDiagnosis} numberOfLines={Platform.OS !== 'web' ? 1 : undefined} style={{color: Platform.OS !== 'web' ? css.colorTextLight: css.colorText}}>{d.customDiagnosis}</TextItem>)
        break
      } else if (d.aiResults && d.aiResults.length > 0) {
        const aiResult = findDiagnosisByMaxProbability(d.aiResults)
        issuesCount++
        if (issuesCount > 2) break
        if (aiResult) {
          issues.push(<TextItem key={aiResult.className+aiResult.probability} style={[styles.eventDiagnosisCode, colorByProbability(aiResult)]}>{aiResult.icdCode.toUpperCase()}</TextItem>)
        }
      }
    }
    if(issues.length > 0) return <ViewRow style={{justifyContent: 'flex-start'}}>{issues}</ViewRow>
    // if (event?.status === EventStatus.completed)
    return <TextItem style={{color: css.colorText}}>no diagnosis found</TextItem>
    // return <TextItem style={{color: css.colorText}}>no issues found</TextItem>
  }
  useEffect(() => {
    setMessageBlock(eventMessage())
  }, [diagnoses, event])
  const diagnosisCodeVPadding = 7
  const diagnosisCodeHPadding = 10
  const styles = StyleSheet.create({
    listItem: {
      width: '100%',
      // marginBottom: css.padding / 2,
      height: 48,
    },
    itemDash: {
      borderTopColor: css.colorPrimary + '50',
      // TODO: find out why 'dashed' is not working!!!
      // it is not working because it is working only on full rectangle but not on one side :(
      // borderStyle: 'dashed',
      borderRadius: 1,
      borderTopWidth: 1,
      width: css.padding * 3/2,
      height: 1,
      alignSelf: 'center',
    },
    itemV1Dash: {
      borderLeftColor: css.colorPrimary + '50',
      borderTopWidth: 0,
      borderLeftWidth: 1,
      width: 1,
      position: 'absolute'
    },
    itemBlock: {
      padding: css.padding/2,
      flexGrow: 1,
      borderRadius: css.borderRadius / 2 ,
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    itemBlockActive: {
      backgroundColor: skin === 'light' ? css.colorDarkBgLighten3 + '10' : css.colorDarkBgLighten3,
      borderRadius: css.borderRadius/3,
    },
    itemText: {
      lineHeight: 14,
      fontFamily: css.fontFamily600,
    },
    eventDiagnosisCode: {
      borderRadius: css.borderRadius/3,
      // borderColor: css.colorSuccess,
      // color: css.colorSuccess,
      paddingTop: diagnosisCodeVPadding,
      paddingBottom: diagnosisCodeVPadding,
      paddingLeft: diagnosisCodeHPadding,
      paddingRight: diagnosisCodeHPadding,
      borderWidth: 1,
      marginRight: css.padding/2
    }
  })
  return (
    <View style={styles.listItem} onStartShouldSetResponder={(e) => true} onResponderRelease={() => {
      if (setActiveEvent) setActiveEvent()
    }}>
      <ViewRow style={{flex: 1}}>
        <View style={styles.itemDash} />
        <View style={[styles.itemDash, styles.itemV1Dash, isLast ? {height:  '50%', top: 0} : {height: '100%'}]} />
        {/* {!isLast &&
          <View style={[styles.itemDash, styles.itemV1Dash, {top:'50%'}]} />
        } */}
        <ViewRow style={[styles.itemBlock, active ? styles.itemBlockActive : undefined]}>
          {event?.createdAt &&
            <View style={{width: 55}}>
              <TextItem style={[styles.itemText, { color: skin === 'light' ? css.colorTextDark + '90' : css.colorTextLight}]}>{moment(event.createdAt).format('DD MMM')}</TextItem>
              <TextItem style={[styles.itemText, {color: css.colorText}]}>{moment(event.createdAt).format('yyyy')}</TextItem>
            </View>
          }
          <LocalizationIcon />
          <View style={wrapContent}>
            {messageBlock}
          </View>
        </ViewRow>
      </ViewRow>
    </View>
  )
}

