import React from 'react'
import { Alert, Platform } from 'react-native';

type Props = {
  children?: JSX.Element | JSX.Element[]
  hasUnsavedChanges?: boolean
  navigation: any
  onSavePressed?: Function
}

export function PreventNavOnDataChange ({children, hasUnsavedChanges, navigation, onSavePressed}: Props) {

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e: any) => {
        const saveMessage = 'You have unsaved changes. Are you sure to discard them and leave the screen?'
        if (!hasUnsavedChanges) {
          return;
        }
        e.preventDefault();
        if (Platform.OS !== 'web') {
          Alert.alert(
            'Discard changes?',
            saveMessage,
            [
              { text: "Save", style: 'default', onPress: () => onSavePressed?.() },
              { text: "Don't leave", style: 'cancel', onPress: () => {} },
              {
                text: 'Discard',
                style: 'destructive',
                onPress: () => navigation.dispatch(e.data.action),
              },
            ]
          )
        } else {
          if (confirm('Are you sure to discard unsaved changes and leave the screen? Otherwise press Cancel on this dialog view and then press save icon or Save button to save your work.')) {
            navigation.dispatch(e.data.action)
          } else {
          }
        }
      }),
    [navigation, hasUnsavedChanges]
  );
  return <>
    {children}
  </>
}

