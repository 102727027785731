import React, {useContext} from 'react'
import {StackNavigationOptions} from '@react-navigation/stack'
import { DrawerActions, CommonActions } from '@react-navigation/routers'
import {NavigationContainer,useLinkBuilder,} from '@react-navigation/native'
import { createDrawerNavigator, DrawerNavigationOptions, DrawerContentOptions, DrawerItem } from '@react-navigation/drawer'
import {css} from '../styles'
import {Image, View} from 'react-native'
import Icon from '../components/IconWrapper'
import { TextItem } from '../components/TextItem'
import { AppContext } from '../contexts'
import { checkIsEventChanged, createEventFormValues } from '../utils'
import { EventsStackScreen, EventStackScreen, HomeStackScreen, IHeader, PatientsListStackScreen, StackNavigationProp, StackRouteProp, TestStackScreen } from './indexHelper'
import {CREATE_EVENT_ROUTE_NAME, EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, HOME_ROUTE_NAME, iconsMapper, IUser, PATIENTS_ROUTE_NAME, PATIENT_ROUTE_NAME, SETTINGS_ROUTE_NAME, TEST_ROUTE_NAME, StackParams} from '../types'
import CustomStackNavigatorWeb from './CustomStackNavigator.web'

const topNavScreenOptions: ({
  route,
  navigation,
}: {
  route: StackRouteProp
  navigation: StackNavigationProp
}) => StackNavigationOptions = ({route, navigation}) => {
  let header: IHeader = {}
  let title = 'SVS'
  if (route.name === HOME_ROUTE_NAME) {
    title += ' - Home'
  } else if (route.name === EVENTS_ROUTE_NAME) {
    title += ' - Events'
  } else if (route.name === CREATE_EVENT_ROUTE_NAME || route.name === EVENT_ROUTE_NAME) {
    const t = (route.params as StackParams[typeof CREATE_EVENT_ROUTE_NAME])?.title
    const eventId = (route.params as StackParams[typeof CREATE_EVENT_ROUTE_NAME])?.eventId
    if (eventId) {
      title += (t ? ' - ' + t : ' - Event') + (eventId ? ' - ' + eventId : '')
    } else {
      title += ' - Create Event'
    }
  } else if (route.name === PATIENTS_ROUTE_NAME) {
    const eventId = (route.params as StackParams[typeof PATIENTS_ROUTE_NAME])?.eventId
    title += ' - ' + (eventId ? 'Event: ' + eventId.toUpperCase() : 'Patients')
  } else if (route.name === PATIENT_ROUTE_NAME) {
    const id = (route.params as StackParams[typeof PATIENT_ROUTE_NAME])?.patientId ?? ''
    title += id ? ' - Patient - ' + id : ''
  } else if (route.name === SETTINGS_ROUTE_NAME) {
    title += ' - User Settings'
  } else if (route.name === TEST_ROUTE_NAME) {
    title += ' - Test Page'
  }
  return { title }
}

const Stack = CustomStackNavigatorWeb()
const Drawer = createDrawerNavigator<StackParams>()

const drawerScreenOptions: ({
  route,
}: {
  route: {name: string}
}) => DrawerNavigationOptions = ({route}) => ({
  drawerIcon: ({focused, color}) => {
    let iconName = 'home'
    if (route.name === HOME_ROUTE_NAME) iconName = iconsMapper.home
    else if (route.name === EVENTS_ROUTE_NAME) iconName = iconsMapper.visits
    else if (route.name === PATIENTS_ROUTE_NAME) iconName = iconsMapper.patients
    else if (route.name === TEST_ROUTE_NAME) iconName = iconsMapper.help
    else if (route.name === CREATE_EVENT_ROUTE_NAME || route.name === EVENT_ROUTE_NAME) iconName = iconsMapper.visit
    return (
      <View style={{alignItems: 'center'}}>
        <Icon name={iconName} color={color} size={25} style={{marginRight: -20}} />
      </View>
    )
  },
  drawerLabel: ({focused, color}) => {
    let routeName = route.name
    if (route.name === CREATE_EVENT_ROUTE_NAME) routeName = 'New event'
    if (route.name === PATIENTS_ROUTE_NAME) routeName = 'Patients'
    return (
      <TextItem style={{fontWeight: '500', color}}>
        {routeName}
      </TextItem>
    )
  },
})

const drawerOptions: DrawerContentOptions = {
  style: {
    backgroundColor: css.colorAppBg,
    borderRightWidth: 0,
  },
  inactiveTintColor: css.colorTextLight + '70',
  activeTintColor: css.colorTextLight, // css.colorPrimary,
  activeBackgroundColor: 'transparent',
}

export function MainNavigation (props: {user: IUser, logOutUser?: Function}) {
  const buildLink = useLinkBuilder();
  const appContext = useContext(AppContext)
  const [initialRouteName, setInitialRouteName] = React.useState<keyof StackParams>(HOME_ROUTE_NAME)
  const linking = {
    prefixes: [],
    config: {
      screens: {
        [HOME_ROUTE_NAME]: '',
        [EVENTS_ROUTE_NAME]: {
          screens: {
            [EVENTS_ROUTE_NAME]: 'events',
            [EVENT_ROUTE_NAME]: {
              path: 'events/event',
              parse: {
                date: (date: string) => `${date}`
              }
            },
          }
        },
        [CREATE_EVENT_ROUTE_NAME]: {
          screens: {
            [CREATE_EVENT_ROUTE_NAME]: 'create-event'
          }
        },
        [PATIENT_ROUTE_NAME]: {
          screens: {
            [PATIENT_ROUTE_NAME]: 'patient'
          }
        },
        [PATIENTS_ROUTE_NAME]: {
          screens: {
            [PATIENTS_ROUTE_NAME]: 'patients',
            [PATIENT_ROUTE_NAME]: {
              path: 'patients/patient',
              parse: {
                
              }
            }
          }
        },
        // [PATIENTS_ROUTE_NAME]: 'patient-item',
        [SETTINGS_ROUTE_NAME]: 'profile',
      }
    }
  }

  return (<>
    <NavigationContainer linking={linking}>
      <Drawer.Navigator
        drawerType="permanent"
        screenOptions={drawerScreenOptions}
        drawerContent={p => <View style={{backgroundColor: css.colorSidebarMenu, flexGrow: 1}}>
          <View
            style={{
              backgroundColor: css.colorSidebarMenu,
              height: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image source={require('../assets/picts/logo-web-menu.png')?.default} style={{height: 40, width: 150}} />
          </View>
          {p.state.routes.map((route, i) => {
          const focused = i === p.state.index;
          const isChanged = route.name === CREATE_EVENT_ROUTE_NAME && checkIsEventChanged(createEventFormValues, appContext?.state.createEventForm ?? createEventFormValues)
          const {
            title,
            drawerLabel,
            drawerIcon,
          } = p.descriptors[route.key].options;
          const wh = 35
          return (
            <View key={route.key}>
              {route.name === CREATE_EVENT_ROUTE_NAME &&
              <View style={{
                ...(isChanged ? {
                  backgroundColor: css.colorSuccess,
                  height: wh,
                  width: wh,
                  borderRadius: wh,
                  position: 'absolute',
                  left: css.padding*3 - wh/2,
                  top: css.padding*1.7 - wh/2,
                } : {
                }),
              }} />}
              <DrawerItem
                label={
                  drawerLabel !== undefined
                    ? drawerLabel
                    : title !== undefined
                    ? title
                    : route.name
                }
                icon={i => drawerIcon?.({
                  ...i,
                  color: isChanged ? css.colorTextLight : i.color
                })}
                focused={focused}
                activeTintColor={css.colorTextLight}
                inactiveTintColor={css.colorText}
                activeBackgroundColor="transparent"
                inactiveBackgroundColor="transparent"
                labelStyle={{}}
                style={{paddingLeft: css.padding}}
                to={buildLink(route.name, route.params)}
                onPress={() => {
                  p.navigation.dispatch({
                    ...(focused
                      ? DrawerActions.closeDrawer()
                      : CommonActions.navigate(route.name, route.params)),
                    target: p.state.key,
                  });
                }}
              />
            </View>
          );
        }) as React.ReactNode as React.ReactElement}
      </View>}
        drawerContentOptions={drawerOptions}
        initialRouteName={initialRouteName}
        drawerStyle={{
          width: 200,
          borderRightWidth: 0,
          position: 'relative',
        }}
      >
        <Drawer.Screen name={HOME_ROUTE_NAME}>
          {(props) => <HomeStackScreen Stack={Stack} topNavScreenOptions={topNavScreenOptions(props)} />}
        </Drawer.Screen>
        <Drawer.Screen name={CREATE_EVENT_ROUTE_NAME}>
          {(props) => <EventStackScreen Stack={Stack} topNavScreenOptions={topNavScreenOptions(props)} />}
        </Drawer.Screen>
        <Drawer.Screen name={EVENTS_ROUTE_NAME}
          // options={{unmountOnBlur: true}}
        >
          {(props) => <EventsStackScreen Stack={Stack} topNavScreenOptions={topNavScreenOptions(props)} />}
        </Drawer.Screen>
        {props?.user?.type === 'specialist' &&
          <Drawer.Screen name={PATIENTS_ROUTE_NAME}>
            {(props) => <PatientsListStackScreen Stack={Stack} topNavScreenOptions={topNavScreenOptions(props)} />}
          </Drawer.Screen>
        }
        {props?.user?.type === 'patient' &&
          <Drawer.Screen name={TEST_ROUTE_NAME}>
            {(props) => <TestStackScreen Stack={Stack} topNavScreenOptions={topNavScreenOptions(props)} />}
          </Drawer.Screen>
        }
      </Drawer.Navigator>
    </NavigationContainer>
    </>
  )
}
