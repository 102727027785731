import React, {ReactNode, useEffect, useState, useContext} from 'react'
import {StyleSheet, StyleProp, View, ViewStyle, TextStyle } from 'react-native'
import {TouchableOpacity} from 'react-native-gesture-handler'
import { AppContext, Theme } from '../contexts'
import {css} from '../styles'
import {ButtonRounded} from './ButtonRounded'
import {IconOutlined, IconStyle} from './IconOutlined'
import {TextItem} from './TextItem'

type Props = {
  title?: string
  subtitle?: string
  iconNameLeft?: string
  iconNameRight?: string
  buttonTitleRight?: string // if we use button with text instead of iconNameRight
  styleIconLeft?: IconStyle
  styleIconRight?: IconStyle
  style?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  subTitleStyle?: StyleProp<TextStyle>
  children?: ReactNode | ReactNode[]
  onPress?: Function
  onTitlePress?: Function
  onLeftIconPress?: Function
  onRightIconPress?: Function
  touchable?: boolean
  reverseTitle?: boolean
  skin?: Theme
}

export function BlockItem (props: Props) {
  const appContext = useContext(AppContext)
  const [skin, setSkin] = useState<Theme>('dark')

  const getStyles = () => StyleSheet.create({
    block: {
      backgroundColor: skin === 'light' ? 'white' : css.colorDarkBgLighten2,
      padding: css.padding * 1.5,
      marginBottom: css.padding / 2,
      borderRadius: css.borderRadius,
      borderBottomWidth: skin === 'light' ? 1 : 0,
      borderColor: css.colorPrimary + '20',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    titleBlock: {
      flex: 1,
      height: 20,
      justifyContent: 'center',
    },
    reverseTitle: {
      flexDirection: 'column-reverse',
    },
    title: {
      fontFamily: css.fontFamily600,
      fontSize: 18,
      lineHeight: 18,
      color: skin === 'light' ? css.colorTextDark : 'white'
    },
    subtitle: {
      color: css.colorText,
      lineHeight: 14,
    },
    content: {
      alignContent: 'stretch',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexGrow: 1,
      color: skin === 'light' ? css.colorTextDark : 'white'
    },
  })
  const [styles, setStyles] = useState(getStyles())

  useEffect(() => {
    setStyles(getStyles())
  }, [skin])

  useEffect(() => {
    if (props.skin && skin !== props.skin) setSkin(props.skin ?? 'dark')
    else if (!props.skin) setSkin(appContext?.state.skin ?? 'dark')
  }, [appContext])

  return (
    <View
      style={[styles.block, !props.title ? {marginBottom: 0} : undefined, props.style]}
      onStartShouldSetResponder={(e) => !!props.touchable && !props.onPress}
      onResponderRelease={(e) => {
        props.onPress?.()
      }}
      >
      <View
        style={[
          styles.header,
          {
            marginBottom: props.children && props.title ? css.padding : 0,
          },
        ]}>
        {props.iconNameLeft && (
          <TouchableOpacity
            disabled={!props.onLeftIconPress}
            activeOpacity={props.onLeftIconPress ? 1 : 0.6}
            onPress={() => {
              props.onLeftIconPress?.() ?? undefined
            }
            }>
            <IconOutlined
              iconName={props.iconNameLeft}
              color={props.styleIconLeft?.borderColor}
              style={[{marginLeft: 0}, props.styleIconLeft] as IconStyle}
            />
          </TouchableOpacity>
        )}
        {props.title &&
          <View
            onStartShouldSetResponder={(e) => !!props.onTitlePress}
            onResponderRelease={(e) =>
              props.onTitlePress?.()
            }
            style={[styles.titleBlock, props.reverseTitle ? styles.reverseTitle : undefined]}>
            {props.title && (
              <TextItem style={styles.title}>{props.title}</TextItem>
            )}
            {props.subtitle && (
              <TextItem style={[styles.subtitle, props.subTitleStyle]}>{props.subtitle}</TextItem>
            )}
          </View>
        }
        {(props.iconNameRight || props.buttonTitleRight) && (
          <TouchableOpacity
            disabled={!props.onRightIconPress}
            activeOpacity={1}
            onPress={() =>
              props.onRightIconPress?.()
            }>
            <>
              {props.iconNameRight && (
                <IconOutlined
                  iconName={props.iconNameRight}
                  color={props.styleIconRight?.borderColor}
                  style={[{marginRight: 0}, props.styleIconRight] as IconStyle}
                />
              )}
              {props.buttonTitleRight && (
                <ButtonRounded
                  title={props.buttonTitleRight}
                  borderColor={props.styleIconRight?.borderColor}
                  style={{flex: 0, ...props.styleIconRight}}
                  width={100}
                  onPress={() => {
                    props.onRightIconPress?.()
                  }}
                />
              )}
            </>
          </TouchableOpacity>
        )}
      </View>
      <View style={[styles.content, props.contentStyle]}>{props.children}</View>
    </View>
  )
}
