import React, { useEffect, useState } from 'react'
import {StyleSheet, View} from 'react-native'
import {css} from '../styles'
import {BodyLocalization, BodyLocalizationSNOMED, Gender, iconsMapper, LocalizationIconPosition, localizationIconPosition, LocalizationSide} from '../types'
import {IconOutlined, IconStyle} from './IconOutlined'
import { LocalizationModal } from './LocalizationModal'

type Props = {
  localization?: BodyLocalization
  gender?: Gender
  onChange?: (localization: BodyLocalization, gender?: Gender) => void
  iconStyle?: IconStyle
}

export function LocalizationIcon ({localization, gender, onChange, iconStyle}: Props) {
  const [toggleModal, setToggleModal] = useState(false)
  const getBodyPosition = (localizationId?: keyof typeof BodyLocalizationSNOMED) => {
    return localizationId ? localizationIconPosition[localizationId] : undefined
  }
  const getBodySideIcon = (localizationSide?: LocalizationSide) => {
    if (localizationSide === 'feet') return iconsMapper.feet
    if (localizationSide === 'back') return iconsMapper.bodyBack
    return iconsMapper.body
  }
  const [bodyPosition, setBodyPosition] = useState<LocalizationIconPosition | undefined>(getBodyPosition(localization?.localizationId))
  const [bodySideIcon, setBodySideIcon] = useState(getBodySideIcon(localization?.localizationSide))
  useEffect(() => {
    setBodyPosition(getBodyPosition(localization?.localizationId))
    setBodySideIcon(getBodySideIcon(localization?.localizationSide))
  }, [localization])
  return (
    <>
      <IconOutlined iconName={bodySideIcon} style={[css.iconOutlinedMin, iconStyle ?? {}]} onPress={() => {
        setToggleModal(true)
      }}>
        {bodyPosition &&
          <View style={[styles.indicator, bodyPosition ? styles[bodyPosition] : {}]} />
        }
      </IconOutlined>
      <LocalizationModal initialLocalization={localization} initialGender={gender} onSave={onChange} toggleModal={toggleModal} onClose={() => setToggleModal(false)} />
    </>
  )
}

const styles = StyleSheet.create({
  localizationIcon: {
    position: 'relative',
  },
  indicator: {
    backgroundColor: 'white',
    position: 'absolute',
    height: 3,
    width: 3,
  },
  positionHandLeft: {
    top: css.padding -2,
    right: css.padding + 4,
  },
  positionHandRight: {
    top: css.padding - 2,
    left: css.padding - 4,
  },
  positionHead: {
    top: css.padding - 6,
    left: css.padding + 1,
  },
  positionBody: {
    top: css.padding,
    left: css.padding + 1.5,
  },
  positionBodyLower: {
    top: css.padding+3,
    left: css.padding + 1.5,
  },
  positionLegRight: {
    top: css.padding + 8,
    left: css.padding - 1,
  },
  positionLegLeft: {
    top: css.padding + 8,
    right: css.padding + 8,
  },
})
