import React, { useEffect } from 'react'
import {ScreenWrapper} from '../components/ScreenWrapper'
import { ButtonRounded } from '../components'
import { SettingsProps } from '../containers'
import { View } from 'react-native'
import { css } from '../styles'
import { TextItem } from '../components/TextItem'
import Moment from 'moment'

type Props = {

}

export function SettingsScreen (props: Props & SettingsProps) {
  const user = props.auth.user
  const firstName = user?.fullName.firstName
  const lastName = user?.fullName.lastName
  const fullName = firstName && lastName ? firstName + ' ' + lastName : firstName ?? '' + lastName ?? ''

  return (
    <ScreenWrapper>
      <View style={{flexGrow: 1, padding: css.padding}}>
        <TextItem>User: {fullName}</TextItem>
        {user?.uid &&
          <TextItem>User UID: {user.uid}</TextItem>
        }
        {user?.type &&
          <TextItem>User type: {user.type}</TextItem>
        }
        {user?.createdAt &&
          <TextItem>Created At: {Moment(user.createdAt).format('LLLL')}</TextItem>
        }
        {user?.email &&
          <TextItem>Email: {user.email}</TextItem>
        }
        {user?.phone &&
          <TextItem>Phone: {user.phone}</TextItem>
        }
        {user?.subscriptions?.aiService &&
          <TextItem>AI Service enabled</TextItem>
        }
        {user?.updatedAt &&
          <TextItem>Updated At: {Moment(user.updatedAt).format('LLLL')}</TextItem>
        }
      </View>
      <View style={{alignItems: 'center', justifyContent: 'flex-end'}}>
        <ButtonRounded style={{alignSelf: 'center', marginBottom: css.padding*2}} title="Sign out" onPress={props.logOutUser} />
      </View>
    </ScreenWrapper>
  )
}
