import React, { useEffect, useState, ChangeEvent } from 'react'
import {Platform, StyleProp, ViewStyle} from 'react-native'
import {css} from '../styles'
import { Item } from '../types'

type Props = {
  options: Item[]
  selected?: string
  setSelected: Function
  iconName?: string
  placeholder?: Item | {}
  wrapperStyle?: StyleProp<ViewStyle>
  dropdownStyle?: StyleProp<ViewStyle>
  skin?: 'light' | 'dark'
}

export function SelectPicker ({selected='all', setSelected, options, iconName, wrapperStyle, dropdownStyle, placeholder = {}, skin = 'dark'}: Props) {
  const [value, setValue] = useState(selected)
  const setColor = () => {
    return skin === 'dark' ? css.colorTextLight : css.colorTextDark
  }

  const cursor = (Platform.OS === 'web' ? {
    cursor: 'pointer',
  } : {})

  useEffect(() => {
    setColor()
  }, [skin])

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const v = e.target.value
    setValue(v)
    setSelected(v === 'all' ? '' : v)
  }
  const selectStyle = {
    ...cursor,
    padding: css.padding/1.5,
    width: '100%',
    borderRadius: 20,
    paddingRight: css.padding,
    border: 'none',
    // minWidth: 'max-content',
    ...(wrapperStyle as Object ?? {}),
  }

  const selectLight = {
    backgroundColor: 'white',
    borderColor: css.colorText,
    borderWidth: 1,
    borderStyle: 'solid',
    color: css.colorTextDark
  }

  const selectDark = {
    backgroundColor: css.colorDarkBg,
    borderColor: 'transparent',
    color: 'white'
  }

  return (
    <select onChange={handleOnChange} style={{...selectStyle, ...(skin === 'light' ? selectLight : selectDark)}}>
      {options.map((item, index) => <option key={item.key} value={item.value} selected={item.value === value ? true : undefined}>
        {item.label}
      </option>)}
    </select>
  )
}
