// css name is a bit out of context, but it well describes, that these properties are global styling variables

import { Platform, StyleSheet } from "react-native";
import { EventStatus } from "../types";

/**
 * Global styling variables
 */
export const css = {
  fontFamily: Platform.OS === 'web' ? 'Raleway' : 'Raleway-Regular',
  fontFamily600: Platform.OS === 'web' ? 'Raleway' : 'Raleway-SemiBold',
  // COLORS
  colorPrimary: '#586FE7',
  colorAccent: '#432EC3',
  colorSuccess: '#27AE60',
  colorTextLight: '#ffffff',
  colorTextDark: '#1D194F',
  colorText: '#B7B8BF',
  colorError: '#EB5757',
  colorDanger: '#EB5757',
  colorWarning: '#F2994A',
  colorInfo: '#2F80ED',
  colorDarkBg: Platform.OS === 'web' ? '#0b0b4b' : '#141432',
  colorDarkBgLighten1: Platform.OS === 'web' ? '#282458' : '#171738',
  colorDarkBgLighten2: Platform.OS === 'web' ? '#211d53' : '#1A1A3F',
  colorDarkBgLighten3: Platform.OS === 'web' ? '#211d53' : '#222250',
  colorDarkBgLighten4: '#262662',
  colorSidebarMenu: '#1D194F',
  colorSidebar: '#282458',
  colorAppBg: Platform.OS === 'web' ? '#0b0b4b' : '#141432',
  colorComponentBg: '#1A1A3F',
  colorComponentActiveBg: Platform.OS === 'web' ? '#211d53' : '#222250',
  colorInputBg: '#161637',
  // SPACINGS
  padding: 15,
  border: 1,
  borderRadius: 20,
  iconOutlinedMin: {
    paddingLeft: 10,
    paddingRight: 7,
    paddingBottom: 7,
    paddingTop: 8,
    fontSize: 18,
  },
  android: {
    minRequiredInputHeight: 40,
  },
  ios: {
    minRequiredInputHeight: 40,
  },
  theme: {
    dark: {

    },
    light: {

    }
  }
}

const eventDiagnoseListItem = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 44,
  },
  titleBlock: {
    flexGrow: 1,
  },
  arrowMore: {
    paddingBottom: 10,
  },
  title: {
    fontFamily: css.fontFamily600,
    fontSize: 18,
    lineHeight: 18,
    color: css.colorTextLight
  },
  subtitle: {
    color: css.colorText,
    lineHeight: 14,
  },
  content: {
    flexDirection: 'row',
    paddingTop: 2,
  },
  image: {
    borderWidth: 1,
    borderColor: css.colorPrimary,
    height: 85,
    width: 85,
    borderRadius: css.borderRadius / 2,
    marginRight: css.padding,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noImage: {
    fontSize: 24,
    color: css.colorPrimary,
  },
  block: {
    backgroundColor: css.colorDarkBgLighten2,
    padding: css.padding * 1.5,
    marginBottom: css.padding / 2,
    borderRadius: css.borderRadius,
    borderBottomWidth: 0,
    borderColor: css.colorPrimary + '20',
  },
})

export const cssEventsListItem = eventDiagnoseListItem
export const cssDiagnoseListItem = {
  ...eventDiagnoseListItem
}

export const eventStatusColor: {[key in EventStatus]: string} = {
  pending: css.colorPrimary,
  completed: css.colorSuccess,
  "completed-with-diagnosis": css.colorPrimary,
  "completed-with-issues": css.colorPrimary,
  canceled: css.colorError,
  "pending-review": css.colorPrimary,
  "needs-visit": css.colorInfo,
  "needs-reply": css.colorError,
  "create-visit": css.colorWarning,
}