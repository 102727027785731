import React, {useEffect, useState} from 'react'
import {View} from 'react-native'
import {BodyLocalization, Gender, iconsMapper, IDiagnosis, IDiagnosisAiResult, IPatient, LONG_DATE_FORMAT} from '../types'
import moment from 'moment'
import { ViewRow } from './ViewRow'
import { TextItem } from './TextItem'
import { css } from '../styles'
import { ImageWithPreview } from './ImageWithPreview'
import Icon from './IconWrapper'
import { IconOutlined } from './IconOutlined'
import { AlertWrapper } from './AlertWrapper'
import { LocalizationIcon } from './LocalizationIcon'
import { cssEventsListItem } from '../styles/variables'
import { ShareButton } from './ShareButton'
import { findDiagnosisByMaxProbability } from '../utils'
import { DiagnosisBadgeOutlined } from './DiagnosisBadgeOutlined'

type Props = {
  display?: 'none' | 'flex'
  disabled?: boolean
  index: number
  // when patient is not attached to the event, then event gender property is used
  eventGender?: Gender
  patient?: Partial<IPatient>
  diagnosis: Partial<IDiagnosis>
  changeLocalization?: (localization: BodyLocalization, gender?: Gender) => void
  removeDiagnosis?: Function
  toggleListItem?: Function
  shareItem?: Function
  saveDicom?: Function
}

export function DiagnosesListClosedItem ({patient, diagnosis, removeDiagnosis, disabled, toggleListItem, index, changeLocalization, eventGender, display = 'flex'}: Props) {
  const [subtitle, setSubtitle] = useState(diagnosis.customDiagnosis ?? `Diagnosis ${index+1}`)
  const images = diagnosis?.imageResult?.originalImageUrl ?? []
  const image = () => {
    const imageUri = diagnosis.imageResult?.originalImageUrl?.[0]
    return <ViewRow style={[cssEventsListItem.image, imageUri ? {borderWidth: 0} : undefined]}>
      {!!imageUri &&
        <ImageWithPreview source={imageUri} />
      }
      {!imageUri && <Icon name={iconsMapper.camera} style={cssEventsListItem.noImage} />}
    </ViewRow>
  }
  const [aiResult, setAiResult] = useState<IDiagnosisAiResult>()
  useEffect(() => {
    setSubtitle(diagnosis.customDiagnosis ?? `Diagnosis ${index+1}`)
    setAiResult(findDiagnosisByMaxProbability(diagnosis.aiResults))
  }, [diagnosis])
  return (
    <View style={[cssEventsListItem.block, {display}]}>
      <ViewRow>
        {image()}
        <View style={{flexGrow: 1}}>
          <View style={cssEventsListItem.header}
            onStartShouldSetResponder={() => true}
            onResponderRelease={() => {
              toggleListItem?.()
            }}
          >
            <View style={cssEventsListItem.titleBlock}>
              <TextItem style={cssEventsListItem.subtitle}>{moment(diagnosis.date).format(LONG_DATE_FORMAT)}</TextItem>
              <TextItem style={[cssEventsListItem.title]}>{subtitle}</TextItem>
            </View>
            <View style={cssEventsListItem.arrowMore}>
              <Icon
                name={iconsMapper.down}
                color={css.colorPrimary}
                size={25}
                style={{marginRight: -3}}
              />
            </View>
          </View>
          <View style={cssEventsListItem.content}>
            {!disabled &&
              <IconOutlined
                iconName={iconsMapper.trash}
                color={css.colorError}
                style={{borderStyle: 'dashed', ...css.iconOutlinedMin}}
                onPress={() => {
                  if (removeDiagnosis) {
                    AlertWrapper({
                      title: "Delete item",
                      message: "Are you sure you want to delete the item?",
                      confirmButton: {text: "OK", onPress:() => {
                        removeDiagnosis()
                      }},
                      cancelButton: {text: "Cancel", onPress:() => {}},
                    })
                  }
                }}
              />
            }
            {diagnosis && aiResult?.icdCode &&
            typeof aiResult.probability === 'number' &&
              <DiagnosisBadgeOutlined aiResults={diagnosis.aiResults!}/>
            }
            {diagnosis?.bodyLocalization &&
              <LocalizationIcon gender={eventGender ?? patient?.gender} localization={diagnosis.bodyLocalization} onChange={!disabled ? (localization: BodyLocalization, gender) => {
                changeLocalization?.(localization, gender)
              } : undefined} />
            }
            {images.length > 0 &&
              <ShareButton buttonTitle="Share" images={images} message="Sharing is anonymous, sensitive data will be removed" isIcon={true} />
            }
          </View>
        </View>
      </ViewRow>
    </View>
  )
}
