import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { SettingsScreen, LogInScreen } from '../screens'
import { fetchClinics, fetchDiagnoses, fetchEvents, fetchPatients, fetchUser, logOutUser, RootState, fetchSpecialists } from '../store'
import { IAuthData } from '../store/types'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    logOutUser: () => {
      dispatch(logOutUser())
    },
    fetchData: (authData: IAuthData) => {
      dispatch(fetchUser(authData))
      dispatch(fetchPatients(authData))
      dispatch(fetchSpecialists())
      dispatch(fetchEvents(authData))
      dispatch(fetchDiagnoses(authData))
      dispatch(fetchClinics(authData))
    }
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type SettingsProps = ConnectedProps<typeof connector>
export const Settings = connector(SettingsScreen)
export const LogIn = connector(LogInScreen)
