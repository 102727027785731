import React, {useEffect, useState} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import { Toast } from './Toast'
import {css} from '../styles'
import { DiagnosisTitle, IDiagnosisAiResult } from '../types'
import { findDiagnosisByMaxProbability } from '../utils'
import {TextItem} from './TextItem'

type Props = {
  style?: StyleProp<ViewStyle>
  // we leave all probabilities of one diagnosis, perhaps later we will show other probabilities in popover
  aiResults: IDiagnosisAiResult[]
}

export function DiagnosisBadgeOutlined ({style, aiResults}: Props) {
  const colorVariants = {
    '0': css.colorPrimary,
    '10': css.colorSuccess,
    '30': css.colorWarning,
    '50': css.colorError
  }
  const [color, setColor] = useState(css.colorPrimary)

  const [aiDiagnosis, setAiDiagnosis] = useState(findDiagnosisByMaxProbability(aiResults))

  useEffect(() => {
    let colorVariant: (keyof typeof colorVariants) = '0'
    const d = findDiagnosisByMaxProbability(aiResults)
    setAiDiagnosis(d)

    if (d?.icdCode === 'd22') colorVariant = '0'
    else {
      for (const cv in colorVariants) {
        if (d && d.probability && (d.probability*100 >= parseInt(cv))) colorVariant = cv as keyof typeof colorVariants
        else break
      }
    }
    setColor(colorVariants[colorVariant])
  }, [aiResults])

  return (<>
    {aiDiagnosis ? <View
      style={[styles.badge, {borderColor: color}, style]}
      onStartShouldSetResponder={() => !!aiDiagnosis?.icdCode}
      onResponderRelease={() => {
        Toast.showWithGravity(DiagnosisTitle[aiDiagnosis.icdCode], Toast.SHORT, Toast.CENTER)
      }}
    >
      <View style={[styles.diagnosis, {borderColor: color}]}>
        <TextItem
          style={[
            styles.badgeInfo, {color: color}
          ]}>
          {aiDiagnosis?.icdCode ? aiDiagnosis?.icdCode.toUpperCase() : '' }
        </TextItem>
      </View>
      <TextItem style={[styles.badgeInfo, {color: color, paddingRight: 0}]}>
        {Math.ceil((aiDiagnosis?.probability || 0)*100)}
      </TextItem>
      <TextItem
        style={{
          color: color,
          fontSize: 10,
          paddingRight: css.padding / 2,
          marginBottom: -2,
        }}>
        %
      </TextItem>
    </View> : <></>
    }
  </>)
}

const styles = StyleSheet.create({
  badge: {
    flexGrow: 1,
    maxWidth: 90,
    maxHeight: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
    borderWidth: 1,
    borderRadius: css.borderRadius / 2,
  },
  badgeInfo: {
    padding: css.padding / 2,
    fontSize: 14,
    fontFamily: css.fontFamily600,
  },
  diagnosis: {
    borderWidth: 0,
    borderRightWidth: 1,
  },
})
