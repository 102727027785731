import React from 'react'
import {Platform, StyleSheet, View} from 'react-native'
import {TextItem} from './TextItem'
import Moment from 'moment'
import {css} from '../styles'
import { IEvent } from '../types'
import { oneOfEventsBelongsToDate } from '../utils/sortFilter'
import { ScrollWrapper } from './ScrollWrapper'

const now = Date.now()
const monthDateWithDelta = (delta: number) => {
  return new Date(now - delta * 3600 * 24 * 1000).getDate()
}
const weekDayWithDelta = (delta: number) => {
  return Moment(now - delta * 3600 * 24 * 1000).format('ddd')
}
type Props = {
  daysCount: number
  events: IEvent[]
  dateSelected: Function
}

export function CalendarHorizontal ({events, daysCount, dateSelected}: Props) {
  let days = []
  for (let i = 0; i < daysCount; i++) {
    const wday = weekDayWithDelta(i)
    const dateOfDay = new Date(now - i * 3600 * 24 * 1000)
    const opacity = {opacity: i !== 0 && (wday === 'Sun' || wday === 'Sat') ? 0.5 : 1}
    days.push(
      <View
        onStartShouldSetResponder={(e) => true}
        onResponderRelease={(e) => {
          dateSelected(dateOfDay)
        }}
        key={i}
        style={[
          styles.calendarItem,
          {
            backgroundColor: i === 0 ? css.colorSuccess : 'transparent',
            marginBottom: Platform.OS === 'web' ? css.padding : 0
          },
        ]}>
        {oneOfEventsBelongsToDate(events, new Date(now - i * 3600 * 24 * 1000)) &&
          <View style={styles.badge} />
        }
        <TextItem style={[styles.weekDay, opacity, {color: 'white'}]}>{wday}</TextItem>
        <TextItem style={[styles.monthDay, opacity, {color: 'white'}]}>{monthDateWithDelta(i)}</TextItem>
      </View>,
    )
  }

  return <ScrollWrapper horizontal={true} style={Platform.OS === 'web' ? (
    {display:'flex', flexDirection: 'row', flex: 1}
  ) : styles.calendar} contentContainerStyle={{alignItems: 'center'}}>
    {days}
  </ScrollWrapper>
}

const cursor = (Platform.OS === 'web' ? {
  cursor: 'pointer',
} : {})

const styles = StyleSheet.create({
  calendar: {
    flexDirection: 'row',
    marginBottom: css.padding,
  },
  calendarItem: {
    maxHeight: 62,
    minWidth: 40,
    alignItems: 'center',
    padding: css.padding / 2,
    borderRadius: css.borderRadius / 2,
    backgroundColor: css.colorSuccess,
    marginRight: css.padding,
    ...cursor
  },
  badge: {
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: css.colorSuccess,
    position: 'absolute',
    right: 2,
    top: 5,
  },
  weekDay: {
    fontSize: 12,
    ...Platform.select({
      ios: {
        paddingBottom: css.padding/1.2,
      },
      android: {
        paddingBottom: css.padding / 2,
      }
    })
  },
  monthDay: {
    fontSize: 16,
    fontFamily: css.fontFamily600,
  },
})
