import React from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import Icon from './IconWrapper'
import {css} from '../styles'
import {iconsMapper} from '../types'
import { DiagnosisImageDropZone } from './DiagnosisImageDropzone'

type Props = {
  style?: StyleProp<ViewStyle>
  photoCallback: Function
}

export function CameraFixed ({style, photoCallback}: Props) {
  return (
    <View style={[styles.libraryCameraBlock, style]}>
      {/*
        If DropZone starts to crash in a strange way it is Chrome browsers fault, close browser and start it again!
        Spend some hours searching for errors, found a problem with the browser.
      */}
      <DiagnosisImageDropZone photoCallback={photoCallback}>
        <View style={styles.cameraIconTouch}>
          <View style={styles.cameraIconWrapper}>
            <Icon
              name={iconsMapper.camera}
              size={25}
              color={css.colorTextLight}
              />
          </View>
        </View>
      </DiagnosisImageDropZone>
    </View>
  )
}

const borderRadius = 22

const styles = StyleSheet.create({
  libraryCameraBlock: {
    // position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: css.colorDarkBgLighten4,
    borderRadius: borderRadius * 2,
    // bottom: 60,
    // right: css.padding + 5,
    padding: 5,
  },
  // libraryIcon: {
  //   paddingLeft: css.padding / 1.5,
  //   paddingRight: css.padding / 1.5,
  //   paddingBottom: css.padding / 4,
  // },
  cameraIconTouch: {
    alignItems: 'center',
    justifyContent: 'center',
    height: borderRadius * 2,
    width: borderRadius * 2,
    borderRadius: borderRadius,
    borderColor: css.colorAccent,
    borderWidth: 2,
  },
  cameraIconWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: css.colorAccent,
    height: borderRadius * 2 - 8,
    width: borderRadius * 2 - 8,
    borderRadius: borderRadius - 4,
    // marginTop: -3,
  },
})
