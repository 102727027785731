import { createSelector } from '@reduxjs/toolkit'
import { IEvent, IPatient, IVisit } from '../../types'
import { IDiagnosesState, IEventsState, IPatientsState } from '../types'
import { filterPatientsById, filterPatientsByName } from './patients'
import moment from 'moment'
import { sortByFinishedEvents, sortByLastActionDate } from '../../utils/sortFilter'
import { filterDiagnosesByIds } from './diagnoses'
import { findDiagnosisByMaxProbability } from '../../utils'

const getEventsState = (state: IEventsState) => state
const getEvents = (state: IEventsState) => state.events
const getEventsArray = (state: IEventsState, props: { events: IEvent[]}) => props.events
const getEventId = (state: IEventsState, props: { eventId?: string }) => props.eventId
const getEventIds = (state: IEventsState, props: { eventIds?: string[] }) => props.eventIds
const getRecentDaysCount = (state: IEventsState, props: { recentDaysCount: number }) => props.recentDaysCount
const getSearchProps = (state: IEventsState, props: { searchProps: {query?: string, date?: Date, diagnosisCode?: string, specialistUid?: string, newEvents?: boolean}  }) => props.searchProps
const getPatient = (state: IEventsState, props: { patient?: IPatient }) => props.patient
const getPatientUid = (state: IEventsState, props: { patientUid?: string }) => props.patientUid

const getPatientsState = (state: IEventsState, props: { patientsState: IPatientsState }) => props.patientsState
const getDiagnosesState = (state: IEventsState, props: { diagnosesState: IDiagnosesState }) => props.diagnosesState

//#region FINDERS - find functionality, returns one value, case-insensitive

export const findEventById = createSelector(
  [getEvents, getEventId],
  (events, eventId) => {
    return eventId ? events.find(e => e.id.toLocaleLowerCase() === eventId.toLocaleLowerCase()) : undefined
  }
)

export const findEventsByMultipleIds = createSelector(
  [getEventsState, getEventIds],
  (state, eventIds) => {
    let events:IEvent[] = []
    eventIds?.forEach(eventId => {
      const e = findEventById(state, {eventId})
      if (e) events.push(e)
    })
    return events
  }
)

export const findLastEventFromEventsArray = createSelector(
  [getEventsState, getEventsArray],
  (state, events) => {
    const e = events ?? state
    return e.sort((b, a) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[0] ?? {}
  }
)

export const findLastEventByPatient = createSelector(
  [getEventsState, getPatient],
  (state, patient): Partial<IEvent> => {
    const events = findEventsByMultipleIds(state, {eventIds: patient?.eventIds})
    return findLastEventFromEventsArray(state, {events})
  }
)

//#endregion

// #region FILTERS - search functionality, returns multiple values in an array, case-insensitive

/**
 * filters by user uid, user id, first and/or last name or event id (OR condition between words)
 */
export const filterEventsBySearchProps = createSelector(
  [getPatientsState, getDiagnosesState, getEvents, getSearchProps],
  (patientsState, diagnosesState, events, searchProps) => {
    let results: IEvent[] = []
    if (!searchProps.query) results = events
    else results = events.filter(e => {
      const f = searchProps.query!.split(' ')
      // if any of words appears in events search fields...
      let includes = f.some(
        word => e.id.toLocaleLowerCase().includes(word) ||
        e.patientUid?.toLocaleLowerCase().includes(word.toLocaleLowerCase()) ||
        filterPatientsById(patientsState, {userId: word}).find(p => p.uid === e.patientUid) ||
        filterPatientsByName(patientsState, { name: word }).find(p => p.uid === e.patientUid)
      )
      //... then include this event in search results
      return includes
    })
    // if (searchProps.specialistUid) {
      results = results.filter(r => r.specialistUid === searchProps.specialistUid)
    // }
    if (searchProps.date) results = results.filter(r => moment(r.createdAt).isSame(searchProps.date, 'day') || moment((r as IVisit).appointmentDate).isSame(searchProps.date, 'day'))
    if (searchProps.diagnosisCode) {
      const diagnosesIds:string[] = []
      results.forEach(r => diagnosesIds.push(...(r.diagnosesIds ? r.diagnosesIds : [])))
      let allDiagnoses = filterDiagnosesByIds(diagnosesState, {diagnosesIds})
      allDiagnoses = allDiagnoses.filter(d => searchProps.diagnosisCode === 'all' ? !!d.aiResults : findDiagnosisByMaxProbability(d.aiResults)?.icdCode === searchProps.diagnosisCode)
      results = results.filter(r => allDiagnoses.find(d => d.eventId === r.id))
    }
    results = sortByLastActionDate(results)
    return results.map(p => {
      return {key: p.id, value: p}
    })
  }
)

export const filterNewEvents = createSelector(
  [getEvents],
  (events) => {
    let re: IEvent[] = sortByLastActionDate(events).filter(e => !e.specialistUid)
    return re.map(p => {
      return {key: p.id, value: p}
    })
  }
)

export const filterRecentEvents = createSelector(
  [getEvents, getRecentDaysCount],
  (events, days) => {
    let re: IEvent[] = []
    sortByLastActionDate(events).some(e => {
      re.push(e)
      const actionDate = (e as IVisit).appointmentDate ?? e.createdAt
      return actionDate // new Date(e.createdAt).getTime() + days*3600*24*1000 < Date.now()
    })
    return re
  }
)

export const filterEventsByPatientUid = createSelector(
  [getEvents, getPatientUid],
  (events, patientUid) => {
    return events.filter(e => !!patientUid && e.patientUid === patientUid)
  }
)

export const filterActivePatientEvents = createSelector(
  [getEvents],
  (events) => {
    events = sortByLastActionDate(events).filter(e =>
      e.status
    ) ?? []
    return sortByFinishedEvents(events, 'asc')
  }
)

// #endregion
