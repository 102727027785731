/** FACE */
const t02120 = [
  {"x": 0.5212516425296078, "y": 0.0},
  {"x": 0.479457424371847, "y": 0.0},
  {"x": 0.44041128005162334, "y": 0.00746029704237864},
  {"x": 0.42053629650816593, "y": 0.023896431834766654},
  {"x": 0.41292283057086776, "y": 0.04230703574961336},
  {"x": 0.4158420651851711, "y": 0.057996072961902574},
  {"x": 0.4273920586025541, "y": 0.06552040778656022},
  {"x": 0.4273920586025541, "y": 0.08398437414501532},
  {"x": 0.42453629650816593, "y": 0.09150871352959124},
  {"x": 0.43340168860792706, "y": 0.10650401389542072},
  {"x": 0.4605824408283896, "y": 0.12016521897165307},
  {"x": 0.48368242766315556, "y": 0.12768954923639245},
  {"x": 0.5082420125242347, "y": 0.12907701380947537},
  {"x": 0.5284862372646125, "y": 0.1263020846633095},
  {"x": 0.5631362175167615, "y": 0.11402835327999664},
  {"x": 0.5750016096165226, "y": 0.1037824449129041},
  {"x": 0.5803804422571393, "y": 0.09150871352959124},
  {"x": 0.5761458089604578, "y": 0.08398437414501532},
  {"x": 0.5819208056691492, "y": 0.06824197676907684},
  {"x": 0.5819208056691492, "y": 0.059383537534985514},
  {"x": 0.5920112010602189, "y": 0.05458077169284449},
  {"x": 0.5920112010602189, "y": 0.037557634631039866},
  {"x": 0.5820016096165226, "y": 0.019787398279167097},
  {"x": 0.570216982902458, "y": 0.009235229038493443},
  {"x": 0.5571169960676921, "y": 0.004044996628305229},
]
/** SCALP */
const t02102 = t02120
/** NECK */
const t023 = [
  {"x": 0.44040168860792706, "y": 0.11130678429748002},
  {"x": 0.44186128663424035, "y": 0.13110485506536879},
  {"x": 0.4317074572849322, "y": 0.14273821888570773},
  {"x": 0.40283247374147485, "y": 0.14887508457736415},
  {"x": 0.3380920980982563, "y": 0.15301195938885715},
  {"x": 0.4086074704501663, "y": 0.16114882508051356},
  {"x": 0.4317074572849322, "y": 0.16867316446508948},
  {"x": 0.463501675442693, "y": 0.16867316446508948},
  {"x": 0.49961125372115517, "y": 0.1768912315762886},
  {"x": 0.5400362306819955, "y": 0.16867316446508948},
  {"x": 0.5732266129078311, "y": 0.16867316446508948},
  {"x": 0.6180304225092881, "y": 0.15842726521783349},
  {"x": 0.6757803510345262, "y": 0.15501195938885715},
  {"x": 0.6309765799947459, "y": 0.15090292583325757},
  {"x": 0.6021015964512885, "y": 0.14748762000428123},
  {"x": 0.5833804422571393, "y": 0.1461535247146841},
  {"x": 0.5674516161991396, "y": 0.13996328973954184},
  {"x": 0.5674516161991396, "y": 0.11130678429748002},
  {"x": 0.5414958287083088, "y": 0.12358051568079288},
  {"x": 0.5255670026503092, "y": 0.12541111821890905},
  {"x": 0.4866016622774589, "y": 0.12507701380947537},
  {"x": 0.4605824408283896, "y": 0.11855268354473601},
]
/** EARS (left) */
const t022l = [
  {"x": 0.5949304356745222, "y": 0.055996072961902574},
  {"x": 0.6093361911862932, "y": 0.05596824082584571},
  {"x": 0.6180304225092881, "y": 0.06077100666798673},
  {"x": 0.6151111878949848, "y": 0.0805690774358755},
  {"x": 0.596326599742597, "y": 0.09556437780170499},
  {"x": 0.5803804422571393, "y": 0.09556437780170499},
  {"x": 0.5750016096165226, "y": 0.08670594312753194},
  {"x": 0.5822362043515273, "y": 0.05849257109058508},
]
/** EARS (right) */
const t022r = [
  {"x": 0.41292283057086776, "y": 0.05577100666798673},
  {"x": 0.39851707505909667, "y": 0.05458077169284449},
  {"x": 0.3855074836154004, "y": 0.057996072961902574},
  {"x": 0.3898228437361018, "y": 0.07576631159373448},
  {"x": 0.4057517083557782, "y": 0.09150871352959124},
  {"x": 0.42161706189386255, "y": 0.09423028251210785},
  {"x": 0.4298516951905441, "y": 0.085318478554449},
  {"x": 0.42803629650816593, "y": 0.06324197676907684},
]
/** AXILLA (right) */ 
const t02420r = [
  {"x": 0.6780957882785811, "y": 0.16328569077216998},
  {"x": 0.701736138525357, "y": 0.17561279143896866},
  {"x": 0.7089707718220385, "y": 0.20826930600086704},
  {"x": 0.698880337869292, "y": 0.22945483222200222},
  {"x": 0.6830149843312077, "y": 0.25133410440943366},
  {"x": 0.6599149974964418, "y": 0.22193051107709938},
  {"x": 0.6409861714384422, "y": 0.2086845935901703},
  {"x": 0.6401304093440541, "y": 0.19099555637788107},
]
/** AXILLA (left) */
const t02420l = [
  {"x": 0.33776710797218185, "y": 0.16367316446508948},
  {"x": 0.3089729090952595, "y": 0.17822533598572227},
  {"x": 0.303197912386568, "y": 0.21445954097600928},
  {"x": 0.3277575165284856, "y": 0.25266820881886737},
  {"x": 0.3668670948069478, "y": 0.2050720672830898},
  {"x": 0.3690228569013359, "y": 0.19227399651520102},
  {"x": 0.35663250007194297, "y": 0.1768912315762886},
]
/** CHEST */
const t02424 = [
  {"x": 0.3219825005389557, "y": 0.15239941484210354},
  {"x": 0.3547228569013359, "y": 0.1761974856099923},
  {"x": 0.3669670816417137, "y": 0.19055243665252095},
  {"x": 0.3657324869067089, "y": 0.20826930600086704},
  {"x": 0.34508250665456003, "y": 0.22812072781256854},
  {"x": 0.332072876649187, "y": 0.24242230957161137},
  {"x": 0.32483828191418224, "y": 0.26227374962298594},
  {"x": 0.3349921112634904, "y": 0.28137807442457846},
  {"x": 0.3711017281036293, "y": 0.295733007227434},
  {"x": 0.41869782727955923, "y": 0.2909302459452112},
  {"x": 0.5024670158155433, "y": 0.2677168693483461},
  {"x": 0.5761458089604578, "y": 0.2889024138091544},
  {"x": 0.6308861846036763, "y": 0.29506711163686766},
  {"x": 0.6627707981525066, "y": 0.28751494011623485},
  {"x": 0.6830149843312077, "y": 0.27113217517732247},
  {"x": 0.6830149843312077, "y": 0.25405568251178684},
  {"x": 0.6730053543258346, "y": 0.23628544387995493},
  {"x": 0.6521304093440541, "y": 0.21648737311206614},
  {"x": 0.6402015832860544, "y": 0.2081086220685744},
  {"x": 0.6402111747297506, "y": 0.19061279143896866},
  {"x": 0.6757803510345262, "y": 0.16456413090948993},
  {"x": 0.691645781695964, "y": 0.153039791524914},
  {"x": 0.6302111747297506, "y": 0.15753629877343306},
  {"x": 0.5659208056691492, "y": 0.16947592574731224},
  {"x": 0.5393516293643736, "y": 0.16947592574731224},
  {"x": 0.49961125372115517, "y": 0.17594689584840234},
  {"x": 0.46527667215138447, "y": 0.16947592574731224},
  {"x": 0.4346266918992356, "y": 0.16747592574731224},
  {"x": 0.41087672481232076, "y": 0.16153629877343306},
]
/** ABDOMEN */
const t02480 = [
  {"x": 0.3378478733578785, "y": 0.28479338025355483},
  {"x": 0.36964209151563926, "y": 0.293733007227434},
  {"x": 0.4086074704501663, "y": 0.29203927950081077},
  {"x": 0.5033862504298466, "y": 0.26774471972407604},
  {"x": 0.5761458089604578, "y": 0.2879302459452112},
  {"x": 0.6208861846036763, "y": 0.29506711163686766},
  {"x": 0.6469054060527455, "y": 0.29303927950081077},
  {"x": 0.6728611549818996, "y": 0.28479338025355483},
  {"x": 0.6589958014438152, "y": 0.31024096184070696},
  {"x": 0.6526803641997603, "y": 0.3687884246073327},
  {"x": 0.6599149974964418, "y": 0.3763127639919086},
  {"x": 0.5949304356745222, "y": 0.3852245588297309},
  {"x": 0.5053862504298466, "y": 0.38963986465870726},
  {"x": 0.4057517083557782, "y": 0.38270021944515497},
  {"x": 0.3477767379775549, "y": 0.37856337199317163},
  {"x": 0.3549478601926444, "y": 0.36195781294937995},
  {"x": 0.3537767379775549, "y": 0.3230554356195718},
]
/** HYPOGASTRIC */
const t02485 = [
  {"x": 0.3469382687489482, "y": 0.37542179754750816},
  {"x": 0.3207575165284856, "y": 0.4077442076999728},
  {"x": 0.31220750383026425, "y": 0.42209912226315527},
  {"x": 0.3537767379775549, "y": 0.4282360061944848},
  {"x": 0.39851707505909667, "y": 0.4473403492357504},
  {"x": 0.4317074572849322, "y": 0.46921960318350875},
  {"x": 0.463501675442693, "y": 0.4671384200036392},
  {"x": 0.5414958287083088, "y": 0.46721960318350875},
  {"x": 0.5790016096165226, "y": 0.47174394256808467},
  {"x": 0.6252015832860544, "y": 0.4368971930310441},
  {"x": 0.6599149974964418, "y": 0.425957584296838},
  {"x": 0.6945649777485906, "y": 0.42254227846786164},
  {"x": 0.6728611549818996, "y": 0.3904982902130438},
  {"x": 0.6599149974964418, "y": 0.37503434209426173},
  {"x": 0.5992457957952236, "y": 0.38363986465870726},
  {"x": 0.5053862504298466, "y": 0.38736144276106044},
  {"x": 0.4447170487286285, "y": 0.38563986465870726},
  {"x": 0.3955978404447933, "y": 0.3802245588297309},
]
/** PERINEUM */
const t025 = [
  {"x": 0.4317074572849322, "y": 0.4693286367391083},
  {"x": 0.4605824408283896, "y": 0.46794118128586193},
  {"x": 0.5414958287083088, "y": 0.46754118128586193},
  {"x": 0.5790016096165226, "y": 0.47174394256808467},
  {"x": 0.5551362175167615, "y": 0.5054004388903099},
  {"x": 0.5384958287083088, "y": 0.5177275395571086},
  {"x": 0.5212516425296078, "y": 0.5204491176594618},
  {"x": 0.5111612471385382, "y": 0.5149526286506159},
  {"x": 0.49961125372115517, "y": 0.5149526286506159},
  {"x": 0.4866016622774589, "y": 0.5204491176594618},
  {"x": 0.463501675442693, "y": 0.5163400841038622},
  {"x": 0.4346266918992356, "y": 0.48698986005501377},
]
/** ARM/anterior (right) */
const t02615r = [
  {"x": 0.3189825005389557, "y": 0.15239941484210354},
  {"x": 0.28018830166203335, "y": 0.16456413090948993},
  {"x": 0.2650075301607324, "y": 0.18233436954132184},
  {"x": 0.2473037073940413, "y": 0.22059640666766572},
  {"x": 0.2463037073940413, "y": 0.23628544387995493},
  {"x": 0.20933834774035265, "y": 0.29984204078303356},
  {"x": 0.1951037337245095, "y": 0.3196401297905954},
  {"x": 0.1682287501810521, "y": 0.3503778092926903},
  {"x": 0.15867875676366913, "y": 0.3653731187783563},
  {"x": 0.15158837101301867, "y": 0.3879461369320841},
  {"x": 0.13072299819409592, "y": 0.43234503975008437},
  {"x": 0.1104787830941373, "y": 0.4582799944493027},
  {"x": 0.13572299819409592, "y": 0.4671384200036392},
  {"x": 0.16459799137797249, "y": 0.47055372583261557},
  {"x": 0.1856979782127384, "y": 0.4473403492357504},
  {"x": 0.22312295517357878, "y": 0.41457481935792556},
  {"x": 0.2454479452996532, "y": 0.38863986465870726},
  {"x": 0.2650075301607324, "y": 0.36265157715534935},
  {"x": 0.2800979255518021, "y": 0.32716446917517134},
  {"x": 0.3219825005389557, "y": 0.2738537532796756},
  {"x": 0.32483828191418224, "y": 0.27113217517732247},
  {"x": 0.3277575165284856, "y": 0.24766820881886737},
  {"x": 0.303197912386568, "y": 0.21520893297474622},
  {"x": 0.3089729090952595, "y": 0.17894689584840234},
  {"x": 0.34076710797218185, "y": 0.16253629877343306},
]
/** ARM/anterior (left) */
const t02615l = [
  {"x": 0.688645781695964, "y": 0.15239941484210354},
  {"x": 0.7158899678746651, "y": 0.15981472067107988},
  {"x": 0.7398457553654959, "y": 0.1761974856099923},
  {"x": 0.746539948126814, "y": 0.18847123523297826},
  {"x": 0.7584053016648984, "y": 0.22534579866640264},
  {"x": 0.7624053016648984, "y": 0.24242230957161137},
  {"x": 0.8014341916810174, "y": 0.3011761451924672},
  {"x": 0.8115245485104103, "y": 0.31825263785800284},
  {"x": 0.8403995320538676, "y": 0.3503778092926903},
  {"x": 0.8534091620592407, "y": 0.36814806616419526},
  {"x": 0.866355242421345, "y": 0.4200179390832857},
  {"x": 0.8952936984847176, "y": 0.4555584163469495},
  {"x": 0.869274515597325, "y": 0.46169526379893283},
  {"x": 0.8403995320538676, "y": 0.47194118128586193},
  {"x": 0.8346245353451762, "y": 0.46100153607230965},
  {"x": 0.7884245616756443, "y": 0.41590890552768617},
  {"x": 0.7595495781321869, "y": 0.38655868147883765},
  {"x": 0.746539948126814, "y": 0.36814806616419526},
  {"x": 0.7277553214127495, "y": 0.32577697724257876},
  {"x": 0.6858707849872726, "y": 0.2738537532796756},
  {"x": 0.6830149843312077, "y": 0.25266820881886737},
  {"x": 0.7078899678746651, "y": 0.21045954097600928},
  {"x": 0.7026553345779836, "y": 0.1758912315762886},
  {"x": 0.678636151690591, "y": 0.16295158636273633},
]
/** PALM (right) */
const t02652r = [
  {"x": 0.11155954847983395, "y": 0.45900153607230965},
  {"x": 0.059044195408762305, "y": 0.4733286367391083},
  {"x": 0.03602497877990262, "y": 0.4828808264994142},
  {"x": 0.020159603550875066, "y": 0.48698986005501377},
  {"x": 0.005753842616368195, "y": 0.49787613598508024},
  {"x": 0.01584422173923047, "y": 0.5040663527205493},
  {"x": 0.02870959696825802, "y": 0.5012914053347104},
  {"x": 0.04057497219728558, "y": 0.4931267075069971},
  {"x": 0.06573842191154756, "y": 0.49040516588399014},
  {"x": 0.058584582921820215, "y": 0.5054004388903099},
  {"x": 0.04757497219728558, "y": 0.5388597329741042},
  {"x": 0.04903458950443726, "y": 0.5470778000853033},
  {"x": 0.05772881118701291, "y": 0.5484652555385497},
  {"x": 0.07073842191154756, "y": 0.5525209198106634},
  {"x": 0.06781919211745378, "y": 0.5600452591952394},
  {"x": 0.07790957304789466, "y": 0.5607389869218625},
  {"x": 0.08945956164506802, "y": 0.5546021394698792},
  {"x": 0.10392878967675434, "y": 0.5546021394698792},
  {"x": 0.11255954847983395, "y": 0.5463840723586801},
  {"x": 0.12848838417825276, "y": 0.5463840723586801},
  {"x": 0.13857876992890322, "y": 0.5341103409753672},
  {"x": 0.15158837101301867, "y": 0.5129247782748859},
  {"x": 0.1674537534723606, "y": 0.46894118128586193},
  {"x": 0.13572299819409592, "y": 0.46721960318350875},
]
/** PALM (left) */
const t02652l = [
  {"x": 0.8432552555865791, "y": 0.4706627593882151},
  {"x": 0.869274515597325, "y": 0.4628042973545324},
  {"x": 0.892374502432091, "y": 0.4568925025167101},
  {"x": 0.9428898362223243, "y": 0.47055372583261557},
  {"x": 0.9789994530624632, "y": 0.48149333456682164},
  {"x": 1, "y": 0.4931267075069971},
  {"x": 0.9876936458237813, "y": 0.4992635914383266},
  {"x": 0.9718282922856969, "y": 0.49515455788272705},
  {"x": 0.9558994662276973, "y": 0.48698986005501377},
  {"x": 0.9400341126896129, "y": 0.48763021849815114},
  {"x": 0.9616744629363888, "y": 0.5265860015907913},
  {"x": 0.9616744629363888, "y": 0.5450499497095733},
  {"x": 0.9472686688629409, "y": 0.5450499497095733},
  {"x": 0.9428898362223243, "y": 0.5470778000853033},
  {"x": 0.9428898362223243, "y": 0.5546021394698792},
  {"x": 0.9327994793929314, "y": 0.5573236810928862},
  {"x": 0.9212494859755485, "y": 0.5497993781876565},
  {"x": 0.905384132437464, "y": 0.553908411743256},
  {"x": 0.8981494991407825, "y": 0.543662494256327},
  {"x": 0.879364872426718, "y": 0.543662494256327},
  {"x": 0.8534091620592407, "y": 0.5074816585495258},
]
/** LEG/anterior (right) */
const t02832r = [
  {"x": 0.31274790580395096, "y": 0.42282070036550845},
  {"x": 0.29110751699549836, "y": 0.48763021849815114},
  {"x": 0.2853325202868069, "y": 0.5149526286506159},
  {"x": 0.29018830166203335, "y": 0.5402471884273505},
  {"x": 0.3580920980982563, "y": 0.7034879216155155},
  {"x": 0.3609478601926444, "y": 0.7232859923834043},
  {"x": 0.3609478601926444, "y": 0.7417499405021863},
  {"x": 0.3479382687489482, "y": 0.7690723141753049},
  {"x": 0.3479382687489482, "y": 0.7991163389094689},
  {"x": 0.3580920980982563, "y": 0.8387124804452465},
  {"x": 0.3898228437361018, "y": 0.9090996707666047},
  {"x": 0.39851707505909667, "y": 0.93775616708883},
  {"x": 0.41869782727955923, "y": 0.9398373867480457},
  {"x": 0.4447170487286285, "y": 0.93775616708883},
  {"x": 0.4663574375370811, "y": 0.93370046633737},
  {"x": 0.463501675442693, "y": 0.920039315980157},
  {"x": 0.4866016622774589, "y": 0.8189144096773577},
  {"x": 0.489457424371847, "y": 0.7998100301567459},
  {"x": 0.4735920322720859, "y": 0.7629354667233216},
  {"x": 0.46927667215138447, "y": 0.7430840631512932},
  {"x": 0.48368242766315556, "y": 0.7267012617330345},
  {"x": 0.48368242766315556, "y": 0.713040038417129},
  {"x": 0.4866016622774589, "y": 0.6549799487625695},
  {"x": 0.4793670289807774, "y": 0.6031100758434791},
  {"x": 0.4952324210805385, "y": 0.5525209198106634},
  {"x": 0.4952324210805385, "y": 0.5348040687019905},
  {"x": 0.49237665898615035, "y": 0.521142845386085},
  {"x": 0.4663574375370811, "y": 0.5160616622062154},
  {"x": 0.4356266918992356, "y": 0.4887392520537507},
  {"x": 0.4317074572849322, "y": 0.46894118128586193},
  {"x": 0.4086074704501663, "y": 0.4525584163469495},
  {"x": 0.35363250007194297, "y": 0.42734503975008437},
]
/** LEG/anterior (left) */
const t02832l = [
  {"x": 0.6915649777485906, "y": 0.42182070036550845},
  {"x": 0.721980324704058, "y": 0.49040516588399014},
  {"x": 0.722980324704058, "y": 0.5238644234884382},
  {"x": 0.691645781695964, "y": 0.6071657401155929},
  {"x": 0.6506803641997603, "y": 0.7048753770687619},
  {"x": 0.6449054060527455, "y": 0.7369471427406175},
  {"x": 0.6597707981525066, "y": 0.7642695893724283},
  {"x": 0.6607707981525066, "y": 0.7936731827047626},
  {"x": 0.6549958014438152, "y": 0.8271324767885568},
  {"x": 0.6208054192179796, "y": 0.9063780926642515},
  {"x": 0.6101111878949848, "y": 0.9364220444397232},
  {"x": 0.5949304356745222, "y": 0.93775616708883},
  {"x": 0.5631362175167615, "y": 0.9350345889864768},
  {"x": 0.5403516293643736, "y": 0.9323130108841237},
  {"x": 0.5403516293643736, "y": 0.9090996707666047},
  {"x": 0.530261233973304, "y": 0.8557889731107821},
  {"x": 0.5202516425296078, "y": 0.8148053761217582},
  {"x": 0.5211708385822343, "y": 0.7991163389094689},
  {"x": 0.5331169960676921, "y": 0.7629354667233216},
  {"x": 0.5350362306819955, "y": 0.7458589740577859},
  {"x": 0.5205670026503092, "y": 0.7205644142810512},
  {"x": 0.5201708385822343, "y": 0.6946294231024867},
  {"x": 0.5244862372646125, "y": 0.6290450305426974},
  {"x": 0.5254862372646125, "y": 0.5860335831779435},
  {"x": 0.5154766458209163, "y": 0.5573236810928862},
  {"x": 0.5146208451648514, "y": 0.5313887628730141},
  {"x": 0.5152516425296078, "y": 0.519142845386085},
  {"x": 0.5374958287083088, "y": 0.5184491176594618},
  {"x": 0.553216982902458, "y": 0.5080157447192864},
  {"x": 0.5809208056691492, "y": 0.4686627593882151},
  {"x": 0.6238054192179796, "y": 0.4372034653044209},
  {"x": 0.6627707981525066, "y": 0.4242360061944848},
]
/** DORSUM FOOT (right) */
const t02851r = [
  {"x": 0.39851707505909667, "y": 0.9384499312947994},
  {"x": 0.39851707505909667, "y": 0.961022912969181},
  {"x": 0.40283247374147485, "y": 0.9671597604211642},
  {"x": 0.3869670816417137, "y": 0.9855703757358066},
  {"x": 0.37687672481232076, "y": 0.991760628950622},
  {"x": 0.3797324869067089, "y": 0.9978974764026053},
  {"x": 0.3927420783504052, "y": 1},
  {"x": 0.4605824408283896, "y": 1},
  {"x": 0.4735920322720859, "y": 0.9958162567433896},
  {"x": 0.4735920322720859, "y": 0.9821551063861765},
  {"x": 0.4793670289807774, "y": 0.9684938830702711},
  {"x": 0.47073627017769776, "y": 0.9637444910715341},
  {"x": 0.47651126688638923, "y": 0.9521110816520124},
  {"x": 0.46927667215138447, "y": 0.9350345889864768},
  {"x": 0.45049204543731997, "y": 0.9368373867480457},
  {"x": 0.42161706189386255, "y": 0.9405589648503989},
]
/** DORSUM FOOT (left) */
const t02851l = [
  {"x": 0.5384958287083088, "y": 0.9340345889864768},
  {"x": 0.530261233973304, "y": 0.9514173904047354},
  {"x": 0.534261233973304, "y": 0.9548326597543656},
  {"x": 0.5351169960676921, "y": 0.9623570356182877},
  {"x": 0.530261233973304, "y": 0.9719091524199012},
  {"x": 0.530261233973304, "y": 0.9808209837370697},
  {"x": 0.5301169960676921, "y": 0.9821551063861765},
  {"x": 0.530261233973304, "y": 0.9910668647446526},
  {"x": 0.5400362306819955, "y": 0.997203712196636},
  {"x": 0.5414958287083088, "y": 1},
  {"x": 0.628120779338681, "y": 0.9978974764026053},
  {"x": 0.6338957760473725, "y": 0.991760628950622},
  {"x": 0.60787659315998, "y": 0.9684938830702711},
  {"x": 0.6101111878949848, "y": 0.9582480020626881},
  {"x": 0.6093361911862932, "y": 0.9378373867480457},
  {"x": 0.5862362043515273, "y": 0.9371714364384601},
  {"x": 0.5631362175167615, "y": 0.9354499312947994},
]
/** UPPER BACK */
const t02451 = [
  {"x": 0.49961125372115517, "y": 0.17622533598572227},
  {"x": 0.4663574375370811, "y": 0.16847592574731224},
  {"x": 0.4317074572849322, "y": 0.16908845205439274},
  {"x": 0.4057517083557782, "y": 0.16056413090948993},
  {"x": 0.34508250665456003, "y": 0.15501195938885715},
  {"x": 0.31474790580395096, "y": 0.157039791524914},
  {"x": 0.31906328520549077, "y": 0.18985869068622466},
  {"x": 0.3292171145547989, "y": 0.21787482856531254},
  {"x": 0.34508250665456003, "y": 0.23767289933320132},
  {"x": 0.32483828191418224, "y": 0.27113217517732247},
  {"x": 0.3348478733578785, "y": 0.2834059065606353},
  {"x": 0.3487767379775549, "y": 0.3148373685083727},
  {"x": 0.3507767379775549, "y": 0.34285350638746054},
  {"x": 0.5024670158155433, "y": 0.3489903538394439},
  {"x": 0.6541400007877503, "y": 0.3455750480104675},
  {"x": 0.6579149974964418, "y": 0.31350324585926587},
  {"x": 0.6700053543258346, "y": 0.2834059065606353},
  {"x": 0.6838707849872726, "y": 0.2708537532796756},
  {"x": 0.6627707981525066, "y": 0.23567289933320132},
  {"x": 0.6830149843312077, "y": 0.2116845935901703},
  {"x": 0.691645781695964, "y": 0.154539791524914},
  {"x": 0.6180304225092881, "y": 0.15853629877343306},
  {"x": 0.5790016096165226, "y": 0.16747592574731224},
  {"x": 0.5354958287083088, "y": 0.16808845205439274},
]
/** LOWER BACK */
const t02452 = [
  {"x": 0.35263250007194297, "y": 0.3435750480104675},
  {"x": 0.3520920980982563, "y": 0.36814806616419526},
  {"x": 0.32083828191418224, "y": 0.4107442076999728},
  {"x": 0.47651126688638923, "y": 0.41590890552768617},
  {"x": 0.5255670026503092, "y": 0.41729636098093253},
  {"x": 0.6887899810398992, "y": 0.4124935996987098},
  {"x": 0.6541400007877503, "y": 0.37289745816293224},
  {"x": 0.6541400007877503, "y": 0.34596253994306014},
  {"x": 0.5761458089604578, "y": 0.34771193194179706},
  {"x": 0.44040168860792706, "y": 0.3473778092926903},
]
/** ARM/posterior (right) */
const t02614r = [
  {"x": 0.691645781695964, "y": 0.152039791524914},
  {"x": 0.6830149843312077, "y": 0.2100720672830898},
  {"x": 0.6656899942051333, "y": 0.236007003742635},
  {"x": 0.6858707849872726, "y": 0.27113217517732247},
  {"x": 0.721980324704058, "y": 0.3230554356195718},
  {"x": 0.7407649514181225, "y": 0.36195781294937995},
  {"x": 0.7595495781321869, "y": 0.3879461369320841},
  {"x": 0.8273899020484947, "y": 0.45347719668773373},
  {"x": 0.8432552555865791, "y": 0.47194118128586193},
  {"x": 0.8952936984847176, "y": 0.4568925025167101},
  {"x": 0.879364872426718, "y": 0.4418971930310441},
  {"x": 0.8634995188886336, "y": 0.41729636098093253},
  {"x": 0.8534091620592407, "y": 0.3763127639919086},
  {"x": 0.8403995320538676, "y": 0.35448684284828985},
  {"x": 0.8086052753344302, "y": 0.3230554356195718},
  {"x": 0.7912802852083558, "y": 0.28751494011623485},
  {"x": 0.7638649382528884, "y": 0.24653134312721092},
  {"x": 0.7595495781321869, "y": 0.21920893297474622},
  {"x": 0.7407649514181225, "y": 0.17761279143896866},
  {"x": 0.7147457685307299, "y": 0.16156413090948993},
]
  /** ARM/posterior (right) */
const t02614l = [
  {"x": 0.31474790580395096, "y": 0.15442726521783349},
  {"x": 0.3257575165284856, "y": 0.2100720672830898},
  {"x": 0.34516327204025665, "y": 0.23628544387995493},
  {"x": 0.3229825005389557, "y": 0.2738537532796756},
  {"x": 0.2833325202868069, "y": 0.320974215960356},
  {"x": 0.2650883148272674, "y": 0.3667606107109489},
  {"x": 0.24098832799250153, "y": 0.3947767485900368},
  {"x": 0.21542872385058393, "y": 0.42209912226315527},
  {"x": 0.1674537534723606, "y": 0.46921960318350875},
  {"x": 0.13857876992890322, "y": 0.46921960318350875},
  {"x": 0.10970378638544583, "y": 0.4596674499025491},
  {"x": 0.1205691592043686, "y": 0.44461877113339726},
  {"x": 0.1403537666375947, "y": 0.41590890552768617},
  {"x": 0.15444414274782597, "y": 0.3763127639919086},
  {"x": 0.16459799137797249, "y": 0.3565146932240198},
  {"x": 0.19524797163012137, "y": 0.3223616714136024},
  {"x": 0.2165729617561958, "y": 0.2889024138091544},
  {"x": 0.24398832799250153, "y": 0.24791879858045732},
  {"x": 0.24822294200834466, "y": 0.21920893297474622},
  {"x": 0.2670883148272674, "y": 0.17894689584840234},
  {"x": 0.28087292226049354, "y": 0.16628569077216998},
]
/** BUTTOCK */
const t02471 = [
  {"x": 0.32083828191418224, "y": 0.41088105515195617},
  {"x": 0.3078921244287245, "y": 0.43576034557906074},
  {"x": 0.28518830166203335, "y": 0.49787613598508024},
  {"x": 0.2873325202868069, "y": 0.5115373228216394},
  {"x": 0.31906328520549077, "y": 0.5221985096581987},
  {"x": 0.3797324869067089, "y": 0.5288040687019905},
  {"x": 0.4374824539936237, "y": 0.5301103409753672},
  {"x": 0.46927667215138447, "y": 0.5270013074197677},
  {"x": 0.4866016622774589, "y": 0.5224769680351917},
  {"x": 0.4952324210805385, "y": 0.5149526286506159},
  {"x": 0.5111612471385382, "y": 0.5143122702074785},
  {"x": 0.5212516425296078, "y": 0.524142845386085},
  {"x": 0.5573612208080699, "y": 0.5293887628730141},
  {"x": 0.6238054192179796, "y": 0.5348040687019905},
  {"x": 0.6830149843312077, "y": 0.5272797293174145},
  {"x": 0.7235207652394214, "y": 0.5177275395571086},
  {"x": 0.7205207652394214, "y": 0.49040516588399014},
  {"x": 0.698880337869292, "y": 0.4370944317488213},
  {"x": 0.6887899810398992, "y": 0.41090890552768617},
  {"x": 0.5949304356745222, "y": 0.4150179390832857},
  {"x": 0.5053862504298466, "y": 0.4160179390832857},
  {"x": 0.4158420651851711, "y": 0.41429636098093253},
]
/** LEG/posterior (right) */
const t02834r = [
  {"x": 0.5414958287083088, "y": 0.9398373867480457},
  {"x": 0.5631362175167615, "y": 0.9451714364384601},
  {"x": 0.5862362043515273, "y": 0.9451714364384601},
  {"x": 0.6101111878949848, "y": 0.9411714364384601},
  {"x": 0.6121111878949848, "y": 0.9240949437729246},
  {"x": 0.6497611681471336, "y": 0.8359909023428933},
  {"x": 0.6599149974964418, "y": 0.7998100301567459},
  {"x": 0.6599149974964418, "y": 0.7629354667233216},
  {"x": 0.6497611681471336, "y": 0.7430840631512932},
  {"x": 0.6459054060527455, "y": 0.7260609032898971},
  {"x": 0.6486803641997603, "y": 0.7082906464183921},
  {"x": 0.7065111352340484, "y": 0.5661821431265689},
  {"x": 0.7156649645833566, "y": 0.5409409161539738},
  {"x": 0.72380324704058, "y": 0.5184491176594618},
  {"x": 0.691645781695964, "y": 0.5246138154871751},
  {"x": 0.6411304093440541, "y": 0.5308040687019905},
  {"x": 0.5949304356745222, "y": 0.5318040687019905},
  {"x": 0.5631362175167615, "y": 0.5307228490427747},
  {"x": 0.5132516425296078, "y": 0.5201985096581987},
  {"x": 0.5104766458209163, "y": 0.5422750388030806},
  {"x": 0.5254862372646125, "y": 0.5882586357921045},
  {"x": 0.5192516425296078, "y": 0.6959635457515935},
  {"x": 0.5205670026503092, "y": 0.7198706500750818},
  {"x": 0.5341169960676921, "y": 0.7417499405021863},
  {"x": 0.5341169960676921, "y": 0.7595201973736914},
  {"x": 0.5225670026503092, "y": 0.7963947608071158},
  {"x": 0.5221708385822343, "y": 0.8148053761217582},
]
/** LEG/posterior (left) */
const t02834l = [
  {"x": 0.3999767116470867, "y": 0.9384499312947994},
  {"x": 0.42161706189386255, "y": 0.9425589648503989},
  {"x": 0.45049204543731997, "y": 0.9398373867480457},
  {"x": 0.46827667215138447, "y": 0.9364220444397232},
  {"x": 0.4643574375370811, "y": 0.9240949437729246},
  {"x": 0.47251126688638923, "y": 0.8783619547851637},
  {"x": 0.48368242766315556, "y": 0.8196081009246347},
  {"x": 0.4866016622774589, "y": 0.7991163389094689},
  {"x": 0.4735920322720859, "y": 0.7554111638180918},
  {"x": 0.47073627017769776, "y": 0.7458589740577859},
  {"x": 0.4802862635950807, "y": 0.7267012617330345},
  {"x": 0.48436016622774589, "y": 0.7158150222823142},
  {"x": 0.4856016622774589, "y": 0.6577548596690623},
  {"x": 0.4793670289807774, "y": 0.6044441620132397},
  {"x": 0.4802862635950807, "y": 0.5921704306299268},
  {"x": 0.4921516556948419, "y": 0.5546021394698792},
  {"x": 0.4961516556948419, "y": 0.5450499497095733},
  {"x": 0.49237665898615035, "y": 0.5188644234884382},
  {"x": 0.4605824408283896, "y": 0.5287228490427747},
  {"x": 0.4374824539936237, "y": 0.5301103409753672},
  {"x": 0.3826517215210123, "y": 0.5287228490427747},
  {"x": 0.3219825005389557, "y": 0.5225860015907913},
  {"x": 0.28587292226049354, "y": 0.5149526286506159},
  {"x": 0.29018830166203335, "y": 0.5388597329741042},
  {"x": 0.3580920980982563, "y": 0.7048753770687619},
  {"x": 0.3609478601926444, "y": 0.7356130930502031},
  {"x": 0.3479382687489482, "y": 0.7642695893724283},
  {"x": 0.3508575033632515, "y": 0.8100559111643288},
  {"x": 0.3580920980982563, "y": 0.8359909023428933},
]
/** FOOT/posterior (right) */
const t02851rp = [
  {"x": 0.5360362306819955, "y": 0.9385589648503989},
  {"x": 0.5341169960676921, "y": 0.9514173904047354},
  {"x": 0.5403516293643736, "y": 0.9623570356182877},
  {"x": 0.5414958287083088, "y": 0.9780994056347165},
  {"x": 0.5501266260730652, "y": 0.9930946786410364},
  {"x": 0.5659919796111496, "y": 0.9992315990517121},
  {"x": 0.5891554389658307, "y": 0.9978974764026053},
  {"x": 0.605020792503915, "y": 0.997203712196636},
  {"x": 0.6252015832860544, "y": 0.997203712196636},
  {"x": 0.6497611681471336, "y": 0.9944822070529752},
  {"x": 0.6599149974964418, "y": 0.9930946786410364},
  {"x": 0.6599149974964418, "y": 0.9855703757358066},
  {"x": 0.6411304093440541, "y": 0.9821551063861765},
  {"x": 0.6252015832860544, "y": 0.9746841362850864},
  {"x": 0.6151111878949848, "y": 0.9644381823188111},
  {"x": 0.6122554258005967, "y": 0.9405867787467827},
  {"x": 0.5949304356745222, "y": 0.9449742342000291},
  {"x": 0.5631362175167615, "y": 0.9449742342000291},
]
/** FOOT/posterior (left) */
const t02851lp = [
{"x": 0.39751707505909667, "y": 0.9398373867480457},
  {"x": 0.39751707505909667, "y": 0.9607444910715341},
  {"x": 0.37687672481232076, "y": 0.9760994056347165},
  {"x": 0.3667228569013359, "y": 0.9805425618394229},
  {"x": 0.35663250007194297, "y": 0.9805703757358066},
  {"x": 0.35063250007194297, "y": 0.9900946786410364},
  {"x": 0.3638670948069478, "y": 0.996203712196636},
  {"x": 0.3797324869067089, "y": 0.9978974764026053},
  {"x": 0.3999767116470867, "y": 0.997203712196636},
  {"x": 0.45480744411969815, "y": 0.9978974764026053},
  {"x": 0.463501675442693, "y": 0.9842362530866999},
  {"x": 0.4663574375370811, "y": 0.9644381823188111},
  {"x": 0.4735920322720859, "y": 0.9548326597543656},
  {"x": 0.4735920322720859, "y": 0.9486958123023823},
  {"x": 0.47073627017769776, "y": 0.9358373867480457},
  {"x": 0.4476362833429319, "y": 0.9409464203036453},
  {"x": 0.41869782727955923, "y": 0.9415867787467827},
]

export const bodyWoman = {
  'back': {
    t02102,
    t022l: t022r,
    t022r: t022l,
    t023,
    t02652l: t02652r,
    t02652r: t02652l,
    t02851r: t02851rp,
    t02851l: t02851lp,
    t02451,
    t02452,
    t02614l,
    t02614r,
    t02471,
    t02834r,
    t02834l
  },
  'front': {
    t02120,
    t022l,
    t022r,
    t023,
    t02420l,
    t02420r,
    t02615l,
    t02615r,
    t02480,
    t02485,
    t02652l,
    t02652r,
    t02832l,
    t02832r,
    t025,
    t02851r,
    t02851l,
    t02424,
  },
}