import React from 'react'
import {Alert, AlertButton, Platform} from 'react-native'

type Props = {
  title: string,
  message?: string,
  confirmButton?: AlertButton
  cancelButton?: AlertButton
  otherButton?: AlertButton
}

/**
 * Alert - react native component is only available on native devices, so we wrap to use it on web
 * Remember "otherButton" will be ignored, because confirm has only "ok", "cancel" states
 * 
 * @param title - the title of the alert dialog (native only)
 * @param message - the message of the alert or confirm dialog views
 * @param confirmButton - button title and confirm function, title is ignored on web
 * @param cancelButton - button title and cancel function, title is ignored on web
 * @param otherButton - middle button on a native device, ignored on web
 */
export function AlertWrapper ({title, message, confirmButton, cancelButton, otherButton}: Props) {
  if (Platform.OS === 'web') {
    if (!confirmButton && !cancelButton && !otherButton) alert(message)
    else {
      const result = confirm(message)
      if (result) {
        confirmButton?.onPress?.()
      } else {
        cancelButton?.onPress?.()
      }
    }
  }
  else Alert.alert(title, message, [
    (cancelButton ?? {}),
    (otherButton ?? {}),
    (confirmButton ?? {}),
  ])
}
