import { IDicomWrapperData } from '@Edvardas/dicomencoder/lib/types'
// IMPORTANT: Leave commented properties as an example for future use
export const dicomRequiredData: Partial<IDicomWrapperData> = {
  appName: 'SmartVisSolution',
  // accessionNumber: '8878KJHG',
  // patient: {
  //   id:'987fE-898R',
  //   fullName: 'First Last',
  //   age: 36,
  //   birthDate: new Date('1995 05 21'),
  //   position: 'HFP',
  //   sex: 'F',
  // },
  // createdAt: new Date(),
  study: {
    description: 'SmartVisSolution study'
  },
  series: {
    description: 'SmartVisSolution series'
  },
  // performingPhysicianFullName: 'Performing Full',
  // referringPhysicianFullName: 'Physician Full',
  // operatorsFullName: 'Operator Name',
  // device: {
  //   serialNumber: 'DEVICE687',
  //   manufacturer: {
  //     name: 'HANDYSCOPE',
  //     model: 'HND578'
  //   },
  // },
  institutionName: 'Kauno klinikos'
}