import React, { useEffect, useState, useContext } from 'react'
import {Platform, StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {TextInput} from 'react-native-gesture-handler'
import {css} from '../styles'
import {TextItem} from './TextItem'
import { AppContext, Theme } from '../contexts'

type Props = {
  label?: string
  placeholder?: string
  editable?: boolean
  helpInfo?: string
  error?: string
  value?: string
  onChangeText?: Function
  blockStyle?: StyleProp<ViewStyle>
  multiline?: Boolean
  multilineHeight?: number
  skin?: 'light' | 'dark'
}

export function InputItem ({
  placeholder,
  label,
  helpInfo,
  error,
  value,
  blockStyle,
  editable = true,
  onChangeText,
  multiline,
  multilineHeight,
  ...props
}: Props) {
  const appContext = useContext(AppContext)
  const [skin, setSkin] = useState<Theme>('dark')
  useEffect(() => {
    if (props.skin && props.skin !== skin) setSkin(props.skin)
    else if(!props.skin) setSkin(appContext?.state.skin ?? 'dark')
  }, [appContext])
  const getInputStyle = () => {
    let inputStyle = {}
    if (skin === 'light') {
      const backgroundColor = editable ? 'white' : css.colorInputBg + '10'
      inputStyle = Object.assign(inputStyle, {
        backgroundColor,
        borderWidth: 1,
        borderRadius: css.borderRadius/3,
        borderColor: css.colorTextDark + '50',
        height: 35,
        color: 'black',
      })
    } else {
      inputStyle = Object.assign(inputStyle, {backgroundColor: css.colorInputBg + (editable ? '' : '50')})
    }
    return inputStyle
  }
  const errorBorder = error ? {borderColor: css.colorError, borderWidth: 1} : {}
  const [inputValue, setInputValue] = useState(value)
  const [inputStyle, setInputStyle] = useState(getInputStyle())
  useEffect(() => {
    if (onChangeText) onChangeText(inputValue ?? '')
  }, [inputValue])
  useEffect(() => {
    setInputStyle(getInputStyle())
  }, [skin])
  return (
    <View style={[{flex: 1, alignSelf: 'stretch'}, blockStyle]}>
      {helpInfo && (
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <TextItem style={styles.label}>{label ? label : ''}</TextItem>
        </View>
      )}
      {label && !helpInfo && <TextItem style={styles.label}>{label}</TextItem>}
      <TextInput
        value={inputValue}
        onChangeText={(text: string) => {
          setInputValue(text)
        }}
        style={[
          styles.input,
          inputStyle,
          errorBorder,
          {...(multilineHeight ? {height: multilineHeight, textAlignVertical: 'top', paddingTop: css.padding/1.5} : {})},
        ]}
        placeholder={placeholder ? placeholder : 'Search'}
        placeholderTextColor={css.colorText + '50'}
        editable={editable}
        multiline={!!multiline}
      />
      {error && <TextItem style={styles.error}>{error}</TextItem>}
    </View>
  )
}
const styles = StyleSheet.create({
  input: {
    marginTop: css.padding/8,
    paddingVertical: css.padding / 3,
    paddingHorizontal: css.padding,
    borderRadius: css.borderRadius / 2,
    fontFamily: css.fontFamily,
    fontVariant: ['lining-nums'],
    color: css.colorTextLight,
    minHeight: Platform.OS === 'android' ? css.android.minRequiredInputHeight : css.ios.minRequiredInputHeight,
  },
  label: {
    color: css.colorText,
    paddingBottom: css.padding / 2,
    fontSize: 13,
  },
  error: {
    color: css.colorError,
    fontSize: 12,
  },
  readonlyInput: {
    opacity: 0.5,
  },
})
