import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import {ScrollView} from 'react-native-gesture-handler'
import {BlockItem, ButtonRounded, CalendarPicker, EventScreenStatus, EventScreenPatient, ViewRow, EventDiagnosesList, Toast} from '../components'
import { ChatList } from '../components/ChatList'
import { Hr } from '../components/Hr'
import {ScreenWrapper} from '../components/ScreenWrapper'
import { SelectPicker } from '../components/SelectPicker'
import { TextItem } from '../components/TextItem'
import {css} from '../styles'
import {consultationStatusOptions, EventStatus, EVENTS_ROUTE_NAME, iconsMapper, IDiagnosis, IEvent, IPatient, ISpecialist, IVisit, UserType, visitStatusOptions} from '../types'
import { fullDateTime } from '../utils/helpers'
import { Formik } from 'formik'
import { EventScreenContainerProps } from '../containers'
import { isEventEditableForUser, eventMessageByStatus, eventTitleByType, checkIsEventChanged, IEventFormValues, combineDiagnoses, createEventFormValues } from '../utils'
import { InputItem } from '../components/InputItem'
import faker from 'faker'
import { AppContext } from '../contexts'
import { useContext } from 'react'
import { CREATE_EVENT_ROUTE_NAME, HOME_ROUTE_NAME, StackParams } from '../types'

type NavigationProps = StackNavigationProp<StackParams, typeof CREATE_EVENT_ROUTE_NAME>

export function EventScreen (props: {eventId?: string, route: any} & EventScreenContainerProps) {
  const navigation = useNavigation<NavigationProps>()
  const appContext = useContext(AppContext)
  // after save/delete we scroll to the top
  const scrollRef = useRef<ScrollView>(null)
  const [initialValues, setInitialValues]= useState<IEventFormValues>({...createEventFormValues})
  const routeName = props.route.name
  const [showSaveButton, setShowSaveButton] = useState(true)

  // read event data, set everything to initialValues
  useEffect(() => {
    let e: Partial<IEvent> = {}
    let d: Partial<IDiagnosis>[] = []
    // if event list item, we read event data related to event id
    if (routeName !== CREATE_EVENT_ROUTE_NAME) {
      e = props.eventById(props.route?.params?.eventId) ?? {}
      d = props.diagnosesByIds(e.diagnosesIds)
    }
    // else if new event, then we read everything from app context
    else {
      e = appContext?.state?.createEventForm?.event ?? {}
      d = appContext?.state?.createEventForm?.diagnoses ?? []
    }
    const eventValues = {...createEventFormValues}
    const patient = props.findPatientByUid(e.patientUid)
    const gender = e.gender ?? patient?.gender
    const iv = {
      ...eventValues,
      event: e,
      diagnoses: d,
      currentMessage: '',
      patient,
      gender,
      flashMessage: (e.status && props.auth.user?.type ? eventMessageByStatus(props.auth.user?.type)[e.status] : '')
    }
    setInitialValues(iv)
  }, [props.route, appContext?.state?.createEventForm])

  const wrapWebEventDetails = (children: JSX.Element, wrap:boolean) => {
    if (Platform.OS === 'web') {
      return <div className={`event-details-wrapper${wrap ? ' odd' : ''}`}>
        {children}
      </div>
    }
    return children
  }
  const wrapInsideWebColumns = (children: JSX.Element) => {
    if (Platform.OS === 'web') {
      return <div className={`events-columns-wrapper${routeName === CREATE_EVENT_ROUTE_NAME ? ' new-event' : ''}`}>
        {children}
      </div>
    }
    return children
  }

  const styles = StyleSheet.create({
    scrollView: {},
    noDiagnoses: {
      padding: css.padding * 1.5,
      justifyContent: 'center',
      borderRadius: css.borderRadius,
      marginBottom: css.padding/2,
      width: '100%',
      flexGrow: 1,
    },
    footerSpace: {
        paddingBottom: 110
    },
    eventDetails: {
      backgroundColor: appContext?.state.skin === 'light' ? css.colorDarkBg + '05' : css.colorDarkBgLighten2, // '#f5f5f6'
    }
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={true}
      validate={(values) => {
        if (routeName === CREATE_EVENT_ROUTE_NAME) {
          const formValues = appContext?.state?.createEventForm ?? {...createEventFormValues}
          if (checkIsEventChanged(formValues, values)) {
            appContext?.dispatch({...(appContext.state ?? {}), createEventForm: values})
          }
        }
      }}
      onSubmit={async (values, actions) => {
        let event: Partial<IEvent> | undefined
        // NEXT: get ID from the server, how to get it? Through dispatch function???
        const eventId = values.event.id ?? faker.datatype.uuid().slice(0, 3)
        event = {...values.event, id: eventId, gender: values.gender ?? values.event.gender}
        if (!event.patientUid && props.auth.user?.type === 'patient') {
          event.patientUid = props.auth.user.uid
          if (!event.status) event.status = EventStatus.pendingReview
        }
        if (!event.specialistUid && props.auth.user?.type === 'specialist') {
          event.specialistUid = props.auth.user.uid
        }
        // NEXT: later in other versions of app give possibility to choose from clinics list???
        if (!event.clinicId) event.clinicId = 'kauno_klinikos'
        if (values.currentMessage) {
          if (!event.comments) event.comments = []
          if (props.auth?.user && values.currentMessage) {
            // TEST: simulate reply from client, remove after test!!!
            // e.comments.push({
            //   message: 'Some reply back immediately before specialist\'s message',
            //   user: {
            //     type: UserType.patient,
            //     fullName: props.auth.user.fullName,
            //     email: props.auth.user.email,
            //     phone: props.auth.user.phone
            //   },
            //   date: new Date().toISOString(),
            // })
            // if user settings does allow to show personal clinic contacts, then we assign clinic id
            if (props.auth.user.type === 'specialist' && (props.auth.user as ISpecialist).settings?.showPersonalContacts && values.event.clinicId) {
              event.comments.push({
                message: values.currentMessage,
                date: new Date().toISOString(),
                clinicId: event.clinicId
              })
            }
            // otherwise we show full user contacts
            else {
              event.comments.push({
                message: values.currentMessage,
                user: {
                  uid: props.auth.user.uid,
                  type: props.auth.user.type
                },
                date: new Date().toISOString(),
              })
            }
            // if (routeName === CREATE_EVENT_ROUTE_NAME) {
            //   // actions.setFieldValue('createEventForm.currentMessage', '')
            //   let state = appContext?.state ?? {}
            //   state = {...state, createEventForm: values}
            //   appContext?.dispatch(state)
            // }
          }
        }
        if (props.auth.user?.type === UserType.patient && values.event.status === EventStatus.needsReply) event.status = EventStatus.pendingReview
        else if (event.status === EventStatus.createVisit) {
          event.status = EventStatus.completedWithIssues
          if (props.auth.user?.type === UserType.specialist && values.appointmentDate) {
            const v: Partial<IVisit> = {}
            v.appointmentDate = values.appointmentDate.toISOString()
            await props.createEvent({
              ...v,
              specialistUid: props.auth.user.uid,
              patientUid: event.patientUid,
              status: EventStatus.pending,
            })
          }
        }
        else if (event.status === EventStatus.pending) event.status = EventStatus.completed

        if (routeName === CREATE_EVENT_ROUTE_NAME) {
          await props.createEvent({...event, ...event.patientUid ? {} : {gender: values.gender}}, values.diagnoses)
          // If page is new event after save we reset the page with empty values
          // Event is saved into the event list and new event page is empty
          if (eventId) {
            Toast.showWithGravity('Event was created', Toast.SHORT, Toast.CENTER);
            // reset data in app new event context
            navigation.navigate(HOME_ROUTE_NAME)
          }
          appContext?.dispatch({...appContext?.state, createEventForm: undefined})
          // actions.resetForm({values: createEventFormValues})
          // actions.setSubmitting(false)
        } else {
          await props.updateEvent({...event, ...(event.patientUid ? {} : {gender: values.gender})}, values.diagnoses)
          Toast.showWithGravity('Event was updated', Toast.SHORT, Toast.CENTER);
          // If page is event list item, then we stay on the same page
          // we leave the same data by saving values to initialValues
          actions.resetForm({values: {...values, event}})
        }
        actions.setSubmitting(false)
        scrollRef.current?.scrollTo({y:0, animated:false})
      }}
    >
      {({handleReset, handleSubmit, setFieldValue, setValues, values, initialValues}) => {
        const isEventChanged = (routeName === CREATE_EVENT_ROUTE_NAME && checkIsEventChanged(createEventFormValues, values)) || (routeName !== CREATE_EVENT_ROUTE_NAME && checkIsEventChanged(initialValues, values))
        const photoCallback = (newDiagnoses: Partial<IDiagnosis>[]) => {
          const diagnoses = combineDiagnoses(values.diagnoses, newDiagnoses, values.event.id)
          setFieldValue(
            'diagnoses',
            diagnoses,
            true
          )
          scrollRef.current?.scrollTo({y:0, animated:false})
        }
        const isEventEditable = isEventEditableForUser(values.event, props.auth.user)
        return <ScreenWrapper
          showFixedCamera={isEventEditable}
          photoCallback={photoCallback}
          preventOnDataChange={isEventEditable ? {
            navigation,
            hasUnsavedChanges: isEventChanged,
            onSavePressed: () => {
              handleSubmit()
              setTimeout(() => {
                navigation.navigate(EVENTS_ROUTE_NAME)
              }, 0);
            }
          } : undefined }
          circleButton={isEventChanged ? {
            iconName: iconsMapper.save,
            color: css.colorSuccess,
            style: {
              paddingLeft: 2,
              display: showSaveButton ? 'flex' : 'none'
            },
            onPress: handleSubmit
          } : undefined }
          wrapperStyle={{
            paddingHorizontal: 0
          }}
        >
          <ScrollView
            ref={scrollRef}
            style={styles.scrollView}
            contentContainerStyle={Platform.OS === 'web' ? {flex: 1}: {}}
            onScroll={event => {
              const ene = event.nativeEvent
              setShowSaveButton(ene.layoutMeasurement.height + ene.contentOffset.y < ene.contentSize.height - 150)
            }}
            scrollEventThrottle={16}
          >
            <>
              {!isEventEditable &&
                <EventScreenStatus skin={appContext?.state.skin ?? 'dark'} user={props.auth.user} event={values.event} message={values.flashMessage} onPressAssignEvent={(eventUpdated) => {
                  setFieldValue('event', eventUpdated, true)
                }} />
              }
              {props.auth?.user?.type === 'specialist' &&
                <EventScreenPatient skin={appContext?.state.skin ?? 'dark'} event={values.event} patient={props.patientByUid(values.event.patientUid)} editable={isEventEditable} assignPatientUid={(patientUid) =>{
                  const patient = props.findPatientByUid(patientUid)
                  let event = Object.assign({}, {...values.event, patientUid, gender: values.gender ?? values.event.gender ?? patient?.gender ?? 'male'})
                  // if (patient && patient.gender !== values.gender) {
                  setFieldValue('gender', event.gender, true)
                  // }
                  setFieldValue('event', event, true)
                }}>
                  {values.event?.comments && values.event.comments.length > 0 &&
                    <>
                      <Hr />
                      <ChatList user={props.auth.user as IPatient | ISpecialist} clinicById={props.clinicById} comments={values.event.comments} eventStatus={values.event.status} />
                    </>
                  }
                </EventScreenPatient>
              }

              {props.auth?.user?.type === UserType.patient && values.event?.comments && values.event.comments.length > 0 &&
                <BlockItem title='Messages' skin={appContext?.state.skin ?? 'dark'}>
                  <ChatList user={props.auth.user as IPatient | ISpecialist} clinicById={props.clinicById} comments={values.event?.comments} eventStatus={values.event?.status} />
                </BlockItem>
              }

              {wrapInsideWebColumns(<>
              
                <EventDiagnosesList
                  hasAI={props.auth.user?.subscriptions?.aiService}
                  editable={isEventEditable}
                  getAIData={props.getAIData}
                  eventGender={values.gender ?? values.event.gender ?? values.patient?.gender ?? 'male'}
                  patient={values.patient}
                />

                  {isEventEditable && values.diagnoses.length === 0 &&
                    <View style={styles.noDiagnoses}>
                      <TextItem style={{ textAlign: 'center', maxWidth: 250, lineHeight: 25, alignSelf: 'center', color: appContext?.state.skin === 'light' ? css.colorText : css.colorTextLight }}>
                        To get new diagnoses, use camera or upload button below
                      </TextItem>
                    </View>
                  }
                  {wrapWebEventDetails(<>
                  {isEventEditable && values.diagnoses.length > 0 &&
                    <BlockItem
                      skin={appContext?.state.skin ?? 'dark'}
                      title={`${eventTitleByType(values.event.type)} details`}
                      subtitle={values.event?.createdAt ? fullDateTime(values.event?.createdAt) : undefined}
                      iconNameLeft={iconsMapper.doc}
                      style={styles.eventDetails}
                    >
                      {props.auth?.user?.type === 'specialist' &&
                        <ViewRow style={{zIndex: 1}}>
                          <SelectPicker skin={appContext?.state.skin ?? 'dark'} wrapperStyle={{marginBottom: css.padding}} dropdownStyle={{}} options={values.event?.type === 'consultation' ? consultationStatusOptions : visitStatusOptions} selected={values.event.status}
                            setSelected={(status: EventStatus) => {
                              setFieldValue('event', {...(values.event ?? {}), status})
                            }}
                          />
                          {values.event.status === 'create-visit' &&
                            <CalendarPicker
                              date={
                                values.appointmentDate
                              }
                              setDate={(date) => {
                                setFieldValue('event', {...(values.event ?? {}), appointmentDate: date})
                              }}
                            />
                          }
                        </ViewRow>
                      }
                      <InputItem multiline={true} multilineHeight={Platform.OS === 'web' && values.diagnoses.length % 2 === 1 ? 204 : 100} value={values.currentMessage} onChangeText={(currentMessage: string) => {
                        setFieldValue('currentMessage', currentMessage, false)
                      }} blockStyle={{height: 135}} label="Comment in general:" placeholder={`Message/comment about diagnosis, ${props.auth.user?.type === 'specialist' ? 'patient' : 'specialist'} will see this message`} />
                      <ViewRow style={Platform.OS === 'web' ? {minWidth:300, alignSelf: 'flex-end'} : {}}>
                        <ButtonRounded style={{marginTop: css.padding, marginRight: css.padding}} borderColor={css.colorError} title="Delete" onPress={() => {
                          if (values.event?.id) {
                            props.deleteEvent(values.event.id)
                          }
                          appContext?.dispatch({...appContext.state, createEventForm: {...createEventFormValues}})
                          handleReset()
                          scrollRef.current?.scrollTo({y:0, animated:false})
                          navigation.navigate(HOME_ROUTE_NAME)
                        }} />
                        <ButtonRounded style={{marginTop: css.padding, backgroundColor: css.colorSuccess}} title={props.auth?.user?.type === UserType.specialist ? 'Save' : 'Send'} onPress={() => {
                          handleSubmit()
                          scrollRef.current?.scrollTo({y:0, animated:true})
                        }} />
                      </ViewRow>
                    </BlockItem>
                  }
                </>, values.diagnoses.length % 2 === 0)}

              </>)}

              {/* FOOTER SPACER VIEW (leaves some extra spacing for camera/upload icons) */}
              <View style={isEventEditable && values.diagnoses.length > 0 ? styles.footerSpace : undefined}></View>
            </>
          </ScrollView>
        </ScreenWrapper>
      }}
    </Formik>
  )
}
