export const feet = {
  t02852r: [
    {"x": 0.23395081233586218, "y": 0.0},
    {"x": 0.18542458828234435, "y": 0.017215676145411344},
    {"x": 0.1803661189430646, "y": 0.05186987747900869},
    {"x": 0.19476157426288845, "y": 0.10235229038493444},
    {"x": 0.22255535701603832, "y": 0.14877903445882817},
    {"x": 0.22255535701603832, "y": 0.17343322667258898},
    {"x": 0.20615702958271231, "y": 0.18165129378378808},
    {"x": 0.18842458828234435, "y": 0.141948431920712},
    {"x": 0.18086468288631352, "y": 0.10918289292305061},
    {"x": 0.17702913296252049, "y": 0.07919228307155511},
    {"x": 0.15551673896413712, "y": 0.03870747919984159},
    {"x": 0.1316198475875622, "y": 0.04070747919984159},
    {"x": 0.12138597023044316, "y": 0.05031301544404192},
    {"x": 0.1163275008911634, "y": 0.06186048277058591},
    {"x": 0.1163275008911634, "y": 0.09413422327373533},
    {"x": 0.1099905149106193, "y": 0.09413422327373533},
    {"x": 0.09103515419476461, "y": 0.09146602357470453},
    {"x": 0.07330272133868976, "y": 0.09968409068590364},
    {"x": 0.06824424777726344, "y": 0.11740096003424973},
    {"x": 0.07585974617692533, "y": 0.1461108256399608},
    {"x": 0.07585974617692533, "y": 0.15838456614311022},
    {"x": 0.05690438123892409, "y": 0.15699709245019072},
    {"x": 0.04295190108086465, "y": 0.16660263325430935},
    {"x": 0.039171948382849237, "y": 0.18165129378378808},
    {"x": 0.04295190108086465, "y": 0.21025443906220065},
    {"x": 0.027776493063025376, "y": 0.21302936820836652},
    {"x": 0.008821130763865728, "y": 0.21847250617339975},
    {"x": 0.0024841468943949028, "y": 0.26489925936713005},
    {"x": 0.017659555440002497, "y": 0.3154883789205995},
    {"x": 0.03155644787211407, "y": 0.37696381088348163},
    {"x": 0.11888452572939898, "y": 0.6174223460509576},
    {"x": 0.16563367764269665, "y": 0.7512593764687496},
    {"x": 0.2149954516200075, "y": 0.8919270915031869},
    {"x": 0.2618001923147897, "y": 0.9657829569370073},
    {"x": 0.30988343276224994, "y": 0.9985484868148321},
    {"x": 0.35988343276224994, "y": 0.999},
    {"x": 0.40849578626531646, "y": 0.9657829569370073},
    {"x": 0.4312866969049642, "y": 0.9097506811788315},
    {"x": 0.44012513583084556, "y": 0.8195654200895846},
    {"x": 0.40849578626531646, "y": 0.6692921824907018},
    {"x": 0.3807020035121666, "y": 0.5545594221554828},
    {"x": 0.36169106245912047, "y": 0.4684298253383496},
    {"x": 0.350351187476488, "y": 0.34281078907306417},
    {"x": 0.34145718510200146, "y": 0.2608435768553432},
    {"x": 0.31616483840560267, "y": 0.1788763646376222},
    {"x": 0.2959309610484836, "y": 0.13650529395567876},
    {"x": 0.2883710556524528, "y": 0.08730362073561915},
    {"x": 0.2756970836913646, "y": 0.04770747919984159},
  ],
  t02852l: [
    {"x": 0.7639783679963053, "y": 0.00},
    {"x": 0.7192320797522167, "y": 0.06275614884915688},
    {"x": 0.7103380773777301, "y": 0.09690915241990121},
    {"x": 0.6969397330558179, "y": 0.15838456614311022},
    {"x": 0.6766502584729213, "y": 0.21302936820836652},
    {"x": 0.6678118533242122, "y": 0.2580686477091773},
    {"x": 0.6450765061331698, "y": 0.43566429546052476},
    {"x": 0.6311240344194035, "y": 0.5381232879330846},
    {"x": 0.6033302178890811, "y": 0.6391948249523981},
    {"x": 0.5780378711926824, "y": 0.7484844655622569},
    {"x": 0.5666424327614447, "y": 0.8277834872007837},
    {"x": 0.5666424327614447, "y": 0.8987577031611397},
    {"x": 0.5868763438957362, "y": 0.953402523466069},
    {"x": 0.6197285622109934, "y": 0.9929986650018466},
    {"x": 0.667285622109934, "y": 1},
    {"x": 0.7272905490914965, "y": 0.9794441802529128},
    {"x": 0.769036837335585, "y": 0.9288550606994433},
    {"x": 0.7955520527817119, "y": 0.870154539643054},
    {"x": 0.8625906877221994, "y": 0.6788977050551472},
    {"x": 0.9309078561925374, "y": 0.502582847148767},
    {"x": 0.9726540768822811, "y": 0.3701331992255289},
    {"x": 0.9789910797514114, "y": 0.34281078907306417},
    {"x": 0.9954450212991013, "y": 0.2799479198966088},
    {"x": 0.9979464235786799, "y": 0.22807804697751835},
    {"x": 0.9701526746027025, "y": 0.21580431559420551},
    {"x": 0.9638156717335722, "y": 0.20897370393625278},
    {"x": 0.9638156717335722, "y": 0.19115011426060816},
    {"x": 0.9638156717335722, "y": 0.17343322667258898},
    {"x": 0.9423032608466025, "y": 0.15838456614311022},
    {"x": 0.9284063863586138, "y": 0.1461108256399608},
    {"x": 0.9309078561925374, "y": 0.11462603088808385},
    {"x": 0.924570853323407, "y": 0.09968409068590364},
    {"x": 0.9081725090014948, "y": 0.09146602357470453},
    {"x": 0.8942200372877285, "y": 0.09413422327373533},
    {"x": 0.8853816321390194, "y": 0.08463542103658835},
    {"x": 0.8853816321390194, "y": 0.0668118176811889},
    {"x": 0.8803231627997398, "y": 0.05731301544404192},
    {"x": 0.8537522825734903, "y": 0.04770747919984159},
    {"x": 0.8423568103650804, "y": 0.054538081737957766},
    {"x": 0.8297384694069422, "y": 0.06958675138727306},
    {"x": 0.8234014665378119, "y": 0.11195782206921649},
    {"x": 0.8006105221209917, "y": 0.17610143549145632},
    {"x": 0.786713647633003, "y": 0.18431950260265542},
    {"x": 0.7803766447638727, "y": 0.17610143549145632},
    {"x": 0.7842122453534243, "y": 0.14877903445882817},
    {"x": 0.7930506505021333, "y": 0.13650529395567876},
    {"x": 0.8170644636686816, "y": 0.09007854988178503},
    {"x": 0.8208443994781107, "y": 0.0668118176811889},
    {"x": 0.8183429971985321, "y": 0.03543374325661046},
    {"x": 0.806947524990122, "y": 0.020385075887254307},
    {"x": 0.7842122453534243, "y": 0.010886270230168624},
  ]
}