import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, Platform, View } from 'react-native'
import { iconsMapper, IPatient } from '../types'
import { ModalView } from './ModalView'
import { TextItem } from './TextItem'
import { useSelector } from 'react-redux'
import { filterPatientsBySearchQuery, filterPatientsByUid, RootState } from '../store'
import { SearchInput } from './SearchInput'
import {ViewRow } from './ViewRow'
import { css } from '../styles'
import { BlockItem } from './BlockItem'

type Props = {
	patientUid?: string
	toggleModal: boolean
	onClose: (patientUid?: string) => void
	disabled?: boolean
}

export function PatientsListDialog ({ patientUid, toggleModal, onClose, disabled = false }: Props) {
	const [searchQuery, setSearchQuery] = useState('')
	const attachedPatient = useSelector((state: RootState) => filterPatientsByUid(state.patients, {userUid: patientUid}))
	const filteredPatients = useSelector((state: RootState) => filterPatientsBySearchQuery(state.patients, {query: searchQuery}))
	const getFullName = (patient?: Partial<IPatient>) => {
		if (patient && patient.fullName && patient.fullName.firstName?.length > 0 && patient.fullName.lastName?.length > 0) {
			return patient.fullName.firstName + ' ' + patient.fullName.lastName
		}
		return undefined
	}

	useEffect(() => {
		setSearchQuery('')
	}, [toggleModal])
	return (
		<ModalView
      title={!disabled ? "Patients list" : "Patient"}
      subtitle={!disabled ? "Attach the patient to the event" : "Event is locked"}
      show={toggleModal}
      onClose={() => onClose(patientUid)}
      iconName={iconsMapper.patient}
      style={{height: '100%', ...(Platform.OS === 'web' ? {maxWidth: 640} : {})}}
    >
        <View>
					{!disabled &&
						<View style={styles.searchBlock}>
							<ViewRow>
								<SearchInput
									search={setSearchQuery}
									searchQuery={searchQuery}
									placeholder='Search ID, UID or full name'
								/>
							</ViewRow>
						</View>
					}
					{patientUid && attachedPatient?.length > 0 &&
						<BlockItem
							title={attachedPatient[0].id.toUpperCase()}
							subtitle={getFullName(attachedPatient[0])}
							subTitleStyle={getFullName(attachedPatient[0]) ? {backgroundColor: ''} : undefined}
							buttonTitleRight={!disabled ? "Remove" : undefined}
							styleIconRight={{borderColor: css.colorError, borderStyle: 'dashed'}}
							onRightIconPress={() => {
								onClose()
							}}
							skin='dark'
						/>
					}
					{!disabled &&
						<FlatList
							nativeID="patients-list"
							data={filteredPatients.filter(p => p.value.uid !== patientUid)}
							showsVerticalScrollIndicator={Platform.OS !== 'web'}
							onContentSizeChange={() => {
								
							}}
							ListEmptyComponent={<View>
								{!!searchQuery && searchQuery.length > 0 &&
									<TextItem style={styles.emptyFlatList}>
										No results found, try another search
									</TextItem>
								}
							</View>}
							renderItem={result => (
								<BlockItem
									key={'patients-list-'+result.index}
									title={result.item.value.id.toUpperCase()}
									subtitle={searchQuery && result.item.value?.fullName ? `${result.item.value.fullName.firstName} ${result.item.value.fullName.lastName}` : undefined}
									buttonTitleRight={!disabled ? "Attach" : undefined}
									onRightIconPress={() => {
										onClose(result.item.value.uid)
									}}
									skin='dark'
									onPress={() => {
										onClose(result.item.value.uid)
									}}
									touchable={false}
									onTitlePress={() => onClose(result.item.value.uid)}
								/>
							)}
						/>
					}
				</View>
		</ModalView>
	)
}

const styles = StyleSheet.create({
	searchBlock: {
		backgroundColor: css.colorDarkBgLighten3,
		padding: css.padding,
		borderRadius: css.borderRadius,
		marginBottom: css.padding / 2
	},
	buttonsBlock: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	emptyFlatList: {
		padding: css.padding,
		textAlign: 'center'
	},
})
