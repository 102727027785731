import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, Platform, TouchableOpacity, ViewProps} from 'react-native'
import { css } from '../styles'
import { BodyLocalization, BodyLocalizationSNOMED, Gender, iconsMapper, localizationImageRatio, LocalizationPosition, LocalizationSide, LocalizationSize } from '../types'
import { ButtonRounded } from './ButtonRounded'
import { ModalView } from './ModalView'
import { TextItem } from './TextItem'
import { ViewRow } from './ViewRow'
import { SvgXml } from 'react-native-svg'
import LocalizationPointer from '../assets/picts/localization_pointer.svg'
import LocalizationHighlight from '../assets/picts/localization_body_highlight.svg'
import { getSelectedAreaCode, localizationImage } from '../utils/localization'
import { IconOutlined } from './IconOutlined'

type Props = {
  toggleModal: boolean
  initialLocalization?: BodyLocalization
  initialGender?: Gender
  onClose: () => void
  onSave?: (localization: BodyLocalization, gender?: Gender) => void
}

export function LocalizationModal ({toggleModal, onClose, initialGender, initialLocalization, onSave}: Props) {
  const pointerWidth = 40
  const [bodyImageSize, setBodyImageSize] = useState<LocalizationSize>({w:0, h:0})
  const getScreenPosition = (position?: LocalizationPosition) => {
    return position ? {
      x: position.x * bodyImageSize.w - pointerWidth/2,
      y: position.y * bodyImageSize.h - pointerWidth/2,      
    } : {x: 0, y: 0}
  }
  const getPointerPosition = () => {
    const position = initialLocalization?.localizationPosition
    return position ? {
      x: position.x,
      y: position.y,      
    } : {x: 0, y: 0}
  }
  const [pointerPosition, setPointerPosition] = useState<LocalizationPosition>(getScreenPosition(initialLocalization?.localizationPosition))
  const [pointerDevicePosition, setPointerDevicePosition] = useState({x:0, y:0}) 
  const [selectedSkinAreaCode, setSelectedSkinAreaCode] = useState<string | undefined>()
  const [selectedSkinAreaTitle, setSelectedSkinAreaTitle] = useState<string | undefined>()
  const [gender, setGender] = useState<Gender | undefined>(initialGender ? initialGender : 'male')
  const [bodySide, setBodySide] = useState<LocalizationSide | undefined>(initialLocalization?.localizationSide ? initialLocalization.localizationSide : 'front')
  const [imageRatio, setImageRatio] = useState(localizationImageRatio[gender!][bodySide!])
  const [imageHeight, setImageHeight] = useState(0)
  const cursor = Platform.OS === 'web' ? {cursor: !!onSave ? 'pointer' : 'auto'} : {}
  const sideLabelHW = 50
  const sideLabel = {
    position: 'absolute',
    height: sideLabelHW,
    width: sideLabelHW,
    borderRadius: sideLabelHW/2,
    backgroundColor: css.colorTextLight + '20',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const styles = StyleSheet.create({
    localizationBlock: {
      overflow: 'hidden',
      borderRadius: css.borderRadius/3,
      borderWidth: 1,
      borderColor: css.colorPrimary + '50',
      marginBottom: css.padding,
      flexGrow: 1,
      backgroundColor: css.colorDarkBgLighten2
    },
    localizationContent: {
      alignItems: 'center',
      justifyContent: 'center',
      ...cursor,
      flexGrow: 1,
    },
    localizationFooter: {
      alignItems: 'center',
      backgroundColor: css.colorPrimary + '50',
      padding: css.padding,
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
    },
    tlk: {
      fontSize: 10,
      lineHeight: 10,
      textAlign: 'center',
      color: css.colorText
    }
  })

  useEffect(() => {
    const areaCode = getSelectedAreaCode(pointerPosition, gender, bodySide)
    if (areaCode) {
      setSelectedSkinAreaTitle(BodyLocalizationSNOMED[areaCode])
      setSelectedSkinAreaCode(areaCode.replace('t', '').replace('r', '').replace('l', ''))
      setPointerDevicePosition(getScreenPosition(pointerPosition))
    } else {
      setSelectedSkinAreaCode(undefined)
      setSelectedSkinAreaTitle(undefined)
      setPointerDevicePosition(getScreenPosition(initialLocalization?.localizationPosition))
    }
  }, [pointerPosition])
  useEffect(() => {
    const iRatio = bodySide && gender ? localizationImageRatio[gender][bodySide] : 1
    const height = bodySide === 'feet' ? imageHeight/2 : imageHeight
    setImageRatio(iRatio)
    setBodyImageSize({h:height, w:imageHeight*iRatio})
    setPointerPosition(getPointerPosition())
  }, [gender, bodySide])

  useEffect(() => {
    const il = initialLocalization
    setGender(initialGender ? initialGender : 'male')
    setBodySide(il?.localizationSide ? il.localizationSide : 'front')
  }, [initialLocalization, initialGender])
  useEffect(() => {
    setPointerPosition(getPointerPosition())
  }, [bodyImageSize, imageHeight])

  const leftSide = () => {
    return {
      ...sideLabel,
      left: 0, // bodySide === 'feet' ? '10%' : '0%',
      bottom: bodySide === 'feet' ? '-2%' : '20%',
      position: 'absolute'
    } as ViewProps
  }

  const rightSide = () => {
    return {
      ...sideLabel,
      right: 0, // bodySide === 'feet' ? '10%' : '0%',
      bottom: bodySide === 'feet' ? '-2%' : '20%',
      position: 'absolute'
    } as ViewProps
  }

  return (
    <ModalView
      title="Localization modal"
      subtitle="Select part of the body"
      show={toggleModal}
      onClose={onClose}
      iconName={iconsMapper.body}
      style={{height: '100%', ...(Platform.OS === 'web' ? {maxWidth: 640} : {})}}
    >
        <View style={[styles.localizationBlock]}>
          {onSave &&
            <>
              <IconOutlined
                blockStyle={{position: 'absolute', bottom: 75+css.padding/2, zIndex: 3, width: 80, padding: css.padding, justifyContent: 'flex-start'}}
                iconName={iconsMapper.feet} color={bodySide === 'feet' ? css.colorTextLight : css.colorPrimary} style={css.iconOutlinedMin} onPress={() => setBodySide('feet')} />
              <ViewRow style={{position: 'absolute', bottom: css.padding*2.5, zIndex: 3, width: 100, padding: css.padding, justifyContent: 'flex-start'}}>
                <IconOutlined iconName={iconsMapper.body} color={bodySide === 'front' ? css.colorTextLight : css.colorPrimary} style={css.iconOutlinedMin} onPress={() => setBodySide('front')} />
                <IconOutlined iconName={iconsMapper.bodyBack} color={bodySide === 'back' ? css.colorTextLight : css.colorPrimary} style={css.iconOutlinedMin} onPress={() => setBodySide('back')} />
              </ViewRow>
              <ViewRow style={{position: 'absolute', bottom: css.padding*2.5, zIndex: 3, width: 100, padding: css.padding, right: css.padding}}>
                <IconOutlined iconName={iconsMapper.female} color={gender === 'female' ? css.colorTextLight : css.colorPrimary} style={css.iconOutlinedMin} onPress={() => setGender('female')} />
                <IconOutlined iconName={iconsMapper.male} color={gender === 'male' ? css.colorTextLight : css.colorPrimary} style={{marginRight: 0, ...css.iconOutlinedMin}} onPress={() => setGender('male')} />
              </ViewRow>
            </>
          }
          <View style={{position: 'absolute', width: '100%', height: '100%'}}>
            {Platform.OS === 'web' &&
              <img width="100%" height="100%" style={{}} src={`${LocalizationHighlight}`} />
            }
            {Platform.OS !== 'web' &&
              // @ts-ignore
              <SvgXml width="100%" height="100%" preserveAspectRatio="none" xml={LocalizationHighlight} />
            }
          </View>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.localizationContent}
            onLayout={(e) => {
              const {height} = e.nativeEvent.layout
              const h = height - css.padding*2
              setBodyImageSize({h:h, w:h*imageRatio})
              setImageHeight(h)
            }}>
            <View style={{
              // backgroundColor: 'red',
              width: bodyImageSize.w,
              height: bodyImageSize.h,
              alignItems: 'center',
              justifyContent: 'center',
            }}
              onStartShouldSetResponder={() => !!onSave}
              onResponderRelease={(e) =>{
                setPointerPosition({
                  x: e.nativeEvent.locationX/bodyImageSize.w,
                  y: e.nativeEvent.locationY/bodyImageSize.h
                })
                e.preventDefault()
              }}
              >
                {/* TODO: if code works on mobile and web, delete web code below */}
                {/* {gender && bodySide && Platform.OS === 'web' && 
                  <img
                    style={{
                      // backgroundColor: 'green',
                      height: bodyImageSize.h,
                      width: bodyImageSize.w,
                      objectFit: 'contain',
                    }}  
                    // @ts-ignore
                    src={localizationImage[gender][bodySide]!.default}
                  />
                } */}
                {gender && bodySide && // Platform.OS !== 'web' &&
                  <>
                    <Image
                      style={{
                        height: bodyImageSize.h,
                        width: bodyImageSize.w,
                        resizeMode: 'contain',
                      }}
                      source={
                        Platform.OS === 'web' ?
                        // @ts-ignore - error because of 'default', but it should be there
                        localizationImage[gender][bodySide]!.default :
                        localizationImage[gender][bodySide]
                      }
                    />
                    {/* LEFT SIDE */}
                    <View style={leftSide()}>
                      <TextItem style={{fontSize: 26, opacity: 0.5}}>
                        {bodySide === 'back' && 'L'}
                        {bodySide === 'feet' && 'R'}
                        {bodySide === 'front' && 'R'}
                      </TextItem>
                    </View>
                    {/* RIGHT SIDE */}
                    <View style={rightSide()}>
                      <TextItem style={{fontSize: 26, opacity: 0.5}}>
                        {bodySide === 'back' && 'R'}
                        {bodySide === 'feet' && 'L'}
                        {bodySide === 'front' && 'L'}
                      </TextItem>
                    </View>
                  </>
                }
              {/* DONT DELETE THIS LINE IT IS USEFUL FOR TESTING */}
              {/* {gender && bodySide && drawSkinAreas(gender, bodySide, bodyImageSize)} */}
              {selectedSkinAreaCode &&
                <View pointerEvents='none' style={{position: 'absolute', left: pointerDevicePosition.x, top: pointerDevicePosition.y}}>
                  {Platform.OS === 'web' &&
                    <img width={pointerWidth} height={pointerWidth} src={`${LocalizationPointer}`} />
                  }
                  {Platform.OS !== 'web' &&
                    // @ts-ignore
                    <SvgXml width={pointerWidth} height={pointerWidth} xml={LocalizationPointer} />
                  }
                </View>
              }
            </View>
          </TouchableOpacity>
          {(onSave || selectedSkinAreaTitle) && <ViewRow style={styles.localizationFooter}>
            {selectedSkinAreaTitle &&
              <>
                <TextItem>Selected: {selectedSkinAreaTitle}</TextItem>
                <View>
                  <TextItem
                    style={styles.tlk}>
                    TLK
                  </TextItem>
                  <TextItem style={{lineHeight: 14}}>{selectedSkinAreaCode}</TextItem>
                </View>
              </>
            }
            {!selectedSkinAreaTitle &&
              <TextItem>Select the area of the diagnosis</TextItem>
            }
          </ViewRow>}
        </View>
        {onSave && <ViewRow>
          <ButtonRounded title="Delete" style={{marginRight: css.padding}} borderColor={css.colorError} />
          <ButtonRounded title="Set" onPress={() => {
            const areaCode = getSelectedAreaCode(pointerPosition, gender, bodySide)
            if (areaCode && bodySide) {
              onSave({
                localizationId: areaCode,
                localizationSide: bodySide,
                localizationPosition: pointerPosition,
              },
              gender)
              onClose()
            }
          }} />
        </ViewRow>}
    </ModalView>
  )
}

