import { IClinic } from "../../types"

// State

export interface IClinicsState {
  clinics: IClinic[]
}

// CRUD

export const READ_CLINICS = 'READ_CLINICS'

// Actions

export interface SetClinicsAction {
  type: typeof READ_CLINICS, clinics: IClinic[]
}

export type ClinicsActionTypes = SetClinicsAction

// Selectors

export const SELECT_CLINICS_ID = 'SELECT_CLINICS_ID'
