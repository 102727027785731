import { IDiagnosis } from "../../types"

// State

export interface IDiagnosesState {
  diagnoses: IDiagnosis[]
}

// CRUD

export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS'
export const READ_DIAGNOSES = 'READ_DIAGNOSES'
export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS'
export const DELETE_DIAGNOSIS = 'DELETE_DIAGNOSIS'

export const AI_API='https://api.smartvissolution.com/ai/detectMolesV1'

// Actions

export interface CreateDiagnosisAction {
  type: typeof CREATE_DIAGNOSIS, diagnosis: IDiagnosis
}
export interface SetDiagnosesAction {
  type: typeof READ_DIAGNOSES, diagnoses: IDiagnosis[]
}
export interface UpdateDiagnosisAction {
  type: typeof UPDATE_DIAGNOSIS, diagnosis: IDiagnosis
}
export interface DeleteDiagnosisAction {
  type: typeof DELETE_DIAGNOSIS, diagnosisId: string
}
export type DiagnosesActionTypes = CreateDiagnosisAction | SetDiagnosesAction | UpdateDiagnosisAction | DeleteDiagnosisAction 

// Selectors

export const SELECT_DIAGNOSES_ID = 'SELECT_DIAGNOSES_ID'
