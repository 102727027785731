import { RouteProp, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { View, FlatList, StyleSheet, Platform } from 'react-native'
import { BlockItem, ButtonRounded, SearchInput, ButtonCall, ButtonEmail, ButtonSms, ViewRow } from '../components'
import { IconStyle } from '../components/IconOutlined'
import { ScreenWrapper } from '../components/ScreenWrapper'
import { css } from '../styles'
import { iconsMapper, IPatient, IEvent, IDiagnosis } from '../types'
import { PatientsListProps } from '../containers'
import moment from 'moment'
import { TextItem } from '../components/TextItem'
import { AppContext } from '../contexts'
import { createEventFormValues } from '../utils'
import { CREATE_EVENT_ROUTE_NAME, PATIENTS_ROUTE_NAME, PATIENT_ROUTE_NAME, StackParams } from '../types'

type NavigationProps = StackNavigationProp<StackParams, typeof PATIENT_ROUTE_NAME>
type Props = {
	route: RouteProp<StackParams, typeof PATIENTS_ROUTE_NAME>
}

export function PatientsListScreen ({
	route,
	filterPatientsBySearchQuery,
	findLastEventByPatient,
	setPatientAsSpecLastViewed,
	deletePatientByUid
}: Props & PatientsListProps) {
	const eventId = route.params?.eventId
	const getPatientUid = route.params?.getPatientUid
	const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
	const navigation = useNavigation<NavigationProps>()
	const lastEventDate = (patient: IPatient) => {
		const lastEvent = findLastEventByPatient(patient)
		if (lastEvent) {
			let str = ''
			if (lastEvent.type === 'consultation') str += 'Consultation: '
			else str += 'Visit: '
			str += moment((lastEvent as IEvent).createdAt).format(
				'DD MMM, dddd, YYYY'
			)
			return str
		} else return 'No events'
	}

	let patientsList:FlatList<{ key: string; value: IPatient; }> | null
	const [createPatientEvent, setCreatePatientEvent] = useState(false)

	useEffect(() => {
		const query = route.params?.query
		setSearchQuery(query && typeof query === 'string' ? query : undefined)
		if (route.params?.deletePatient) {
			deletePatientByUid(route.params.deletePatient)
		}
	}, [route])

	const onPatientListItemPress = (patient: IPatient) => {
		if (eventId === undefined) {
			setPatientAsSpecLastViewed(patient.uid)
			if (Platform.OS === 'web') {
				navigation.navigate(PATIENTS_ROUTE_NAME)
			}
			navigation.navigate(PATIENT_ROUTE_NAME, { patientId: patient.uid})
		}
	}

	return (
		<AppContext.Consumer>
			{values =>
				<ScreenWrapper showFixedCamera={Platform.OS !== 'web'} circleButton={{
					iconName: iconsMapper.newUser,
					onPress: () => {
						setCreatePatientEvent(true)
						if (Platform.OS === 'web') {
							navigation.navigate(PATIENTS_ROUTE_NAME)
						}
						navigation.navigate(PATIENT_ROUTE_NAME)
					}
				}}
				wrapperStyle={{backgroundColor: css.colorDarkBgLighten1}}
				photoCallback={(diagnoses: IDiagnosis[]) => {
					let state = {...values?.state ?? {}}
					let createEventForm = {...(state.createEventForm ?? {...createEventFormValues})}
					createEventForm = {...createEventForm, diagnoses: [...(createEventForm.diagnoses ?? []), ...diagnoses]}
					state = {...state, createEventForm}
					values?.dispatch({...state})
					navigation.navigate(CREATE_EVENT_ROUTE_NAME)
				}}
				>
					<View style={styles.searchBlock}>
						<ViewRow>
							<SearchInput
								search={setSearchQuery}
								searchQuery={searchQuery}
								placeholder='Search ID, UID or full name'
							/>
						</ViewRow>
					</View>
					<FlatList
						nativeID="patients-list"
						ref={ ref => patientsList = ref}
						ListFooterComponent={<View></View>}
						ListFooterComponentStyle={styles.flatList}
						data={filterPatientsBySearchQuery(searchQuery)}
						showsVerticalScrollIndicator={Platform.OS !== 'web'}
						onContentSizeChange={() => {
							if (createPatientEvent) {
								if (patientsList) {
									patientsList.scrollToEnd({animated: true})
								}
								setCreatePatientEvent(false)
							}
						}}
						ListEmptyComponent={<View>
							{(Object.keys(patientsList ?? {}).length === 0 && (!searchQuery || searchQuery === '')) &&
								<TextItem style={styles.emptyFlatList}>
									The list is empty, press on the "+User" icon below to create new patient
								</TextItem>
							}
							{!!searchQuery && searchQuery.length > 0 &&
								<TextItem style={styles.emptyFlatList}>
									No results found, try another search
								</TextItem>
							}
						</View>}
						renderItem={result => (
							<BlockItem
								title={result.item.value.id.toUpperCase()}
								subtitle={searchQuery && result.item.value.fullName ? `${result.item.value.fullName?.firstName} ${result.item.value.fullName?.lastName}` : lastEventDate(result.item.value)}
								iconNameRight={!eventId ? iconsMapper.right : undefined}
								styleIconRight={
									styles.openPatientIconStyle as IconStyle
								}
								touchable={!eventId}
								onTitlePress={() => onPatientListItemPress(result.item.value)}
								onRightIconPress={() => onPatientListItemPress(result.item.value)}
								onPress={() => onPatientListItemPress(result.item.value)}
								skin='dark'
								key={result.item.value.id}
							>
								<View style={styles.buttonsBlock}>
									<ViewRow>
										<ButtonCall phone={result.item.value.phone} small={true} />
										<ButtonSms phone={result.item.value.phone} small={true} />
										<ButtonEmail email={result.item.value.email} small={true} />
										{!result.item.value.phone && !result.item.value.email &&
											<TextItem style={{color: css.colorText + '50'}}>No contacts provided</TextItem>
										}
									</ViewRow>
									{eventId &&
										<ButtonRounded
											title='Attach to event'
											style={{marginLeft: css.padding}}
											onPress={(e: any) => {
												getPatientUid?.(result.item.value.uid)
												navigation.goBack()
											}}
										/>
									}
								</View>
							</BlockItem>
						)}
					/>
				</ScreenWrapper>
			}
		</AppContext.Consumer>
	)
}

const styles = StyleSheet.create({
	searchBlock: {
		backgroundColor: css.colorDarkBgLighten3,
		padding: css.padding,
		borderRadius: css.borderRadius,
		marginBottom: css.padding / 2
	},
	openPatientIconStyle: {
		borderWidth: 0,
		marginRight: -css.padding,
		fontSize: 30
	},
	buttonsBlock: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	flatList: {
		paddingBottom: 110
	},
	createButton: {
		width: 200,
	},
	emptyFlatList: {
		padding: css.padding,
		textAlign: 'center'
	}
})
