import { Dispatch } from 'react';
import { RootState } from '..';
import { EventType, Gender, IConsultation, IDiagnosis, IEvent, IPatient, ISpecialist, IUser, UserType } from '../../types'
import { READ_EVENTS, UPDATE_EVENT, DELETE_EVENT, CREATE_EVENT, CREATE_DIAGNOSIS, UPDATE_DIAGNOSIS, DELETE_DIAGNOSIS, IAuthData } from '../types';
import faker from 'faker'

//#region DIAGNOSIS CRUD Actions
// IMPORTANT: CREATE, UPDATE and DELETE diagnosis actions are inside the events actions,
// because by design these should be updated only together with the event
// so to get rid of circular dependencies, we use only one file for cross operations (event and diagnose together)

const createDiagnosis = (diagnosis: Partial<IDiagnosis>, eventId?: string) => {
  return async (dispatch: Dispatch<{type: string, diagnosis: Partial<IEvent>}>, getState: () => RootState) => {
    try {
      if (diagnosis.eventId || eventId) {
        const d: IDiagnosis = {
          // if some properties exists, that not pushed down below
          ...diagnosis,
          // mandatory and other fields
          date: diagnosis.date ?? new Date().toISOString(),
          // NEXT: get ID from the server
          id: diagnosis.id ?? 'tmp_' + faker.datatype.uuid().slice(0, 3),
          eventId: diagnosis.eventId! ?? eventId!,
          imageResult: diagnosis.imageResult ?? {
            id: 'tmp_' + faker.datatype.uuid().slice(0, 3),
            source: 'unknown',
            originalImageUrl: []
          },
          aiResults: diagnosis.aiResults ?? []
        }
        dispatch({type: CREATE_DIAGNOSIS, diagnosis: d})
      }
      else console.log('No eventId exists to connect the diagnosis with')
    } catch (e) {
      console.log(e)
    }
  }
}

export const updateDiagnosis = (diagnosis: Partial<IDiagnosis>) => {
  return async (dispatch: Dispatch<{type: string, diagnosis: Partial<IDiagnosis>}>) => {
    try {
      dispatch({type: UPDATE_DIAGNOSIS, diagnosis})
    } catch(e) {
      console.log('An error occurred by updating the diagnosis')
      console.error(e)
    }
  }
}

export const deleteDiagnosis = (diagnosisId: string) => {
  return async (dispatch: Dispatch<{type: string, diagnosisId: string}>) => {
    try {
      dispatch({type: DELETE_DIAGNOSIS, diagnosisId})
    } catch(e) {
      console.log('An error occurred by deleting the diagnosis: ', diagnosisId)
      console.error(e)
    }
  }
}
/**
 * @param event current event
 * @param diagnoses filter all event diagnoses to find deleted
 * @returns event without deleted diagnoses ids, update event later
 */
const diagnosesDelete = async (dispatch: Dispatch<any>, event: Partial<IEvent>, diagnoses: Partial<IDiagnosis>[] = []) => {
  const diagsDeleteIds = event.diagnosesIds?.filter(id => !diagnoses.find(d => d.id === id))
  if (diagsDeleteIds && event.id) {
    for await (const diagId of diagsDeleteIds) {
      dispatch(await deleteDiagnosis(diagId))
      if (event.diagnosesIds) {
        event.diagnosesIds = event.diagnosesIds.filter(id => id !== diagId)
      }
    }
  }
  return event
}

/**
 * @param event current event
 * @param diagnoses  check all event diagnoses for changes
 */
const diagnosesUpdate = async (dispatch: Dispatch<any>, event: Partial<IEvent>, diagnoses: Partial<IDiagnosis>[]) => {
  const diagsUpdateIds = event.diagnosesIds?.filter(id => diagnoses.find(d => d.id === id))
  if (diagsUpdateIds && event.id) {
    for await (const diagId of diagsUpdateIds) {
      const diagnosis = diagnoses.find(d => d.id === diagId)!
      dispatch(await updateDiagnosis(diagnosis))
    }
  }
}
/**
 * @param event current event
 * @param diagnoses filter all event diagnoses to find new
 * @returns event with new diagnoses ids, update event later
 */
const diagnosesCreate = async (dispatch: Dispatch<any>, event: Partial<IEvent>, diagnoses: Partial<IDiagnosis>[]) => {
  const diagsCreate = diagnoses.filter(d => !event.diagnosesIds?.find(id => id === d.id))
  for await (const diagnosis of diagsCreate) {
    dispatch(await createDiagnosis(diagnosis, event.id))
  }
  event.diagnosesIds = [...(event.diagnosesIds ?? []), ...diagsCreate.map(d => d.id!)]
  return event
}

//#endregion

//#region EVENT CRUD Actions

export const fetchEvents = (authData: IAuthData) => {
  return async (dispatch: Dispatch<{type: string, events: IEvent[]}>, getState: () => RootState) => {
    try {
      if (authData === 'specialist' || 'patient') {
        try {
          const events = require('../../mocks/data/events.json') as IEvent[]
          // We can't use real date fields inside our events because
          // of serialization requirements inside navigation params
          // events.forEach(e => e.createdAt = new Date(e.createdAt))
          if (authData === 'specialist') {
            dispatch({type: READ_EVENTS, events: events as IEvent[] ?? []})
          } else {
            const user = getState().auth.user
            const userEvents = user ? events.filter(e => e.patientUid === user.uid) : []
            dispatch({type: READ_EVENTS, events: userEvents})
          }
        } catch(e) {
          dispatch({type: READ_EVENTS, events: []})
          console.log('An error occurred, it is possible, that no data exists.')
          console.log('Please run yarn mock:data to mock events first')
          console.error(e)
        }
      }
      // Otherwise read data from remote DB by user id
      else return dispatch({type: READ_EVENTS, events: []}) // NEXT: get json from remote DB when not using mocked data
  } catch (err) {
      throw err
    }
  }
}

// NEXT: all these functions which are used to connect to the GraphQL should use transactions or batch calls to
// update all diagnoses and event at the same time and if one fails, all other should fail

const patientWithNewEventIds = (eventId: string, patient: IPatient) => {
  if (patient) {
    if (!patient.eventIds) patient.eventIds = [eventId]
    else if (!patient?.eventIds.includes(eventId)) patient.eventIds.push(eventId)
  }
  return patient
}

export const createEvent = (event: Partial<IEvent>, diagnoses: Partial<IDiagnosis>[] = [], gender?: Gender, findPatientById?: Function, updatePatient?: Function) => {
  return async (dispatch: Dispatch<{type: string, event: Partial<IEvent>}>, getState: () => RootState) => {
    try {
      const user = getState().auth.user
      const clinicId = (getState().auth.user as ISpecialist)?.clinicId
      const specialistUid = user?.type === 'specialist' ? user.id : undefined
      if (user && user.uid) {
        let e: Partial<IEvent> = {
          // if some properties exists, that not pushed down below
          ...event,
          // mandatory and other fields
          createdAt: event.createdAt ?? new Date().toISOString(),
          clinicId: event.clinicId ?? clinicId,
          id: event.id,
          diagnosesIds: event.diagnosesIds ?? [],
          ...(event.patientUid ? {patientUid: event.patientUid} : {}),
          type: event.type ?? (user.type === UserType.patient ? EventType.consultation : EventType.visit),
          comments: event.comments ?? []
        }
        if (specialistUid) e = {...e, specialistUid} as IConsultation
        if (event.id && event.patientUid) {
          let patient: IPatient = findPatientById?.(getState().patients, event.patientUid)
          if (patient) {
            patient = patientWithNewEventIds(event.id, patient)
            if (gender) {
              updatePatient?.({...patient, gender})
            } else updatePatient?.(patient)
          }
        }
        // NEXT: create batch sequence or transaction to GraphQL before updating the state
        if (diagnoses.length > 0) e = await diagnosesCreate(dispatch, e, diagnoses) as IEvent
        dispatch({type: CREATE_EVENT, event: e})
      }
      else console.log('No auth user found to create the event')
    } catch (e) {
      console.log(e)
    }
  }
}

export const updateEvent = (event: Partial<IEvent>, diagnoses: Partial<IDiagnosis>[] = [], gender?: Gender, findPatientById?: Function, updatePatient?: Function) => {
  return async (dispatch: Dispatch<{type: string, event: Partial<IEvent>}>, getState: () => RootState) => {
    try {
      // NEXT: create batch sequence or transaction to GraphQL before updating the state
      await diagnosesUpdate(dispatch, event, diagnoses)
      event = await diagnosesDelete(dispatch, event, diagnoses)
      event = await diagnosesCreate(dispatch, event, diagnoses)
      if (event.id && event.patientUid) {
        let patient: IPatient = findPatientById?.(getState().patients, event.patientUid)
        if (patient) {
          patient = patientWithNewEventIds(event.id, patient)
          if (gender) {
            updatePatient?.({...patient, gender})
          } else updatePatient?.(patient)
        }
      }
      console.log('UPDATE EVENT', event)
      dispatch({type: UPDATE_EVENT, event})
    } catch(e) {
      console.log('An error occurred by updating the event')
      console.error(e)
    }
  }
}

export const deleteEvent = (eventId: string) => {
  return async (dispatch: Dispatch<{type: string, eventId: string}>, getState: () => RootState) => {
    try {
      const event = getState().events.events.find(e => e.id === eventId)
      if (event) {
        // NEXT: create batch sequence or transaction to GraphQL before updating the state
        if (event.diagnosesIds.length > 0) await diagnosesDelete(dispatch, event, [])
        dispatch({type: DELETE_EVENT, eventId})
      } else console.log('Event is not found: ', eventId)
    } catch(e) {
      console.log('An error occurred by deleting the event')
      console.error(e)
    }
  }
}
//#endregion