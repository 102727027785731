import React from 'react'
import {Platform, StyleProp, StyleSheet, TextStyle, View} from 'react-native'
import Icon from './IconWrapper'
import {css} from '../styles'
import { IComment, iconsMapper } from '../types'
import { TextItem } from './TextItem'
import { ViewRow } from './ViewRow'
import { fullDateTime } from '../utils'

type Props = {
  comment: IComment
}

const iconSize = 20
const padding = css.padding
export function ChatListItem ({comment}: Props) {
  const specialist = comment.user?.type === 'specialist' || comment.clinicId
  const commentDate = fullDateTime(comment.date)
  const wrapContent: StyleProp<TextStyle> = Platform.OS === 'web' ? {width:'min-content', flex: 1, flexWrap: 'wrap'} : {}
  return (
    <ViewRow style={[styles.chatListItem, specialist ? styles.chatListReceiver : undefined]}>
      <Icon name={iconsMapper.patient} color={specialist ? css.colorSuccess : css.colorPrimary} size={iconSize} style={[styles.icon, specialist ? styles.iconReceiver : undefined]}/>
      <View style={[styles.pointer, specialist ? styles.pointerReceiver : undefined]} />
      <ViewRow style={[styles.chatText, specialist ? styles.chatTextReceiver : undefined, {flex: 1}]}>
        <TextItem style={wrapContent}>
          <TextItem style={styles.chatTextDate}>{commentDate}</TextItem>&nbsp;&nbsp;
          {comment.message}
        </TextItem>
      </ViewRow>
    </ViewRow>
  )
}


const pointerSize = 10
const styles = StyleSheet.create({
  chatListItem: {
    alignItems: 'center',
    marginBottom: css.padding,
  },
  icon: {
    marginRight: padding,
  },
  iconReceiver: {
    marginRight: 0,
    marginLeft: padding,
  },
  chatListReceiver: {
    flexDirection: 'row-reverse'
  },
  chatText: {
    flexGrow: 1,    
    padding: css.padding / 2,
    paddingLeft: css.padding,
    paddingRight: css.padding,
    backgroundColor: css.colorDarkBgLighten4,
    borderRadius: css.borderRadius/2,
    lineHeight: 18,
    width: '90%',
  },
  chatTextReceiver: {
    backgroundColor: css.colorDarkBgLighten1,
  },
  chatTextDate: {
    color: css.colorText,
    fontFamily: css.fontFamily600,
    paddingRight: css.padding/2,
  },
  pointer: {
    position: 'absolute',
    height: pointerSize,
    width: pointerSize,
    left: iconSize + padding - pointerSize/2,
    backgroundColor: css.colorDarkBgLighten4,
    transform: [{rotate: '45deg'}],
  },
  pointerReceiver: {
    right: iconSize + padding - pointerSize/2,
    backgroundColor: css.colorDarkBgLighten1,
  }
})