import React, { useEffect } from 'react'
import {AppRegistry, Platform, StatusBar, Text} from 'react-native'
import {Provider} from 'react-redux'
import { AppContext, appValuesReducer } from './contexts'
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import RNBootSplash from "react-native-bootsplash"
import { LogIn } from './containers/AuthContainer'
import { css } from './styles'
import { APP_CONTEXT, getPersistContextValue, setPersistContextValue } from './utils'
// @ts-ignore
import { LogBox } from 'react-native';

// NEXT: check what non-serializable values we are sending through navigation, if they safe let this stay, if not check the error and remove this ignore
LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state',
]);

export function App () {
  const [state, dispatch] = React.useReducer(appValuesReducer, {})

  useEffect(() => {
    const s = {...state, openWaitOverlay: false}
    delete s.createEventForm
    setPersistContextValue(APP_CONTEXT, s)
  }, [state])

  useEffect(() => {
    const init = async () => {
      // …do multiple sync or async tasks
    };

    init().finally(async () => {
      dispatch(await getPersistContextValue(APP_CONTEXT))
      if (Platform.OS !== 'web') {
        await RNBootSplash.hide({ fade: true });
      }
    });
  }, []);
  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppContext.Provider value={{ state, dispatch }}>
        <StatusBar
          backgroundColor={css.colorAppBg}
          barStyle="light-content"
        />
        <LogIn />
      </AppContext.Provider>
    </PersistGate>
  </Provider>
}

AppRegistry.registerComponent('SmartVisSolution', () => App)
if (Platform.OS === 'web') {
  AppRegistry.runApplication('SmartVisSolution', {
    rootTag: document.getElementById('root'),
  })
}
