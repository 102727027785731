import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { Platform, StyleSheet} from 'react-native'
import { css } from '../styles'
import {iconsMapper, IEvent, IUser, UserType} from '../types'
import { BlockItem } from './BlockItem'
import { ButtonRounded } from './ButtonRounded'
import { IconOutlined } from './IconOutlined'
import { TextItem } from './TextItem'
import { ViewRow } from './ViewRow'

type Props = {
  user?: IUser
  // TODO: remove event, use only specialistUid, simplify code
  event: Partial<IEvent>
  message: string
  onPressAssignEvent?: (updateEvent: Partial<IEvent>) => void
  skin?: 'light' | 'dark'
}

export function EventScreenStatus ({skin, user, event, message, onPressAssignEvent}: Props) {
  const wrapContent: StyleProp<TextStyle> = Platform.OS === 'web' ? { flex: 1, flexWrap: 'wrap'} : {flexGrow: 1, flex: 1}
  return <BlockItem skin={skin} style={{marginBottom: css.padding / 2}}>
    <ViewRow style={{alignItems: 'center'}}>
      <IconOutlined iconName={iconsMapper.read} />
      <TextItem style={{alignSelf:'center', ...wrapContent, marginLeft: css.padding / 2, color: Platform.OS === 'web' ? css.colorTextDark : css.colorTextLight}}>{message}</TextItem>
    </ViewRow>
    {user?.type === UserType.specialist && !(event?.specialistUid) &&
      <ButtonRounded style={{marginTop: css.padding, backgroundColor: css.colorSuccess, width: '100%'}} title="Assign event to me" onPress={() => {
        if (user?.uid) {
          const updateEvent = {...event, specialistUid: user.uid}
          onPressAssignEvent?.(updateEvent)
        }
      }} />
    }
  </BlockItem>
}

const styles = StyleSheet.create({
})