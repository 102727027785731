import { Platform } from 'react-native';
import {
  CREATE_DIAGNOSIS,
  DELETE_DIAGNOSIS,
  DiagnosesActionTypes,
  IDiagnosesState,
} from '../types'
import d from './diagnosesHelper'

const initialState: IDiagnosesState = {
  diagnoses: [],
}

const diagnoses = (state = initialState, action: DiagnosesActionTypes) => {
  if (action.type === CREATE_DIAGNOSIS) {
    const source = action.diagnosis.imageResult.originalImageUrl
    if (Platform.OS === 'web' && window.indexedDB && source && source.length > 0) {
      const fileUrl = source[0]
      fetch(fileUrl).then(r => {
        r.blob().then(blob => {
          // @ts-ignore
          import('idb-keyval').then((idb) => {
            idb.set(fileUrl, blob)
          })
        })
      })
    }
  }
  else if (action.type === DELETE_DIAGNOSIS) {
    const diagnoses = [...state.diagnoses]
    const dIndex = diagnoses.findIndex(d => d.id === action.diagnosisId)
    if (dIndex > -1) {
      const source = diagnoses[dIndex].imageResult.originalImageUrl
      if (Platform.OS === 'web' && source && source.length > 0) {
        import('idb-keyval').then((idb) => {
          idb.del(source[0])
        })
      }
    } else throw('No diagnosis found to delete')
  }
  return d(state, action)
}

export default diagnoses;
