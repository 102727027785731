import React, {useContext} from 'react'
import {ButtonRounded} from './ButtonRounded'
import { AppContext } from '../contexts'
import { dicomEncoder } from '@Edvardas/dicomencoder'
import { dicomRequiredData } from '../utils'
import { IDicomWrapperData } from '@Edvardas/dicomencoder/lib/types'
import { css } from '../styles'
import { iconsMapper, IPatient } from '../types'
import { Platform, StyleProp, ViewStyle } from 'react-native'
import { IconOutlined } from './IconOutlined'
import { get } from 'idb-keyval'

type Props = {
  buttonTitle: string
  images: any[]
  anonymous?: true
  message?: string
  patient?: Partial<IPatient>
  isIcon?: boolean
  style?: StyleProp<ViewStyle>
}

export function DicomButton ({images, buttonTitle, anonymous, message, patient, isIcon, style}: Props) {
  const appContext = useContext(AppContext)
  const finishSharing = async () =>{
    appContext?.dispatch({...appContext?.state, openWaitOverlay: false})
  }
  const dicomSave = async () => {
    appContext?.dispatch({...appContext?.state, openWaitOverlay: true})
    try {
      const imgs = await Promise.all(images.map(async img => {
        let width = 0
        let height = 0
        let file: File
        const cachedImg = await get(img)
        if (cachedImg) {
          file = new File([await cachedImg], 'picture.jpg')
        } else {
          const imgBlob = await fetch(img)
          const blob = await imgBlob.blob()
          file = new File([blob], 'picture.jpg') // .arrayBuffer()
        }
        const FR= new FileReader();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d")!;
        let buffer = new Uint8ClampedArray(0)

        const result = await new Promise((resolve, reject) => {
          FR.onload = function(e) {
            var img = new Image();
            let t = 0
            img.src = e.target!.result as string;
            img.onload = function() {
              try {
                const i = this as HTMLImageElement;
                width = i.width; height = i.height;
                canvas.height = height
                canvas.width = width
                ctx.drawImage(i, 0, 0, width, height);
                // DICOM requires rgb, while canvas returns rgba
                // so we need to convert
                buffer = new Uint8ClampedArray(3 * width * height)
                const rgbaBuffer = ctx.getImageData(0, 0, width, height).data
                for(let i = 0; i < rgbaBuffer.length; i+=4) {
                  buffer[t] = rgbaBuffer[i]
                  buffer[t + 1] = rgbaBuffer[i + 1]
                  buffer[t + 2] = rgbaBuffer[i + 2]
                  t+=3
                }
                resolve({
                  height: height,
                  width: width,
                  buffer: buffer.buffer
                })
              } catch (e) {
                reject(e)
              }
            };
            img.onerror = () => reject('Image read error')
          };
          FR.readAsDataURL( file );
        })
        return result}
      ))
      const lastName = patient?.fullName?.lastName
      const firstName = patient?.fullName?.firstName
      const fullName = (firstName ? firstName + (lastName ? ' ' + lastName : '')  : (lastName ? ' ' + lastName : ''))
      dicomEncoder.encode({
        ...dicomRequiredData,
        createdAt: new Date(),
        images: imgs,
        anonymous,
        ...(patient ? 
          {patient: {
            id: patient?.id,
            fullName,
            ...(patient?.gender ? {sex: patient.gender.toUpperCase()} : {}),
          }} : {}
        )
      } as IDicomWrapperData).then(async ({fileName, base64String}) => {
        if (fileName && base64String) {
          const url = 'data:application/zip;base64,' + base64String;
          fetch(url).then(async (res) => {
            const blob = await res.blob()
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
        } else console.log('No base64String or file name defined')
        finishSharing()
      })
    } catch (e) {
      console.log('Error', e)
      finishSharing()
    }
  }
  return <>
    {isIcon && 
      <IconOutlined
        onPress={dicomSave}
        iconName={iconsMapper.share}
        style={[css.iconOutlinedMin, {marginRight: 0}]}
      />
    }
    {!isIcon && <ButtonRounded skin={Platform.OS === 'web' ? "light" : undefined} title={buttonTitle} onPress={dicomSave} style={style} />}
  </>
}

