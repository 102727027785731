import React, { useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet, Platform } from 'react-native'
import { css } from '../styles';
import ImageWithPreview from './ImageWithPreview';
import { TextItem } from './TextItem';
import { ViewRow } from './ViewRow';

type Props = {
  images: any
  footerTitle: string,
  hasLocalization: boolean
  children?: JSX.Element | JSX.Element[]
  intervalItemsCount?: number
}

export const ImageCarousel = ({images, footerTitle, hasLocalization, children, intervalItemsCount}: Props) => {

  const itemsPerInterval = intervalItemsCount === undefined
    ? 1
    : intervalItemsCount;

  const [interval, setInterval] = React.useState(1);
  const [intervals, setIntervals] = React.useState(1);
  const [width, setWidth] = React.useState(0);

  const getBullets = () => {
    const b = []
    for (let i = 1; i <= intervals; i++) {
      b.push(
        <View key={`circle_${i}`} style={[styles.circle, i === interval ? styles.circleActive : undefined]}  />
      )
    }
    return b
  }
  
  const init = (width: number) => {
    setWidth(width);
    const totalItems = images.length;
    setIntervals(Math.ceil(totalItems / itemsPerInterval));
    setBullets(getBullets())
  }

  const getInterval = (offset: any) => {
    for (let i = 1; i <= intervals; i++) {
      if (offset+1 < (width / intervals) * i) {
        return i;
      }
      if (i == intervals) {
        return i;
      }
    }
    return 1
  }

  const [bullets, setBullets] = useState<JSX.Element[]>([]);
  
  useEffect(() => {
    setBullets(getBullets)
  }, [images, interval])

  return (
    <View style={styles.container}>
      {/* <ScrollView
        horizontal={true}
        contentContainerStyle={{width: `100%`, height: 200, flexDirection: Platform.OS === 'web' ? 'row-reverse' : 'row'}}
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={(w, h) => {console.log('content size change');init(w)}}
        onScroll={e => {
          setWidth(e.nativeEvent.contentSize.width)
          setInterval(getInterval(e.nativeEvent.contentOffset.x));
        }}
        scrollEventThrottle={200}
        pagingEnabled
        decelerationRate="fast"
      > */}
      <View style={{position: 'relative', width: `100%`, height: 200, flexDirection: Platform.OS === 'web' ? 'row-reverse' : 'row'}}>
        {images.map((imageSource: any, index: number) => {
          const imageWidth = 100/images.length
          const localizationWidth = index === 0 && hasLocalization === true ? 100 : 0
          const w = width/intervals - localizationWidth
          return <View key={`slider_image_${index}`} style={{position: 'absolute', width:`${imageWidth}%`, height: '100%', left: `${imageWidth*index}%`}}>
              <ImageWithPreview source={imageSource.uri ? imageSource.uri : imageSource} />
              {index === images.length - 1 && children}
          </View>
        })}
      </View>
      {/* </ScrollView> */}
      <ViewRow style={styles.diagnosisImageFooter}>
        <TextItem style={{fontSize: 13}}>
          {footerTitle}
        </TextItem>
        <ViewRow>
          {bullets?.length > 1 && bullets}
        </ViewRow>
      </ViewRow>
    </View>
  )
}

export default ImageCarousel;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: css.borderRadius / 3,
    overflow: 'hidden',
    flex: 1,
  },
  diagnosisImageFooter: {
    backgroundColor: css.colorPrimary + '50',
    padding: css.padding / 2,
    paddingLeft: css.padding,
    paddingRight: css.padding,
    alignItems: 'center',
  },
  circle: {
    marginLeft: 3,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: css.colorPrimary + '50',
  },
  circleActive: {
    backgroundColor: css.colorPrimary,
  },
})