import React, { useEffect, useState } from 'react'
import {Dimensions, StyleSheet, View} from 'react-native'
import Icon from './IconWrapper'
import {css} from '../styles'
import { IClinic, IComment, iconsMapper, IPatient, ISpecialist, IUser, UserType } from '../types'
import { TextItem } from './TextItem'
import { ViewRow } from './ViewRow'
import { fullDateTime } from '../utils'
import { ButtonCall } from './ButtonCall'
import { ButtonEmail } from './ButtonEmail'
import { Platform } from 'react-native'

type Props = {
  user?: IUser
  clinic?: IClinic
  comment: IComment,
  backgroundColor?: string
  iconBackgroundColor: string
}

const iconSize = 15
const padding = css.padding
export function ChatListItemLast ({comment, backgroundColor, iconBackgroundColor, user, clinic}: Props) {
  const specialist = comment.user?.type === UserType.specialist || comment.clinicId
  const commentDate = fullDateTime(comment.date)
  const fullName = () => {
    let fullName = user?.fullName ? `${user.fullName.firstName} ${user.fullName.lastName}` : undefined
    if (fullName && user?.type === UserType.specialist) {
      const preTitle = (user as ISpecialist).preTitle
      const postTitle = (user as ISpecialist).postTitle
      fullName = preTitle ? `${preTitle} ${fullName}` : fullName
      fullName = postTitle ? `${fullName}, ${postTitle}` : fullName
    }
    const clinicTitle = user?.type === UserType.specialist && clinic?.departmentTitle ? clinic.departmentTitle : undefined
    return fullName ?? (clinicTitle ? clinicTitle : '---')
  }
  const clinicTitle = () => {
    return clinic?.departmentTitle ?? clinic?.titleShort
  }
  // const subtitle = () => {
  //   const clinicTitle = user?.fullName && user?.type === UserType.specialist && clinic?.departmentTitle ? clinic.departmentTitle : undefined
  //   return clinicTitle
  // }
  const [title, setTitle] = useState<string | undefined>()
  const [subtitle, setSubtitle] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()
  const [phone, setPhone] = useState<number | undefined>()
  const titleWidth = Platform.OS === "web" ? {width:'auto'} : { width: Dimensions.get('window').width - 250}

  useEffect(() => {
    let e = clinic?.departmentAddress.email
    let p = clinic?.address.phone
    let t = specialist ? clinicTitle() : fullName()
    let st = specialist ? clinic?.departmentTitle ? clinic?.titleShort : undefined : undefined
    if (!specialist || (user as ISpecialist)?.settings?.showPersonalContacts) {
      e = user?.email ?? clinic?.address.email
      p = user?.phone ?? clinic?.address.phone
      t = fullName()
      st = clinicTitle()
    }
    setEmail(e)
    setPhone(p)
    // NEXT: add full name back after full specialist name is present
    // setTitle(t)
    // NEXT: add department back after clinic is present
    // setSubtitle(st)
    setTitle('Last comment')
    setSubtitle(undefined)
  }, [user, clinic])
  const minContent = Platform.OS === 'web' ? 'min-content' : undefined
  return (
    <>
      <ViewRow style={[styles.chatListItemLast, specialist ? styles.chatListSpecialist : undefined]}>
        <View style={[styles.icon, iconBackgroundColor ? {backgroundColor: iconBackgroundColor} : undefined]}>
          <Icon name={iconsMapper.patient} color={css.colorTextLight} size={iconSize} style={styles.iconUser}/>
        </View>
        <View style={[styles.pointer, specialist ? styles.pointerReceiver : undefined, {backgroundColor: backgroundColor ? backgroundColor : undefined}]} />
        <View style={{flex: 1, flexShrink: 1, width: 0, flexWrap: 'wrap', paddingRight: css.padding/2}}>
          {title &&
            <TextItem style={{flexWrap: 'wrap', flexShrink: 1, ...titleWidth, color: css.colorText}}>{title}</TextItem>
          }
          {subtitle &&
            <TextItem style={{fontSize: 12, flexShrink: 1, color: css.colorText, flexWrap: 'wrap', ...titleWidth}}>{subtitle}</TextItem>
          }
        </View>
        <ButtonEmail email={email} small={true} reverseMargin={true} />
        <ButtonCall phone={phone} small={true} reverseMargin={true} />
      </ViewRow>
      <ViewRow style={[styles.chatText, specialist ? styles.chatTextReceiver : undefined, {backgroundColor: backgroundColor ? backgroundColor : undefined}, {flex: 1, minHeight: minContent}]}>
        <TextItem style={{ flexGrow: 1, width: minContent }}>
          <TextItem style={styles.chatTextDate}>{commentDate}</TextItem>&nbsp;&nbsp;
          {comment.message}
        </TextItem>
      </ViewRow>
    </>
  )
}


const pointerSize = 12
const styles = StyleSheet.create({
  chatListItemLast: {
    alignItems: 'center',
    marginBottom: css.padding,
    position: 'relative',
    // width: 'min-content',
    // alignSelf: 'flex-start',
    // flex: 1
  },
  icon: {
    marginLeft: padding/3,
    marginRight: padding/2,
    borderRadius: iconSize,
    height: iconSize*2,
    width: iconSize*2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconUser: {
    marginRight: 0,
    marginLeft: 0,
  },
  chatListSpecialist: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  chatText: {
    position: 'relative',
    lineHeight: 18,
    padding: css.padding,
    backgroundColor: css.colorDarkBgLighten3,
    borderRadius: css.borderRadius/2,
  },
  chatTextReceiver: {
    backgroundColor: css.colorDarkBgLighten1,
  },
  chatTextDate: {
    color: css.colorTextLight + '50',
    fontFamily: css.fontFamily600,
  },
  pointer: {
    position: 'absolute',
    height: pointerSize,
    width: pointerSize,
    backgroundColor: css.colorDarkBgLighten3,
    bottom: -iconSize - pointerSize/2,
    left: css.padding,
    transform: [{rotate: '45deg'}],
  },
  pointerReceiver: {
    left: 'auto',
    right: iconSize + padding/2 - pointerSize/2,
    backgroundColor: css.colorDarkBgLighten1,
  }
})