import { Dispatch } from 'react';
import { Platform } from 'react-native';
import { RootState } from '..';
import { IPatient, ISpecialist } from '../../types'
import { IAuthData, LOGOUT_USER, READ_USER, UPDATE_USER } from '../types';

// CRUD Actions

// NEXT: fix authData type, it is unknown at the moment
export const fetchUser = (authData?: IAuthData) => {
  // If user is not changing, then clear the cache and run Metro again
  return async (dispatch: Dispatch<{type: string, user: ISpecialist | IPatient | undefined}>) => {
    try {
      if (authData === 'specialist' || authData === 'patient') {
        try {
          if (authData === 'specialist') {
            // we use first mocked specialist as an authorized user
            const specialists = require('../../mocks/data/specialists.json')
            const uid = 'v1_spec_' + Date.now()
            // NEXT: later set/get subscriptions value from particular user, now we give permissions to AI to any specialist
            const subscriptions = {
              // NEXT: bring back restriction
              aiService: true // Platform.OS === 'web' ? false : true
            }
            dispatch({type: READ_USER, user: {
              ...specialists?.[0],
              id: uid,
              uid,
              subscriptions,
              createdAt: new Date().toISOString()
            } as ISpecialist ?? undefined})
          } else {
            // otherwise if patient is the authorized user, we use first patient from patients mocked data list
            const patients = require('../../mocks/data/patients.json')
            dispatch({type: READ_USER, user: patients?.[0] as IPatient ?? undefined})
          }
        } catch(e) {
          dispatch({type: READ_USER, user: undefined})
          console.log('An error occurred, it is possible, that no data exists.')
          console.log('Please run yarn mock:data to mock auth first')
          console.error(e)
        }
      } else dispatch({type: READ_USER, user: undefined}) // NEXT: get users data from remote DB
    } catch (err) {
      throw err
    }
  }
}

export const logOutUser = () => {
  return async (dispatch: Dispatch<{type: string}>) => {
    try {
      dispatch({type: LOGOUT_USER})
    } catch (err) {
      throw err
    }
  }
}

export const updateAuthSpecialist = (authUser: Partial<ISpecialist>) => {
  return async (dispatch: Dispatch<{type: string, user: ISpecialist | undefined}>, getState: () => RootState) => {
    try {
      const user = Object.assign({}, getState().auth.user as ISpecialist)
      if (user) {
        if (user.type === 'specialist') {
          dispatch({type: UPDATE_USER, 
            user: {
              ...user,
              ...authUser
            }
          })
        }
      } else console.log('User does not exist, check auth state or log in')
    } catch(e) {
      console.log('An error occurred by setting active patient to the specialist')
      console.error(e)
    }
  }
}
