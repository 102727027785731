import { Dispatch } from 'react';
import { ISpecialist } from '../../types'
import { READ_SPECIALISTS } from '../types';


// CRUD Actions

export const fetchSpecialists = () => {
  return async (dispatch: Dispatch<{type: string, specialists: ISpecialist[]}>) => {
    try {
      const specialists = require('../../mocks/data/specialists.json')
      dispatch({type: READ_SPECIALISTS, specialists: specialists as ISpecialist[] ?? []})
    } catch(e) {
      dispatch({type: READ_SPECIALISTS, specialists: []})
      console.log('An error occurred, it is possible, that no data exists.')
      console.log('Please run yarn mock:data to mock specialists first')
      console.error(e)
    }
  }
}
