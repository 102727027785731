import { createSelector } from '@reduxjs/toolkit'
import { ISpecialistsState } from '../types'

const getSpecialists = (state: ISpecialistsState) => state.specialists
const getUserUid = (state: ISpecialistsState, props: { userUid?: string }) => props.userUid
const getUserId = (state: ISpecialistsState, props: { userId?: string }) => props.userId

//#region FINDERS - find functionality, returns one value, case-insensitive

export const findSpecialistByUid = createSelector(
  [getSpecialists, getUserUid],
  (patients, uid) => {
    if (!uid) return undefined
    return patients?.find(p => p.uid.toLocaleLowerCase() === uid.toLocaleLowerCase())
  }
)

export const findSpecialistById = createSelector(
  [getSpecialists, getUserId],
  (patients, id) => {
    if (!id) return undefined
    return patients?.find(p => p.id.toLocaleLowerCase() === id.toLocaleLowerCase())
  }
)

//#endregion
