import React, { useEffect, useState, useContext } from 'react'
import Icon from '../components/IconWrapper'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { CalendarHorizontal } from '../components/CalendarHorizontal'
import { ScreenWrapper } from '../components/ScreenWrapper'
import { TextItem } from '../components/TextItem'
import { css } from '../styles'
import { EventStatus, IClinic, iconsMapper, IDiagnosis, IEvent, ISpecialist, IVisit } from '../types'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { HomeProps } from '../containers'
import {
	BlockItem,
	ButtonRounded,
	HomeAppointmentMap,
	HomeChatBlock,
	HomeActiveEvent,
	SearchInput,
	ScrollWrapper,
	DiagnosisImageDropZone,
	HomeHowToBlock,
	CameraModal,
} from '../components'
import { createEventFormValues, imageLibraryDiagnosis } from '../utils'
import { AppContext } from '../contexts'
import { EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, PATIENTS_ROUTE_NAME, PATIENT_ROUTE_NAME } from '../types'
import howToPrepare from '../assets/picts/how-to-prepare.svg'
import howToCloser from '../assets/picts/how-to-closer.svg'
import howToCenter from '../assets/picts/how-to-center.svg'
import howToFocus from '../assets/picts/how-to-focus.svg'
import howToUpload from '../assets/picts/how-to-upload.svg'

const showSmallCameraIcon = Dimensions.get('window').height < 600
const cameraIconHeight = showSmallCameraIcon ? 50 : 60
const newDiagnosisBlockHeight = showSmallCameraIcon ? undefined : 210
const calendarHorizontalDaysCount = 14

export const HomeScreen = ({
	auth,
	lastPatientUpdated,
	userByUid,
	findEventById,
	recentEvents,
	activePatientEvents,
	diagnosesByIds,
	clinicById,
	deleteEvent,
}: HomeProps) => {
	const { navigate } = useNavigation/*<NavigationProps>*/()
	const lastEventViewed = (eventId: string | undefined) => {
		let event: IEvent | undefined
		if (auth.user?.type === 'specialist' && eventId) {
			event = findEventById(eventId)
		}
		return event
	}
	const navigateSearchTo = (query: string) => {
		if (query.length > 0) navigate(PATIENTS_ROUTE_NAME, {screen: PATIENTS_ROUTE_NAME, params:{ query, focus:true }})
	}
	const isFocused = useIsFocused()
	const showPatientSearch = Dimensions.get('window').height > 700
	const showBlocks670 = Dimensions.get('window').height <= 670
	const showBlocks600 = Dimensions.get('window').height <= 600

	const [activePatientEvent, setActivePatientEvent] = useState<IEvent | undefined>()
	const [activeEventClinic, setActiveEventClinic] = useState<IClinic | undefined>()
	const [toggleCameraModal, setToggleCameraModal] = useState(false)
	const appContext = useContext(AppContext)
	const [diagnoses, setDiagnoses] = useState<Partial<IDiagnosis>[]>([])
	useEffect(() => {
		setDiagnoses(appContext?.state.createEventForm?.diagnoses ?? [])
	}, [appContext])

	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		setIsLoading(true)
		// Use some async loading code there if needed
		setIsLoading(false)
	}, [])

	useEffect(() => {
    setActivePatientEvent(activePatientEvents?.[0])
	}, [activePatientEvents])

	useEffect(() => {
		setActiveEventClinic(clinicById(activePatientEvent?.clinicId))
	}, [activePatientEvent])

	return (
		<>
			{isLoading && (
				<ScreenWrapper>
					<TextItem>Screen is loading...</TextItem>
				</ScreenWrapper>
			)}
			{!isLoading && (
				<ScreenWrapper>
					{auth.user?.type === 'specialist' && (
						<ScrollWrapper contentContainerStyle={{flexGrow: 1}}>
							<>
								{showBlocks670 &&
									<BlockItem
										title='Patients'
										iconNameLeft={iconsMapper.patients}
										contentStyle={{backgroundColor: 'red'}}
										buttonTitleRight="Browse all"
										onRightIconPress={() => {
											navigate(PATIENTS_ROUTE_NAME, {screen: PATIENTS_ROUTE_NAME, params: undefined})
										}}
									/>
								}
								{!showBlocks670 &&
								<BlockItem
									skin="dark"
									title='Patients'
									subtitle={
										lastPatientUpdated?.updatedAt
											? `Last updated: ${moment(
												lastPatientUpdated?.updatedAt
											).format('DD MMM, dd')}, ID:${
												lastPatientUpdated?.id.toUpperCase()
											}`
										: 'No patients available. Press "Browse All"'
									}
									iconNameLeft={iconsMapper.patients}
									style={styles.patientsBlock}
								>
									<View style={styles.blockButtons}>
										{userByUid(
											(auth.user as ISpecialist)
												.lastViewedPatientUid
										) && (
											<ButtonRounded
												style={{marginRight: css.padding}}
												title='Last Viewed'
												onPress={() => {
													const patient = userByUid((auth.user as ISpecialist).lastViewedPatientUid)
													navigate(PATIENTS_ROUTE_NAME)
													setTimeout(() => {
														navigate(PATIENTS_ROUTE_NAME,
																{
																screen: PATIENT_ROUTE_NAME,
																params:
																	{
																		patientId: (auth.user as ISpecialist).lastViewedPatientUid,
																		...(patient?.fullName.firstName ? {firstName: patient?.fullName.firstName} : {}),
																		...(patient?.fullName.lastName ? {lastName: patient?.fullName.lastName} : {}),
																	}
															}
														)
													}, 0);
												}}
											/>
										)}
										<ButtonRounded
											title='Browse All'
											onPress={() => {
												navigate(PATIENTS_ROUTE_NAME, {screen: PATIENTS_ROUTE_NAME, params: undefined})
											}}
										/>
									</View>
									{showPatientSearch &&
										<View style={styles.searchInput}>
											{isFocused && (
												<SearchInput
													placeholder='Search for a patient'
													navigateWithString={
														navigateSearchTo
													}
												/>
											)}
										</View>
									}
								</BlockItem>
								}
								<BlockItem
									skin="dark"
									title='Visits & Consultations'
									subtitle='Swipe dates to quick filter events'
									iconNameLeft={iconsMapper.visits}
									style={{flexGrow: 1}}
								>
									{(!showBlocks600 || (showBlocks600 && diagnoses.length === 0)) &&
									<CalendarHorizontal
										daysCount={calendarHorizontalDaysCount}
										events={recentEvents(
											calendarHorizontalDaysCount
										)}
										dateSelected={(date: Date) => {
											navigate(EVENTS_ROUTE_NAME, {screen: EVENTS_ROUTE_NAME, params: { date: date.getTime()}})
										}}
									/>
									}
									<View style={styles.blockButtons}>
										{lastEventViewed(
											(auth.user as ISpecialist)
												.lastViewedEventId
										) && (
											<ButtonRounded
												style={{marginRight: css.padding}}
												title='Last Viewed'
												onPress={() => {
													navigate(EVENTS_ROUTE_NAME)
													setTimeout(() => {
														navigate(EVENTS_ROUTE_NAME, {screen: EVENT_ROUTE_NAME, params: {
															eventId: (auth.user as ISpecialist)
																	.lastViewedEventId
														}})
													}, 0);
												}}
											/>
										)}
										<ButtonRounded
											title='Browse All'
											onPress={() => {
												navigate(EVENTS_ROUTE_NAME, {screen: EVENTS_ROUTE_NAME, params: { date: undefined }})
											}}
										/>
									</View>
								</BlockItem>
							</>
						</ScrollWrapper>
					)}
					{auth.user?.type === 'patient' && !activePatientEvent && 
						<ScrollWrapper style={styles.scrollView} contentContainerStyle={{flexGrow: 1}}>
							<BlockItem
								iconNameLeft={iconsMapper.library}
								title='How to use our app'
								style={styles.howToBlock}
								contentStyle={styles.howToBlockContent}
							>
								<HomeHowToBlock
									title='Prepare'
									text='Prepare for taking pictures. Remove all objects from your sking around the mole: jeverly, hairs, etc.'
									imageXml={howToPrepare as unknown as string}
								/>
								<HomeHowToBlock
									title='Come closer'
									text='The distance between object and mobile phone camera should be 5 cm to 10 cm.'
									imageXml={howToCloser as unknown as string}
								/>
								<HomeHowToBlock
									title='Center'
									text='Center your mole in the camera view finder, if possible choose square picture mode.'
									imageXml={howToCenter as unknown as string}
									height={240}
								/>
								<HomeHowToBlock
									title='Make it sharp'
									text='Hold the phone still while taking a picture to make image as sharp as possible'
									imageXml={howToFocus as unknown as string}
									height={200}
								/>
								<HomeHowToBlock
									title='Upload'
									text='Use camera icon to start making pictures of your moles and save with the comment.'
									imageXml={howToUpload as unknown as string}
									height={60}
								/>
							</BlockItem>
						</ScrollWrapper>
					}
					{activePatientEvent?.type === 'visit' && activePatientEvent.status !== EventStatus.completed &&
						<HomeAppointmentMap
							coords={[23.9237, 54.9213]}
							appointmentDate={(activePatientEvent as IVisit).appointmentDate!}
							clinic={activeEventClinic}
							event={activePatientEvent}
						/>}
					{(activePatientEvent?.type === 'consultation' || activePatientEvent?.type === 'visit' && activePatientEvent.status === EventStatus.completed) &&
						<HomeChatBlock clinicById={clinicById} userByUid={userByUid} diagnosesByIds={diagnosesByIds} event={activePatientEvent} deleteConsultation={() => deleteEvent(activePatientEvent.id)} />
					}
					<AppContext.Consumer>
						{values => {
							const diagnoses = values?.state?.createEventForm?.diagnoses ?? []
							
							return <>
								{diagnoses.length > 0 && <HomeActiveEvent />}
								{diagnoses.length === 0 &&
									<BlockItem
										skin="dark"
										title='New Diagnosis'
										subtitle={`Upload file from library${Platform.OS !== 'web' ? ' or use camera' : ''}`}
										iconNameLeft={iconsMapper.visit}
										style={styles.newDiagnosisBlock}
									>
									<CameraModal key="HomeCameraModal" toggleModal={toggleCameraModal} onClose={(cameraDiagnoses) => {
										setToggleCameraModal(false)
										if (cameraDiagnoses.length > 0) {
											const diagnoses = [...(values?.state?.createEventForm?.diagnoses ?? []), ...cameraDiagnoses]
											values?.dispatch({...values.state, createEventForm: {
												...(values.state.createEventForm ?? {...createEventFormValues}), diagnoses
											}})
										}
									}} />
									<View style={styles.newDiagnosisButtons}>
										{Platform.OS !== 'web' &&
											<>
												<TouchableOpacity
													style={{ flexDirection: 'row', height: 50, alignItems: 'center' }}
													onPress={async () => {
														try {
															const diagnosis = await imageLibraryDiagnosis()
															if (diagnosis) {
																let diagnoses = values?.state.createEventForm?.diagnoses ?? []
																diagnoses.push(diagnosis)
																let createEventForm = values?.state.createEventForm ?? {}
																createEventForm = {...createEventForm, diagnoses}
																values?.dispatch({...values.state, createEventForm})
															}
														} catch (e) {
															console.log(e)
														}
													}}
												>
													<Icon
														name={iconsMapper.library}
														size={25}
														color={css.colorPrimary}
													/>
													<TextItem
														style={styles.newDiagnosisUploadText}
													>
														Upload file
													</TextItem>
												</TouchableOpacity>
												<TouchableOpacity onPress={() => {
													setToggleCameraModal(!toggleCameraModal)
												}}>
													<View
														style={styles.newDiagnosisCameraBlock}
													>
														<View
															style={
																styles.newDiagnosisCameraIconWrapper
															}
														>
															<Icon
																name={iconsMapper.camera}
																size={30}
																color={css.colorTextLight}
																style={
																	styles.newDiagnosisCameraIcon
																}
															/>
														</View>
													</View>
												</TouchableOpacity>
											</>
										}
										{Platform.OS === 'web' &&
											<DiagnosisImageDropZone photoCallback={(d: IDiagnosis[]) => {
													let diagnoses = values?.state.createEventForm?.diagnoses ?? []
													diagnoses = [...d, ...diagnoses]
													let createEventForm = values?.state.createEventForm ?? {}
													createEventForm = {...createEventForm, diagnoses}
													values?.dispatch({...values.state, createEventForm})
												}}>
												<View
													style={styles.newDiagnosisCameraBlock}
												>
													<View
														style={
															styles.newDiagnosisCameraIconWrapper
														}
													>
														<Icon
															name={iconsMapper.camera}
															size={30}
															color={css.colorTextLight}
															style={
																styles.newDiagnosisCameraIcon
															}
														/>
													</View>
												</View>
											</DiagnosisImageDropZone>
										}
									</View>
								</BlockItem>
								}
							</>
						}}
					</AppContext.Consumer>
				</ScreenWrapper>
			)}
		</>
	)
}

const styles = StyleSheet.create({
	patientsBlock: {
		backgroundColor: css.colorDarkBgLighten1
	},
	blockButtons: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	searchInput: {
		backgroundColor: css.colorDarkBgLighten2,
		margin: -1.5 * css.padding,
		marginTop: css.padding,
		padding: css.padding,
		paddingTop: css.padding / 2,
		paddingBottom: css.padding / 2,
		borderBottomLeftRadius: css.borderRadius,
		borderBottomRightRadius: css.borderRadius,
		flexDirection: 'row'
	},
	scrollView: {
		borderRadius: css.borderRadius,
		marginBottom: css.padding / 2,
		flexGrow: 1,
	},
	howToBlock: {
		flexGrow: 1,
	},
	howToBlockContent: {
		justifyContent: 'flex-start',
	},
	newDiagnosisBlock: {
		backgroundColor: css.colorDarkBgLighten3,
		height: Platform.OS === 'web' ? 'auto' : newDiagnosisBlockHeight,
	},
	newDiagnosisButtons: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	},
	newDiagnosisUploadText: {
		color: css.colorPrimary,
		marginLeft: css.padding,
		paddingTop: 8,
		fontSize: 18,
		lineHeight: 18,
		alignSelf: 'center'
	},
	newDiagnosisCameraBlock: {
		padding: 4,
		borderRadius: 40,
		borderColor: css.colorAccent,
		borderWidth: 2,
		alignItems: 'center',
	},
	newDiagnosisCameraIconWrapper: {
		height: cameraIconHeight,
		width: cameraIconHeight,
		backgroundColor: css.colorAccent,
		borderRadius: cameraIconHeight / 2,
		justifyContent: 'center',
	},
	newDiagnosisCameraIcon: {
		textAlign: 'center',
		color: css.colorTextLight,
		textAlignVertical: 'center',
	}
})
