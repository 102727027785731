import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { EventsListScreen } from '../screens/EventsListScreen'
import { RootState, filterDiagnosesByCode, filterEventsBySearchProps, updateAuthSpecialist, deleteEvent, findLastDiagnosisByEvent, findPatientByUid } from '../store'
import { DiagnosisCode, IEvent, IPatient, ISpecialist } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user as (ISpecialist | IPatient | undefined),
    events: state.events.events,
    filterDiagnosesByCode: (code?: string) => filterDiagnosesByCode(state.diagnoses, {code}),
    filterEventsBySearchProps: (searchProps: {
      query?: string,
      date?: Date,
      diagnosisCode?: DiagnosisCode,
      specialistUid?: string, // to search for new events we look at undefined specialistUid
    }) => filterEventsBySearchProps(state.events, {patientsState: state.patients, searchProps, diagnosesState: state.diagnoses}),
    findLastDiagnosisByEvent: (event?: IEvent) => findLastDiagnosisByEvent(state.diagnoses, {event}),
    patientByUid: (userUid?: string) => findPatientByUid(state.patients, {userUid})
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setEventAsLastViewed: (eventId?: string) => {
      dispatch(updateAuthSpecialist({
        lastViewedEventId: eventId
      }))
    },
    deleteEvent: (eventId: string) => {
      dispatch(deleteEvent(eventId))
    }
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type EventsListProps = ConnectedProps<typeof connector>
export const EventsList = connector(EventsListScreen)
