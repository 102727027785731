import React, {useEffect, useState} from 'react'
import {View} from 'react-native'
import {iconsMapper, IDiagnosisAiResult, IEvent, IPatient, IVisit, LONG_DATE_FORMAT} from '../types'
import moment from 'moment'
import { ViewRow } from './ViewRow'
import { TextItem } from './TextItem'
import { css } from '../styles'
import { ImageWithPreview } from './ImageWithPreview'
import Icon from './IconWrapper'
import { useSelector } from 'react-redux'
import { findLastDiagnosisByEvent, findPatientByUid, RootState } from '../store'
import { IconOutlined } from './IconOutlined'
import { AlertWrapper } from './AlertWrapper'
import { LocalizationIcon } from './LocalizationIcon'
import { cssEventsListItem } from '../styles/variables'
import { DiagnosisBadgeOutlined } from './DiagnosisBadgeOutlined'

type Props = {
  showPatientDetails?: boolean
  disabled?: boolean
  event: IEvent
  removeEvent?: Function
  navigateRoute: Function
}

export function EventsListItem ({event, removeEvent, disabled, navigateRoute, showPatientDetails}: Props) {
  const getLastDiagnosisFromEvent = useSelector((state: RootState) => (event?: IEvent) => findLastDiagnosisByEvent(state.diagnoses, {event}))
  const getPatientWithUid = useSelector((state: RootState) => (patientUid?: string) => findPatientByUid(state.patients, {userUid: patientUid}))
  const diagnosis = getLastDiagnosisFromEvent(event)
  const [patient, setPatient] = useState<IPatient>()
  const getPatientIdInfo = () => patient?.id ? 'Patient ID: ' + patient.id.toUpperCase() : 'No patient attached'
  const getPatientNameInfo = () => showPatientDetails && patient?.fullName && patient.fullName.firstName && patient.fullName.lastName ? patient.fullName.firstName + ' ' + patient.fullName.lastName : undefined
  const [subtitle, setSubtitle] = useState(getPatientNameInfo() ?? getPatientIdInfo())
  const eventDate = () => {
    let aDate = (event as IVisit).appointmentDate
    if (event && event.type === 'visit' && aDate) return aDate ?? event.createdAt
    return event?.createdAt
  }
  const image = () => {
    const imageUri = diagnosis?.imageResult?.originalImageUrl?.[0]
    return <ViewRow style={[cssEventsListItem.image, imageUri ? {borderWidth: 0} : undefined]}>
      {!!imageUri &&
        <ImageWithPreview source={imageUri} />
      }
      {!imageUri && <Icon name={iconsMapper.camera} style={cssEventsListItem.noImage} />}
    </ViewRow>
  }
  useEffect(() => {
    setPatient(getPatientWithUid(event?.patientUid))
  }, [event])
  useEffect(() => {
    setSubtitle(getPatientNameInfo() ?? getPatientIdInfo())
  }, [patient, showPatientDetails])

  return (
    <View style={cssEventsListItem.block}>
      <ViewRow>
        {image()}
        <View style={{flexGrow: 1}}>
          <View style={cssEventsListItem.header}
            onStartShouldSetResponder={() => true}
            onResponderRelease={() => {
              navigateRoute()
            }}
          >
            <View style={cssEventsListItem.titleBlock}>
              <TextItem style={cssEventsListItem.subtitle}>{moment(eventDate()).format(LONG_DATE_FORMAT)}</TextItem>
              <TextItem style={[cssEventsListItem.title]}>{subtitle}</TextItem>
            </View>
            <View style={cssEventsListItem.arrowMore}>
                <Icon
                  name={iconsMapper.right}
                  color={css.colorPrimary}
                  size={25}
                />
            </View>
          </View>
          <View style={cssEventsListItem.content}>
            {!disabled &&
              <IconOutlined
                iconName={iconsMapper.trash}
                color={css.colorError}
                style={{borderStyle: 'dashed', ...css.iconOutlinedMin}}
                onPress={() => {
                  if (removeEvent) {
                    AlertWrapper({
                      title: "Delete item",
                      message: "Are you sure you want to delete the item?",
                      confirmButton: {text: "OK", onPress:() => {
                        removeEvent()
                      }},
                      cancelButton: {text: "Cancel", onPress:() => {}},
                    })
                  }
                }}
              />
            }
            {diagnosis?.aiResults &&
              <DiagnosisBadgeOutlined aiResults={diagnosis.aiResults}/>
            }
            {diagnosis?.bodyLocalization &&
              <LocalizationIcon gender={patient?.gender ?? event?.gender} localization={diagnosis.bodyLocalization} />
            }
          </View>
        </View>
      </ViewRow>
    </View>
  )
}
