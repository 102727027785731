import { get } from "idb-keyval"
import { Base64, setIcdCodesForAiResults } from "."
import { AI_API } from "../store/types"
import { IDiagnosisAiResult } from "../types"

const dataUrlToBlob = function (url: string) {
  const arr = url.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1] || 'image-jpeg'
  const str = Base64.atob(arr[1])
  let length = str.length
  const uintArr = new Uint8Array(length)
  while (length--) {
    uintArr[length] = str.charCodeAt(length)
  }
  return new Blob([uintArr], { type: mime })
}

const resizeImage = (imageBlob: Blob) => {
  return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.readAsDataURL(imageBlob)
      reader.addEventListener('load', function (e) {
          const ele = new Image()
          ele.addEventListener('load', function () {
              const canvas = document.createElement('canvas')
              const context = canvas.getContext('2d')
              const maxSize = 1000
              const ratio = ele.width / ele.height
              let w = ele.width
              let h = ele.height
              if (w > h && w > maxSize) {
                w = maxSize
                h = w / ratio
              } else {
                if (h > w && h > maxSize) {
                  h = maxSize
                  w = h * ratio
                }
              }
              canvas.width = w
              canvas.height = h
              context!.drawImage(ele, 0, 0, w, h)
              const url = canvas.toDataURL('image/jpeg')
              // TO TEST IT VISUALLY bring this image tag in public/index.html file
              // <img id="immmm" style="display: flex; min-height: 300px;min-width: 300px;position: absolute; background: red; z-index: 10000;top: 0;left:0" />
              // // @ts-ignore
              // document.getElementById('immmm').src = url
              resolve(dataUrlToBlob(url))
          })
          ele.src = e.target?.result as string
      });

      reader.addEventListener('error', function (e) {
          reject()
      })
  })
}

export const getAiResultForImageUri = async (uri: string): Promise<IDiagnosisAiResult[]> => {
  let result: IDiagnosisAiResult[] = []
  try {
    const maxFileSize = 4 * 1024 * 1024 // 4Mbs
    let blob = await get(uri)
    if (!blob) blob = await (await (await fetch(uri))).blob()
    if ((blob as Blob).size > maxFileSize) {
      blob = await resizeImage(blob)
    }
    const body = new FormData()
    body.append('image', blob, 'image.jpg')
    const res = await fetch(AI_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body
    })
    const air = (await res.json()).moles || []
    result = setIcdCodesForAiResults(air as IDiagnosisAiResult[])
  }
  catch(err) {
    console.log(err)
  }
  return result
}