import React from "react";
import { ImageSourcePropType } from "react-native"
import { Svg, Polyline } from "react-native-svg"
import { bodyLocalizationMap, BodyLocalizationSNOMED, Gender, LocalizationPosition, LocalizationSide, LocalizationSize } from "../types";

export const localizationImage:{
  [key in Gender]: {
    [key in LocalizationSide]?: ImageSourcePropType;
  }
} = {
  'male': {
    'front': require(`../assets/picts/male_front.png`),
    'back': require(`../assets/picts/male_back.png`),
    'feet': require(`../assets/picts/feet.png`),
  },
  'female': {
    'front': require(`../assets/picts/female_front.png`),
    'back': require(`../assets/picts/female_back.png`),
    'feet': require(`../assets/picts/feet.png`),
  }
}

const isPointInPoly = (p: LocalizationPosition, polygon:LocalizationPosition[]) => {
  if (polygon.length === 0) return false
  let isInside = false;
  let minX = polygon[0].x, maxX = polygon[0].x;
  let minY = polygon[0].y, maxY = polygon[0].y;
  for (let n = 1; n < polygon.length; n++) {
    let q = polygon[n];
    minX = Math.min(q.x, minX);
    maxX = Math.max(q.x, maxX);
    minY = Math.min(q.y, minY);
    maxY = Math.max(q.y, maxY);
  }
  
  if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
    return false;
  }
  
  let i = 0, j = polygon.length - 1;
  for (i; i < polygon.length; j = i++) {
    if ( (polygon[i].y > p.y) != (polygon[j].y > p.y) &&
    p.x < (polygon[j].x - polygon[i].x) * (p.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x ) {
      isInside = !isInside;
    }
  }
  return isInside;
}

export const getSelectedAreaCode = (position: LocalizationPosition, gender?: Gender, localizationSide?: LocalizationSide) => {
  if (gender && localizationSide) {
    const areas = bodyLocalizationMap[gender][localizationSide]
    if (areas) {
      const polyIndex = Object.values(areas).findIndex(polygon => polygon ? isPointInPoly(position, polygon) : false)
      return polyIndex === -1 ? undefined : Object.keys(areas)[polyIndex] as keyof typeof BodyLocalizationSNOMED
    }
  }
  return undefined
}

export const drawPolygon = (size: LocalizationSize, polygon: LocalizationPosition[], color?: string) => {
  let points = ''
  let maxX = 0
  let maxY = 0
  let strokeWidth = 0
  const fillColor = color && color.includes('#') ? color + '50' : color
  const strokeColor = color && color.includes('#') ? color + '80' : color
  polygon.forEach(p => {
    points += p.x*size.w + ',' + p.y*size.h + ' '
    if (maxX < p.x*size.w) maxX = p.x*size.w
    if (maxY < p.y*size.h) maxY = p.y*size.h
  })
  points = points.slice(0, -1)
  return <Svg style={{position: 'absolute', top: 0, left: 0}} height={maxY+strokeWidth} width={maxX+ strokeWidth}>
  <Polyline
    points={points}
    fill={fillColor ? fillColor :'#00ff0050'}
    stroke={strokeColor ? strokeColor :'#00ff0080'}
    strokeWidth={strokeWidth}
  />
</Svg>
}

export const drawFront = (bodyImageSize: LocalizationSize, gender: Gender) => (<>
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02120!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t023!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t022l!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t022r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02420r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02420l!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02424!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02480!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02485!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t025!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02832r!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02832l!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02851r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02851l!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02615r!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02615l!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02652r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['front']!.t02652l!, '#FFA500')}
</>)

export const drawBack = (bodyImageSize: LocalizationSize, gender: Gender) => (<>
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02102!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t023!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t022l!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t022r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02451!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02452!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02614l!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02614r!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02471!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02834r!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02834l!, '#ff0000')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02851r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02851l!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02652r!, '#FFA500')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap[gender]['back']!.t02652l!, '#FFA500')}
</>)

export const drawFeet = (bodyImageSize: LocalizationSize) => (<>
  {drawPolygon(bodyImageSize, bodyLocalizationMap['male']['feet']!.t02852l!, '#00ff00')}
  {drawPolygon(bodyImageSize, bodyLocalizationMap['male']['feet']!.t02852r!, '#00ff00')}
</>)

export const drawSkinAreas = (gender: Gender, bodySide: LocalizationSide, imageSize: LocalizationSize) => {
  if (bodySide === 'front') return drawFront(imageSize, gender)
  if (bodySide === 'back') return drawBack(imageSize, gender)
  if (bodySide === 'feet') return drawFeet(imageSize)
}