import { DiagnosisAiClassNameDict, DiagnosisCode, EventStatus, EventType, Gender, IDiagnosis, IDiagnosisAiResult, IEvent, IPatient, IUser, UserType } from "../types"

export const eventStatusMessage = {
  'specialist': {
    'pending-review': 'Consultation is received and is pending for a review. Please assign this consultation to your account and take some action.',
    'needs-reply': 'Consultation is awaiting patients actions, if it takes more time than usual call the patient.',
    'needs-visit': 'Patient received a message for a visit request, patient will call to arrange an appointment.',
    'completed-with-issues': 'Event is completed with issues.',
    'completed-with-diagnosis': 'Event is completed with diagnosis',
  },
  'patient': {
    'pending-review': 'Your consultation is received and is pending for a review. You can\'t edit this event until specialist will take some action.',
    'needs-reply': 'Consultation is awaiting your actions, please read our last message in the chat below.',
    'needs-visit': 'You need to arrange a visit, please call to our clinic.',
    'completed-with-issues': 'Event is completed.',
    'completed-with-diagnosis': 'Event is completed.',
  },
  'both': {
    'pending': 'Event is pending for the visit.',
    'create-visit': '', // this is temporary (used only on save action)
    'completed': 'Event is completed.',
    'canceled': 'Event is canceled.',
  }
}

export interface IEventFormValues {
  event: Partial<IEvent>,
  diagnoses: Partial<IDiagnosis>[]
  currentMessage: string
  flashMessage: string
  gender?: Gender
  patient?: IPatient
  appointmentDate?: Date
}

export const createEventFormValues: IEventFormValues = {
  event: {},
  diagnoses: [],
  currentMessage: '',
  flashMessage: '',
}

export const isEventEditableForUser = (event?: Partial<IEvent>, user?: IUser) => {
  const isEventSaved = !!event?.createdAt
  if (user?.type === UserType.specialist) {
    const isSpecDefined = event && user ? event.specialistUid === user.uid : false
    const isVisitEditable = (
      !!event &&
      event.status !== EventStatus.completed &&
      event.status !== EventStatus.completedWithDiagnosis &&
      event.status !== EventStatus.completedWithIssues
    )
    // for a specialist we show the edit and save block always on related events
    return !isEventSaved || isEventSaved && isSpecDefined // || isVisitEditable)
  } else {
    const isPatientDefined = event && user ? event.patientUid === user.uid : false
    const isConsultationEditable = (
        !!event &&
        event?.status === EventStatus.needsReply
    )
    // if event is not created, then it is new, so we can edit
    return !isEventSaved || isEventSaved && isPatientDefined && isConsultationEditable
  }
}

export const eventMessageByStatus = (userType?: UserType): {[key in EventStatus]: string} => {
  return {
    ...eventStatusMessage[userType ?? 'patient'],
    ...eventStatusMessage['both'],
  }
}

export const eventTitleByType = (eventType?: EventType) => {
  return eventType ? 'Event' : eventType === 'visit' ? 'Visit' : 'Consultation'
}

const compareAiResults = (r1?: IDiagnosisAiResult[], r2?: IDiagnosisAiResult[]) => {
  if (!r1 && !r2) return true
  if (!r1 && r2 || !r2 && r1) return false
  if (r1 && r2) {
    if (r1.length !== r2.length) return true
    let areEqual = true
    for (const r of r1) {
      areEqual = false
      for (const rr of r2) {
        if (r.className === rr.className && r.probability === rr.probability) {
          areEqual = true
          break
        }
      }
      if (!areEqual) break
    }
    return !areEqual
  }
  return false
}

export const compareDiagnoses = (d1?: Partial<IDiagnosis>, d2?: Partial<IDiagnosis>) => {
  if (!d1 && !d2) return true
  if (!d1 && d2 || !d2 && d1) return false
  if (d1 && d2) {
    return (
      false
      || d1.bodyLocalization?.localizationId !== d2.bodyLocalization?.localizationId
      || d1.bodyLocalization?.localizationPosition.x !== d2.bodyLocalization?.localizationPosition.x
      || d1.bodyLocalization?.localizationPosition.y !== d2.bodyLocalization?.localizationPosition.y
      || d1.bodyLocalization?.localizationSide !== d2.bodyLocalization?.localizationSide
      || d1.date !== d2.date
      || compareAiResults(d1.aiResults, d2.aiResults)
      || d1.eventId !== d2.eventId
      || d1.id !== d2.id
      || d1.imageResult?.id !== d2.imageResult?.id
      || d1.imageResult?.originalImageUrl?.[0] !== d2.imageResult?.originalImageUrl?.[0]
      || d1.imageResult?.source !== d2.imageResult?.source
      || d1.imageResult?.transformedImageUrl !== d2.imageResult?.transformedImageUrl
      || d1.customDiagnosis !== d2.customDiagnosis
    )
  }
  return false
}

export const checkIsEventChanged = (initialValues: IEventFormValues, values: IEventFormValues) => {
  let areDiagnosesChanged = initialValues?.diagnoses?.length !== values?.diagnoses?.length
  if (!areDiagnosesChanged) {
    for (const diagnosis of initialValues.diagnoses) {
      const originalDiagnosis = values.diagnoses.find(od => od.id === diagnosis.id)
      areDiagnosesChanged = compareDiagnoses(diagnosis, originalDiagnosis)
      if (areDiagnosesChanged) break;
    }
  }
  const compare = (
    areDiagnosesChanged
    || initialValues.event?.id !== values.event?.id
    || initialValues.event?.clinicId !== values.event?.clinicId
    || initialValues.event?.createdAt !== values.event?.createdAt
    || initialValues.event?.patientUid !== values.event?.patientUid
    || initialValues.event?.specialistUid !== values.event?.specialistUid
    || initialValues.event?.status !== values.event?.status
    || initialValues.event?.type !== values.event?.type
    || initialValues.gender !== values.gender
    || initialValues.currentMessage !== values.currentMessage
  )
  return compare
}

export const combineDiagnoses = (diagnoses: Partial<IDiagnosis>[], newDiagnoses: Partial<IDiagnosis>[], eventId?: string) => {
  if (eventId) {
    newDiagnoses = newDiagnoses.map(cd => {
      cd.eventId = eventId
      return cd
    })
  }
  return [...newDiagnoses, ...(diagnoses ?? [])] ?? []
}

export const setIcdCodesForAiResults = (aiResults: IDiagnosisAiResult[]) => {
  return aiResults.map(ar => {
    ar.icdCode = DiagnosisAiClassNameDict[ar.className] as keyof typeof DiagnosisCode
    return ar
  })
}
