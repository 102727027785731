import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { EventScreen } from '../screens/EventScreen'
import { RootState, createEvent, updateEvent, findDiagnosisById, deleteEvent, findClinicById, filterDiagnosesByIds, getAIData, updatePatient, findPatientById, findPatientByUid, findEventById } from '../store'
import { Gender, IDiagnosis, IEvent, IPatient } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    diagnosisById: (diagnosisId?: string) => findDiagnosisById(state.diagnoses, {diagnosisId}),
    diagnosesByIds: (diagnosesIds?: string[]) => filterDiagnosesByIds(state.diagnoses, {diagnosesIds}),
    clinicById: (clinicId?: string) => findClinicById(state.clinics, {clinicId}),
    getAIData: (images?: any) => getAIData(images),
    patientByUid: (userUid?: string) => findPatientByUid(state.patients, {userUid}),
    eventById: (eventId?: string) => findEventById(state.events, {eventId}),
    findPatientByUid: (patientUid?: string) => findPatientByUid(state.patients, {userUid: patientUid})
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createEvent: (event: Partial<IEvent>, diagnoses?: Partial<IDiagnosis>[], gender?: Gender) => {
      dispatch(createEvent(event, diagnoses, gender, findPatientById, updatePatient))
    },
    updateEvent: (event: Partial<IEvent>, diagnoses?: Partial<IDiagnosis>[], gender?: Gender) => {
      dispatch(updateEvent(event, diagnoses, gender, findPatientById, updatePatient))
    },
    updatePatient: (patient: Partial<IPatient>) => {
      dispatch(updatePatient(patient))
    },
    deleteEvent: (eventId: string) => {
      dispatch(deleteEvent(eventId))
    },
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type EventScreenContainerProps = ConnectedProps<typeof connector>
export const Event = connector(EventScreen)
