import { IEvent } from "../../types"

// State

export interface IEventsState {
  events: IEvent[]
}

// CRUD

export const CREATE_EVENT = 'CREATE_EVENT'
export const READ_EVENTS = 'READ_EVENTS'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'

// Actions

export interface CreateEventAction {
  type: typeof CREATE_EVENT, event: IEvent
}
export interface SetEventsAction {
  type: typeof READ_EVENTS, events: IEvent[]
}
export interface UpdateEventAction {
  type: typeof UPDATE_EVENT, event: IEvent
}
export interface DeleteEventAction {
  type: typeof DELETE_EVENT, eventId: string
}
export type EventsActionTypes = CreateEventAction | SetEventsAction | UpdateEventAction | DeleteEventAction 

// Selectors

export const SELECT_EVENTS_ID = 'SELECT_EVENTS_ID'
