import { Dispatch } from 'react';
import { RootState } from '..';
import { IDiagnosis, IDiagnosisAiResult } from '../../types'
import { getAiResultForImageUri } from '../../utils/getAiResult';
import { IAuthData, READ_DIAGNOSES } from '../types';

let AUTH_DATA: IAuthData | undefined

// CRUD Actions
// IMPORTANT: CREATE, UPDATE and DELETE actions are inside the events actions,
// because by design these should be updated only together with the event
// so to get rid of circular dependencies, we use only one file for cross operations

export const fetchDiagnoses = (authData: IAuthData) => {
  AUTH_DATA = authData
  return async (dispatch: Dispatch<{type: string, diagnoses: IDiagnosis[]}>, getState: () => RootState) => {
    try {
      if (authData === 'specialist' || authData === 'patient') {
        try {
          const diagnoses = require('../../mocks/data/diagnoses.json') as IDiagnosis[]
          dispatch({type: READ_DIAGNOSES, diagnoses: diagnoses as IDiagnosis[] ?? []})
          if (authData === 'specialist') {
            dispatch({type: READ_DIAGNOSES, diagnoses: diagnoses as IDiagnosis[] ?? []})
          } else {
            const user = getState().auth.user
            const events = getState().events.events
            let diagnosesIds: string[] = []
            events.forEach(event => {
              diagnosesIds.push(...event.diagnosesIds)
            });
            const userDiagnoses = user ? diagnoses.filter(d => diagnosesIds.includes(d.id)) : []
            dispatch({type: READ_DIAGNOSES, diagnoses: userDiagnoses as IDiagnosis[] ?? []})
          }
        } catch(e) {
          dispatch({type: READ_DIAGNOSES, diagnoses: []})
          console.log('An error occurred, it is possible, that no data exists.')
          console.log('Please run yarn mock:data to mock diagnoses first')
          console.error(e)
        }
      }
      // Otherwise read data from remote DB
      else dispatch({type: READ_DIAGNOSES, diagnoses: []}) // NEXT: get json from remote DB when not using mocked data
  } catch (err) {
      throw err
    }
  }
}

export const getAIData = async (images?: any) => {
  let results: IDiagnosisAiResult[] = [] 
  if (typeof images === 'object') {
    for (const image of images) {
      // NEXT: set polarized and normal images into an array or object after this is implemented into AI
      // for now we just take the first image and send it to AI api
      results = await getAiResultForImageUri(image)
      break
    }
  }
  return results
}