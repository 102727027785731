import React from 'react'
import { createDiagnosisObj } from '../utils'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { useRef } from 'react'

type Props = {
  photoCallback?: Function
  children: JSX.Element | JSX.Element[]
}

export function DiagnosisImageDropZone ({children, photoCallback}: Props) {
  const dropzoneRef = useRef<DropzoneRef>(null)
  return (
    <Dropzone accept='image/jpeg' ref={dropzoneRef} multiple={false} onDrop={(files) => {
      if (files.length > 0) {
        const fileUrl = URL.createObjectURL(files[0])
        const diagnosis = createDiagnosisObj([fileUrl])
        if (diagnosis && photoCallback) {
          photoCallback([diagnosis])
        }
      }
    }}>
      {({getRootProps, getInputProps}) => {
        return (
          // @ts-ignore
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
          </div>
        )
      }}
    </Dropzone>
  )
}
