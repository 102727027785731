import React from 'react'
import {StyleSheet, Dimensions, StyleProp, ViewStyle} from 'react-native'
import {TouchableOpacity} from 'react-native'
import {css} from '../styles'
import {TextItem} from './TextItem'

type Props = {
  title?: string
  borderColor?: string
  color?: string
  onPress?: Function
  width?: number | string
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  skin?: 'light' | 'dark'
}

export function ButtonRounded ({title, borderColor, width, onPress, style, disabled, skin, color}: Props) {
  const screenWidth = Dimensions.get('window').width - css.padding * 4
  let w: number | string | undefined = undefined
  if (width?.toString().includes('%') || typeof width === 'number') w = width
  else w = 'auto'
  return (
    <TouchableOpacity
      style={[
        styles.button,
        {borderColor: borderColor ? borderColor : css.colorPrimary + '50'},
        {width: w ? w : screenWidth},
        style,
        disabled ? styles.disabledButton : undefined
      ]}
      disabled={disabled}
      onPress={() => {
        if (onPress) onPress()
      }}
      >
        <TextItem
          style={{
            color: color ? color :
              disabled ?
                css.colorError + '50' : 
                (borderColor && (borderColor !== css.colorPrimary))
                  ? borderColor
                  : (skin === 'light' ? css.colorPrimary : css.colorTextLight),
          }}>
          {title}
        </TextItem>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    width: '100%',
    height: 30,
    maxHeight: 30,
    backgroundColor: 'transparent',
    paddingTop: css.padding / 3,
    paddingBottom: css.padding / 1.5,
    paddingLeft: css.padding,
    paddingRight: css.padding,
    borderWidth: 1,
    alignItems: 'center',
    borderRadius: css.borderRadius * 3,
    fontSize: 14,
  },
  disabledButton: {
    backgroundColor: css.colorError + '20',
    borderColor: css.colorError + '20',
  }
})
