import {
  READ_SPECIALISTS,
  ISpecialistsState,
  SpecialistsActionTypes
} from '../types'

const initialState: ISpecialistsState = {
  specialists: [],
}

const specialists = (state = initialState, action: SpecialistsActionTypes) => {
  switch (action.type) {
    case READ_SPECIALISTS:
      return {
        specialists: action.specialists
      }
    default:
      return state;
  }
};

export default specialists;
