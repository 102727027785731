import { EventStatus, IDiagnosis, IEvent } from "../types"
import moment from 'moment'
import { getLastActionStamp } from "./helpers"

/**
 * Sorts by createdAt date or by appointment date, compares two events and if appointment is later
 * then shows appointment
 * @param events - all events
 * @returns list of sorted by action date events
 */
export const sortByLastActionDate = (events: IEvent[]) => {
  return events.sort((b, a) => {
    const aDate = getLastActionStamp(a)
    const bDate = getLastActionStamp(b)
    return aDate - bDate
  })
}

export const sortDiagnosesByDate = (diagnoses?: Partial<IDiagnosis>[]) => {
  return diagnoses?.sort((b, a) => {
    const aDate = new Date(a.date ?? Date.now()).getTime()
    const bDate = new Date(b.date ?? Date.now()).getTime()
    return aDate - bDate
  }) ?? []
}

export const sortByFinishedEvents = (events: IEvent[], order: 'desc' | 'asc' = 'desc') => {
  return events.sort((b, a) => {
    const aCompleted = a.status === EventStatus.canceled || a.status === EventStatus.completed || a.status === EventStatus.completedWithDiagnosis || a.status === EventStatus.completedWithIssues
    const bCompleted = b.status === EventStatus.canceled || b.status === EventStatus.completed || b.status === EventStatus.completedWithDiagnosis || b.status === EventStatus.completedWithIssues
    if (bCompleted && !aCompleted) return order === 'desc' ? -1 : 1
    if (aCompleted && !bCompleted) return order === 'desc' ? 1 : -1
    return 0
  })
}

export const eventBelongsToDate = (event: IEvent, date: Date) => {
  return moment(event.createdAt).isSame(date, 'day')
}

export const oneOfEventsBelongsToDate = (events: IEvent[], date: Date) => {
  return events.some(e => eventBelongsToDate(e, date))
}
