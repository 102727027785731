import React from 'react'
import {StyleSheet, View, Dimensions} from 'react-native'
import Icon from './IconWrapper'
import {css} from '../styles'
import { IClinic, iconsMapper, IEvent, ILatLong } from '../types'
import { ButtonRounded } from './ButtonRounded'
import { TextItem } from './TextItem'
import { TimeItem } from './TimeItem'
import { ViewRow } from './ViewRow'
import { getStaticMapsImageUri, openMaps } from '../utils'
import { ButtonCall } from './ButtonCall'
import { ButtonSms } from './ButtonSms'
import { addressOneLine } from '../utils'
import moment from 'moment'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { useNavigation } from '@react-navigation/core'
import ImageWithPreview from './ImageWithPreview'
import { EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, StackParams } from '../types'

type Props = {
  appointmentDate: string
  coords: ILatLong
  clinic?: IClinic
  event?: IEvent
}

type NavigationProps = BottomTabNavigationProp<StackParams, typeof EVENT_ROUTE_NAME>

export function HomeAppointmentMap ({appointmentDate, coords, clinic, event}: Props) {
  const showEvents = Dimensions.get('window').height > 690
  const staticMapsImageUri = getStaticMapsImageUri(coords)
  const phone = clinic?.departmentAddress.phone
  const titleWidth = Dimensions.get('window').width - 110
  const departmentAddress = addressOneLine(clinic?.departmentAddress ?? clinic?.address)
  const {navigate} = useNavigation<NavigationProps>()
  return (
    <View
      style={styles.activeAppointment}
    >
      <ViewRow style={{alignItems: 'center'}}
        onStartShouldSetResponder={() => !!event}
        // TODO: fix this error
        // @ts-ignore
        onResponderRelease={() => navigate(EVENT_ROUTE_NAME, {event})}
      >
        <View style={{flexGrow: 1}}>
          <TextItem style={styles.headerText}>My next visit</TextItem>
          <TextItem style={[styles.subheaderText, {width: titleWidth}]} numberOfLines={1}>{clinic?.titleShort + ', ' + clinic?.title}</TextItem>
        </View>
        {event &&
          <Icon name={iconsMapper.right} color={css.colorPrimary} size={30} />
        }
      </ViewRow>
      <View style={styles.content}>
        <View
          style={styles.imageContainer}
          onStartShouldSetResponder={() => true}
          onResponderRelease={() => openMaps(coords)}>
          <ImageWithPreview
            style={styles.image}
            source={{uri: staticMapsImageUri}}
          />
        </View>
        <View style={styles.clinicInfo}>
          {/* Skin and Veneral Disease department */}
          <TextItem style={styles.headerSmallText}>
            {clinic?.departmentTitle ?? clinic?.title}
          </TextItem>
          <TextItem style={[styles.subheaderText, styles.subheaderSmallText]}>{departmentAddress}</TextItem>
          <ViewRow style={{marginTop: css.padding, alignItems: 'center'}}>
            <TimeItem date={new Date(appointmentDate)} />
            <View style={{flexGrow: 1, marginLeft: css.padding}}>
              {appointmentDate &&
                <>
                  <TextItem style={{fontFamily: css.fontFamily600, lineHeight: 14}}>
                    {moment(appointmentDate).format('Do MMM')}
                  </TextItem>
                  <TextItem style={{fontSize: 10, color:css.colorText, lineHeight: 10}}>
                    {moment(appointmentDate).format('dddd')}
                  </TextItem>
                </>
              }
            </View>
            <ButtonCall phone={phone} small={true} />
            <ButtonSms phone={phone} small={true} />
          </ViewRow>
        </View>
      </View>
      {showEvents &&
        <ViewRow style={{marginTop: css.padding}}>
          <ButtonRounded title="All events" width='96%' onPress={() => navigate(EVENTS_ROUTE_NAME)} />
        </ViewRow>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  activeAppointment: {
    borderRadius: css.borderRadius,
    backgroundColor: css.colorDarkBgLighten2,
    padding: css.padding * 1.5,
    marginBottom: css.padding / 2,
    flexGrow: 1,
  },
  headerText: {
    fontSize: 18,
    fontFamily: css.fontFamily600,
  },
  headerSmallText: {
    fontFamily: css.fontFamily600,
  },
  subheaderText: {
    color: css.colorText,
  },
  subheaderSmallText: {
    fontSize: 12,
  },
  content: {
    marginTop: css.padding,
    borderRadius: css.borderRadius/2,
    backgroundColor: css.colorDarkBgLighten3,
    position: 'relative',
    flexGrow: 1,
  },
  imageContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: css.colorPrimary + '20',
    overflow: 'hidden',
    borderRadius: css.padding/2,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  image: {
    width: '100%',
    flex: 1,
  },
  clinicInfo: {
    padding: css.padding*1.5,
    flexShrink: 1
  }
})