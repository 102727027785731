import React from 'react'
import {StyleProp, StyleSheet, Text, TextStyle} from 'react-native'
import {css} from '../styles'

type Props = {
  children: string
  style?: StyleProp<TextStyle>
}

export function TextCodeItem ({children, style}: Props) {
  return <Text style={[styles.textCode, style]}>{children}</Text>
}

const styles = StyleSheet.create({
  textCode: {
    padding: css.padding / 2,
    textAlign: 'center',
    fontSize: 18,
    flex: 1,
    fontFamily: css.fontFamily600,
    borderWidth: 1,
    borderRadius: css.borderRadius / 2,
    borderColor: css.colorPrimary,
    borderStyle: 'dashed',
    textTransform: 'uppercase',
    fontVariant: ['lining-nums'],
    color: css.colorTextLight,
  },
})
