import * as React from 'react';
import { Text, TouchableOpacity, View, StyleSheet, StyleProp, ViewStyle, Platform } from 'react-native';
import {
  NavigationHelpersContext,
  useNavigationBuilder,
  TabRouter,
  StackRouter,
  TabActions,
  DefaultNavigatorOptions,
  TabRouterOptions,
  createNavigatorFactory,
  useNavigation,
  CommonActions
} from '@react-navigation/native';
import { StackView, StackNavigationOptions } from '@react-navigation/stack';
import { TextItem } from '../components/TextItem';
import { css } from '../styles';
import { AppContext } from '../contexts';
import { CREATE_EVENT_ROUTE_NAME, EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, PATIENTS_ROUTE_NAME, PATIENT_ROUTE_NAME } from '../types';
import { ViewRow } from '../components';
import { color } from 'react-native-reanimated';
// import { BottomTabView } from '@react-navigation/bottom-tabs';

// Props accepted by the view
type StackNavigationConfig = {
  // tabBarStyle: StyleProp<ViewStyle>;
  // contentStyle: StyleProp<ViewStyle>;
  // sideComponent?: JSX.Element | JSX.Element[]
};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<StackNavigationOptions> &
  StackNavigationConfig;

function CustomStackNavigator({
  initialRouteName,
  // backBehavior,
  children,
  screenOptions,
  ...rest
}: Props) {
  const { state, descriptors, navigation } = useNavigationBuilder(StackRouter, {
    initialRouteName,
    // backBehavior,
    children,
    screenOptions,
  })
  const appContext = React.useContext(AppContext)

  const routeName = state.routes[0].name
  let isMounted = true
  React.useEffect(() => {
    if (isMounted) {
      if (state.routes.length === 1 && (routeName === EVENT_ROUTE_NAME || routeName === PATIENT_ROUTE_NAME)) {
        navigation.dispatch((st:any) => {
          const routes = [
            { name: routeName === PATIENT_ROUTE_NAME ? PATIENTS_ROUTE_NAME : EVENTS_ROUTE_NAME, params: {date: undefined} },
            //current state
            ...st.routes
          ];
          return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
          });
        });
      }
    }
    return () => {
      isMounted = false
    }
  })

  const styles = StyleSheet.create({
    contentBg: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: appContext?.state.skin === 'light' ? '#fafafa' : css.colorDarkBg,
      width: '100%'
    },
    sidebar: {
      flexGrow: 0,
      width: 400,
      backgroundColor: css.colorSidebar, // css.colorDarkBgLighten1,
      paddingTop: css.padding
    },
    mainBlock: {
      minWidth: 400,
      position: 'relative',
      paddingTop: 0
    },
    screenWithoutSideBlock: {
      paddingTop: css.padding,
      height: '100%',
      // @ts-ignore
      overflow: 'auto'
    },
    header: {
      height: 65,
      backgroundColor: appContext?.state.skin === 'light' ? 'white' : css.colorDarkBgLighten1,
      marginBottom: css.padding/2,
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingHorizontal: css.padding*2,
    },
    headerTitle: {
      color: appContext?.state.skin === 'light' ? css.colorTextDark : css.colorTextLight,
      fontSize: 20,
      // fontFamily: css.fontFamily600,
      // fontWeight: '700'
    },
    footer: {
      height: 65,
      borderColor: 'transparent',
      borderTopColor: css.colorText + '20',
      borderWidth: 1,
      // backgroundColor: appContext?.state.skin === 'light' ? '#efefef' : css.colorComponentBg,
      marginTop: css.padding/2,
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingHorizontal: css.padding*2,
      width: '100%',
      zIndex: -1
    },
    footerText: {
      color: appContext?.state.skin === 'light' ? css.colorText : css.colorText + '80',
      fontSize: 12,
      flexGrow: 1,
    },
  })
  
  return (
    <View style={styles.contentBg}>
      {state.routes.map((route, i) => (
        <View
          key={route.key}
          style={[
            StyleSheet.absoluteFill,
            { display: (i === state.index || i === 0) ? 'flex' : 'none' },
            (i === 0 && routeName !== CREATE_EVENT_ROUTE_NAME) ? styles.sidebar : { flexGrow: 1},
            (i === 1 || routeName === CREATE_EVENT_ROUTE_NAME) ? styles.screenWithoutSideBlock : {},
            styles.mainBlock,
          ] as ViewStyle}
        >
          {i !== 1 && route.name !== CREATE_EVENT_ROUTE_NAME && descriptors[route.key].render()}
          {(i === 1 || route.name === CREATE_EVENT_ROUTE_NAME) &&
            <View style={{minHeight: '100%'}}>
              <View style={styles.header}>
                <TextItem style={styles.headerTitle}>{route.name}</TextItem>
              </View>
              <View style={{maxWidth: 860, alignSelf: 'center', width: '100%', paddingHorizontal: css.padding, flex: 1}}>
                { descriptors[route.key].render() }
              </View>
              <View style={styles.footer}>
                <ViewRow style={{width: '100%', alignItems: 'center'}}>
                  <TextItem style={styles.footerText}>SmartVisSolution, all rights reserved, {new Date().getFullYear()}</TextItem>
                    <a href="https://apps.apple.com/us/app/" style={{marginRight: css.padding/2}}>
                      <img src={`/appstore_${appContext?.state.skin ?? 'dark'}.svg`} alt='Download our app from Apple App Store' />
                    </a>
                    <a href="https://play.google.com/store/apps/details">
                      <img src={`/googleplay_${appContext?.state.skin ?? 'dark'}.svg`} alt='Download our app from Google Play' />
                    </a>
                </ViewRow>
              </View>
            </View>
          }
        </View>
      ))}
    </View>
    
  )
}

export default createNavigatorFactory(CustomStackNavigator);
