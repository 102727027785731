import React, {ReactNode} from 'react'
import {Platform, StyleProp, StyleSheet, Text, TextStyle} from 'react-native'
import {css} from '../styles'

type Props = {
  style?: StyleProp<TextStyle>
  children: ReactNode | ReactNode[]
  numberOfLines?: number
}

export function TextItem ({style, children, numberOfLines}: Props) {
  const styles = StyleSheet.create({
    block: {
      fontFamily: css.fontFamily,
      fontSize: 14,
      color: css.colorTextLight,
      fontVariant: ['lining-nums'],
    },
  })
  return <Text
    style={[styles.block, style]}
    numberOfLines={numberOfLines}>
    {children}
  </Text>
}
