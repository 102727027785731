
import {bodyMan} from './utils/localizationMan'
import {bodyWoman} from './utils/localizationWoman'
import {feet} from './utils/localizationFeet'

export enum iconsMapper {
  home = 'home-outline',
  visits = 'calendar-outline',
  patients = 'people-outline',
  patient = 'person-outline',
  user = 'person-outline',
  visit = 'document-text-outline',
  search = 'search-outline',
  library = 'download-outline',
  camera = 'camera-outline',
  call = 'call-outline',
  mail = 'mail-outline',
  chat = 'chatbox-outline',
  right = 'arrow-forward-outline',
  left = 'arrow-back-outline',
  down = 'chevron-down-circle-outline',
  up = 'chevron-up-circle-outline',
  help = 'help-circle-outline',
  trash = 'trash-outline',
  share = 'arrow-redo-outline',
  body = 'body-outline',
  bodyBack = 'body',
  close = 'close-outline',
  doc = 'reader-outline',
  calendar = 'calendar-outline',
  newUser = 'person-add-outline',
  read = 'book-outline',
  female = 'woman-outline',
  male = 'man-outline',
  // 'footsteps' icon, react-native-vector-icons does not have that icon
  // it uses 5.0.1 version of Ionicons, 'footsteps' was introduced only in 5.4.0
  // so we add glyphs to the old Ionicons.ttf fonts and reuse react-native-vector-icons
  // from dts package registry. Since new font has different glyph ranges, so we
  // reuse old font, to have the same namings on all used glyphs.
  feet = 'footsteps-outline',
  point = 'disc-outline',
  rotate = 'sync-outline',
  save = 'save-outline',
  refresh = 'refresh-outline',
  moon = 'moon-outline',
  sun = 'sunny-outline',
  check = 'checkmark-outline'
}

export type ILatLong = [number, number]

export interface IAddress {
  address1: string
  address2?: string
  city: string
  country: string
  postCode: string
  // latitude and longitude for map coordinates
  latLong: ILatLong
  phone: number // 600000000 to 699999999 ===> +370 (600) 000 000
  email?: string
}

export interface IClinic {
  id: string // should it be some kind of hardcoded array???
  title: string
  titleShort: string
  address: IAddress
  departmentTitle: string
  departmentAddress: IAddress
}

export enum UserType {
  patient = 'patient',
  specialist = 'specialist'
}

export interface IFullName {
  firstName: string
  lastName: string
}
export interface IUser {
  type: UserType,
  createdAt: string
  updatedAt: string
  id: string
  uid: string
  fullName: IFullName
  phone?: number // 37060000000 to 37069999999 ===> +370 (600) 00 000
  email?: string
  gender?: Gender
  subscriptions?: {
    aiService?: boolean // later boolean maybe changes to some object with options, so to check now just use `if (aiService)`
  }
}

export interface ISpecialist extends IUser {
  lastViewedPatientUid?: string // uid of last viewed patient
  lastViewedEventId?: string
  activePatientUid?: string
  activeEventId?: string
  patientUids: string[] // array of patient uid
  clinicId?: string // id of clinic
  type: UserType.specialist
  preTitle?: string // it could be 'Prof.', 'Dr.', etc.
  postTitle?: string // it could be 'MD', 'MD PhD', etc.
  settings?: {
    // true - shows specialists full name and specified clinic address
    // false - shows only clinic details
    showPersonalContacts?: boolean
  }
}

export interface IPatient extends IUser {
  eventIds: string[]
  comment?: string
  specialistUid: string
  type: UserType.patient
}


export interface IChat {
  createdAt: string
  messages: IComment[]
}

export enum EventType {
  'consultation' = 'consultation',
  'visit' = 'visit'
}

/**
 * IEvent combines both types IConsultation and IVisit
 * 
 * It uses status as combined type for easier implementation in the code
 */
export interface IEvent {
  createdAt: string
  clinicId?: string
  id: string
  diagnosesIds: string[]
  patientUid?: string
  specialistUid?: string
  type: EventType
  comments: IComment[]
  status?: EventStatus
  // NEXT: debate about it, if there is no patient, then it is not possible to get or set a gender.
  // Should we add an optional gender parameter which would be used if no patient exists?  
  // *** NOTE: I think this gender value is necessary and it should be highest in priority
  // if patient has gender value it should be rewritten by a new event gender value.
  // Scenario: first event was saved as man, so patient as well. On another visit specialist
  // sees, that he/she saved wrong sex, so on another event he/she chooses sex, so event value changes
  // after event save patient gets new sex value.
  gender?: Gender
}

export interface IConsultation extends IEvent {
  status?: ConsultationStatus
  type: EventType.consultation
  visitId?: string
}

export interface IVisit extends IEvent {
  status?: VisitStatus
  type: EventType.consultation
  appointmentDate?: string
}

// TODO: Check all diagnoses codes, fix if needed, these are created in relation with the response from AI results
export enum DiagnosisCode {
  c43 = 'c43', // Melanoma, MEL
  d22 = 'd22', // Melanocytic nevus, NV - is it just mole?
  c4491 = 'c4491', // Carcinoma, BCC - Basal cell
  c4492 = 'c4492', // Carcinoma, SCC - Squamous cell
  l57 = 'l57', // Actinic keratosis, AK
  l82 = 'l82', // Seborrheic - benign keratosis, BKL
  d23 = 'd23',  // Dermatofibroma, DF
  h02 = 'h02', // Vascular lesion, VASC
  // none = 'none', // nothing serious found
}

/**
 * AI algorith returns codes that are not a standard, we remap to the standard values
 */
 export const DiagnosisAiClassNameDict = {
  MEL: 'c43',
  NV: 'd22',
  BCC: 'c4491',
  SCC: 'c4492',
  AK: 'l57',
  BKL: 'l82',
  DF: 'd23',
  VASC: 'h02',
}

// // **********************
// // These codes below does not exist in AI results
// b22 = 'B22', // Mole

export enum DiagnosisTitle {
  // 'c44' = 'Carcinoma',
  // 'c43' = 'Melanoma',
  // 'l82' = 'Seborrheic keratosis',
  // 'b22' = 'Mole',
  // 'none' = 'Nothing serious found',
  // 'all' = 'All diagnoses'
  'c43' = 'Melanoma',
  'd22' = 'Melanocytic nevus - mole',
  'c4491' = 'Carcinoma, Basal cell',
  'c4492' = 'Carcinoma, Squamous cell',
  'l57' = 'Actinic keratosis',
  'l82' = 'Seborrheic - benign keratosis',
  'd23' = 'Dermatofibroma',
  'h02' = 'Vascular lesion',
  // 'none' = 'Nothing serious found'
}

export interface IImageResult {
  id: string
  source: 'external' | 'device' | 'unknown' | 'internal' | string // NEXT: fix to specific device name, when device is developed
  originalImageUrl: string[]
  transformedImageUrl?: string[]
}

// Body localization map

// If we need use CT, then use these two enums below
// export enum BodyLocalizationSNOMED_CT {
//   '43067004​' = 'Skin structure of scalp',
// }
// export enum SNOMEDToSNOMEDCTMapper {
//   t02102 = '43067004​'
// }

export enum BodyLocalizationSNOMED {  
  // ANTERIOR - front view of the body
  t02120 = 'Face',
  t02424 = 'Chest',
  t02480 = 'Abdomen region',
  t02485 = 'Hypogastric region',
  t025 = 'Perineum and genitalia',
  t02420l = 'Axilla (left)',
  t02420r = 'Axilla (right)',
  t02615l = 'Anterior surface of arm (left)',
  t02615r = 'Anterior surface of arm (right)',
  t02652l = 'Palmar area of hand (left)',
  t02652r = 'Palmar area of hand (right)',
  t02832l = 'Anterior surface of leg (left)',
  t02832r = 'Anterior surface of leg (right)',
  t02851l = 'Dorsum of foot (left)',
  t02851r = 'Dorsum of foot (right)',
  
  // POSTERIOR - back view of the body
  t02102 = 'Scalp',
  t02451 = 'Upper back',
  t02452 = 'Lower back',
  t02471 = 'Buttock',
  t02614l = 'Posterior surface of arm (left)',
  t02614r = 'Posterior surface of arm (right)',
  t02651l = 'Dorsal area of hand (left)',
  t02651r = 'Dorsal area of hand (right)',
  t02834l = 'Posterior surface of leg (left)',
  t02834r = 'Posterior surface of leg (right)',

  // ANTERIOR/POSTERIOR (front and back) parts of the body
  t022l = 'Ear (left)',
  t022r = 'Ear (right)',
  t023 = 'Neck',

  // FEET
  t02852l = 'Foot sole (left)',
  t02852r = 'Foot sole (right)',
}

export type LocalizationIconPosition = 'positionHandLeft' | 'positionHandRight' | 'positionLegRight' | 'positionLegLeft' | 'positionHead' | 'positionBody' | 'positionBodyLower'

export const localizationIconPosition: {[key in keyof typeof BodyLocalizationSNOMED]: LocalizationIconPosition} = {
  t02120: 'positionHead',
  t02424: 'positionBody',
  t02480: 'positionBody',
  t02485: 'positionBody',
  t025: 'positionBodyLower',
  t02420l: 'positionHandLeft',
  t02420r: 'positionHandRight',
  t02615l: 'positionHandLeft',
  t02615r: 'positionHandRight',
  t02652l: 'positionHandLeft',
  t02652r: 'positionHandRight',
  t02832l: 'positionLegLeft',
  t02832r: 'positionLegRight',
  t02851l: 'positionLegLeft',
  t02851r: 'positionLegRight',
  
  // POSTERIOR - back view of the body
  t02102: 'positionHead',
  t02451: 'positionBody',
  t02452: 'positionBody',
  t02471: 'positionBodyLower',
  t02614l: 'positionHandLeft',
  t02614r: 'positionHandRight',
  t02651l: 'positionHandLeft',
  t02651r: 'positionHandRight',
  t02834l: 'positionLegLeft',
  t02834r: 'positionLegRight',

  // ANTERIOR/POSTERIOR (front and back) parts of the body
  t022l: 'positionHead',
  t022r: 'positionHead',
  t023: 'positionHead',

  // FEET
  t02852l: 'positionLegLeft',
  t02852r: 'positionLegRight',
}

/**
 * position in percentage, value ranges from 0 to 1 (0.00 - 1.00)
 */
export type LocalizationPosition = {
  x: number
  y: number
}
export type LocalizationSize = {
  w: number
  h: number
}
export type LocalizationCoords = [LocalizationPosition, LocalizationPosition]

export type Gender = 'male' | 'female'
export type LocalizationSide = 'back' | 'front' | 'feet'
// export enum LocalizationSide { 'left', 'bilateral', 'right' }



export type BodyLocalization = {
  localizationId: keyof typeof BodyLocalizationSNOMED
  localizationPosition: LocalizationPosition // for more precise pointer positioning
  localizationSide: LocalizationSide
  // splitFraction: {x: 0.1, y: 0.05}
}

// body is sliced into fractions in size of 0.1x0.05 blocks,
// with coordinations in range of 0 - 1.0
// starting from (0, 0) at top left point, to (1, 1) at down right
// so the full map of body will be:
// 10 blocks by x axis and 20 blocks by y axis
// array shows filled blocks
export interface bodyLocalizationArea {

}



export const bodyLocalizationMap:{
  [key in Gender]: {
    [key in LocalizationSide]?: {
      [key in keyof typeof BodyLocalizationSNOMED]?: LocalizationPosition[]
    }
  }
} = {
  'male': {
    ...bodyMan,
    feet,
  },
  'female': {
    ...bodyWoman,
    feet,
  }
}

export const localizationImageRatio = {
  'male': {
    'front': 0.435,
    'back': 0.435,
    'feet': 0.54,
  },
  'female': {
    'front': 0.473,
    'back': 0.473,
    'feet': 0.54,
  }
}

export interface IDiagnosisAiResult {
  className: keyof typeof DiagnosisAiClassNameDict
  icdCode: keyof typeof DiagnosisCode
  probability: number
  rect: {
    leftX: number
    topY: number
    rightX: number
    bottomY: number
  }
}

// // Diagnosis code is usefull in the future for other than AI diagnosis codes
// export interface IDiagnosisCode {
//   aiCode?: keyof typeof DiagnosisCode
//   aiProbability?: number
//   // specialistCode: any
// }

export interface IDiagnosis {
  id: string
  customDiagnosis?: string
  // patientId?: string
  eventId: string
  // comment?: string
  date: string // time of diagnosis
  imageResult: IImageResult
  bodyLocalization?: BodyLocalization
  aiResults: IDiagnosisAiResult[]
}

export interface IComment {
  date: string
  message: string
  user?: {
    uid?: string
    type: UserType
  }
  clinicId?: string
}

/**
 * **pending** - visit is created with this status, so it is pending till the visit date
 * 
 * **canceled** - is canceled by patient or by specialist
 * 
 * **completed** - is saved by specialist after no bad diagnoses found
 * 
 * **completedWithDiagnosis** - is saved by specialist when visit is completed with diagnoses
 */
export enum VisitStatus {
  // if specialist is "moving" the old event into the new date, first the old event is canceled and new is created
  pending = 'pending',
  canceled = 'canceled',
  completed = 'completed',
  completedWithDiagnosis = 'completed-with-diagnosis',
}

/**
 * **pendingReview** - consultation is created with this status, it is pending while specialist's works with it
 * 
 * **needsReply** - if bad data is provided or more data is needed specialist waits for input from patient's side
 * 
 * **needsVisit** - created by specialist if visit is needed, asks for a patient to call
 * 
 * **completed** - created by specialist if no other action is needed and no bad diagnosis found
 * 
 * **completedWithIssues** - created by specialist if no other action is needed, but with some issues
 * 
 * **canceled** - canceled by the patient (only patient is capable to cancel)
 */
export enum ConsultationStatus {
  pendingReview = 'pending-review',
  needsReply = 'needs-reply',
  needsVisit = 'needs-visit',
  completed = 'completed',
  completedWithIssues = 'completed-with-issues',
  canceled = 'canceled',
  // this is used only temporary and should not be used in DB
  // specialist changes consultation to createVisit only for UX purposes
  // create-visit is not saved to DB, completeWithIssues is saved instead
  createVisit = 'create-visit',
}

export const EventStatus = {...VisitStatus, ...ConsultationStatus}
export type EventStatus = VisitStatus | ConsultationStatus

export const consultationStatusOptions: Item[] = [
  {
    label: 'Success',
    value: ConsultationStatus.completed,
    key: ConsultationStatus.completed,
  },
  {
    label: 'Completed with diagnosis or issues',
    value: ConsultationStatus.completedWithIssues,
    key: ConsultationStatus.completedWithIssues,
  },
  {
    label: 'Bad data, images or more data needed',
    value: ConsultationStatus.needsReply,
    key: ConsultationStatus.needsReply,
  },
  {
    label: 'Needs visit',
    value: ConsultationStatus.needsVisit,
    key: ConsultationStatus.needsVisit,
  },
  {
    label: 'Assign new visit',
    value: 'create-visit',
    key: 'create-visit',
  },
]

export const visitStatusOptions: Item[] = [
  {
    label: 'Success',
    value: VisitStatus.completed,
    key: VisitStatus.completed,
  },
  {
    label: 'Completed with diagnosis or issues',
    value: VisitStatus.completedWithDiagnosis,
    key: VisitStatus.completedWithDiagnosis,
  },
]

// mimics react-native-picker-select interface, we clone this interface
// because we need to use it without calling that picker on web
export interface Item {
  label: string;
  value: any;
  key?: string | number;
  color?: string;
  /**
   * Used when you want a different label displayed
   * on the input than what is displayed on the Picker
   *
   * If falsy, label is used
   */
  inputLabel?: string;
}

export interface IDevice {
  connected: boolean
  id: string
  name: string
}

export const IDB_NAME = 'svsDb'
export const IDB_DIAGNOSES_PHOTOS = 'svsDiagnosesPhotos'
export const IDB_VERSION = 1.0

export const HOME_ROUTE_NAME = 'Home'
export const CREATE_EVENT_ROUTE_NAME = 'Create Event'
export const EVENT_ROUTE_NAME = 'Event'
export const EVENTS_ROUTE_NAME = 'Events'
export const PATIENT_ROUTE_NAME = 'Patient'
export const PATIENTS_ROUTE_NAME = 'Patients'
export const SETTINGS_ROUTE_NAME = 'Settings'
// NEXT: delete everything related to the test: TestScreen and TestContainer
export const TEST_ROUTE_NAME = 'Test'

export type StackParams = {
  [HOME_ROUTE_NAME]: {data: string} | undefined
  [CREATE_EVENT_ROUTE_NAME]: {eventId?: string, title?: string, subtitle?: string, cameraDiagnoses?: Partial<IDiagnosis>[], watch?: Function} | undefined
  [EVENT_ROUTE_NAME]: {eventId?: string, title?: string, subtitle?: string, cameraDiagnoses?: Partial<IDiagnosis>[]} | undefined
  [EVENTS_ROUTE_NAME]: {query?: any, date?: any} | undefined
  [PATIENT_ROUTE_NAME]: {patientId?: string, firstName?: string, lastName?: string} | undefined
  [PATIENTS_ROUTE_NAME]: {query?: string, focus?: boolean, deletePatient?: string, eventId?: string, getPatientUid?: (patientUid: string) => void} | undefined
  [SETTINGS_ROUTE_NAME]: {data: string} | undefined
  [TEST_ROUTE_NAME]: {event?: IEvent} | undefined
}

export const LONG_DATE_FORMAT = 'hh:mm, DD MMM YYYY'
