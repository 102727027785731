import AsyncStorage from '@react-native-async-storage/async-storage'
import { combineReducers, Reducer } from '@reduxjs/toolkit'
import auth from './auth'
import patients from './patients'
import specialists from './specialists'
import events from './events'
import diagnoses from './diagnoses'
import clinics from './clinics'
import { persistReducer, purgeStoredState } from "redux-persist"
import { LOGOUT_USER } from '../types'

const appReducer = combineReducers({
  auth,
  patients,
  specialists,
  events,
  diagnoses,
  clinics,
})

export type RootState = ReturnType<typeof appReducer>

export const rootReducer = (rootState: RootState, action: any) => {
  if (action.type === LOGOUT_USER) {
        rootState = {
          auth: {
            loggedIn: false,
          },
          patients: {
            patients: []
          },
          specialists: {
            specialists: []
          },
          events: {
            events: []
          },
          diagnoses: {
            diagnoses: []
          },
          clinics: {
            clinics: []
          },
        }
      }
  return appReducer(rootState, action)
}

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  // blacklist: ['bleUiState'],
}

// uncomment if you want to erase the async storage state
// purgeStoredState(persistConfig)

export const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer)