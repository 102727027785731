import React from 'react'
import {View} from 'react-native'
import { ViewRow } from './ViewRow'
import {css} from '../styles'
import { SvgXml } from 'react-native-svg'
import { TextItem } from './TextItem'

type Props = {
  title: string
  imageXml: string
  text: string
  height?: number
}

export function HomeHowToBlock ({title, imageXml, text, height}: Props) {
  return (
    <ViewRow style={{marginBottom: css.padding*3}}>
      <SvgXml xml={imageXml} height={height ?? 120} width={120} />
      <View style={{marginLeft: css.padding, flex: 1, justifyContent: 'center'}}>
        <TextItem style={{marginBottom: css.padding/2, alignSelf: 'flex-start', fontWeight: '700', fontFamily: css.fontFamily600}}>
          {title}
        </TextItem>
        <TextItem>
          {text}
        </TextItem>
      </View>
    </ViewRow>
  )
}
