import React from 'react'
import { StyleSheet} from 'react-native'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../../node_modules/react-perfect-scrollbar/dist/css/styles.css';

type Props = {
  style?: any
  children?: JSX.Element | JSX.Element[]
}

export function ScrollWrapper ({style, children}: Props) {
  return (<PerfectScrollbar style={typeof style === 'object' ? style : {}}>
    {children}
  </PerfectScrollbar>)
}
