import { IPatient } from "../../types"

// State

export interface IPatientsState {
  patients: IPatient[]
}

// CRUD

export const CREATE_PATIENT = 'CREATE_PATIENT'
export const READ_PATIENTS = 'READ_PATIENTS'
export const UPDATE_PATIENT = 'UPDATE_PATIENT'
export const DELETE_PATIENT = 'DELETE_PATIENT'

// Actions

export interface CreatePatientAction {
  type: typeof CREATE_PATIENT, patient: IPatient
}
export interface SetPatientsAction {
  type: typeof READ_PATIENTS, patients: IPatient[]
}
export interface UpdatePatientAction {
  type: typeof UPDATE_PATIENT, patient: IPatient
}
export interface DeletePatientAction {
  type: typeof DELETE_PATIENT, patientUid: string
}
export type PatientsActionTypes = CreatePatientAction | SetPatientsAction | UpdatePatientAction | DeletePatientAction 

// Selectors

export const SELECT_PATIENTS_ID = 'SELECT_PATIENTS_ID'
