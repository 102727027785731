import React from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { css } from '../styles';

export const Toast = {
  showWithGravity: (message: string, duration: number) => {
    toast(message, {
      duration: duration * 2,
      // position: position === 'CENTER' ? 'bottom-center' : 'top-center'
    })
  },
  SHORT: 1000,
  CENTER: 'CENTER'
}

export const ToastComponent = <Toaster toastOptions={{
  style: {
    fontFamily: css.fontFamily,
    color: css.colorTextLight,
    backgroundColor: css.colorInfo
  }
}}/>