import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { TestScreen} from '../screens/TestScreen'
import { RootState, filterActivePatientEvents, updateEvent, createEvent, filterDiagnosesByIds } from '../store'
import { IDiagnosis, IEvent, UserType } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    specialists: state.specialists,
    activePatientEvents: state.auth.user?.type === UserType.patient ? filterActivePatientEvents(state.events) : [],
    activeEventDiagnoses: (diagnosesIds: string[]) => filterDiagnosesByIds(state.diagnoses, {diagnosesIds}) 
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateEvent: (event: IEvent, diagnoses: IDiagnosis[]) => dispatch(updateEvent(event, diagnoses)),
    createEvent: (event: IEvent, diagnoses: IDiagnosis[]) => dispatch(createEvent(event, diagnoses))
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type TestProps = ConnectedProps<typeof connector>
export const Test = connector(TestScreen)
