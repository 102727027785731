import React from 'react'
import { StyleProp } from 'react-native'
import { StyleSheet, ViewStyle} from 'react-native'

type Props = {
  name: string
  color?: string
  size?: number
  onPress?: Function
  style?: StyleProp<ViewStyle> | StyleProp<ViewStyle>[]
  iconClass?: string
}

export default function Icon ({name, color, size, style, iconClass}: Props) {
  const mainStyle = {alignSelf: 'center', display:'block', color: color ?? '#ffffff', fontSize: size ?? 20}
  return (
    <>
      {/* @ts-ignore */}
      <ion-icon name={name} style={{...mainStyle, ...(StyleSheet.flatten(style ?? {}))}} class={iconClass}></ion-icon>
    </>
  )
}

