import {
  CREATE_DIAGNOSIS,
  READ_DIAGNOSES,
  UPDATE_DIAGNOSIS,
  DELETE_DIAGNOSIS,
  DiagnosesActionTypes,
  IDiagnosesState,
} from '../types'
import { IDiagnosis } from '../../types';
import { Platform } from 'react-native';

const initialState: IDiagnosesState = {
  diagnoses: [],
}

const diagnoses = (state = initialState, action: DiagnosesActionTypes) => {
  switch (action.type) {
    case CREATE_DIAGNOSIS:
      return {
        diagnoses: [...state.diagnoses, action.diagnosis]
      }
    case READ_DIAGNOSES:
      return {
        diagnoses: action.diagnoses
      }
    case UPDATE_DIAGNOSIS: {
      const diagnoses = [...state.diagnoses]
      const dIndex = diagnoses.findIndex(d => d.id === action.diagnosis.id)
      if (dIndex > -1) {
        diagnoses.splice(dIndex, 1, action.diagnosis)
      } else throw('No diagnosis found to update')
      return { diagnoses }
    }
    case DELETE_DIAGNOSIS: {
      const diagnoses = [...state.diagnoses]
      const dIndex = diagnoses.findIndex(d => d.id === action.diagnosisId)
      if (dIndex > -1) {
        diagnoses.splice(dIndex, 1)
      } else throw('No diagnosis found to delete')
      return { diagnoses }
    }
    default:
      return state;
  }
};

export default diagnoses;