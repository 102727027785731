import { Linking, Platform } from "react-native"
import { ILatLong } from "../types"

const mapToken = 'pk.eyJ1IjoiZWRhbXVzaXMiLCJhIjoiY2ttb3RkcnZpMjhjcDJwcWtmOXp4b2o2aSJ9.LV94P8xSI8PixF6ZNdlHBg'
const providerUrl = 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static'
const staticMapPath = '/pin-s+ff0000'
const zoom = 14.82

export const getStaticMapsImageUri = (
  coords: ILatLong,
  height = 600,
  width = 600,
  ) => {
  return `${providerUrl}${staticMapPath}(${coords[0]},${coords[1]})/${coords[0]},${coords[1]},${zoom},0/${width}x${height}@2x?before_layer=admin-0-boundary-bg&access_token=${mapToken}`
}

export const openMaps = (coords: ILatLong) => {
  var scheme = Platform.OS === 'ios' ? 'maps:' : 'geo:';
  var url = scheme + `${coords[1]},${coords[0]}`;
  Linking.openURL(url);
}
