import React, {ReactNode, useContext} from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import {GestureHandlerRootView} from 'react-native-gesture-handler'
import Icon from './IconWrapper'
import { CameraFixed } from '../components/CameraFixed'
import { css } from '../styles'
import { iconsMapper, IDiagnosis } from '../types'
import { PreventNavOnDataChange } from './PreventNavOnDataChange'
import { Platform } from 'react-native'
import { AppContext } from '../contexts'
import { IconOutlined } from './IconOutlined'

type Props = {
  showFixedCamera?: boolean
  photoCallback?: Function
  children: ReactNode | ReactNode[]
  sideChildren?: ReactNode | ReactNode[]
  preventOnDataChange?: {
    navigation: any
    hasUnsavedChanges: boolean
    onSavePressed?: Function
  }
  circleButton?: {
    iconName: string
    color?: string
    style?: StyleProp<ViewStyle>
    onPress: Function
  }
  wrapperStyle?: StyleProp<ViewStyle>
}

export function ScreenWrapper ({children, showFixedCamera, photoCallback, preventOnDataChange, circleButton, wrapperStyle}: Props) {
  const appContext = useContext(AppContext)

  const toggleWebSkin = () => {
    const skin = appContext?.state.skin
    appContext?.dispatch({
      ...appContext?.state,
      skin: skin === 'light' ? 'dark' : 'light'
    })
  }
  const rootView = <>
      {preventOnDataChange &&
        <PreventNavOnDataChange navigation={preventOnDataChange?.navigation} hasUnsavedChanges={preventOnDataChange?.hasUnsavedChanges} onSavePressed={preventOnDataChange.onSavePressed} />
      }
      {children}
      {Platform.OS === 'web' &&
        <View style={styles.buttonChangeTheme}>
          <Text style={{color: css.colorText, marginRight: css.padding/2}}>{appContext?.state.skin === 'light' ? 'Dark theme' : 'Light theme'}</Text>
          <IconOutlined
            style={{borderRadius: 40, borderColor: 'transparent', ...css.iconOutlinedMin, padding: 6, backgroundColor: appContext?.state.skin === 'light' ? css.colorAppBg + '20' : '#ffffff20'}}
            iconName={appContext?.state.skin === 'light' ? iconsMapper.moon : iconsMapper.sun}
            onPress={toggleWebSkin}
            color={appContext?.state.skin === 'light' ? css.colorPrimary : 'white'}
          />
        </View>
      }
      <View style={styles.bottomButtonsFixed}>
        {circleButton &&
          <View style={[styles.circleButton, circleButton.color ? {backgroundColor: circleButton.color} : {}, circleButton.style]}
            onStartShouldSetResponder={() => true}
            onResponderRelease={() => circleButton.onPress()}
          >
            <Icon name={circleButton.iconName} style={styles.circleButtonIcon} size={20} color={css.colorTextLight} />
          </View>
        }
        {showFixedCamera && <CameraFixed photoCallback={(diagnoses: Partial<IDiagnosis>[]) => {
          photoCallback?.(diagnoses)
        }} />}
      </View>
  </>

  return Platform.OS === 'web' ? <View style={[styles.block, {flexDirection:'column'}, wrapperStyle]}>
    {rootView}
  </View> :
  (
    <GestureHandlerRootView style={[styles.block, {backgroundColor: css.colorAppBg}]}>
      {rootView}
    </GestureHandlerRootView>
  )
}
const circleButtonHeight = 54
const styles = StyleSheet.create({
  // @ts-ignore - should stay here, because position fixed is only supported in web
  buttonChangeTheme: {
    ...(Platform.OS === 'web' ? {position: 'fixed', } : {position: 'absolute'}),
    flexDirection: 'row',
    alignItems: 'center',
    top: css.padding,
    padding: 0,
    right: css.padding,
  },
  // @ts-ignore - should stay here, because position fixed is only supported in web
  bottomButtonsFixed: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    
    ...(Platform.OS === 'web' ? {position: 'fixed', bottom: 60} : {bottom: css.padding * 0.5,position: 'absolute'}),
    padding: css.padding * 1.5,
  },
  circleButton: {
		backgroundColor: css.colorAccent,
		borderRadius: circleButtonHeight/2,
		width: circleButtonHeight,
		height: circleButtonHeight,
		alignItems: 'center',
		justifyContent: 'center',
    marginRight: css.padding / 2
	},
  circleButtonIcon: {
		marginLeft: -1,
	},
  block: {
    position: 'relative',
    flex: 1,
    padding: 0,
    paddingHorizontal: css.padding,
  },
})
