import { createSelector } from '@reduxjs/toolkit'
import { IDiagnosis, IEvent } from '../../types'
import { findDiagnosisByMaxProbability } from '../../utils'
import { IDiagnosesState } from '../types'

const getState = (state: IDiagnosesState) => state
const getDiagnoses = (state: IDiagnosesState) => state.diagnoses
const getDiagnosisCode = (state: IDiagnosesState, props: { code?: string }) => props.code
const getEvent = (state: IDiagnosesState, props: { event?: IEvent }) => props.event
const getDiagnosisId = (state: IDiagnosesState, props: { diagnosisId?: string }) => props.diagnosisId
const getDiagnosesIds = (state: IDiagnosesState, props: { diagnosesIds?: string[] }) => props.diagnosesIds

// #region FILTERS - search funtionality, returns multiple values in an array, case-insensitive

export const filterDiagnosesByCode = createSelector(
  [getDiagnoses, getDiagnosisCode],
  (diagnoses, code) => {
    return diagnoses.filter(d => {
      const aiResult = findDiagnosisByMaxProbability(d.aiResults)
      return aiResult?.icdCode?.toLocaleLowerCase() === code?.toLocaleLowerCase()
    })
  }
)

export const filterDiagnosesByIds = createSelector(
  [getDiagnoses, getDiagnosesIds],
  (allDiagnoses, diagnosesIds) => {
    if (!diagnosesIds) return []
    let diagnoses: IDiagnosis[] = []
    allDiagnoses.forEach(diagnosis => {
      diagnosesIds.some(id => {
        if (diagnosis.id === id) diagnoses.push(diagnosis)
        return diagnosis.id === id
      })
    })
    return diagnoses
  }
)

// #endregion

//#region FINDERS - find functionality, returns one value, case-insensitive

export const findLastDiagnosisByEvent = createSelector(
  [getState, getDiagnoses, getEvent],
  (state, diagnoses, event) => {
    const diags = filterDiagnosesByIds(state, {diagnosesIds: event?.diagnosesIds})
    const lu = (diags ?? diagnoses).map(p => p && p.date ? (new Date(p.date)).getTime() : 0)
    if (lu.length > 0) {
      const last = Math.max.apply(Math, lu)
      return (diags ?? diagnoses)[lu.indexOf(last)]
    }
    return undefined
  }
)

export const findDiagnosisById = createSelector(
  [getDiagnoses, getDiagnosisId],
  (diagnoses, diagnosisId) => {
    return diagnoses.find(d => d.id.toLocaleLowerCase() === diagnosisId?.toLocaleLowerCase())
  }
)

//#endregion
