import React, { useState } from 'react'
import {Platform, StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import { css } from '../styles'
import {iconsMapper} from '../types'
import Icon from './IconWrapper'
import { TextItem } from './TextItem'

type Props = {
  onPress?: (isActive: boolean) => any
  align?: 'left' | 'right'
  label?: string
  checkColor?: string
  checkActiveColor?: string
  checkIconColor?: string
  labelColor?: string
  active?: boolean
  reverse?: boolean
  styleWrapper?: StyleProp<ViewStyle>
}

export function Checkbox ({onPress, align = 'left', label, checkActiveColor, checkColor, checkIconColor, active, reverse, styleWrapper, labelColor}: Props) {
  const [isActive, setIsActive] = useState(!!active)
  const styles = StyleSheet.create({
    checkWrapper: {
      justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
      flexDirection: reverse ? 'row-reverse' : 'row',
      alignItems: 'center',
      height: Platform.OS === 'android' ? css.android.minRequiredInputHeight : undefined
    },
    checkIconBg: {
      width: 26,
      height: 26,
      borderRadius: css.borderRadius,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: css.colorDarkBgLighten1,
    }
  })
  return <View style={[styles.checkWrapper, styleWrapper]}
    onStartShouldSetResponder={() => true}
    onResponderRelease={() => {
      onPress?.(!isActive)
      setIsActive(!isActive)
    }}
  >
    <View style={[styles.checkIconBg, reverse ? {marginLeft: css.padding/2} : {marginRight: css.padding/2}, isActive && checkActiveColor ? {backgroundColor: checkActiveColor} : {}]}>
      {isActive &&
        <Icon
          name={iconsMapper.check}
          color={checkIconColor ?? 'white'}
          size={18}
        />
      }
    </View>
    <TextItem style={{color: labelColor ?? css.colorText + (isActive ? 'ff' : '50' )}}>{label}</TextItem>
  </View>
}
