import { RouteProp, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { View, FlatList, StyleSheet, Platform } from 'react-native'
import { iconsMapper, IDiagnosis, Item } from '../types'
import { ScreenWrapper } from '../components/ScreenWrapper'
import { TextItem } from '../components/TextItem'
import { ViewRow } from '../components/ViewRow'
import { css } from '../styles'
import { DiagnosisCode, DiagnosisTitle, IEvent, UserType } from '../types'
import {
	BlockItem,
	CalendarPicker,
	SearchInput
} from '../components'
import { EventsListProps } from '../containers'
import { fullDateTime } from '../utils/helpers'
import { AppContext } from '../contexts'
import { createEventFormValues } from '../utils'
import { CREATE_EVENT_ROUTE_NAME, EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, StackParams } from '../types'
import { EventsListItem } from '../components/EventsListItem'
import { updateAuthSpecialist } from '../store'
import { useDispatch } from 'react-redux'
import { SelectPicker } from '../components/SelectPicker'

type NavigationProps = StackNavigationProp<StackParams, typeof EVENT_ROUTE_NAME>

type Props = {
	route: RouteProp<StackParams, typeof EVENTS_ROUTE_NAME>
}

// let initialFilterDate: Date | undefined = undefined

// Linking.getInitialURL().then(url => {
// 	if (url) {
// 		const u = new URL(url)
// 		const date = u.searchParams.get('date')
// 		if (date) {
// 			initialFilterDate = new Date(parseInt(date))
// 		}
// 	}
// })

export function EventsListScreen ({
	route,
	user,
	events,
	filterEventsBySearchProps,
	setEventAsLastViewed,
	deleteEvent,
	findLastDiagnosisByEvent,
	patientByUid,
}: Props & EventsListProps) {
	const dispatch = useDispatch()
	const routeFilterQuery = route.params?.query
	const routeFilterDate = parseInt(route.params?.date ?? 0)
	// ?? initialFilterDate
	const getRouteFilterDate = () => routeFilterDate && routeFilterDate > 0 ? new Date(routeFilterDate) : undefined
	const [searchQuery, setSearchQuery] = useState(
		routeFilterQuery && typeof routeFilterQuery === 'object' ? routeFilterQuery : undefined
	)
	const [filterDate, setFilterDate] = useState(getRouteFilterDate())
	useEffect(() => {
		setFilterDate(getRouteFilterDate())
	}, [routeFilterDate])
	const [filterDiagnosisType, setFilterDiagnosisType] = useState<
		DiagnosisCode | undefined
	>(undefined)
	const diagnosisOptions: Item[] = Platform.OS === 'web' ? [{label: 'Show all', value: 'all'}] : []
	// shows only attached events, if false shows unattached events (future functionality)
	const [showMyEvents, setShowMyEvents] = useState(true)
	for (const title in DiagnosisTitle) {
		diagnosisOptions.push({
			label: DiagnosisTitle[title as keyof typeof DiagnosisCode],
			value: title
		})
	}

	const [searchResults, setSearchResults] = useState<{key: string; value: IEvent;}[]>([])
	const [newEventsCount, setNewEventsCount] = useState(0)
	const [searchNewEventsResults, setSearchNewEventsResults] = useState<{key: string; value: IEvent;}[]>([])

	useEffect(() => {
		setSearchResults(filterEventsBySearchProps({
			query: searchQuery,
			date: filterDate,
			diagnosisCode: filterDiagnosisType,
			...(user?.type === UserType.specialist ? {specialistUid: user?.uid} :{})
		}))
		setNewEventsCount(filterEventsBySearchProps({}).length)
		setSearchNewEventsResults(filterEventsBySearchProps({
			query: searchQuery,
			date: filterDate,
			diagnosisCode: filterDiagnosisType,
		}))
	},[searchQuery, filterDate, filterDiagnosisType, user, showMyEvents, events])

	const navigation = useNavigation<NavigationProps>()
	const { navigate } = useNavigation<NavigationProps>()
	const listHeaderComponent = <View style={styles.headerBlock}>
		<ViewRow>
			<SearchInput
				search={setSearchQuery}
				searchQuery={routeFilterQuery}
				placeholder='Search by full name, ID, UID or event ID'
			/>
		</ViewRow>
		{/* NEXT: remove this on AI support */}
		{/* <View
			style={{
				marginTop: css.padding
			}}
		>
			<CalendarPicker
				date={filterDate}
				setDate={date => navigate(EVENTS_ROUTE_NAME, {date: date?.getTime()})}
			/>
		</View> */}
		{/* NEXT: add this picker on AI support! */}
		<ViewRow
			style={{
				marginTop: css.padding
			}}
		>
			<CalendarPicker
				date={filterDate}
				setDate={setFilterDate}
			/>
			<SelectPicker
				iconName={iconsMapper.doc}
				options={diagnosisOptions}
				setSelected={(selected: string) =>
					setFilterDiagnosisType(
						selected as DiagnosisCode
					)
				}
				wrapperStyle={{ marginLeft: css.padding }}
				placeholder={{
					label: 'Show all',
					color: css.colorTextLight + '50',
					key: 'all',
					value: undefined
				}}
			/>
		</ViewRow>
	</View>

	return (
		<AppContext.Consumer>
			{values =>
				<ScreenWrapper showFixedCamera={Platform.OS !== 'web'}
				photoCallback={(diagnoses: IDiagnosis[]) => {
					let state = {...values?.state ?? {}}
					let createEventForm = {...(state.createEventForm ?? {...createEventFormValues})}
					createEventForm = {...createEventForm, diagnoses: [...(createEventForm.diagnoses ?? []), ...diagnoses]}
					state = {...state, createEventForm}
					values?.dispatch({...state})
					navigate(CREATE_EVENT_ROUTE_NAME)
				}}
				>
					{/* NEXT: uncomment on next version with the backend and login */}
					{/* {user?.type === 'specialist' &&
						<ViewRow style={styles.tabs}>
							<ButtonRounded
								style={[
									styles.tab,
									showMyEvents ? styles.activeTab : undefined
								]}
								title='My events'
								onPress={() => setShowMyEvents(true)}
							/>
							<ButtonRounded
								style={[
									styles.tab,
									!showMyEvents ? styles.activeTab : undefined
								]}
								title='New events'
								onPress={() => setShowMyEvents(false)}
							/>
							{newEventsCount > 0 && <View style={styles.badge} />}
						</ViewRow>
					} */}
					<FlatList
						data={showMyEvents ? searchResults : searchNewEventsResults}
						ListFooterComponent={<View></View>}
						ListFooterComponentStyle={styles.flatListFooter}
						ListHeaderComponent={user?.type === UserType.specialist ? listHeaderComponent : undefined}
						ListHeaderComponentStyle={{zIndex: 1}}
						showsVerticalScrollIndicator={Platform.OS !== 'web'}
						ListEmptyComponent={<BlockItem style={{backgroundColor: css.colorDarkBgLighten1}}>
							<TextItem style={{textAlign: 'center'}}>
								{`No events found,${user?.type === UserType.specialist ? ' choose and attach events from "New events" tab above or' : ''} click on camera/upload icons below to create a picture for a new event`}
							</TextItem>
						</BlockItem>}
						renderItem={result => {
						return <EventsListItem
							event={result.item.value}
							disabled={!showMyEvents}
							showPatientDetails={searchQuery}
							removeEvent={() => {
								deleteEvent(result.item.value.id)
								navigate(EVENTS_ROUTE_NAME)
							}}
							navigateRoute={() => {
								const event = result.item.value
								dispatch(updateAuthSpecialist({
									lastViewedEventId: event.id
								}))
								if (Platform.OS === 'web') navigation.navigate(EVENTS_ROUTE_NAME)
								const nativeParams = Platform.OS !== 'web' ? {
									title:
									event.type === 'visit'
									? `Visit`
									: `Consultation`,
									subtitle: 'ID: ' + event.id.toUpperCase() + ', ' + fullDateTime(event.createdAt),
								} : {}
								navigation.navigate(EVENT_ROUTE_NAME, {
									eventId: event.id,
									...nativeParams,
								})
							}}
						/>
						}}
					/>
					{/* NEXT: bring back new unattached events when server is available */}
					{/* {!showMyEvents && (
						<FlatList
							data={filterEventsBySearchProps({
								query: searchQuery,
								date: filterDate,
								diagnosisCode: filterDiagnosisType,
							})}
							ListFooterComponent={<View></View>}
							ListFooterComponentStyle={styles.flatListFooter}
							ListHeaderComponent={user?.type === UserType.specialist ? listHeaderComponent : undefined}
							renderItem={result => (
								fix here to adapt to the new component (was EventListItem, now EventsListItem)
								<EventsListItem
									userType={user?.type}
									event={result.item.value}
									lastDiagnosis={findLastDiagnosisByEvent(result.item.value)}
									showPress={() => {
										const event = result.item.value
										setEventAsLastViewed(event.id)
										navigate(EVENT_ROUTE_NAME, {
											event: event,
											title:
												event.type === 'visit'
													? `Visit`
													: `Consultation`,
											subtitle: event.id.toUpperCase() + ' ' + fullDateTime(event.createdAt)
										})
									}}
									callback={(buttonPress: string, data: any) => {
										if (buttonPress === 'delete' && data) {
											deleteEvent(data as string)
										}
									}}
									disabled={true}
								/>
							)}
							ListEmptyComponent={<BlockItem style={{backgroundColor: css.colorDarkBgLighten1}}>
								<TextItem style={{textAlign: 'center'}}>
									Phewww, all job done! No new events found. You may work with existing events on "My events" tab.
								</TextItem>
							</BlockItem>}
						/>
								)} */}
				</ScreenWrapper>
			}
		</AppContext.Consumer>
	)
}

const badgeWh = 10
const styles = StyleSheet.create({
	tabs: {
		marginBottom: css.padding / 2,
		padding: css.padding / 2,
		backgroundColor: css.colorDarkBgLighten3,
		borderRadius: css.borderRadius
	},
	tab: {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		width: '47%'
	},
	activeTab: {
		backgroundColor: css.colorPrimary
	},
	flatListFooter: {
		paddingBottom: 110
	},
	badge: {
		backgroundColor: css.colorSuccess,
		width: badgeWh,
		height: badgeWh,
		borderRadius: badgeWh / 2,
		position: 'absolute',
		right: css.padding / 1.5,
		top: css.padding / 1.5
	},
	headerBlock: {
		backgroundColor: css.colorDarkBgLighten2,
		padding: css.padding,
		borderRadius: css.borderRadius,
		marginBottom: css.padding / 2,
	},
	noItems: {
		textAlign: 'center',
		padding: css.padding
	}
})
