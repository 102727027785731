import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { PatientsListScreen } from '../screens/PatientsListScreen'
import { filterPatientsByUid, filterPatientsBySearchQuery, findLastEventByPatient, RootState, updateAuthSpecialist, deletePatientByUid } from '../store'
import { IPatient, ISpecialist } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user as (ISpecialist | undefined),
    patients: state.patients,
    filterPatientsByUid: (userUid: string) => filterPatientsByUid(state.patients, {userUid}),
    filterPatientsBySearchQuery: (query?: string) => filterPatientsBySearchQuery(state.patients, {query}),
    findLastEventByPatient: (patient: IPatient) => findLastEventByPatient(state.events, {patient}),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setSpecActivePatientUid: (patientUid?: string) => {
      dispatch(updateAuthSpecialist({
        activePatientUid: patientUid
      }))
    },
    setPatientAsSpecLastViewed: (patientUid?: string) => {
      dispatch(updateAuthSpecialist({
        lastViewedPatientUid: patientUid
      }))
    },
    deletePatientByUid: (patientUid?: string) => {
      if (patientUid) {
        dispatch(deletePatientByUid(patientUid))
      }
    }
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type PatientsListProps = ConnectedProps<typeof connector>
export const PatientsList = connector(PatientsListScreen)
