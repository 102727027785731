import {
  CREATE_PATIENT,
  READ_PATIENTS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  PatientsActionTypes,
  IPatientsState,
} from '../types'

const initialState: IPatientsState = {
  patients: [],
}

const patients = (state = initialState, action: PatientsActionTypes) => {
  switch (action.type) {
    case CREATE_PATIENT:
      return {
        patients: [...state.patients, action.patient]
      }
    case READ_PATIENTS:
      return {
        patients: action.patients
      }
    case UPDATE_PATIENT: {
      const patients = [...state.patients]
      const pIndex = patients.findIndex(p => p.uid === action.patient.uid)
      if (pIndex > -1) {
        patients.splice(pIndex, 1, action.patient)
      } else throw('No user found to update')
      return { patients }
    }
    case DELETE_PATIENT: {
      const patients = [...state.patients]
      const pIndex = patients.findIndex(p => p.uid === action.patientUid)
      if (pIndex > -1) {
        patients.splice(pIndex, 1)
        return { patients }
      } // else throw('No user found to delete')
      return state
    }
    default:
      return state;
  }
};

export default patients;
