import React, { useEffect, useRef, useState } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import { ModalView } from './ModalView'
import { get } from 'idb-keyval'

type Props = {
  source: ImageSourcePropType | string // object for fake required images
  style?: ImageStyle
}

export const ImageWithPreview = ({source, style}: Props) => {
  const [modalData, setModalData] = useState<{show?: boolean, imageInfo?: any}>({})
  const [imageSource, setImageSource] = useState<ImageSourcePropType | undefined>()
  const imageContainerRef = useRef<View>(null)
  const [transform, setTransform] = useState("")
  const [scale, setScale] = useState(1)
  const [imageLeft, setImageLeft] = useState(0)
  const [imageTop, setImageTop] = useState(0)
  const [wheelProps, setWheelProps] = useState<WheelEvent>()
  const getImageInfo = () => {
    if (typeof source === 'string') {
      setModalData({show:true, imageInfo: { url: source }})
    } else {
      setModalData({show:true, imageInfo: { url: '', props: {source} }})
    }
  }
  const handleScroll = (wheel: WheelEvent) => {
    setWheelProps(wheel)
  }

  useEffect(() => {
    let isMounted = true
    if (typeof source === 'string') {
      if (isMounted) {
        get(source).then((res: Blob) => {
          if (res) setImageSource({uri: URL.createObjectURL(res as Blob)})
          else setImageSource({uri: source})
        })
      }
    } else if (source) setImageSource(source)
    return () => {
      isMounted = false
    }
  }, [source])

  useEffect(() => {
    //@ts-ignore
    imageContainerRef?.current?.addEventListener("wheel", handleScroll, true);
    //@ts-ignore
    return () => imageContainerRef?.current?.removeEventListener("wheel", handleScroll, true);
  }, [imageContainerRef?.current, modalData]);
  
  const zoomImage = (s: number) => {
    let left = imageLeft
    let top = imageTop
    let delta = -(wheelProps?.deltaY ?? 0);
    (delta >= 0) ? s*=1.2 : s/= 1.2
    if (s < 0.5) s = 0.5
    // if (s < 1) s = 1
    if (s > 5) s = 5

    // NEXT: if needed explore this functionality to make zoom on mouse point
    // https://jsfiddle.net/fgLmyxw4/
    // if (wheelProps?.x && wheelProps.y) {
    //   left = (wheelProps.clientX - wheelProps.offsetX) - left * (s - 1)
    //   top = (wheelProps.clientY - wheelProps.offsetY) - top * (s - 1)
    // }
    
    setTransform(`scale(${s})`)
    setScale(s)
    setImageLeft(imageLeft - left)
    setImageTop(imageTop - top)
  }
  
  useEffect(() => {
    // on focus when show/hide is happening reset image zoom
    if (modalData.show) {
      zoomImage(1.2)
    }
  }, [modalData])
  useEffect(() => {
    zoomImage(scale)
  }, [wheelProps])
  return (<>
    {imageSource && modalData.show &&
      <ModalView style={{height: '100%'}} title="Image" subtitle="subtitle" onClose={() => setModalData({show: false})} show={modalData.show && !!modalData.imageInfo}>
        {/* TODO: we need image view for web here */}
        <View
          ref={imageContainerRef}
          style={{flexGrow: 1, width: '100%', overflow: 'hidden'}}
          onStartShouldSetResponder={() => true}
          onResponderRelease={getImageInfo}
        >
          {/* @ts-ignore */}
          <Image style={[{height: '100%', minWidth: '100%'}, style, {transform, left: imageLeft, top: imageTop}]} source={imageSource} />
        </View>
      </ModalView>
    }
    <View
      style={{height: '100%', width: '100%'}}
      onStartShouldSetResponder={() => true}
      onResponderRelease={getImageInfo}
    >
      <Image style={[{height: '100%', width: '100%'}, style]} source={imageSource!} resizeMode='cover' />
    </View>
  </>)
}

export default ImageWithPreview
