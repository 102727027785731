import { IUser } from "../../types"

export const CREATE_USER = 'CREATE_USER'
export const READ_USER = 'READ_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

// export const CREATE_NEW_DIAGNOSES = 'CREATE_NEW_DIAGNOSES'
// export const DELETE_NEW_DIAGNOSES = 'DELETE_NEW_DIAGNOSES'

// export const CREATE_NEW_EVENT_STATE = 'CREATE_NEW_EVENT_STATE'
// export const DELETE_NEW_EVENT_STATE = 'DELETE_NEW_EVENT_STATE'

// export const CREATE_UPDATE_DEVICES = 'CREATE_UPDATE_DEVICES'
// export const DELETE_DEVICE = 'DELETE_DEVICE'

// interface is not ITempEvent because it holds not only the event itself but the diagnoses too
// export interface ITempEventState {
//   event?: Partial<IEvent>
//   diagnoses?: Partial<IDiagnosis>[]
//   deleteState?: true
// }
export interface IAuthState {
  loggedIn: boolean,
  user?: IUser,
  // newDiagnoses?: IDiagnosis[]
  // newEventState?: ITempEventState
  // devices: IDevice[]
}

// NEXT: auth data related to the remote server (tokens, etc.)
export type IAuthData = any

// AUTH USER ACTIONS
export interface CreateAuthAction {
  type: typeof CREATE_USER, user: IUser
}
export interface SetAuthAction {
  type: typeof READ_USER, user: IUser
}
export interface UpdateAuthAction {
  type: typeof UPDATE_USER, user: IUser
}
export interface DeleteAuthAction {
  type: typeof DELETE_USER, userId: {uid: string}
}
export interface LogoutAuthAction {
  type: typeof LOGOUT_USER
}

export type AuthActionTypes = CreateAuthAction
  | SetAuthAction
  | UpdateAuthAction
  | DeleteAuthAction
  | LogoutAuthAction
