import React from 'react'
import {Linking, StyleSheet} from 'react-native'
import { css } from '../styles'
import {iconsMapper} from '../types'
import { IconOutlined } from './IconOutlined'

type Props = {
  phone?: number
  small?: boolean
  reverseMargin?: boolean
}

export function ButtonCall ({phone, small, reverseMargin}: Props) {
  const margin = small ? css.padding / 2 : css.padding
  return <>
    {!!phone &&
    <IconOutlined
      onPress={() => Linking.openURL(`tel:+${phone}`)}
      iconName={iconsMapper.call}
      color={css.colorSuccess}
      style={[{marginLeft: margin}, small ? styles.smallIcon : {}, reverseMargin ? {marginLeft: 0, marginRight: margin} : {}]}
    />}
  </>
}

const styles = StyleSheet.create({
  smallIcon: {
    marginRight: 0,
    alignSelf: 'flex-end',
    fontSize: 18,
    paddingTop: 9,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 8,
  }
})