import React from 'react'
import {View, ViewStyle} from 'react-native'
import {css} from '../styles'

type Props = {
  style?: ViewStyle
}

export function Hr ({style}: Props) {
  return (
    <View
      style={{
        borderBottomColor: css.colorPrimary + '20', // opacity of 20%
        borderBottomWidth: 1,
        marginLeft: -css.padding * 1.5,
        marginRight: -css.padding * 1.5,
        marginTop: css.padding * 1.5,
        marginBottom: css.padding * 1.5,
        ...(style ?? {}),
      }}
    />
  )
}
