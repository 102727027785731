import { persistedReducer as rootReducer } from './reducers/index'
import ReduxThunk from 'redux-thunk'
import { persistStore } from "redux-persist"
import { configureStore } from '@reduxjs/toolkit'

export * from './actions'
export * from './selectors'
export type { RootState } from './reducers/index'

export const store = configureStore({
  reducer: rootReducer,
  middleware: [ReduxThunk],
  // (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }
  // ),
})

export const persistor = persistStore(store)
