import React, {ReactNode} from 'react'
import {Modal, StyleSheet, View, ViewStyle} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import {css} from '../styles'
import {iconsMapper} from '../types'
import {IconOutlined} from './IconOutlined'
import {TextItem} from './TextItem'
import {ViewRow} from './ViewRow'

interface Props {
  title?: string
  subtitle?: string
  show: boolean
  iconName?: string
  onClose?: () => void
  children?: ReactNode | ReactNode[]
  style?: ViewStyle
}

export function ModalView ({
  show,
  onClose,
  children,
  iconName,
  title,
  subtitle,
  style,
}: Props) {
  return (
    <View style={{backgroundColor: css.colorDarkBg}}>
      <Modal animationType='slide' transparent={true} visible={show} onRequestClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <View style={styles.modalOuter}>
            <View style={[styles.modalView, style]}>
              <ViewRow style={styles.modalHeader}>
                {iconName && <IconOutlined iconName={iconName} />}
                <View style={styles.headerText}>
                  <TextItem style={styles.title}>{title}</TextItem>
                  <TextItem style={styles.subtitle}>{subtitle}</TextItem>
                </View>
                <IconOutlined
                  iconName={iconsMapper.close}
                  style={{borderStyle: 'dashed', opacity: 0.7, marginRight: 0}}
                  onPress={() => onClose?.() ?? undefined}
                />
              </ViewRow>
              {children}
            </View>
          </View>
        </SafeAreaView>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  modalOuter: {
    backgroundColor: css.colorDarkBg,
    alignItems: 'center',
    height: '100%',
    padding: css.padding,
  },
  modalView: {
    backgroundColor: css.colorDarkBgLighten3,
    borderRadius: css.borderRadius,
    padding: css.padding,
    width: '100%',
  },
  title: {
    fontFamily: css.fontFamily600,
    fontSize: 18,
    lineHeight: 18,
  },
  headerText: {
    flexGrow: 1,
    marginLeft: css.padding / 2,
  },
  subtitle: {
    color: css.colorText,
    lineHeight: 14,
  },
  modalHeader: {
    marginBottom: css.padding,
    alignItems: 'center',
  },
})
