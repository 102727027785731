import { createSelector } from '@reduxjs/toolkit'
import { IPatient, IUser } from '../../types'
import { IPatientsState } from '../types'

const getPatients = (state: IPatientsState) => state.patients
const getUserUid = (state: IPatientsState, props: { userUid?: string }) => props.userUid
const getUserId = (state: IPatientsState, props: { userId?: string }) => props.userId
const getEventId = (state: IPatientsState, props: { eventId?: string }) => props.eventId
const getName = (state: IPatientsState, props: { name?: string }) => props.name
const getQuery = (state: IPatientsState, props: { query?: string }) => props.query

//#region FINDERS - find functionality, returns one value, case-insensitive

export const findPatientByUid = createSelector(
  [getPatients, getUserUid],
  (patients, uid) => {
    if (!uid) return undefined
    return patients?.find(p => p.uid.toLocaleLowerCase() === uid.toLocaleLowerCase())
  }
)

export const findPatientById = createSelector(
  [getPatients, getUserId],
  (patients, id) => {
    if (!id) return undefined
    return patients?.find(p => p.id.toLocaleLowerCase() === id.toLocaleLowerCase())
  }
)

export const findPatientByEventId = createSelector(
  [getPatients, getEventId],
  (patients, eventId) => {
    if (!eventId) return undefined
    return patients?.find(p => p.eventIds.map(id => id.toLocaleLowerCase()).includes(eventId.toLocaleLowerCase()))
  }
)

export const findSpecLastUpdatedPatient = createSelector(
  [getPatients],
  (patients) => {
    if (patients) {
      const lu = patients?.map(p => p && p.updatedAt ? (new Date(p.updatedAt)).getTime() : 0)
      if (lu.length > 0) {
        const last = Math.max.apply(Math, lu)
        return patients[lu.indexOf(last)]
      }
    }
    return undefined
  }
)

//#endregion

// #region FILTERS - search funtionality, returns multiple values in an array, case-insensitive

export const filterPatientsByUid = createSelector(
  [getPatients, getUserUid],
  (patients, uid) => {
    if (!uid) return []
    return patients?.filter(p => p.uid.toLocaleLowerCase().includes(uid.toLocaleLowerCase())) ?? []
  }
)

export const filterPatientsById = createSelector(
  [getPatients, getUserId],
  (patients, id) => {
    if (!id) return []
    return patients?.filter(p => p.id.toLocaleLowerCase().includes(id.toLocaleLowerCase())) ?? []
  }
)

export const filterPatientsByEventId = createSelector(
  [getPatients, getEventId],
  (patients, id) => {
    if (!id) return []
    return patients?.filter(p => p.eventIds.map(id => id.toLocaleLowerCase()).includes(id.toLocaleLowerCase())) ?? []
  }
)

/**
 * filters either first name or second name, returns which one finds
 */
export const filterPatientsByName = createSelector(
  [getPatients, getName],
  (patients, name) => {
    if (!name) return []
    return patients?.filter(p => p.fullName.firstName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) || p.fullName.lastName.toLocaleLowerCase().includes(name.toLocaleLowerCase())) ?? []
  }
)

/**
 * filters by user uid, user id, name or event id (OR condition), returns object for flat list
 */
export const filterPatientsBySearchQuery = createSelector(
  [getPatients, getQuery],
  (patients, query) => {
    let results: IPatient[] = []
    if (!query) results = patients
    else results = patients.filter(p => {
      const f = query.split(' ')
      // if any of words appears in patients search fields...
      let includes = f.some(word => p.id.toLocaleLowerCase().includes(word.toLocaleLowerCase()) ||
      p.uid.toLocaleLowerCase().includes(word.toLocaleLowerCase()) ||
      p.fullName?.firstName?.toLocaleLowerCase().includes(word.toLocaleLowerCase()) ||
      p.fullName?.lastName?.toLocaleLowerCase().includes(word.toLocaleLowerCase()))
      //... then include this patient in search results
      return includes
    })
    return results.map(p => {
      return {key: p.id, value: p}
    })
  }
)

// #endregion
