import React from 'react'
import {StyleSheet, View, ImageURISource, Platform} from 'react-native'
import { CameraFixed } from '../components/CameraFixed'
import {css} from '../styles'
import { iconsMapper, IDiagnosis } from '../types'
import { ButtonRounded } from './ButtonRounded'
import { IconOutlined } from './IconOutlined'
import { TextCodeItem } from './TextCodeItem'
import { TextItem } from './TextItem'
import { ViewRow } from './ViewRow'
import { getImageUriForDiagnosis } from '../utils'
import Icon from './IconWrapper'
import ImageWithPreview from './ImageWithPreview'
import { useNavigation } from '@react-navigation/native'
import { AppContext } from '../contexts'
import { CREATE_EVENT_ROUTE_NAME } from '../types'

export function HomeActiveEvent () {
  const navigation = useNavigation()
  return (
    <>
      <AppContext.Consumer>
        {values => {
          const patientId = values?.state.createEventForm?.patient?.id
          const getBigImgSource = (diagnoses: Partial<IDiagnosis>[]) => {
            const diagnosesLength = diagnoses?.length ?? 0
            if (diagnosesLength > 0) {
              return getImageUriForDiagnosis(diagnoses![0])
            }
          }
          const smallImages = (diagnoses: Partial<IDiagnosis>[]) => {
            const diagnosesLength = diagnoses?.length ?? 0
            let images: JSX.Element[] = []
            for (let i = 0; i < 4; i++) {
              let imgSrc: ImageURISource | undefined = undefined
              if (i < diagnosesLength - 1) imgSrc = getImageUriForDiagnosis(diagnoses?.[i+1])
              const marginBottom = StyleSheet.create({ mb: { marginBottom: css.padding / 2 }})
              images.push(<View key={`active_event_image_${i}`} style={[styles.thumbnail, styles.thumbnailSmall, i < 2 ? marginBottom.mb : undefined]}>
                {imgSrc && imgSrc.uri &&
                  <ImageWithPreview source={imgSrc.uri} style={styles.maxWidthHeight} />
                }
                {!imgSrc && <Icon name={iconsMapper.camera} style={[styles.noImage, styles.noImageSmall]} />}
              </View>)
            }
            return images
          }
          const bigImgSource = getBigImgSource(values?.state.createEventForm?.diagnoses ?? [])
          return (
            <View
              style={styles.activeEvent}
            >
              <ViewRow style={{alignItems: 'center'}}>
                <IconOutlined iconName={iconsMapper.trash} color={css.colorError} style={{borderStyle: 'dashed'}} onPress={() => {
                  values?.dispatch({...values.state, createEventForm: undefined })
                }} />
                <View style={styles.headerText}>
                  <TextItem style={styles.headerTextBig}>New event</TextItem>
                  {values?.state.createEventForm?.event?.patientUid &&
                    <TextItem style={styles.headerTextSmall}>Patient ID: {values.state.createEventForm?.event?.patientUid.toUpperCase()}</TextItem>
                  }
                </View>
                <CameraFixed style={{position:'relative', right: -css.padding/2, bottom: 0, backgroundColor: 'transparent'}} photoCallback={(d: Partial<IDiagnosis>[]) => {
                  let diagnoses = values?.state.createEventForm?.diagnoses ?? []
                  diagnoses = [...d, ...diagnoses]
                  let createEventForm = values?.state.createEventForm ?? {}
                  createEventForm = {...createEventForm, diagnoses}
                  values?.dispatch({...values.state, createEventForm})
                }} />
              </ViewRow>
              <View style={styles.content}>
                <ViewRow style={{padding: css.padding}}>
                  <View style={[styles.thumbnail, styles.thumbnailBig]}>
                    {bigImgSource &&
                      <ImageWithPreview source={bigImgSource.uri ? bigImgSource.uri : bigImgSource} style={styles.maxWidthHeight} />
                    }
                    {!bigImgSource && <Icon name={iconsMapper.camera} style={styles.noImage} />}
                  </View>
                  <View style={{flexGrow: 1, alignItems: 'flex-start', flexWrap: 'wrap', flexDirection: 'row', width: 110}}>
                    {smallImages(values?.state.createEventForm?.diagnoses ?? [])}
                  </View>
                  <View style={{flexShrink: 1}}>
                    <TextCodeItem>{ values?.state.createEventForm?.event?.id ?? '---' }</TextCodeItem>
                    {/* {
                      patientUid &&
                      <ButtonRounded
                        title="Patient"
                        width={120}
                        style={{marginTop: css.padding}}
                        onPress={() => navigation.navigate('Patients', {screen: PATIENT_ROUTE_NAME, params: {patientUid}})} />
                    } */}
                    <ButtonRounded title="Open" style={{marginTop: css.padding, maxHeight: Platform.OS === 'android' ? css.android.minRequiredInputHeight : css.ios.minRequiredInputHeight, width: '100%', minWidth: 80}} onPress={() => navigation.navigate(CREATE_EVENT_ROUTE_NAME)} />
                  </View>
                </ViewRow>
              </View>
            </View>
          )
        }}
      </AppContext.Consumer>
    </>
  )
}


const styles = StyleSheet.create({
  activeEvent: {
    borderRadius: css.borderRadius,
    backgroundColor: css.colorDarkBgLighten2,
    padding: css.padding * 1.5,
  },
  headerText: {
    flexGrow: 1,
    paddingLeft: css.padding / 2,
  },
  headerTextBig: {
    fontFamily: css.fontFamily600,
    fontSize: 18,
    lineHeight: 18,
  },
  headerTextSmall: {
    fontSize: 14,
    lineHeight: 14,
    color: css.colorText
  },
  content: {
    borderRadius: css.borderRadius,
    backgroundColor: css.colorDarkBgLighten3,
    margin: -css.padding * 1.5,
    marginTop: css.padding,
  },
  thumbnail: {
    backgroundColor: css.colorDarkBgLighten2,
    borderRadius: css.borderRadius / 2,
    marginRight: css.padding/2,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailBig: {
    height: css.padding * 6.5,
    width: css.padding * 6.5,
  },
  thumbnailSmall: {
    height: css.padding * 3,
    width: css.padding * 3,
  },
  noImage: {
    fontSize: 32,
    color: css.colorPrimary + '50',
  },
  noImageSmall: {
    fontSize: 18,
  },
  maxWidthHeight: {
    width : '100%',
    height: '100%',
  }
})