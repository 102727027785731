import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { PatientScreen } from '../screens/PatientScreen'
import { RootState, createPatient, updatePatient, filterDiagnosesByIds, filterEventsByPatientUid, findPatientByUid } from '../store'
import { IPatient } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    findPatient: (userUid?: string) => findPatientByUid(state.patients, {userUid}),
    patientEvents: (patientUid?: string) => filterEventsByPatientUid(state.events, {patientUid}),
    filterDiagnosesByIds: (diagnosesIds?: string[]) => filterDiagnosesByIds(state.diagnoses, {diagnosesIds}),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createPatient: (patient: Partial<IPatient>) => {
      dispatch(createPatient(patient))
    },
    updatePatient: (patient: Partial<IPatient>) => {
      dispatch(updatePatient(patient))
    },
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type PatientScreenProps = ConnectedProps<typeof connector>
export const Patient = connector(PatientScreen)
