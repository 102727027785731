import React, { useState } from 'react'
import { Platform, View } from 'react-native'
import { BodyLocalization, Gender, IDiagnosis, IPatient } from '../types'
import { DiagnosesListClosedItem } from './DiagnosesListClosedItem'
import { DiagnosesListOpenItem } from './DiagnosesListOpenItem'

type Props = {
  index: number
  hasAI?: boolean
  disabled?: boolean
  diagnosis: Partial<IDiagnosis>
  // when patient is not attached to the event, then event gender property is used
  eventGender?: Gender
  patient?: Partial<IPatient>
  getAIData?: Function
  removeDiagnosis?: Function
  changeLocalization?: (localization: BodyLocalization, gender?: Gender) => void
  setCustomDiagnosis?: (text?: string) => void
  // for web we will show only opened diagnoses cards
  showOpenAlways?: boolean
}
/**
 * Toggable Diagnoses List Item (it has two components inside DiagnosesListOpenItem & DiagnosesListClosedItem)
 */
export function DiagnosesListItem ({disabled, hasAI, getAIData, eventGender, patient, diagnosis, index, removeDiagnosis, changeLocalization, showOpenAlways, setCustomDiagnosis}: Props) {
  const [toggleListItem, setToggleListItem] = useState(!!showOpenAlways)
  // HELP ADVICE: this is a refactor from one huge component which was too big and to hard to understand
  // if there are some functionality lost please check EventListItem component (not EventsListItem)
  // which was used last time in v0.0.2-last version on mobile-app branch.
  // Again look into component without "s" Event(no s)ListItem!
  return (<View style={Platform.OS === 'web' ? {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'calc(50% - 10px)',
      marginBottom: 0,
    } : undefined}>
      {!showOpenAlways &&
        <DiagnosesListClosedItem
          display={toggleListItem ? 'none' : 'flex'}
          diagnosis={diagnosis}
          eventGender={eventGender}
          patient={patient}
          disabled={disabled ? true : undefined}
          index={index}
          removeDiagnosis={removeDiagnosis}
          toggleListItem={() => {
            setToggleListItem(true)
          }}
          changeLocalization={changeLocalization}
        />
      }
      {/* Open item (on web we always show enlarged state) */}
      <DiagnosesListOpenItem
        display={toggleListItem ? 'flex' : 'none'}
        hideToggleIcon={showOpenAlways}
        diagnosis={diagnosis}
        eventGender={eventGender}
        patient={patient}
        disabled={disabled ? true : undefined}
        index={index}
        removeDiagnosis={removeDiagnosis}
        toggleListItem={() => setToggleListItem(false)}
        changeLocalization={changeLocalization}
        setCustomDiagnosis={setCustomDiagnosis}
        getAIData={hasAI ? getAIData : undefined}
      />
  </View>
  )
}
