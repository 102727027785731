import { Dispatch } from 'react';
import { RootState } from '..';
import { mockPatientUid } from '../../mocks/patients';
import { IPatient, UserType } from '../../types'
import { CREATE_PATIENT, READ_PATIENTS, UPDATE_PATIENT, DELETE_PATIENT, IAuthData } from '../types';

// CRUD Actions

export const fetchPatients = (authData: IAuthData) => {
  return async (dispatch: Dispatch<{type: string, patients: IPatient[]}>) => {
    try {
      if (authData === 'specialist') {
        try {
          const patients = require('../../mocks/data/patients.json')
          dispatch({type: READ_PATIENTS, patients: patients as IPatient[] ?? []})
        } catch(e) {
          dispatch({type: READ_PATIENTS, patients: []})
          console.log('An error occurred, it is possible, that no data exists.')
          console.log('Please run yarn mock:data to mock patients first')
          console.error(e)
        }
      }
      // if user is patient it gets an empty array of patients [],
      // because it does not have any other patients to work with
      else if (authData === 'patient') dispatch({type: READ_PATIENTS, patients: []})
      // Otherwise read data from remote DB
      else dispatch({type: READ_PATIENTS, patients: []})  // NEXT: get json from remote DB when not using mocked data
  } catch (err) {
      throw err
    }
  }
}

export const createPatient = (patient: Partial<IPatient>) => {
  return async (dispatch: Dispatch<{type: string, patient: IPatient}>, getState: () => RootState) => {
    try {
      const specialistUid = getState().auth.user?.uid
      let uid: string | undefined
      if (getState().auth.user?.type === 'specialist') uid = patient.uid ?? mockPatientUid()
      if (specialistUid) {
        const modifiedPatient: IPatient = Object.assign(patient, {
          specialistUid,
          uid,
          eventIds: [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          fullName: {
            firstName: patient?.fullName?.firstName ?? '',
            lastName: patient?.fullName?.lastName ?? ''
          },
          type: UserType.patient,
        }) as IPatient
        dispatch({type: CREATE_PATIENT, patient: modifiedPatient})// mockPatient(uid)})
      }
      else console.log('No auth user/specialist found to attach with the created patient, log in or check the auth state')
    } catch (e) {
      console.log('An error occurred by creating the patient')
      console.error(e)
    }
  }
}

export const updatePatient = (patient: Partial<IPatient>) => {
  return async (dispatch: Dispatch<{type: string, patient: Partial<IPatient>}>) => {
    try {
      if (patient.uid) {
        if (patient.fullName?.firstName === '' && patient.fullName.lastName === '') delete patient.fullName
        dispatch({type: UPDATE_PATIENT, patient})
      }
      else console.log('Patient has no uid, update failed')
    } catch(e) {
      console.log('An error occurred by updating the patient')
      console.error(e)
    }
  }
}

// export const updatePatientEvents = (patientUid: string) => {
//   return async (dispatch: Dispatch<{type: string, patientUid: string}>) => {
//     try {

//       if (patient.uid) {
//         if (patient.fullName?.firstName === '' && patient.fullName.lastName === '') delete patient.fullName
//         dispatch({type: UPDATE_PATIENT, patient})
//       }
//       else console.log('Patient has no uid, update failed')
//     } catch(e) {
//       console.log('An error occurred by updating the patient')
//       console.error(e)
//     }
//   }
// }

export const deletePatientByUid = (patientUid: string) => {
  return async (dispatch: Dispatch<{type: string, patientUid: string}>) => {
    try {
      dispatch({type: DELETE_PATIENT, patientUid})
    } catch(e) {
      console.log('An error occurred by setting active patient to the specialist')
      console.error(e)
    }
  }
}
