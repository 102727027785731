import { Dispatch } from 'react';
import { IClinic } from '../../types'
import { IAuthData, READ_CLINICS } from '../types';

// CRUD Actions

export const fetchClinics = (authData: IAuthData) => {
  return async (dispatch: Dispatch<{type: string, clinics: IClinic[]}>) => {
    try {
      if (authData === 'specialist' || authData === 'patient') {
        try {
          const clinics = require('../../mocks/data/clinics.json')
          dispatch({type: READ_CLINICS, clinics: clinics as IClinic[] ?? []})
        } catch(e) {
          dispatch({type: READ_CLINICS, clinics: []})
          console.log('An error occurred, it is possible, that no data exists.')
          console.log('Please run yarn mock:data to mock clinics first')
          console.error(e)
        }
      }
      // Otherwise read data from remote DB
      else dispatch({type: READ_CLINICS, clinics: []}) // NEXT: get json from remote DB when not using mocked data
  } catch (err) {
      throw err
    }
  }
}
