import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { SettingsScreen } from '../screens/SettingsScreen'
import { logOutUser, RootState } from '../store'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    logOutUser: () => {
      dispatch(logOutUser())
    },
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

export type SettingsProps = ConnectedProps<typeof connector>
export const Settings = connector(SettingsScreen)
