import { createSelector } from '@reduxjs/toolkit'
import { IClinicsState } from '../types'

const getClinics = (state: IClinicsState) => state.clinics
const getClinicId = (state: IClinicsState, props: { clinicId?: string }) => props.clinicId

// #region FILTERS - search funtionality, returns multiple values in an array, case-insensitive
// #endregion

//#region FINDERS - find functionality, returns one value, case-insensitive

export const findClinicById = createSelector(
  [getClinics, getClinicId],
  (clinics, clinicId) => {
    return clinics.find(c => c.id.toLocaleLowerCase() === clinicId?.toLocaleLowerCase())
  }
)

//#endregion
