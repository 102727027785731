import { Dispatch } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { HomeScreen } from '../screens/HomeScreen'
import { findSpecLastUpdatedPatient, findPatientByUid, RootState, findEventById, filterRecentEvents, filterActivePatientEvents, filterDiagnosesByIds, findClinicById, deleteEvent, findSpecialistByUid } from '../store'
import { UserType } from '../types'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    patients: state.patients,
    userByUid: (userUid?: string) => findPatientByUid(state.patients, {userUid}) ?? findSpecialistByUid(state.specialists, {userUid}),
    lastPatientUpdated: findSpecLastUpdatedPatient(state.patients),
    findEventById: (eventId: string) => findEventById(state.events, {eventId}),
    recentEvents: (recentDaysCount: number) => filterRecentEvents(state.events, {recentDaysCount}),
    activePatientEvents: state.auth.user?.type === UserType.patient ? filterActivePatientEvents(state.events) : [],
    diagnosesByIds: (diagnosesIds?: string[]) => filterDiagnosesByIds(state.diagnoses, {diagnosesIds}),
    clinicById: (clinicId?: string) => findClinicById(state.clinics, {clinicId})
  }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    deleteEvent: (eventId: string) => dispatch(deleteEvent(eventId)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export type HomeProps = ConnectedProps<typeof connector>
export const Home = connector(HomeScreen)
