import {
  CREATE_USER,
  READ_USER,
  UPDATE_USER,
  DELETE_USER,
  AuthActionTypes,
  IAuthState,
} from '../types'

const initialState: IAuthState = {
  loggedIn: false,
}

const auth = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case READ_USER:
      return {
        ...state,
        loggedIn: true, // NEXT: create login in the future
        user: action.user,
      }
    case UPDATE_USER:
      return {
        ...state,
        user: action.user
      }
    default:
      return state;
  }
};

export default auth;
