import React, {useEffect, useState} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import { ViewRow } from '.'
import {css} from '../styles'
import { DiagnosisTitle, IDiagnosisAiResult } from '../types'
import { findDiagnosisByMaxProbability } from '../utils'
import {TextItem} from './TextItem'
import {Toast} from './Toast'

type Props = {
  style?: StyleProp<ViewStyle>
  // we leave all probabilities of one diagnosis, perhaps later we will show other probabilities in popover
  aiResult: IDiagnosisAiResult
  showColorsByIcd?: boolean
  onPress?: Function
  showToast?: boolean
}

export function DiagnosisBadge ({style, aiResult, showColorsByIcd = false, onPress, showToast = true}: Props) {
  const colorByProbability = {
    '0': css.colorPrimary,
    '10': css.colorSuccess,
    '30': css.colorWarning,
    '50': css.colorError
  }
  const colorByIcd = {
    'c43': css.colorError, // Melanoma, MEL
    'd22': css.colorPrimary, // Melanocytic nevus, NV - is it just mole?
    'c4491': css.colorError, // Carcinoma, BCC - Basal cell
    'c4492': css.colorError, // Carcinoma, SCC - Squamous cell
    'l57': css.colorError, // Actinic keratosis, AK
    'l82': css.colorError, // Seborrheic - benign keratosis, BKL
    'd23': css.colorWarning,  // Dermatofibroma, DF
    'h02': css.colorWarning, // Vascular lesion, VASC
    'none': css.colorPrimary,
  }
  const [color, setColor] = useState(css.colorPrimary)

  useEffect(() => {
    let colorVariant: (keyof typeof colorByProbability) = '0'
    if (aiResult?.icdCode === 'd22') colorVariant = '0'
    else {
      for (const cv in colorByProbability) {
        if (aiResult && aiResult.probability && (aiResult.probability*100 >= parseInt(cv))) colorVariant = cv as keyof typeof colorByProbability
        else break
      }
    }
    setColor(showColorsByIcd && aiResult.icdCode ? colorByIcd[aiResult.icdCode] : colorByProbability[colorVariant])
  }, [aiResult])

  return (<>
    {aiResult ? <View
      style={[styles.badge, {backgroundColor: color}, style]}
      onStartShouldSetResponder={() => !!aiResult?.icdCode}
      onResponderRelease={() => {
        onPress?.()
        if (showToast && aiResult.icdCode) {
          Toast.showWithGravity(DiagnosisTitle[aiResult.icdCode] + ', ' + aiResult.probability * 100 + '%, ' + aiResult.className, Toast.SHORT, Toast.TOP)
        }
      }}
    >
      <TextItem
        style={styles.badgeDiagnosis}>
        {aiResult?.icdCode ? aiResult?.icdCode.toUpperCase() : '' }
      </TextItem>
      <ViewRow style={styles.badgeInfo}>
        <TextItem style={styles.badgeProbability}>
          {Math.round((aiResult?.probability || 0)*100)}
        </TextItem>
        <TextItem
          style={styles.badgePercentage}>
          %
        </TextItem>
      </ViewRow>
    </View> : <></>
    }
  </>)
}

const styles = StyleSheet.create({
  badge: {
    flexGrow: 1,
    maxWidth: 85,
    minWidth: 85,
    maxHeight: 40,
    minHeight: 36,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
    borderWidth: 0,
    borderRadius: css.borderRadius / 2,
  },
  badgeDiagnosis: {
    flex: 1,
    textAlign: 'center'
  },
  badgeInfo: {
    width: 35,
    right: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundColor: css.colorComponentActiveBg,
    padding: css.padding / 3,
    borderRadius: css.borderRadius/4,
  },
  badgePercentage: {
    color: 'white',
    fontSize: 10,
  },
  badgeProbability: {
    fontSize: 14,
    fontFamily: css.fontFamily600,
    color: 'white',
    paddingRight: 0,
  },
  diagnosis: {
    borderWidth: 0,
    borderRightWidth: 1,
  },
})
