import faker from 'faker'
import { IDiagnosis, IEvent, IPatient, UserType } from "../types";
import { patientDetails } from './patientDetails';

let allEvents: IEvent[] = []
let allDiagnoses: IDiagnosis[] = []

export const mockPatientUid = () => faker.datatype.uuid().substr(0, 5)

export const mockPatient = (specialistUid: string): IPatient => {
  const gender = Math.round(Math.random())
  const patientUid = mockPatientUid()
  const {events, diagnoses} = patientDetails(patientUid)
  const patientEventsIds = events.map(pe => pe.id)
  const firstName = faker.name.firstName(gender)
  const lastName = faker.name.lastName(gender)
  allEvents.push(...events)
  allDiagnoses.push(...diagnoses)
  return {
    createdAt: faker.date.past(2).toISOString(),
    updatedAt: faker.date.recent(20).toISOString(),
    specialistUid,
    fullName: {
      firstName: firstName,
      lastName: lastName,
    },
    id: faker.datatype.uuid().substr(0, 2).toUpperCase() + faker.datatype.number({ min: 100000, max: 999999 }),
    uid: patientUid,
    eventIds: patientEventsIds,
    type: UserType.patient,
    phone: faker.datatype.number({min: 37060000000, max:37069999999}),
    email: faker.internet.email(firstName, lastName),
    gender: gender === 1 ? 'female' : 'male',
  }
}

export const patientsList = (max: number, specialistUid: string) => {
  let patients: IPatient[] = []
  while (max > 0) {
    patients.push(
      mockPatient(specialistUid),
    )
    max--
  }
  return {
    patients,
    events: allEvents,
    diagnoses: allDiagnoses,
  }
}
