import React, { useState, useEffect } from 'react'
import {ScreenWrapper} from '../components/ScreenWrapper'
import { ActivityIndicator, View } from 'react-native'
import { ButtonRounded, WaitOverlay, ToastComponent } from '../components'
import { SettingsProps } from '../containers/AuthContainer'
import { css } from '../styles'
import { MainNavigation } from '../navigation'
import { TextItem } from '../components/TextItem'

type Props = {
  navigation?: {openDrawer: Function}
}

export function LogInScreen ({ auth, fetchData, logOutUser }: Props & SettingsProps) {
  const [timer, setTimer] = useState<Function|undefined>()
  // NEXT: add login functionality refactor fetching after server is available
  useEffect(() => {
    if (auth.user === undefined) {
      fetchData('specialist')
    }
  }, [])
  return (
    <>
      {auth?.loggedIn && auth.user &&
        <MainNavigation user={auth.user} logOutUser={logOutUser} />
      }
      {!auth?.loggedIn &&
        <ScreenWrapper>
          <View style={{alignItems: 'center', justifyContent: 'center', height: '100%', paddingBottom: css.padding * 3, opacity: 0.3}}>
            <ActivityIndicator color="white" />
            <TextItem style={{marginTop: css.padding}}>first time load</TextItem>
          </View>
          {/* NEXT: bring back login */}
          {/* <View style={{alignItems: 'center', justifyContent: 'flex-end', height: '100%', paddingBottom: css.padding * 3}}>
            <ButtonRounded title="Log in as specialist" onPress={() => fetchData('specialist')} />
            <ButtonRounded title="Log in as patient" style={{marginTop: css.padding}} onPress={() => fetchData('patient')} />
          </View> */}
        </ScreenWrapper>
      }
      <WaitOverlay />
      {ToastComponent}
    </>
  )
}
