import './GestureHandler'
import React from 'react'
import { TypedNavigator } from '@react-navigation/core'
import {StackNavigationOptions} from '@react-navigation/stack'
import { NavigationProp,RouteProp} from '@react-navigation/native'
import {BottomTabBarOptions,} from '@react-navigation/bottom-tabs'
import { Home } from '../containers/HomeContainer'
import { EventsList } from '../containers/EventsListContainer'
import { Patient } from '../containers/PatientContainer'
import { Event } from '../containers/EventContainer'
import { Settings } from '../containers/SettingsContainer'
import { PatientsList } from '../containers/PatientsListContainer'
import { Test } from '../containers/TestContainer'
import {css} from '../styles'
import {CREATE_EVENT_ROUTE_NAME, EVENTS_ROUTE_NAME, EVENT_ROUTE_NAME, HOME_ROUTE_NAME, PATIENTS_ROUTE_NAME, PATIENT_ROUTE_NAME, SETTINGS_ROUTE_NAME, StackParams, TEST_ROUTE_NAME} from '../types'

export type StackRouteProp = RouteProp<
  StackParams,
  typeof PATIENT_ROUTE_NAME |
  typeof HOME_ROUTE_NAME |
  typeof EVENTS_ROUTE_NAME |
  typeof PATIENTS_ROUTE_NAME |
  typeof SETTINGS_ROUTE_NAME |
  typeof CREATE_EVENT_ROUTE_NAME |
  typeof EVENT_ROUTE_NAME |
  typeof TEST_ROUTE_NAME
>
export type StackNavigationProp = NavigationProp<
  StackParams,
  typeof PATIENT_ROUTE_NAME |
  typeof HOME_ROUTE_NAME |
  typeof EVENTS_ROUTE_NAME |
  typeof PATIENTS_ROUTE_NAME |
  typeof SETTINGS_ROUTE_NAME |
  typeof CREATE_EVENT_ROUTE_NAME |
  typeof EVENT_ROUTE_NAME |
  typeof TEST_ROUTE_NAME
>

export interface IHeader {
  title?: string
  subtitle?: string
  reverseOrder?: true
  iconName?: string
}

export const tabBarOptions: BottomTabBarOptions = {
  style: {
    backgroundColor: css.colorAppBg,
    borderTopWidth: 0,
  },
  activeTintColor: css.colorTextLight,
  inactiveTintColor: css.colorPrimary,
  showLabel: false,
  keyboardHidesTabBar: true,
}

export type ITypedNavigator = TypedNavigator<any, any, any, any, any>
interface IStackScreenProps {Stack: ITypedNavigator, topNavScreenOptions: StackNavigationOptions}

export const HomeStackScreen = ({Stack, topNavScreenOptions}: IStackScreenProps) => {
  return (
    <Stack.Navigator screenOptions={topNavScreenOptions}>
      <Stack.Screen name={HOME_ROUTE_NAME} component={Home} />
      <Stack.Screen name={PATIENT_ROUTE_NAME} component={Patient} />
      {/* <Stack.Screen name={PATIENTS_ROUTE_NAME} component={PatientsList} /> */}
      <Stack.Screen name={EVENT_ROUTE_NAME} component={Event} />
      {/* <Stack.Screen name={EVENTS_ROUTE_NAME} component={EventsList} /> */}
      <Stack.Screen name={SETTINGS_ROUTE_NAME} component={Settings} />
    </Stack.Navigator>
  )
}

export const EventsStackScreen = ({Stack, topNavScreenOptions}: IStackScreenProps) => {
  return (
    <Stack.Navigator screenOptions={topNavScreenOptions}>
      <Stack.Screen name={EVENTS_ROUTE_NAME} component={EventsList} />
      <Stack.Screen name={EVENT_ROUTE_NAME} component={Event} />
      <Stack.Screen name={PATIENTS_ROUTE_NAME} component={PatientsList} />
      <Stack.Screen name={PATIENT_ROUTE_NAME} component={Patient} />
      <Stack.Screen name={SETTINGS_ROUTE_NAME} component={Settings} />
    </Stack.Navigator>
  )
}

export const EventStackScreen = ({Stack, topNavScreenOptions}: IStackScreenProps) => {
  return (
    <Stack.Navigator screenOptions={topNavScreenOptions}>
      <Stack.Screen name={CREATE_EVENT_ROUTE_NAME} component={Event} />
      <Stack.Screen name={PATIENT_ROUTE_NAME} component={Patient} />
      <Stack.Screen name={PATIENTS_ROUTE_NAME} component={PatientsList} />
      <Stack.Screen name={SETTINGS_ROUTE_NAME} component={Settings} />
    </Stack.Navigator>
  )
}

export const TestStackScreen = ({Stack, topNavScreenOptions}: IStackScreenProps) => {
  return (
    <Stack.Navigator screenOptions={topNavScreenOptions}>
      <Stack.Screen name={TEST_ROUTE_NAME} component={Test} />
    </Stack.Navigator>
  )
}

export const PatientsListStackScreen = ({Stack, topNavScreenOptions}: IStackScreenProps) => {
  return (
    <Stack.Navigator screenOptions={topNavScreenOptions}>
      <Stack.Screen
        name={PATIENTS_ROUTE_NAME}
        // options={{title: 'Patients'}}
        component={PatientsList}
      />
      <Stack.Screen name={PATIENT_ROUTE_NAME} component={Patient} />
      <Stack.Screen name={SETTINGS_ROUTE_NAME} component={Settings} />
    </Stack.Navigator>
  )
}
