import React from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'
import { css } from '../styles'
import { BodyLocalization, Gender, IDiagnosis } from '../types'

type Props = {
  toggleModal: boolean
  initialLocalization?: BodyLocalization
  initialGender?: Gender
  onClose: (diagnoses: Partial<IDiagnosis>[]) => void
}

type CameraState = {
  isFocused?: boolean
  isFlashOn: boolean
  isTakingPict: boolean
  isRearCamera: boolean
  activeLense?: any
  diagnoses: Partial<IDiagnosis>[]
  // focusPoint: Point
  localization?: BodyLocalization
  gender?: Gender
}

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const thumbWH = (windowWidth - css.padding*2 - css.padding*0.5*3)/4
const thumbSize = {
  height: '100%', // thumbWH,
  width: thumbWH,
  maxHeight: thumbWH,
}
const focusPointerSize = {
  height: 40,
  width: 40,
}

export function CameraModal ({toggleModal, onClose, initialGender, initialLocalization}: Props) {


  return (
    <View style={{backgroundColor: css.colorDarkBg}}>
      
    </View>
  )
}

const focusViewBorderWidht = 20
const styles = StyleSheet.create({
  modalOuter: {
    backgroundColor: css.colorDarkBg,
    // alignItems: 'center',
    height: '100%',
  },
  modalHeader: {
    paddingVertical: css.padding/2,
    paddingHorizontal: css.padding,
    alignItems: 'center',
  },
  closeButton: {
    borderRadius: css.borderRadius,
    backgroundColor: css.colorText + 50,
    paddingHorizontal: css.padding,
    paddingVertical: 0,
    paddingTop: 2,
    justifyContent: 'center',
    height: 30,
  },
  lenseInfo: {
    fontWeight: '700',
    fontSize: 12,
    flexGrow: 1,
    paddingLeft: css.padding/2,
  },
  lenseAttached: {
    color: css.colorSuccess,
  },
  noLense: {
    color: css.colorText + '70',
  },
  cameraView: {
    backgroundColor: css.colorDarkBgLighten1,
  },
  cameraFocusView: {
    borderWidth: focusViewBorderWidht,
    height: windowWidth,
    width: windowWidth,
    position: 'relative',
  },
  cameraFocusViewBorder: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 1,
    height: '100%',
    width: '100%',
  },
  shotDetails: {
    backgroundColor: css.colorDarkBgLighten1,
    alignItems: 'center',
    height: 50,
    paddingHorizontal: css.padding,
  },
  inFocusText: {
    color: css.colorSuccess
  },
  outOfFocusText: {
    color: css.colorError,
  },
  focusInfo: {
    lineHeight: 17,
    flexGrow: 1,
  },
  thumbnail: {
    backgroundColor: css.colorDarkBgLighten1,
    borderRadius: css.borderRadius/2,
    marginRight: css.padding*0.5,
    overflow: 'hidden',
  },
  images: {
    height: thumbSize.height,
    padding: css.padding,
  },
  flashIcon: {
    borderRadius: 60,
    borderWidth: 1,
    borderColor: css.colorPrimary,
    padding: 12,
  },
  bottomButtons: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  bottomButtonsDisabled: {
    opacity: 0.3,
  }
})
