import React, {useContext, useEffect, useState} from 'react'
import {View, Platform, StyleProp, TextStyle, ActivityIndicator} from 'react-native'
import {BodyLocalization, DiagnosisTitle, Gender, iconsMapper, IDiagnosis, IDiagnosisAiResult, IPatient, UserType} from '../types'
import moment from 'moment'
import { ViewRow } from './ViewRow'
import { TextItem } from './TextItem'
import { css } from '../styles'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { DiagnosisBadge } from './DiagnosisBadge'
import { BlockItem } from './BlockItem'
import { ShareButton } from './ShareButton'
import ImageCarousel from './ImageCarousel'
import { LocalizationImage } from './LocalizationImage'
import { LocalizationModal } from './LocalizationModal'
import { InputItem } from './InputItem'
import { ButtonRounded } from './ButtonRounded'
import { AppContext } from '../contexts'
import { DicomButton } from './DicomButton'
import { Checkbox } from './Checkbox'
import { findDiagnosisByMaxProbability } from '../utils'

type Props = {
  display?: 'none' | 'flex'
  disabled?: boolean
  index: number
  patient?: Partial<IPatient>
  // when patient is not attached to the event, then event gender property is used
  eventGender?: Gender
  diagnosis: Partial<IDiagnosis>
  removeDiagnosis?: Function
  toggleListItem?: Function
  getAIData?: Function
  hideToggleIcon?: boolean
  setCustomDiagnosis?: (text?: string) => void
  changeLocalization?: (localization: BodyLocalization, gender?: Gender) => void
}

export function DiagnosesListOpenItem ({patient, diagnosis, removeDiagnosis, disabled, toggleListItem, index, changeLocalization, eventGender, setCustomDiagnosis, getAIData, display = 'flex', hideToggleIcon}: Props) {
  const appContext = useContext(AppContext)
  const appUserType = useSelector((state: RootState) => state.auth.user?.type)
  const wrapContent: StyleProp<TextStyle> = Platform.OS === 'web' ? {width:'min-content', flex: 1, flexWrap: 'wrap'} : {}
  const [localization, setLocalization] = useState<BodyLocalization>()
  const [aiResult, setAiResult] = useState<IDiagnosisAiResult>()
  const subtitle = `Diagnosis ${index+1}`
  const images = diagnosis?.imageResult?.originalImageUrl ?? []
  const [toggleBodyLocalization, setToggleBodyLocalization] = useState(false)
  const [useAsAnonymized, setUseAsAnonymized] = useState(false)
  const device = diagnosis?.imageResult?.source !== 'internal' ? (diagnosis?.imageResult?.source ?? 'Internal camera') : 'Internal camera'
  const [aiResLoading, setAiResLoading] = useState(false)
  const [placeholder, setPlaceholder] = useState('Specific diagnosis')
  const [showAllDiagnoses, setShowAllDiagnoses] = useState(false)

  useEffect(() => {
    setLocalization(diagnosis.bodyLocalization)
    setAiResult(findDiagnosisByMaxProbability(diagnosis.aiResults))
  }, [diagnosis])
  useEffect(() => {
    if (aiResult?.icdCode) {
      setPlaceholder(DiagnosisTitle[aiResult.icdCode])
    }
  }, [aiResult])
  return (
    <BlockItem
      style={{display, flexGrow: 1}}
      title={diagnosis?.date ? moment(diagnosis?.date).format('hh:mm, DD MMM YYYY') + '' : 'df'}
      subtitle={subtitle}
      reverseTitle={true}
      // iconNameLeft={!disabled ? iconsMapper.trash : undefined}
      styleIconLeft={{borderColor: css.colorError, borderStyle: 'dashed'}}
      iconNameRight={hideToggleIcon ? undefined : iconsMapper.up}
      styleIconRight={{
        borderWidth: 0,
        fontSize: 25,
        marginRight: -3,
        padding: 0,
      }}
      onTitlePress={() => toggleListItem?.()}
      onRightIconPress={() => toggleListItem?.()}
      // onLeftIconPress={() => {
      //   removeDiagnosis?.()
      // }}
    >
      <ImageCarousel hasLocalization={!!(localization || !disabled)} images={images} footerTitle={device}>
        <>
          {!!(localization || !disabled) &&
            <View style={{
              width: 100, position: 'absolute', zIndex: 1,
              height: 200,
              top: 0, right: 0,
              backgroundColor: css.colorComponentActiveBg}}
              onStartShouldSetResponder={() => true}
              onResponderRelease={() => setToggleBodyLocalization(true)}
            >
              <LocalizationImage gender={eventGender ?? patient?.gender} localization={localization} />
              <ViewRow style={{...(localization ? {flexShrink: 1} : {flexGrow: 1}), alignItems: 'center', justifyContent: 'center', backgroundColor: css.colorDarkBgLighten2}}>
                <TextItem
                  style={{
                    height: localization ? 25 : undefined,
                    textAlign: 'center',
                    fontFamily: css.fontFamily600,
                  }}>
                  {localization?.localizationId ?? 'select localization'}
                </TextItem>
              </ViewRow>
              <LocalizationModal onSave={!disabled ? (localization: BodyLocalization, gender) => {
                changeLocalization?.(localization, gender)
              } : undefined} initialGender={eventGender ?? patient?.gender} initialLocalization={localization} toggleModal={toggleBodyLocalization} onClose={() => setToggleBodyLocalization(false)} />
            </View>
          }
        </>
      </ImageCarousel>
      <ViewRow style={{}}>
        {appUserType === UserType.specialist && !disabled &&
          <InputItem
            label='Specify diagnosis (optional):'
            placeholder={placeholder}
            value={diagnosis?.customDiagnosis || ''}
            blockStyle={{marginTop: css.padding/2, marginBottom: css.padding}}
            onChangeText={(text?: string) => {
              if (diagnosis?.customDiagnosis !== text
                && !(diagnosis?.customDiagnosis === undefined && text === '')) {
                setCustomDiagnosis?.((text && text.length > 0) ? text : undefined)
              }
            }}
          />
        }
        {appUserType === UserType.specialist && disabled && diagnosis?.customDiagnosis &&
          <TextItem style={{...wrapContent, color: appContext?.state.skin === 'light' ? css.colorText : css.colorTextLight, marginTop: css.padding}}>
            {diagnosis?.customDiagnosis ?? ''}
          </TextItem>
        }
        {aiResult?.icdCode &&
        typeof aiResult.probability === 'number' &&
          <View style={{marginLeft: css.padding, alignSelf: 'flex-end'}}>
            <TextItem style={{color: css.colorText, paddingBottom: 6}}>
              AI diagnosis:
            </TextItem>
            <DiagnosisBadge style={{marginRight: 0, height: 40, marginBottom: css.padding}} aiResult={aiResult} onPress={() => setShowAllDiagnoses(!showAllDiagnoses)} showToast={false}/>
          </View>
        }
        {getAIData && !aiResult && 
          <View style={{justifyContent: 'flex-end', alignItems: 'flex-end', marginLeft: css.padding}}>
            <TextItem style={{color: css.colorText, paddingBottom: 0, height: 24}}>
              AI diagnosis:
            </TextItem>
            <ViewRow style={{justifyContent: 'center', alignItems: 'center', marginBottom: css.padding}}>
              <ButtonRounded title='Call AI' style={{
                // opacity: aiResLoading ? 0.5 : 1,
                backgroundColor: css.colorSuccess,
                minHeight: Platform.OS === 'ios' ? css.ios.minRequiredInputHeight : css.android.minRequiredInputHeight,
                flex: 1,
                borderRadius: css.borderRadius/2,
                alignSelf: 'center',
                justifyContent: 'center',
                minWidth: 85
              }} onPress={async () => {
                setAiResLoading(true)
                await getAIData?.(images)
                setAiResLoading(false)
              }} disabled={aiResLoading} />
              {aiResLoading &&
                <ActivityIndicator animating={true} size={Platform.OS === 'web' ? 20 : 30} color='white' style={{position: 'absolute', left: -48}} />
              }
            </ViewRow>
          </View>
        }
      </ViewRow>
      {showAllDiagnoses &&
        <ViewRow style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {diagnosis.aiResults?.map((air, index) => {
            return <DiagnosisBadge key={'diagn_badge_' + index} aiResult={air} style={{marginRight: 0, marginBottom: css.padding/2, opacity: (air?.probability || 1) + 0.5}} showColorsByIcd={true} />
          })}
        </ViewRow>
      }
      {!disabled &&
          <>
            <ViewRow style={{marginBottom: css.padding, alignItems: 'center'}}>
              <Checkbox label="Anonymize DICOM" onPress={(isActive) => setUseAsAnonymized(isActive)} active={useAsAnonymized} styleWrapper={{flexGrow: 1}} checkActiveColor={css.colorSuccess} />
              {images.length > 0 && typeof images[0] !== 'number' &&
                <DicomButton anonymous={useAsAnonymized ? true : undefined} buttonTitle="Get DICOM" images={images} patient={patient} style={{marginLeft: css.padding, minWidth: 80}} />
              }
            </ViewRow>
            <ViewRow>
            {images.length > 0 && typeof images[0] !== 'number' &&
              <ButtonRounded title='Delete' style={{marginRight: css.padding}} borderColor={css.colorError} onPress={() => {
                removeDiagnosis?.()
              }} />
            }
            {/* <ButtonRounded skin={skin} title='Share' width={!diagnosis?.aiResults && getAIData ? '45%' : '96%'} onPress={() => shareSave('Sharing is anonymous, sensitive data will be removed', true)} /> */}
            <ShareButton buttonTitle="Share" images={images} message="Sharing is anonymous, sensitive data will be removed" />
            </ViewRow>
        </>
      }
    </BlockItem>
  )
}
