import {
  READ_CLINICS,
  ClinicsActionTypes,
  IClinicsState,
} from '../types'

const initialState: IClinicsState = {
  clinics: [],
}

const clinics = (state = initialState, action: ClinicsActionTypes) => {
  switch (action.type) {
    case READ_CLINICS:
      return {
        clinics: action.clinics
      }
    default:
      return state;
  }
};

export default clinics;
