import moment from 'moment'
import faker from 'faker'
import { BodyLocalization, IAddress, IDiagnosis, IDiagnosisAiResult, IEvent, IImageResult, IVisit } from '../types'
import { IAppValues } from '../contexts'
// import { launchImageLibrary } from 'react-native-image-picker'

export const fullDateTime = (date: string | Date) => moment(date).format('hh:mm, DD MMM YYYY')
export const fullDate = (date: string | Date) => moment(date).format('DD MMM, dddd, YYYY')
export const shortDate = (date: string | Date) => moment(date).format('DD MMM, dd')

export const fakeId = () => faker.datatype.uuid().slice(0, 5)
export const fakeNumber = (max = 9) => faker.datatype.number({min: 0, max})
export const fakeImages: {[key in string]: any} = {
  '../mocks/images/0.jpg': require('../mocks/images/0.jpg').default,
  '../mocks/images/1.jpg': require('../mocks/images/1.jpg').default,
  '../mocks/images/2.jpg': require('../mocks/images/2.jpg').default,
  '../mocks/images/3.jpg': require('../mocks/images/3.jpg').default,
  '../mocks/images/4.jpg': require('../mocks/images/4.jpg').default,
  '../mocks/images/5.jpg': require('../mocks/images/5.jpg').default,
  '../mocks/images/6.jpg': require('../mocks/images/6.jpg').default,
  '../mocks/images/7.jpg': require('../mocks/images/7.jpg').default,
  '../mocks/images/8.jpg': require('../mocks/images/8.jpg').default,
  '../mocks/images/9.jpg': require('../mocks/images/9.jpg').default,
}

export const fakeImageResult = ():IImageResult => {
  const photoCount = fakeNumber(4) + 1
  const source = photoCount === 1 ? 'device' : 'external'
  const originalImageUrl:string[] = []
  for(let i = photoCount; i > 0; i--) {
    originalImageUrl.push(`../mocks/images/${fakeNumber()}.jpg`)
  }
  return {
    id: 'tmp_' + fakeId(),
    source,
    originalImageUrl
  }
}

export const getImageUriForDiagnosis = (diagnosis?: Partial<IDiagnosis>) => {
  const image = diagnosis?.imageResult?.originalImageUrl?.[0]
  const key = Object.keys(fakeImages).find(fi => fi === image)
  const uri = image ? {uri: image} : undefined
  return key ? fakeImages[key] : uri
}

// export const debounce = (fn: Function, wait: number, immediate: boolean = false, ...debArgs: any) => {
//   let timer: NodeJS.Timeout | number | undefined
//   return function (this: any, ...args: any[]) {
//     if (timer === undefined && immediate) {
//       fn.apply(this, ...[...args, debArgs])
//     }
//     global.clearTimeout(timer as number)
//     timer = setTimeout(() => fn.apply(this, args), wait)
//     return timer
//   }
// }

export const debounce = (fn: Function) => {
  let timer: NodeJS.Timeout | number | undefined
  // return function (this: any, ...args: any[]) {
  //   if (timer === undefined) {
  //     fn.apply(this, ...[...args, debArgs])
  //   }
  //   global.clearTimeout(timer as number)
  //   timer = setTimeout(() => fn.apply(this, args), wait)
  //   return timer
  // }
  return function (...args: any) {
    // if (timer === undefined) {
    //   // console.log(fn(args))
    //   fn.apply(this, ...args)
    // }
    // console.log(...args)
    global.clearTimeout(timer as number)
    timer = setTimeout(() => fn(args), 300)
    // return timer
    // console.log(args)
  }
}

export const addressOneLine = (address?: IAddress) => {
  const address1 = address?.address1 ? address?.address1 + ', ' : ''
  const address2 = address?.address2 ? address?.address2 + ', ' : ''
  const city = address?.city ? address?.city + ', ' : ''
  const postCode = address?.postCode ? address?.postCode : ''
  return address1+address2+city+postCode
}

/**
 * @returns - timestamp (number) of last action found in the event, if no event given, returns -1
 */
export const getLastActionStamp = (event?: IEvent) => {
  if (!event) return -1
  const actionDate = (event as IVisit).appointmentDate ?? event.createdAt
  let lastCommentDate = 0
  event.comments?.forEach(c => {
    const commentDate = new Date(c.date).getTime()
    if (commentDate > lastCommentDate) lastCommentDate = commentDate
  })
  if (!actionDate || new Date(actionDate).getTime() < lastCommentDate) return lastCommentDate
  return new Date(actionDate).getTime()
}

export const createDiagnosisObj = (uris: string[], localization?: BodyLocalization, lenseName?: string) => {
  const diagnosis: Partial<IDiagnosis> = {
    id: 'tmp_' + fakeId(),
    date: new Date().toISOString(),
    imageResult: {
      id: 'tmp_' + fakeId(),
      source: lenseName ? 'device_name' : 'internal',
      originalImageUrl: uris ?? []
    },
    aiResults: [],
    bodyLocalization: localization
  }
  return diagnosis
}

export const imageLibraryDiagnosis = ():Promise<Partial<IDiagnosis> | undefined> => {
  return new Promise((resolve, reject) => {
  })
}

export const compareScalarArrays = (a1?: Array<any>, a2?: Array<any>) => {
  if (!a1 && !a2) return true
  if (!a1 && a2 || a1 && !a2) return false
  if (a1 && a2) {
    const a2Sorted = a2.slice().sort();
    a1.length === a2.length && a1.slice().sort().every(function(value, index) {
        return value === a2Sorted[index];
    })
  }
  return false
}

export const newAppContextValues: IAppValues = {
  userSettings: {}
}

export const findDiagnosisByMaxProbability = (aiResults?: IDiagnosisAiResult[]) => {
  aiResults = aiResults || []
  if (aiResults.length > 0) {
    let p = 0
    let d = aiResults[0]
    for (const aiResult of aiResults) {
      if (aiResult.probability > p) {
        p = aiResults[0].probability
        d = aiResults[0]
      }
    }
    return d
  }
  return undefined
}