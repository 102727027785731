/** FACE */
export const t02120 = [
  {"x": 0.5573907825440495, "y": 0.00},
  {"x": 0.4568501403518106, "y": 0.00},
  {"x": 0.42234751348583144, "y": 0.0149846312970465},
  {"x": 0.40350907594644475, "y": 0.03478270320491485},
  {"x": 0.40820141005470173, "y": 0.05458077169284449},
  {"x": 0.42545274445283277, "y": 0.06690787235964316},
  {"x": 0.43173222363262836, "y": 0.08329064641839212},
  {"x": 0.42234751348583144, "y": 0.10175461277684723},
  {"x": 0.4425988059536795, "y": 0.1207499131426767},
  {"x": 0.47417045006545155, "y": 0.132020846633095},
  {"x": 0.4977012636433782, "y": 0.13500485506536879},
  {"x": 0.5259933866450717, "y": 0.13249231963845173},
  {"x": 0.5542165762615383, "y": 0.12768954923639245},
  {"x": 0.5793344929807205, "y": 0.11744364998913645},
  {"x": 0.5966548026943614, "y": 0.10922558287793734},
  {"x": 0.602934281874157, "y": 0.09764557922124767},
  {"x": 0.5934805544815672, "y": 0.085318478554449},
  {"x": 0.5966548026943614, "y": 0.06552040778656022},
  {"x": 0.6139060951622095, "y": 0.059383537534985514},
  {"x": 0.6185984712007495, "y": 0.037557634631039866},
  {"x": 0.602934281874157, "y": 0.021121500408641636},
  {"x": 0.5762292200834361, "y": 0.00746029704237864},
]
/** SCALP */
export const t02102 = t02120
/** NECK */
export const t023 = [
  {"x": 0.428626992665627, "y": 0.11264087958707715},
  {"x": 0.43173222363262836, "y": 0.13996328973954184},
  {"x": 0.39095011758685366, "y": 0.15362448569593765},
  {"x": 0.32656818071735944, "y": 0.16253629877343306},
  {"x": 0.3768040141557239, "y": 0.17481003015674593},
  {"x": 0.46312961953160614, "y": 0.17961279143896866},
  {"x": 0.5134344282854806, "y": 0.1857496571306251},
  {"x": 0.5620831585825894, "y": 0.18094689584840234},
  {"x": 0.6453034910611873, "y": 0.17481003015674593},
  {"x": 0.7159648651801942, "y": 0.16456413090948993},
  {"x": 0.624877950380545, "y": 0.15229039040634051},
  {"x": 0.5887881784430273, "y": 0.14337858644868165},
  {"x": 0.5903753235145659, "y": 0.11644364998913645},
  {"x": 0.5589089103697952, "y": 0.12549231963845173},
  {"x": 0.5197139074652761, "y": 0.1325479839105655},
  {"x": 0.49452701543058397, "y": 0.1325479839105655},
  {"x": 0.4568501403518106, "y": 0.12496798025387582},
]
/** EARS (left) */
export const t022r = [
  {"x": 0.38308349333551944, "y": 0.05535570539892865},
  {"x": 0.40820141005470173, "y": 0.05735570539892865},
  {"x": 0.4287603684142928, "y": 0.06824197676907684},
  {"x": 0.429626992665627, "y": 0.08398437414501532},
  {"x": 0.42545274445283277, "y": 0.1030887171862809},
  {"x": 0.3899092451227252, "y": 0.1030887171862809},
  {"x": 0.372111680047467, "y": 0.08165727803813493}
]
/** EARS (right) */
export const t022l = [
  {"x": 0.6358498055988806, "y": 0.057996072961902574},
  {"x": 0.6117727194135437, "y": 0.058383537534985514},
  {"x": 0.592934281874157, "y": 0.06824197676907684},
  {"x": 0.5966548026943614, "y": 0.1051212489565083},
  {"x": 0.624877950380545, "y": 0.10175461277684723},
  {"x": 0.6484087639584717, "y": 0.085318478554449},
  {"x": 0.6453034910611873, "y": 0.06552040778656022}
]
/** AXILLA (right) */ 
export const t02420r = [
  {"x": 0.29986313989178004, "y": 0.29984204078303356},
  {"x": 0.27502470235239335, "y": 0.33463543927626144},
  {"x": 0.2637733889194038, "y": 0.31350324585926587},
  {"x": 0.2857170783909333, "y": 0.27251964887024194},
  {"x": 0.28889130563858606, "y": 0.21059640666766572},
  {"x": 0.31589130563858606, "y": 0.21059640666766572},
  {"x": 0.32528870153756384, "y": 0.24178193288880087},
  {"x": 0.3233939534697067, "y": 0.2786565145618984},
  {"x": 0.3233939534697067, "y": 0.3306565145618984},
]
/** AXILLA (left) */
export const t02420l = [
  {"x": 0.7269367623288127, "y": 0.29503927950081077},
  {"x": 0.7568160304020448, "y": 0.3291922830715551},
  {"x": 0.764613637407586, "y": 0.3223616714136024},
  {"x": 0.7363904477911195, "y": 0.25405568251178684},
  {"x": 0.7410827818993764, "y": 0.21220893297474622},
  {"x": 0.7109648651801942, "y": 0.21287482856531254},
  {"x": 0.6937135727123461, "y": 0.24519723871777724},
  {"x": 0.6958188036793475, "y": 0.2786565145618984},
  {"x": 0.7062725310719372, "y": 0.3148373685083727},
]
/** CHEST */
export const t02424 = [
  {"x": 0.5118473251442249, "y": 0.18513713082354458},
  {"x": 0.4618473251442249, "y": 0.17813713082354458},
  {"x": 0.36808349333551944, "y": 0.17422533598572227},
  {"x": 0.3233939534697067, "y": 0.16253629877343306},
  {"x": 0.26059916167175107, "y": 0.17070099660114635},
  {"x": 0.2354812449525688, "y": 0.17822533598572227},
  {"x": 0.2684657439928022, "y": 0.19188652282228152},
  {"x": 0.2857170783909333, "y": 0.2130720672830898},
  {"x": 0.3140092223577683, "y": 0.2116845935901703},
  {"x": 0.3233939534697067, "y": 0.23767289933320132},
  {"x": 0.32656818071735944, "y": 0.264995309485666},
  {"x": 0.3799092451227252, "y": 0.2834059065606353},
  {"x": 0.41130664102170306, "y": 0.28276554811749793},
  {"x": 0.5134344282854806, "y": 0.27251964887024194},
  {"x": 0.607626615982414, "y": 0.28276554811749793},
  {"x": 0.6453034910611873, "y": 0.2834059065606353},
  {"x": 0.6987135727123461, "y": 0.2663294138950997},
  {"x": 0.6939522213582963, "y": 0.24519723871777724},
  {"x": 0.7127906588976829, "y": 0.2120893297474622},
  {"x": 0.747362261079172, "y": 0.2123051107709938},
  {"x": 0.7550391361579454, "y": 0.20693520159143336},
  {"x": 0.7997315541267683, "y": 0.17594689584840234},
  {"x": 0.7222443443599897, "y": 0.1620158636273633},
  {"x": 0.6562753462795229, "y": 0.17522533598572227},
  {"x": 0.5662753462795229, "y": 0.17922533598572227},
]
/** ABDOMEN */
export const t02480 = [
  {"x": 0.5092753462795229, "y": 0.2722533598572227},
  {"x": 0.42545274445283277, "y": 0.28051494011623485},
  {"x": 0.3799092451227252, "y": 0.28051494011623485},
  {"x": 0.3233939534697067, "y": 0.26410434304126557},
  {"x": 0.32028870153756384, "y": 0.320974215960356},
  {"x": 0.3375400149705534, "y": 0.3503778092926903},
  {"x": 0.4050271618419075, "y": 0.36195781294937995},
  {"x": 0.5165397011827649, "y": 0.3653731187783563},
  {"x": 0.6107318888796983, "y": 0.3640390326085957},
  {"x": 0.6813932629987052, "y": 0.35512723777077343},
  {"x": 0.7049930518921417, "y": 0.3230554356195718},
  {"x": 0.6955393244995519, "y": 0.26974471972407604},
  {"x": 0.6594495944923171, "y": 0.2791808539464743},
  {"x": 0.607626615982414, "y": 0.2821808539464743},
]
/** HYPOGASTRIC */
export const t02485 = [
  {"x": 0.5118473251442249, "y": 0.3627606107109489},
  {"x": 0.40820141005470173, "y": 0.3617606107109489},
  {"x": 0.3375400149705534, "y": 0.3489903538394439},
  {"x": 0.33126053579075787, "y": 0.3763127639919086},
  {"x": 0.3200863085431051, "y": 0.39611083475979736},
  {"x": 0.3343657877229007, "y": 0.41457481935792556},
  {"x": 0.38033482773365053, "y": 0.4370944317488213},
  {"x": 0.42173222363262836, "y": 0.47255372583261557},
  {"x": 0.5134344282854806, "y": 0.47674394256808467},
  {"x": 0.6044524096999028, "y": 0.4726627593882151},
  {"x": 0.6388498055988806, "y": 0.43576034557906074},
  {"x": 0.6876727421785007, "y": 0.41457481935792556},
  {"x": 0.6955393244995519, "y": 0.4036351741443733},
  {"x": 0.6995393244995519, "y": 0.39136144276106044},
  {"x": 0.6845675112114994, "y": 0.37017588006057905},
  {"x": 0.6813932629987052, "y": 0.3559021486772662},
  {"x": 0.607626615982414, "y": 0.36414806616419526},
]
/** GENITALIA */
export const t025 = [
  {"x": 0.5118473251442249, "y": 0.47646552067043785},
  {"x": 0.42234751348583144, "y": 0.4726627593882151},
  {"x": 0.4364935749866782, "y": 0.5149526286506159},
  {"x": 0.45812961953160614, "y": 0.543662494256327},
  {"x": 0.48044992924524715, "y": 0.553908411743256},
  {"x": 0.5023935977516352, "y": 0.5559362256396398},
  {"x": 0.541111303364254, "y": 0.5559362256396398},
  {"x": 0.5652292200834361, "y": 0.5422750388030806},
  {"x": 0.5897600336613628, "y": 0.5129247782748859},
  {"x": 0.607626615982414, "y": 0.4723564871148383},
]
/** ARM/anterior (right) */
export const t02615r = [
  {"x": 0.23030699673977456, "y": 0.17794689584840234},
  {"x": 0.20577620412698944, "y": 0.19188652282228152},
  {"x": 0.1906301216610012, "y": 0.2130720672830898},
  {"x": 0.1906301216610012, "y": 0.2403944774355545},
  {"x": 0.1946301216610012, "y": 0.25405568251178684},
  {"x": 0.18055303547566437, "y": 0.275241208732922},
  {"x": 0.17268644267204245, "y": 0.29706711163686766},
  {"x": 0.16640696349224687, "y": 0.320974215960356},
  {"x": 0.1412890467730646, "y": 0.35309938739504343},
  {"x": 0.1271429643070764, "y": 0.37289745816293224},
  {"x": 0.11299689232365889, "y": 0.4370944317488213},
  {"x": 0.09574556840809857, "y": 0.47194118128586193},
  {"x": 0.13500956759326907, "y": 0.47946552067043785},
  {"x": 0.16951220494181896, "y": 0.48149333456682164},
  {"x": 0.17268644267204245, "y": 0.47055372583261557},
  {"x": 0.23706834809382443, "y": 0.41590890552768617},
  {"x": 0.26059916167175107, "y": 0.38997395082846786},
  {"x": 0.27157099592494505, "y": 0.3667606107109489},
  {"x": 0.27502470235239335, "y": 0.3353291670028846},
  {"x": 0.2637733889194038, "y": 0.31825263785800284},
  {"x": 0.28461182645879046, "y": 0.2738537532796756},
  {"x": 0.28889130563858606, "y": 0.21579362714576986},
  {"x": 0.2668786408515466, "y": 0.19060810092463468},
]
/** ARM/anterior (left) */
export const t02615l = [
  {"x": 0.7991852395890751, "y": 0.17933436954132184},
  {"x": 0.740362261079172, "y": 0.21579362714576986},
  {"x": 0.7379827818993764, "y": 0.2561925482034433},
  {"x": 0.7637878436900972, "y": 0.31691855168824223},
  {"x": 0.764613637407586, "y": 0.3305797385248015},
  {"x": 0.7536417402589676, "y": 0.32849855534493194},
  {"x": 0.7583341582277905, "y": 0.3640390326085957},
  {"x": 0.764613637407586, "y": 0.392695528930821},
  {"x": 0.786626281229484, "y": 0.4200179390832857},
  {"x": 0.821197967271539, "y": 0.4582799944493027},
  {"x": 0.8337569256311301, "y": 0.4828808264994142},
  {"x": 0.8729518446750832, "y": 0.48149333456682164},
  {"x": 0.9075235307171382, "y": 0.4746627593882151},
  {"x": 0.9075235307171382, "y": 0.4658042973545324},
  {"x": 0.8949645723575471, "y": 0.44600622658664363},
  {"x": 0.8933774272860086, "y": 0.3818092530007545},
  {"x": 0.8933774272860086, "y": 0.3640390326085957},
  {"x": 0.8729518446750832, "y": 0.33463543927626144},
  {"x": 0.8635671764585693, "y": 0.31691855168824223},
  {"x": 0.8525953631705168, "y": 0.28137807442457846},
  {"x": 0.847833927955901, "y": 0.2677168693483461},
  {"x": 0.8274774464513346, "y": 0.2499466489561873},
  {"x": 0.8352749695963099, "y": 0.22673327235932214},
  {"x": 0.8274774464513346, "y": 0.2055477278985139},
]
/** PALM (right) */
export const t02652r = [
  {"x": 0.08574556840809857, "y": 0.4723564871148383},
  {"x": 0.05330733673398998, "y": 0.48698986005501377},
  {"x": 0.01563045903457392, "y": 0.502678897267303},
  {"x": 0.001484380008179206, "y": 0.5143122702074785},
  {"x": 0.0077638590241846, "y": 0.5174491176594618},
  {"x": 0.04702785755419442, "y": 0.5088157447192864},
  {"x": 0.040748375753756165, "y": 0.553908411743256},
  {"x": 0.04392261872526502, "y": 0.572372359862038},
  {"x": 0.06117393477889728, "y": 0.572372359862038},
  {"x": 0.06745341395869284, "y": 0.581924549622344},
  {"x": 0.08159949118339571, "y": 0.5832586357921045},
  {"x": 0.08946608922830301, "y": 0.5798433299631282},
  {"x": 0.10361217169429125, "y": 0.5805370576897513},
  {"x": 0.1271429643070764, "y": 0.553908411743256},
  {"x": 0.13024820575664847, "y": 0.5682633263064385},
  {"x": 0.1443942882226367, "y": 0.5709849044087917},
  {"x": 0.1585403602060542, "y": 0.5402471884273505},
  {"x": 0.16640696349224687, "y": 0.5300013074197677},
  {"x": 0.17579168412161453, "y": 0.500651046891573},
  {"x": 0.16640696349224687, "y": 0.4826023681224212},
  {"x": 0.13024820575664847, "y": 0.4798808264994142},
]
/** PALM (left) */
export const t02652l = [
  {"x": 0.8305826354880529, "y": 0.4828808264994142},
  {"x": 0.8243031563082573, "y": 0.500651046891573},
  {"x": 0.8274774464513346, "y": 0.5313887628730141},
  {"x": 0.8337569256311301, "y": 0.5682633263064385},
  {"x": 0.8447287389191827, "y": 0.5695974489555452},
  {"x": 0.8494210730274396, "y": 0.5675695985798153},
  {"x": 0.8588748423503123, "y": 0.553908411743256},
  {"x": 0.8698466556383648, "y": 0.5805370576897513},
  {"x": 0.8839927590694946, "y": 0.5832586357921045},
  {"x": 0.9012440515373427, "y": 0.587367669347704},
  {"x": 0.9153901549684724, "y": 0.5860335831779435},
  {"x": 0.921669634148268, "y": 0.5785092437933675},
  {"x": 0.9405080716876547, "y": 0.5737064825111448},
  {"x": 0.9467875508674503, "y": 0.5143122702074785},
  {"x": 0.9640388433352983, "y": 0.5190616622062154},
  {"x": 0.9891567600544806, "y": 0.5313887628730141},
  {"x": 0.9891567600544806, "y": 0.5313887628730141},
  {"x": 0.9990514871571963, "y": 0.5229247782748859},
  {"x": 0.9546540912582184, "y": 0.49515455788272705},
  {"x": 0.9122158648253952, "y": 0.47674394256808467},
  {"x": 0.8792313238548788, "y": 0.4796023681224212},
]
/** LEG/anterior (right) */
export const t02832r = [
  {"x": 0.5055678459644294, "y": 0.5553236810928862},
  {"x": 0.48196805707099283, "y": 0.5540236810928862},
  {"x": 0.4600243885646048, "y": 0.5450499497095733},
  {"x": 0.4364935749866782, "y": 0.5163400841038622},
  {"x": 0.42234751348583144, "y": 0.4726627593882151},
  {"x": 0.38308349333551944, "y": 0.4388971930310441},
  {"x": 0.3343657877229007, "y": 0.41590890552768617},
  {"x": 0.31711447428991113, "y": 0.433679125919845},
  {"x": 0.3124220982513712, "y": 0.47055372583261557},
  {"x": 0.28889130563858606, "y": 0.5484652555385497},
  {"x": 0.2857170783909333, "y": 0.5894488525275736},
  {"x": 0.3061426190715756, "y": 0.6475089786614794},
  {"x": 0.34071426318334763, "y": 0.6912141537528566},
  {"x": 0.35168609743654167, "y": 0.7397221266058026},
  {"x": 0.34540661825674607, "y": 0.7854551155935635},
  {"x": 0.3485118701888889, "y": 0.8100559111643288},
  {"x": 0.39095011758685366, "y": 0.9138490627653416},
  {"x": 0.394055348553855, "y": 0.93775616708883},
  {"x": 0.418626992665627, "y": 0.9398373867480457},
  {"x": 0.45057066117201505, "y": 0.9364220444397232},
  {"x": 0.4772756810324529, "y": 0.9302851969877399},
  {"x": 0.47417045006545155, "y": 0.9152365182185881},
  {"x": 0.4882475362507884, "y": 0.8517332723593222},
  {"x": 0.4977012636433782, "y": 0.8271324767885568},
  {"x": 0.49928836678463384, "y": 0.8086684557110824},
  {"x": 0.49142178446358264, "y": 0.7827335374912103},
  {"x": 0.4772756810324529, "y": 0.7478867879541696},
  {"x": 0.4882475362507884, "y": 0.7328915149478499},
  {"x": 0.49142178446358264, "y": 0.7205644142810512},
  {"x": 0.4882475362507884, "y": 0.7069031909651458},
  {"x": 0.49452701543058397, "y": 0.6925482764019633},
  {"x": 0.49928836678463384, "y": 0.6379034560970339},
  {"x": 0.5086730769314307, "y": 0.6133559933304081},
  {"x": 0.5086730769314307, "y": 0.5880613970743273},
]
/** LEG/anterior (left) */
export const t02832l = [
  {"x": 0.6908469903912949, "y": 0.41629636098093253},
  {"x": 0.6421292847786761, "y": 0.4320944317488213},
  {"x": 0.610626615982414, "y": 0.4646627593882151},
  {"x": 0.5883753235145659, "y": 0.5177275395571086},
  {"x": 0.565362637762385, "y": 0.5422750388030806},
  {"x": 0.5479370970817427, "y": 0.5546021394698792},
  {"x": 0.5165397011827649, "y": 0.5573236810928862},
  {"x": 0.5134344282854806, "y": 0.5880613970743273},
  {"x": 0.5259933866450717, "y": 0.641318725446664},
  {"x": 0.5275115144708173, "y": 0.6836898508476268},
  {"x": 0.5369652418634072, "y": 0.7062094267591763},
  {"x": 0.533790993650613, "y": 0.7205644142810512},
  {"x": 0.5432447210432028, "y": 0.7383345981938638},
  {"x": 0.5479370970817427, "y": 0.7464993325009233},
  {"x": 0.5275115144708173, "y": 0.7963947608071158},
  {"x": 0.5228191803625605, "y": 0.8175802870282509},
  {"x": 0.5369652418634072, "y": 0.8455430191445068},
  {"x": 0.5542165762615383, "y": 0.9063780926642515},
  {"x": 0.5479370970817427, "y": 0.9288977415344934},
  {"x": 0.5762292200834361, "y": 0.9364220444397232},
  {"x": 0.6044524096999028, "y": 0.9364220444397232},
  {"x": 0.6311574295603406, "y": 0.9350345889864768},
  {"x": 0.6453034910611873, "y": 0.8756403766828105},
  {"x": 0.6735956559931638, "y": 0.825744948376618},
  {"x": 0.6798751351729594, "y": 0.8059468776087293},
  {"x": 0.6798751351729594, "y": 0.7738217061740418},
  {"x": 0.6735956559931638, "y": 0.7232859923834043},
  {"x": 0.6845675112114994, "y": 0.6884925756505034},
  {"x": 0.7159648651801942, "y": 0.6502305567638326},
  {"x": 0.7301109686113239, "y": 0.6119685013978156},
  {"x": 0.7332162415086082, "y": 0.5470778000853033},
  {"x": 0.7127906588976829, "y": 0.47194118128586193},
  {"x": 0.7100906588976829, "y": 0.44323131568015084},
]
/** DORSUM FOOT (right) */
export const t02851r = [
  {"x": 0.394055348553855, "y": 0.9411714364384601},
  {"x": 0.3972295967666492, "y": 0.9576075706608583},
  {"x": 0.40033482773365053, "y": 0.9644381823188111},
  {"x": 0.3799092451227252, "y": 0.9740994056347165},
  {"x": 0.3539655766163372, "y": 0.9825703757358066},
  {"x": 0.35168609743654167, "y": 0.995760628950622},
  {"x": 0.4176551374472915, "y": 1},
  {"x": 0.48214230528378705, "y": 0.9958162567433896},
  {"x": 0.48542178446358264, "y": 0.986957831189053},
  {"x": 0.4882475362507884, "y": 0.9698813385235174},
  {"x": 0.49142178446358264, "y": 0.9521110816520124},
  {"x": 0.48514230528378705, "y": 0.9439464203036453},
  {"x": 0.48044992924524715, "y": 0.93016193196368466},
  {"x": 0.45374490938480927, "y": 0.9354499312947994},
  {"x": 0.4207603684142928, "y": 0.9405589648503989},
]
/** DORSUM FOOT (left) */
export const t02851l = [
  {"x": 0.551111303364254, "y": 0.9302851969877399},
  {"x": 0.533790993650613, "y": 0.9445867787467827},
  {"x": 0.5369652418634072, "y": 0.9623570356182877},
  {"x": 0.5382447210432028, "y": 0.9910668647446526},
  {"x": 0.5542165762615383, "y": 0.9978974764026053},
  {"x": 0.5903753235145659, "y": 0.9992315990517121},
  {"x": 0.6107318888796983, "y": 0.997203712196636},
  {"x": 0.6311574295603406, "y": 0.9992315990517121},
  {"x": 0.6714214077803696, "y": 0.9930946786410364},
  {"x": 0.6693161768133683, "y": 0.9842362530866999},
  {"x": 0.6421292847786761, "y": 0.9780994056347165},
  {"x": 0.624877950380545, "y": 0.9657723049679179},
  {"x": 0.6293316777731348, "y": 0.9582480020626881},
  {"x": 0.6311574295603406, "y": 0.93575616708883},
  {"x": 0.5966548026943614, "y": 0.9354499312947994},
  {"x": 0.5714678687293864, "y": 0.93575616708883},
]
/** UPPER BACK */
export const t02451 = [
  {"x": 0.5118473251442249, "y": 0.18585869068622466},
  {"x": 0.3799092451227252, "y": 0.17322533598572227},
  {"x": 0.32656818071735944, "y": 0.16056413090948993},
  {"x": 0.24273255838555836, "y": 0.17481003015674593},
  {"x": 0.28102470235239335, "y": 0.22673327235932214},
  {"x": 0.29986313989178004, "y": 0.2861808539464743},
  {"x": 0.29827603675052444, "y": 0.3032573466120099},
  {"x": 0.3280863085431051, "y": 0.3333013166271547},
  {"x": 0.4176551374472915, "y": 0.3380507086258916},
  {"x": 0.5118473251442249, "y": 0.33943820055848417},
  {"x": 0.607626615982414, "y": 0.33671662245613104},
  {"x": 0.6908469903912949, "y": 0.3305797385248015},
  {"x": 0.7269367623288127, "y": 0.29503927950081077},
  {"x": 0.7442570720424536, "y": 0.22534579866640264},
  {"x": 0.7787596569781498, "y": 0.17461279143896866},
  {"x": 0.7210701380774785, "y": 0.16528569077216998},
  {"x": 0.6499958670997273, "y": 0.17361279143896866},
]
/** LOWER BACK */
export const t02452 = [
  {"x": 0.5118473251442249, "y": 0.33815974218149114},
  {"x": 0.3799092451227252, "y": 0.3368256560117306},
  {"x": 0.3280863085431051, "y": 0.3353291670028846},
  {"x": 0.3375400149705534, "y": 0.3667606107109489},
  {"x": 0.32656818071735944, "y": 0.38997395082846786},
  {"x": 0.32656818071735944, "y": 0.4002198683153969},
  {"x": 0.33126053579075787, "y": 0.41457481935792556},
  {"x": 0.41130664102170306, "y": 0.4124935996987098},
  {"x": 0.5118473251442249, "y": 0.4124935996987098},
  {"x": 0.6217727194135437, "y": 0.41590890552768617},
  {"x": 0.6908469903912949, "y": 0.4170179390832857},
  {"x": 0.6955393244995519, "y": 0.4050226295976197},
  {"x": 0.6939522213582963, "y": 0.38863986465870726},
  {"x": 0.6798751351729594, "y": 0.3687884246073327},
  {"x": 0.6955393244995519, "y": 0.3313013166271547},
  {"x": 0.602934281874157, "y": 0.33685350638746054},
]
/** ARM/posterior (right) */
export const t02614r = [
  {"x": 0.7787596569781498, "y": 0.1768912315762886},
  {"x": 0.737362261079172, "y": 0.22534579866640264},
  {"x": 0.7302162415086082, "y": 0.29231771963813075},
  {"x": 0.7536417402589676, "y": 0.32577697724257876},
  {"x": 0.7536417402589676, "y": 0.3408256560117306},
  {"x": 0.7615083645103017, "y": 0.3838371033764845},
  {"x": 0.7787596569781498, "y": 0.4111595135289492},
  {"x": 0.8180236771284618, "y": 0.4521431105179732},
  {"x": 0.8305826354880529, "y": 0.48149333456682164},
  {"x": 0.8729518446750832, "y": 0.4808808264994142},
  {"x": 0.9075235307171382, "y": 0.47674394256808467},
  {"x": 0.8949645723575471, "y": 0.4418971930310441},
  {"x": 0.8933774272860086, "y": 0.38997395082846786},
  {"x": 0.8902722382492901, "y": 0.3667606107109489},
  {"x": 0.8792313238548788, "y": 0.3408256560117306},
  {"x": 0.8666723654952877, "y": 0.3305797385248015},
  {"x": 0.8557005522072352, "y": 0.28276554811749793},
  {"x": 0.8447287389191827, "y": 0.2677168693483461},
  {"x": 0.8274774464513346, "y": 0.25133410440943366},
  {"x": 0.8352749695963099, "y": 0.22401169425696899},
  {"x": 0.8243031563082573, "y": 0.20079833589977694},
  {"x": 0.8070518638404093, "y": 0.18713713082354458},
]
  /** ARM/posterior (right) */
export const t02614l = [
  {"x": 0.24334782727361998, "y": 0.1768912315762886},
  {"x": 0.20877620412698944, "y": 0.19327399651520102},
  {"x": 0.1946301216610012, "y": 0.2116845935901703},
  {"x": 0.18993776658760275, "y": 0.2315360336415449},
  {"x": 0.19780436987379543, "y": 0.25266820881886737},
  {"x": 0.18055303547566437, "y": 0.2738537532796756},
  {"x": 0.17268644267204245, "y": 0.28959614153577756},
  {"x": 0.16323272576202338, "y": 0.3196401297905954},
  {"x": 0.13811480904284115, "y": 0.35171193194179706},
  {"x": 0.1271429643070764, "y": 0.3838371033764845},
  {"x": 0.11458400594748526, "y": 0.43576034557906074},
  {"x": 0.09574556840809857, "y": 0.4753564871148383},
  {"x": 0.13500956759326907, "y": 0.48079960684019846},
  {"x": 0.16640696349224687, "y": 0.48079960684019846},
  {"x": 0.17579168412161453, "y": 0.4671384200036392},
  {"x": 0.24334782727361998, "y": 0.40843793542659607},
  {"x": 0.26059916167175107, "y": 0.38863986465870726},
  {"x": 0.27157099592494505, "y": 0.36265157715534935},
  {"x": 0.2684657439928022, "y": 0.3380507086258916},
  {"x": 0.29986313989178004, "y": 0.29984204078303356},
  {"x": 0.29199655757072884, "y": 0.2554431379650332},
  {"x": 0.27785047510474065, "y": 0.22193051107709938},
]
/** BUTTOCK */
export const t02471 = [
  {"x": 0.5086730769314307, "y": 0.41390890552768617},
  {"x": 0.33126053579075787, "y": 0.41629636098093253},
  {"x": 0.32028870153756384, "y": 0.43506661785243755},
  {"x": 0.3140092223577683, "y": 0.4658042973545324},
  {"x": 0.2951707848183816, "y": 0.5224769680351917},
  {"x": 0.2951707848183816, "y": 0.5348040687019905},
  {"x": 0.34381949415034896, "y": 0.543662494256327},
  {"x": 0.42234751348583144, "y": 0.5484652555385497},
  {"x": 0.47417045006545155, "y": 0.5463840723586801},
  {"x": 0.4977012636433782, "y": 0.5409409161539738},
  {"x": 0.5023935977516352, "y": 0.5286138154871751},
  {"x": 0.5165397011827649, "y": 0.5300013074197677},
  {"x": 0.5228191803625605, "y": 0.536138154871751},
  {"x": 0.5589089103697952, "y": 0.5450499497095733},
  {"x": 0.6107318888796983, "y": 0.5511868336409028},
  {"x": 0.6673161768133683, "y": 0.5484652555385497},
  {"x": 0.708098324789426, "y": 0.5450499497095733},
  {"x": 0.7301109686113239, "y": 0.5388597329741042},
  {"x": 0.708098324789426, "y": 0.4733286367391083},
  {"x": 0.7018188036793475, "y": 0.44323131568015084},
  {"x": 0.6908469903912949, "y": 0.4194053945365321},
]
/** LEG/posterior (right) */
export const t02834r = [
  {"x": 0.5259933866450717, "y": 0.5375256103249975},
  {"x": 0.5165397011827649, "y": 0.5682633263064385},
  {"x": 0.5165397011827649, "y": 0.5996947700145028},
  {"x": 0.5275115144708173, "y": 0.6399846757562497},
  {"x": 0.5275115144708173, "y": 0.6884925756505034},
  {"x": 0.5369652418634072, "y": 0.7144274938703754},
  {"x": 0.5369652418634072, "y": 0.7356130930502031},
  {"x": 0.5479370970817427, "y": 0.7458589740577859},
  {"x": 0.5259933866450717, "y": 0.8113900338134356},
  {"x": 0.5275115144708173, "y": 0.8298539819322176},
  {"x": 0.5400704728304085, "y": 0.8530673950084289},
  {"x": 0.551111303364254, "y": 0.9118212488689579},
  {"x": 0.5448318241844584, "y": 0.9316193196368466},
  {"x": 0.5651883895495907, "y": 0.9370345889864768},
  {"x": 0.5997600336613628, "y": 0.93470046633737},
  {"x": 0.624877950380545, "y": 0.9364220444397232},
  {"x": 0.6311574295603406, "y": 0.9090996707666047},
  {"x": 0.6704214077803696, "y": 0.825051257129341},
  {"x": 0.6735956559931638, "y": 0.8086684557110824},
  {"x": 0.6735956559931638, "y": 0.7779307397296413},
  {"x": 0.6673161768133683, "y": 0.7219518697342976},
  {"x": 0.6798751351729594, "y": 0.6946294231024867},
  {"x": 0.6939522213582963, "y": 0.6748313523345979},
  {"x": 0.7159648651801942, "y": 0.6515646794129394},
  {"x": 0.7301109686113239, "y": 0.6105810459445692},
  {"x": 0.7332162415086082, "y": 0.5422750388030806},
  {"x": 0.708098324789426, "y": 0.5454652555385497},
  {"x": 0.6453034910611873, "y": 0.5505209198106634},
  {"x": 0.602934281874157, "y": 0.5495209198106634},
  {"x": 0.5620831585825894, "y": 0.5460778000853033},
]
/** LEG/posterior (left) */
export const t02834l = [
  {"x": 0.4977012636433782, "y": 0.5409409161539738},
  {"x": 0.48044992924524715, "y": 0.5434652555385497},
  {"x": 0.42545274445283277, "y": 0.5481868336409028},
  {"x": 0.35955272168787583, "y": 0.5450652555385497},
  {"x": 0.32656818071735944, "y": 0.5422750388030806},
  {"x": 0.29199655757072884, "y": 0.536138154871751},
  {"x": 0.28889130563858606, "y": 0.5846460912453509},
  {"x": 0.29986313989178004, "y": 0.627657575089451},
  {"x": 0.3124220982513712, "y": 0.6563674042158159},
  {"x": 0.34071426318334763, "y": 0.6898266982996101},
  {"x": 0.35168609743654167, "y": 0.7260609032898971},
  {"x": 0.3485118701888889, "y": 0.7888703849431936},
  {"x": 0.3485118701888889, "y": 0.8134712534726514},
  {"x": 0.3861887243025208, "y": 0.8954384474506992},
  {"x": 0.394055348553855, "y": 0.9425589648503989},
  {"x": 0.4458782851334751, "y": 0.9370499312947994},
  {"x": 0.48044992924524715, "y": 0.9288977415344934},
  {"x": 0.47099620185265734, "y": 0.9166239736718345},
  {"x": 0.48514230528378705, "y": 0.8496520527001064},
  {"x": 0.49928836678463384, "y": 0.825051257129341},
  {"x": 0.49928836678463384, "y": 0.8113900338134356},
  {"x": 0.47099620185265734, "y": 0.7478867879541696},
  {"x": 0.48044992924524715, "y": 0.7397221266058026},
  {"x": 0.49452701543058397, "y": 0.6973510012048398},
  {"x": 0.49928836678463384, "y": 0.6304324859959438},
  {"x": 0.5055678459644294, "y": 0.5935045532790336},
  {"x": 0.5023935977516352, "y": 0.5607389869218625},
]

export const bodyMan = {
  'back': {
    t02102,
    t022l: t022r,
    t022r: t022l,
    t023,
    t02652l: t02652r,
    t02652r: t02652l,
    t02851r: t02851l,
    t02851l: t02851r,
    t02451,
    t02452,
    t02614l,
    t02614r,
    t02471,
    t02834r,
    t02834l
  },
  'front': {
    t02120,
    t022l,
    t022r,
    t023,
    t02420l,
    t02420r,
    t02615l,
    t02615r,
    t02480,
    t02485,
    t02652l,
    t02652r,
    t02832l,
    t02832r,
    t025,
    t02851r,
    t02851l,
    t02424,
  },
}