import React from 'react'
import {Platform, StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native'
import Icon from './IconWrapper'
import {css} from '../styles'

export interface IconStyle extends ViewStyle {
  fontSize?: number
}

type Props = {
  iconName?: string
  color?: string
  blockStyle?: IconStyle | IconStyle[]
  style?: IconStyle | IconStyle[]
  onPress?: Function
  children?: JSX.Element | JSX.Element[]
}

export function IconOutlined ({children, iconName, color, style, blockStyle, onPress}: Props) {
  style = StyleSheet.flatten(style)
  const cursor = (Platform.OS === 'web' && onPress !== undefined ? {
    cursor: 'pointer',
  } : {})
  const border = (Platform.OS === 'web' ? {
    border: '1px solid green'
  } : {})
  const styles = StyleSheet.create({
    iconOutlined: {
      marginRight: 8,
      padding: 11,
      paddingLeft: 12,
      paddingBottom: 9,
      borderRadius: 10,
      alignSelf: 'flex-start',
      borderWidth: 1,
      // borderStyle: 'dotted',
      ...cursor,
      // borderColor: css.colorDanger,
      ...border
    },
  })
  
  return (
    <TouchableOpacity  onPress={() => onPress?.()} style={{...blockStyle}}>
      {iconName &&
        <Icon
          name={iconName}
          color={color ? color : css.colorPrimary}
          size={{...style}?.fontSize ? {...style}.fontSize : 24}
          style={{
            ...styles.iconOutlined,
            ...{borderColor: color ? color : css.colorPrimary},
            ...(style ?? {}),
          }}
          iconClass="outlined-icon"
        />
      }
      {!iconName &&
        <View
          style={{
            ...styles.iconOutlined,
            ...{borderColor: color ? color : css.colorPrimary},
            ...(style ?? {}),
            minHeight: 37
          }}
        />
      }
      {children}
    </TouchableOpacity>
  )
}
