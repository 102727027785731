import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import {StyleSheet} from 'react-native'
import { PatientsListDialog } from './PatientsListDialog'
import { css } from '../styles'
import {iconsMapper, IEvent, IPatient} from '../types'
import { BlockItem } from './BlockItem'
import { TextItem } from './TextItem'
import { CREATE_EVENT_ROUTE_NAME, StackParams } from '../types'

type NavigationProps = StackNavigationProp<StackParams, typeof CREATE_EVENT_ROUTE_NAME>

type Props = {
  patient?: IPatient
  event: Partial<IEvent>
  editable: boolean
  children: JSX.Element | JSX.Element[] | undefined | false
  assignPatientUid: (patientUid: string | undefined) => void
  skin?: 'light' | 'dark'
}

export function EventScreenPatient ({event, patient, editable, children, assignPatientUid, skin}: Props) {
  const navigation = useNavigation<NavigationProps>()
  const [togglePatientDialog, setTogglePatientDialog] = useState(false)
  return <BlockItem
    skin={skin}
    title='Patient'
    subtitle={patient?.id ? `ID: ${patient.id.toUpperCase()}` : 'No patient is attached'}
    iconNameLeft={iconsMapper.patient}
    buttonTitleRight={editable ? (event?.patientUid ? 'Details' : 'Attach') : (event?.patientUid ? 'Open' : undefined)}
    styleIconRight={!editable ? {backgroundColor: css.colorPrimary} : (!event?.patientUid ? StyleSheet.flatten(styles.attachPatientButton) : StyleSheet.flatten(styles.detailsPatientButton))}
    onRightIconPress={() => {
      setTogglePatientDialog(true)
    }}
    onLeftIconPress={() => {
      setTogglePatientDialog(true)
      // navigateToPatientScreen()
    }}
  >
    {children}
    {!children && event.patientUid && <TextItem style={{width: '100%', textAlign: 'center', fontSize: 13, color: css.colorText}}>To remove or change patient click Details button</TextItem>}
    {!children && !event.patientUid && <TextItem style={{width: '100%', textAlign: 'center', fontSize: 13, color: css.colorText}}>To add patient click Attach button</TextItem>}
    <PatientsListDialog
      disabled={!editable}
      toggleModal={togglePatientDialog}
      onClose={(patientUid?: string) => {
        assignPatientUid(patientUid)
        setTogglePatientDialog(false)
      }}
      patientUid={event.patientUid}
    />
  </BlockItem>
}

const styles = StyleSheet.create({
  attachPatientButton: {
    backgroundColor: css.colorSuccess,
    color: 'white',
  },
  detailsPatientButton: {
    backgroundColor: css.colorInfo,
    borderWidth: 1,
  },
})