import React from 'react'
import {StyleSheet} from 'react-native'
import { css } from '../styles'
import { TextItem } from './TextItem'
import Moment from 'moment'
import { ViewRow } from './ViewRow'

type Props = {
  date: Date
}

export function TimeItem ({date}: Props) {
  return <ViewRow style={styles.time}>
    <TextItem style={{lineHeight: 17}}>
      {Moment(date).format('hh:mm')}
    </TextItem>
  </ViewRow>
}

const styles = StyleSheet.create({
  time: {
    paddingLeft: css.padding/1.5,
    paddingRight: css.padding/1.5,
    backgroundColor: css.colorSuccess,
    borderRadius: css.borderRadius/3,
    flexShrink: 1,
    height: 30,
    alignItems: 'center',
  },
})
