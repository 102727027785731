import React from 'react'
import { useFormikContext } from 'formik'
import { Platform } from 'react-native'
import { Gender, IPatient } from '../types'
import { IEventFormValues, sortDiagnosesByDate } from '../utils'
import { DiagnosesListItem } from './DiagnosesListItem'

type Props = {
  hasAI?: boolean
  editable?: boolean
  getAIData?: Function
  // when patient is not attached to the event, then event gender property is used
  eventGender?: Gender
  patient?: Partial<IPatient>
}

export function EventDiagnosesList ({editable, hasAI, getAIData, eventGender, patient}: Props) {
  const { values, setFieldValue } = useFormikContext<IEventFormValues>()
  return (
    <>
      {sortDiagnosesByDate(values.diagnoses)?.map((d, index) => {
        const diagnosis = Object.assign({}, d)
        const listKey = diagnosis?.id ? diagnosis.id : 'diagnosis_' + Date.now() + '_' + index
        
        // HELP ADVICE: this is a refactor from one huge component which was too big and to hard to understand
        // if there are some functionality lost please check EventListItem component (not EventsListItem)
        // which was used last time in v0.0.2-last version on mobile-app branch.
        // Again look into component without "s" Event(no s)ListItem!
        return !!diagnosis &&
          <DiagnosesListItem
            diagnosis={diagnosis}
            index={index}
            removeDiagnosis={() => {
              const ds = values.diagnoses.slice()
                ds.splice(index, 1)
                setFieldValue('diagnoses', ds, true)
            }}
            changeLocalization={(localization, gender) => {
              setFieldValue(`diagnoses[${index}].bodyLocalization`, localization, true)
              setFieldValue('gender', gender, true)
            }}
            disabled={!editable}
            eventGender={eventGender}
            getAIData={async (images: any) => {
              if (typeof getAIData === 'function') {
                try {
                  const aiData = await getAIData(images)
                  setFieldValue(`diagnoses[${index}].aiResults`, aiData || [], true)
                  // TODO: get rid of this code, I think custom diagnosis is only specified by specialist and there is no AI part here
                  // if (Array.isArray(aiData) && aiData.length > 0) {
                  //   const aiResult = findDiagnosisByMaxProbability(aiData)
                  //   if (aiResult?.className) {
                  //     const d = DiagnosisTitle[aiResult.icdCode]
                  //     console.log('diagnosis', d, aiResult.icdCode)
                  //     if (!diagnosis.customDiagnosis) {
                  //       setFieldValue(`diagnoses[${index}].customDiagnosis`, d, true)
                  //     }
                  //   }
                  // }
                } catch (err) {
                  console.log(err)
                }
              }
            }}
            hasAI={hasAI}
            patient={patient}
            showOpenAlways={Platform.OS === 'web'}
            setCustomDiagnosis={(text) => setFieldValue(`diagnoses[${index}].customDiagnosis`, text, true)}
            key={listKey}
          />
      })}
    </>
  )
}
