import { ISpecialist } from "../../types"

// State

export interface ISpecialistsState {
  specialists: ISpecialist[]
}

// CRUD

export const READ_SPECIALISTS = 'READ_SPECIALISTS'

// Actions

export interface SetSpecialistsAction {
  type: typeof READ_SPECIALISTS, specialists: ISpecialist[]
}


export type SpecialistsActionTypes =  SetSpecialistsAction 

// Selectors

export const SELECT_SPECIALISTS_ID = 'SELECT_SPECIALISTS_ID'
