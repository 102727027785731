import React, { useEffect, useState } from 'react'
import {ScreenWrapper} from '../components/ScreenWrapper'
import { ButtonRounded } from '../components'
import { TestProps } from '../containers'
import { css } from '../styles'
import { TextItem } from '../components/TextItem'
import { EventStatus, IConsultation, IDiagnosis, IEvent, UserType } from '../types'
import { View } from 'react-native'
import { fakeId } from '../utils'

export function TestScreen ({ user, activePatientEvents, updateEvent, createEvent, specialists, activeEventDiagnoses }:  TestProps) {
  const [activePatientEvent, setActivePatientEvent] = useState<IEvent | undefined>()
  const [activeDiagnoses, setActiveDiagnoses] = useState<IDiagnosis[]>([])
  const isEventCompleted = (activePatientEvent?.status === EventStatus.completed ||
    activePatientEvent?.status === EventStatus.completedWithDiagnosis ||
    activePatientEvent?.status === EventStatus.completedWithIssues ||
    activePatientEvent?.status === EventStatus.canceled
    )
  const specialist = specialists.specialists.length > 0 ? specialists.specialists[0] : undefined
  const userSpecialist = {
    uid: specialist?.uid,
    type: UserType.specialist
  }
  useEffect(() => {
    setActivePatientEvent(activePatientEvents?.[0])
	}, [activePatientEvents])
  useEffect(() => {
    setActiveDiagnoses(activeEventDiagnoses(activePatientEvent?.diagnosesIds ?? []))
  }, [activeEventDiagnoses])
  return (
    <ScreenWrapper>
      {activePatientEvent && !isEventCompleted &&
        <>
          <TextItem style={{fontWeight: '700', marginBottom: css.padding}}>
            Recent {activePatientEvent.type}: {activePatientEvent.id.toUpperCase()}, status: {activePatientEvent.status}
          </TextItem>
          <TextItem style={{marginBottom: css.padding}}>Specialist actions:</TextItem>
          {/* ALL CONSULTATION ACTIONS */}
          {activePatientEvent?.status !== EventStatus.pending && 
            <>
              <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Complete consultation (no issues)" onPress={() => {
                updateEvent({...activePatientEvent,
                  status: EventStatus.completed,
                  comments: [...activePatientEvent.comments, {
                    date: new Date().toISOString(),
                    message: 'No bad diagnosis found. Everything looks normal, no further action needed',
                    user: userSpecialist,
                    clinicId: 'kauno_klinikos'
                  }]
                }, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
              }} />
              <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Complete consultation (diagnosis)" onPress={() => {
                updateEvent({...activePatientEvent,
                  status: EventStatus.completedWithIssues,
                  comments: [...activePatientEvent.comments, {
                    date: new Date().toISOString(),
                    message: 'Diagnosis found. You should do that and that. No further action needed',
                    user: userSpecialist,
                    clinicId: 'kauno_klinikos'
                  }]
                }, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
              }} />
              {activePatientEvent.status !== EventStatus.needsVisit &&
                <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Needs a visit" onPress={() => {
                  updateEvent({...activePatientEvent,
                    status: EventStatus.needsVisit,
                    comments: [...activePatientEvent.comments, {
                      date: new Date().toISOString(),
                      message: 'We need further investigation. Please contact us for an appointment',
                      user: userSpecialist,
                      clinicId: 'kauno_klinikos'
                    }]
                  }, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
                }} />
              }
              {activePatientEvent.status !== EventStatus.needsReply &&
                <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Needs a reply" onPress={() => {
                  updateEvent({...activePatientEvent,
                    status: EventStatus.needsReply,
                    comments: [...activePatientEvent.comments, {
                      date: new Date().toISOString(),
                      message: 'Some pictures are bad, please update consultation',
                      user: userSpecialist,
                      clinicId: 'kauno_klinikos'
                    }]
                  }, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
                }} />
              }
              {activePatientEvent.status === EventStatus.needsVisit &&
                <>
                  <TextItem style={{ marginBottom: css.padding }}>Patient has called, specialist creates a visit</TextItem>
                  <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Create a visit" onPress={() => {
                    const id = fakeId()
                    const date = Date.now()
                    const createdAt = new Date(date + 1).toISOString()
                    const appointmentDate = new Date(date + 3600*24*5*1000).toISOString()
                    createEvent({
                      id,
                      createdAt,
                      type: 'visit',
                      comments: [],
                      diagnosesIds: [],
                      patientUid: user?.uid,
                      status: EventStatus.pending,
                      appointmentDate,
                      clinicId: 'kauno_klinikos',
                    } as IEvent, [])
                    const consultation: IConsultation = {...(activePatientEvent as IConsultation),
                      status: EventStatus.completedWithIssues,
                      comments: [...activePatientEvent.comments, {
                        date: new Date(date).toISOString(),
                        message: 'New visit is created with ID: ' + id,
                        user: userSpecialist,
                        clinicId: 'kauno_klinikos'
                      }],
                    }
                    updateEvent(consultation as IEvent, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
                  }} />
                </>
              }
            </>
          }
          {/* FINISH VISIT */}
          {activePatientEvent?.status === EventStatus.pending && 
            <ButtonRounded style={{marginBottom: css.padding, width: '100%'}} title="Complete active visit" onPress={() => {
              updateEvent({...activePatientEvent,
                status: EventStatus.completed,
                comments: [...activePatientEvent.comments, {
                  date: new Date().toISOString(),
                  message: 'Eveything is fine, nothing to worry about',
                  user: userSpecialist,
                  clinicId: 'kauno_klinikos',
                }]
              }, activeEventDiagnoses(activePatientEvent.diagnosesIds ?? []))
            }} />
          }
        </>
      }
      {activePatientEvent?.status !== EventStatus.pending && (!activePatientEvent || isEventCompleted) &&
        <View style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
          <TextItem style={{alignSelf: 'center', maxWidth: 250, textAlign: 'center'}}>No active events found, use patient to create active event</TextItem>
        </View>
      }
    </ScreenWrapper>
  )
}
