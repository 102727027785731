import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

export * from './sortFilter'
export * from './helpers'
export * from './helpers.event'
export * from './maps'
export * from './localization'
export * from './dicom'
export * from './Base64'
// export * from './IndexedDd'

export const APP_CONTEXT = 'AppContext'

export const getPersistContextValue = async (key: string) => {
  let contextString: string | null = ''
  try {
    if (Platform.OS === 'web') {
      contextString = window.localStorage.getItem(key)
    }
    else contextString = await AsyncStorage.getItem(key)
    if (contextString) return JSON.parse(contextString)
  } catch (e) {
    console.log(e)
  }
  return {}
}

export const setPersistContextValue = async (key: string, value: Object) => {
  if (Object.keys(value).length > 0) {
    try {
      if (Platform.OS === 'web') {
        return window.localStorage.setItem(key, JSON.stringify(value))
      }
      return await AsyncStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      console.log(e)
    }
  }
}
