import React, { useEffect, useState } from 'react'
import {StyleSheet} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import {css, eventStatusColor} from '../styles'
import { ConsultationStatus, EventStatus, IClinic, IComment, IPatient, ISpecialist } from '../types'
import { ChatListItem } from './ChatListItem'
import { ChatListItemLast } from './ChatListItemLast'

type Props = {
  comments?: IComment[]
  user?: IPatient | ISpecialist
  clinicById: (clinicId?: string) => IClinic | undefined
  eventStatus?: EventStatus
}

export function ChatList ({comments = [], eventStatus = ConsultationStatus.pendingReview, clinicById, user}: Props) {
  const commentsWithoutLast = comments.length > 1 ? comments.slice(0, comments.length - 1) : []
  let chatListItems = commentsWithoutLast.map((c, index) => <ChatListItem comment={c} key={'chat_message_' + index} />)
  const lastComment = comments[comments.length - 1]
  const lastMessageColor = eventStatusColor[eventStatus]
  let scrollView: ScrollView | null = null
  const [clinic, setClinic] = useState<IClinic | undefined>()
  const styles = StyleSheet.create({
    chatList: {
      maxHeight: 400,
    },
    lastChatItem: {
      borderRadius: css.borderRadius,
      backgroundColor: css.colorDarkBgLighten2,
      padding: css.padding * 1.5,
      marginBottom: css.padding/2,
    },
  })

  useEffect(() => {
    scrollView?.scrollToEnd()
  }, [scrollView])

  useEffect(() => {
    const comment = comments.length > 0 ? comments?.[comments.length - 1] : undefined
    setClinic(clinicById(comment?.clinicId))
  }, [comments])

  return (
    <ScrollView style={styles.chatList} ref={ref => scrollView = ref as ScrollView}>
      {chatListItems}
      <ChatListItemLast clinic={clinic} user={user} comment={lastComment} backgroundColor={lastMessageColor} iconBackgroundColor={css.colorError} />
    </ScrollView>
  )
}
