import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, Platform} from 'react-native'
import { css } from '../styles'
import { SvgXml } from 'react-native-svg'
import LocalizationPointer from '../assets/picts/localization_pointer.svg'
import LocalizationHighlight from '../assets/picts/localization_body_highlight.svg'
import { localizationImage } from '../utils/localization'
import { BodyLocalization, Gender, localizationImageRatio, LocalizationPosition, LocalizationSize } from '../types'

type Props = {
  localization?: BodyLocalization
  gender?: Gender
}

export function LocalizationImage ({gender, localization}: Props) {
  const pointerWidth = 20
  const [bodyImageSize, setBodyImageSize] = useState<LocalizationSize>({w:0, h:0})
  const getScreenPosition = (position?: LocalizationPosition) => {
    return position ? {
      x: position.x * bodyImageSize.w - pointerWidth/2,
      y: position.y * bodyImageSize.h - pointerWidth/2,      
    } : {x: 0, y: 0}
  }
  const [pointerDevicePosition, setPointerDevicePosition] = useState({x:0, y:0}) 
  const bodySide = localization?.localizationSide ? localization.localizationSide : 'front'
  const imageRatio = gender && bodySide ? localizationImageRatio[gender][bodySide] : localizationImageRatio["male"]["front"]
  const [imageHeight, setImageHeight] = useState(0)
  const styles = StyleSheet.create({
    localizationBlock: {
      overflow: 'hidden',
      flexGrow: 1,
      width: 100,
      height: 100,
    },
    localizationContent: {
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
  })

  useEffect(() => {
    setPointerDevicePosition(getScreenPosition(localization?.localizationPosition))
  }, [localization, gender, bodyImageSize, imageHeight])

  return (
    <View style={[styles.localizationBlock]}>
      <View style={{position: 'absolute', width: '100%', height: '100%'}}>
        {Platform.OS === 'web' &&
          <img width="100" height="100%" style={{objectFit: 'cover'}} src={`${LocalizationHighlight}`} />
        }
        {Platform.OS !== 'web' &&
          // @ts-ignore
          <SvgXml width="100%" height="100%" preserveAspectRatio="none" xml={LocalizationHighlight} />
        }
      </View>
      <View
        style={styles.localizationContent}
        onLayout={(e) => {
          let {height} = e.nativeEvent.layout
          height = height - css.padding*2
          const h = height
          setBodyImageSize({h:h, w:h*imageRatio})
          setImageHeight(h)
        }}>
        <View style={{
          width: bodyImageSize.w,
          height: bodyImageSize.h,
          alignItems: 'center',
          justifyContent: 'center',
        }}
          >
            <Image
              style={{
                height: bodyImageSize.h,
                width: bodyImageSize.w,
                resizeMode: 'contain',
              }}
              source={
                Platform.OS === 'web' ?
                //@ts-ignore
                localizationImage[gender ? gender : 'male'][bodySide]!.default :
                localizationImage[gender ? gender : 'male'][bodySide]
              }
            />
            
            {localization &&
              <View pointerEvents='none' style={{position: 'absolute', left: pointerDevicePosition.x, top: pointerDevicePosition.y}}>
                {Platform.OS === 'web' &&
                  <img width={pointerWidth} height={pointerWidth} src={`${LocalizationPointer}`} />
                }
                {Platform.OS !== 'web' &&
                  // @ts-ignore
                  <SvgXml width={pointerWidth} height={pointerWidth} xml={LocalizationPointer} />
                }  
              </View>
            }
        </View>
      </View>
    </View>
  )
}
