import React, {ReactNode} from 'react'
import {StyleProp, StyleSheet, ViewStyle, View, GestureResponderEvent} from 'react-native'

type Props = {
  style?: StyleProp<ViewStyle>
  children: ReactNode | ReactNode[]
  pointerEvents?: "box-none" | "none" | "box-only" | "auto" | undefined
  onStartShouldSetResponder?: ((event: GestureResponderEvent) => boolean)
  onResponderRelease?: ((event: GestureResponderEvent) => void)
}

export function ViewRow ({style, children, pointerEvents, onStartShouldSetResponder, onResponderRelease}: Props) {
  return <View pointerEvents={pointerEvents} style={[styles.row, style]} onStartShouldSetResponder={onStartShouldSetResponder} onResponderRelease={onResponderRelease}>{children}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
